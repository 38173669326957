/* eslint-disable */
// @ts-nocheck
import React, { useState } from 'react'
import propTypes from 'prop-types'
import { AdminPageWrapper, PageHeader } from 'components'
import { TFilledPlusIcon } from 'components/Icons'
import { connect } from 'react-redux'
import ProjectSingleSendContractDocuments from 'components/ProjectSingleSendContractDocuments'
import CustomButton from 'components/CustomButton'
import useDocusealConfigModal from 'hooks/useDocusealConfigModal'
import DocusealConfigModel from '../docusealConfig/component/DocusealModelConfig'

const IS_ALLOW_TWO_BUYER_ROLE_FOR_SINGULAR_CONTRACT = [true, 'true'].includes(process.env.REACT_APP_IS_ALLOW_TWO_BUYER_ROLE_FOR_SINGULAR_CONTRACT)

const SingleSendContractDocuments = (props) => {
  const { appProject } = props
  const [isCreateSinglePageCampaign, setIsCreateSinglePageCampaign] = useState(false)
  const [isCreateSingularContract, setIsCreateSingularContract] = useState(false)
  const { loading, docusealData } = useDocusealConfigModal()
  const [isDocusealModalOpen, setIsDocusealModalOpen] = useState(false)

  const RightContent = () => (
    <div className='flex flex-row items-center gap-3'>
      {
        IS_ALLOW_TWO_BUYER_ROLE_FOR_SINGULAR_CONTRACT && (
          <CustomButton
            disabled={!appProject || loading}
            handleClick={!docusealData ? () => setIsDocusealModalOpen(true) : () => setIsCreateSingularContract(true)}
          >
            <span className='flex items-center gap-2 px-3.5 py-2'>
              <TFilledPlusIcon className='h-6 w-6' />
              Create single contract
            </span>
          </CustomButton>
        )
      }
      <CustomButton
        disabled={!appProject || loading}
        handleClick={!docusealData ? () => setIsDocusealModalOpen(true) : () => setIsCreateSinglePageCampaign(true)}
      >
        <span className='flex items-center gap-2 px-3.5 py-2'>
          <TFilledPlusIcon className='h-6 w-6' />
          Create single page campaigns
        </span>
      </CustomButton>
    </div>
  )

  return (
    <>
      <PageHeader
        title='Single Send Contract Documents'
        rightContent={<RightContent />}
        className='!h-[64px]'
      />
      <AdminPageWrapper style={{ height: 'calc(100vh - 100px)' }} fullscreen>
        <ProjectSingleSendContractDocuments
          isCreateSinglePageCampaign={isCreateSinglePageCampaign}
          setIsCreateSinglePageCampaign={setIsCreateSinglePageCampaign}
          isCreateSingularContract={isCreateSingularContract}
          setIsCreateSingularContract={setIsCreateSingularContract}
        />
      </AdminPageWrapper>
      <DocusealConfigModel open={isDocusealModalOpen} close={() => setIsDocusealModalOpen(false)} />
    </>
  )
}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SingleSendContractDocuments)

SingleSendContractDocuments.propTypes = {
  appProject: propTypes.string
}
