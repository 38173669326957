// @ts-nocheck
/* eslint-disable */
import React, { useEffect } from 'react'
import { Building, DollarSign, Percent, Home, MoreHorizontal, Users, ArrowUp, ArrowDown, Phone, Mail, Clock, Calendar, Star } from 'lucide-react'
import KPICard from './KPICard'
import { LineChart, DonutChart, BarChart, PieChart, AreaChart, MockDataOverlay } from './charts'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ShadcnComponents/card'
import { Button } from 'components/ShadcnComponents/button'

const ProjectsTab = ({ selectedPeriod, userObject, customStartDate, customEndDate }) => {
    // Format date for display
    const formatDate = (dateString) => {
        if (!dateString) return '';

        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };

    // Get period label for KPI cards
    const getPeriodLabel = () => {
        switch (selectedPeriod) {
            case 'today':
                return 'Today';
            case '7d':
                return 'Last 7 Days';
            case '30d':
                return 'Last 30 Days';
            case 'ytd':
                return 'Year to Date';
            case 'all':
                return 'All Time';
            case 'custom':
                return `${formatDate(customStartDate)} - ${formatDate(customEndDate)}`;
            default:
                return '';
        }
    };

    useEffect(() => {
        // Call APIs with period and custom date range parameters if needed
        // Add implementation here that passes customStartDate and customEndDate when selectedPeriod is 'custom'
        // ... existing code ...
    }, [selectedPeriod, customStartDate, customEndDate]);

    return (
        <div className="flex flex-col gap-4">
            {/* Period indicator */}
            {selectedPeriod === 'custom' && (
                <div className="bg-blue-50 p-3 rounded-lg mb-2">
                    <p className="text-sm text-blue-600">
                        <span className="font-medium">Custom Period:</span> {formatDate(customStartDate)} - {formatDate(customEndDate)}
                    </p>
                </div>
            )}

            {/* Top Row - Key Metrics */}
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
                <KPICard
                    title="Total Leads"
                    value="534"
                    icon={Users}
                    trend={{ value: 12.5, text: `12.5% in ${getPeriodLabel()}`, isPositive: true }}
                />
                <KPICard
                    title='Total Units Sold'
                    value='145'
                    icon={Building}
                    trend={{ value: 8.2, text: `8.2% in ${getPeriodLabel()}`, isPositive: true }}
                />
                <KPICard
                    title='Revenue to Date'
                    value='$12,500,000'
                    icon={DollarSign}
                    trend={{
                        value: 5,
                        text: `for ${getPeriodLabel()}`,
                        isPositive: true
                    }}
                />
                <KPICard
                    title='Sales Conversion Rate'
                    value='25%'
                    icon={Percent}
                    trend={{
                        value: 3,
                        text: `for ${getPeriodLabel()}`,
                        isPositive: true
                    }}
                />
                <KPICard
                    title='Average Price'
                    value='$840,000'
                    icon={DollarSign}
                    trend={{ value: 3.1, text: `3.1% in ${getPeriodLabel()}`, isPositive: true }}
                />
                <KPICard
                    title='Selling Rate'
                    value='12.4'
                    icon={Percent}
                    trend={{ value: -2.3, text: `2.3% in ${getPeriodLabel()}`, isPositive: false }}
                />
                <KPICard
                    title='Website Visits'
                    value='8,542'
                    icon={Users}
                    trend={{ value: 15.6, text: `15.6% in ${getPeriodLabel()}`, isPositive: true }}
                />
                <KPICard
                    title='Average Price per Unit'
                    value='$850,000'
                    icon={Home}
                    trend={{
                        value: 2,
                        text: `for ${getPeriodLabel()}`,
                        isPositive: false
                    }}
                />
            </div>
            {/* Second Row - Charts */}
            <div className='grid gap-3 md:grid-cols-3'>
                <div className='bg-white p-4 rounded-lg shadow-sm'>
                    <div className='flex items-center justify-between mb-4'>
                        <div>
                            <h3 className='text-lg font-medium'>Sales Over Time</h3>
                            <p className='text-sm text-gray-500'>Units sold per week</p>
                        </div>
                        <button className='p-2 hover:bg-gray-100 rounded-full'>
                            <MoreHorizontal className='h-4 w-4' />
                        </button>
                    </div>

                    <MockDataOverlay position="top" >
                        <LineChart
                            className='h-64'
                            data={[
                                { week: 'Week 1', sales: 5 },
                                { week: 'Week 2', sales: 8 },
                                { week: 'Week 3', sales: 12 },
                                { week: 'Week 4', sales: 10 },
                                { week: 'Week 5', sales: 15 },
                                { week: 'Week 6', sales: 18 }
                            ]}
                            categories={['sales']}
                            index='week'
                            colors={['#3b82f6']}
                            valueFormatter={(value) => `${value} units`}
                            showLegend={false}
                        /></MockDataOverlay>
                </div>
                <div className='bg-white p-4 rounded-lg shadow-sm'>
                    <div className='flex items-center justify-between mb-4'>
                        <div>
                            <h3 className='text-lg font-medium'>Unit Type Popularity</h3>
                            <p className='text-sm text-gray-500'>Distribution by unit type</p>
                        </div>
                        <button className='p-2 hover:bg-gray-100 rounded-full'>
                            <MoreHorizontal className='h-4 w-4' />
                        </button>
                    </div>
                    <MockDataOverlay position="top" >
                        <PieChart
                            className='h-64'
                            data={[
                                { type: '1 Bedroom', value: 35 },
                                { type: '2 Bedroom', value: 45 },
                                { type: '3 Bedroom', value: 15 },
                                { type: 'Penthouse', value: 5 }
                            ]}
                            category='value'
                            index='type'
                            colors={['#3b82f6', '#06b6d4', '#4f46e5', '#7c3aed']}
                            valueFormatter={(value) => `${value}%`}
                        />
                    </MockDataOverlay>
                </div>
                <div className='bg-white p-4 rounded-lg shadow-sm'>
                    <div className='flex items-center justify-between mb-4'>
                        <div>
                            <h3 className='text-lg font-medium'>Monthly Revenue</h3>
                            <p className='text-sm text-gray-500'>Revenue trend by month</p>
                        </div>
                        <button className='p-2 hover:bg-gray-100 rounded-full'>
                            <MoreHorizontal className='h-4 w-4' />
                        </button>
                    </div>
                    <MockDataOverlay position="top" s>
                        <LineChart
                            className='h-64'
                            data={[
                                { month: 'Jan', revenue: 850000 },
                                { month: 'Feb', revenue: 950000 },
                                { month: 'Mar', revenue: 1250000 },
                                { month: 'Apr', revenue: 1100000 },
                                { month: 'May', revenue: 1500000 },
                                { month: 'Jun', revenue: 1800000 }
                            ]}
                            categories={['revenue']}
                            index='month'
                            colors={['#10b981']}
                            valueFormatter={(value) => `$${(value / 1000).toFixed(0)}k`}
                            showLegend={false}
                            showXAxis
                            showYAxis
                            showGridLines
                        />
                    </MockDataOverlay>
                </div>
            </div>
            {/* Third Row - Realtor Performance and Buyer Journey */}
            <div className='grid gap-3 md:grid-cols-2'>
                <div className='bg-white p-4 rounded-lg shadow-sm'>
                    <div className='flex items-center justify-between mb-4'>
                        <div>
                            <h3 className='text-lg font-medium'>Realtor Performance</h3>
                            <p className='text-sm text-gray-500'>Top performing realtors</p>
                        </div>
                        <button className='p-2 hover:bg-gray-100 rounded-full'>
                            <MoreHorizontal className='h-4 w-4' />
                        </button>
                    </div>
                    <MockDataOverlay position="top" >
                        <BarChart
                            className='h-64'
                            data={[
                                { name: 'John D.', sales: 12 },
                                { name: 'Sarah M.', sales: 10 },
                                { name: 'Mike R.', sales: 8 },
                                { name: 'Lisa K.', sales: 7 },
                                { name: 'Tom B.', sales: 6 }
                            ]}
                            index='name'
                            categories={['sales']}
                            colors={['#4f46e5']}
                            valueFormatter={(value) => `${value} units`}
                            layout='vertical'
                            showLegend={false}
                            showXAxis
                            showYAxis
                        />
                    </MockDataOverlay>
                </div>

                <div className='bg-white p-4 rounded-lg shadow-sm'>
                    <div className='flex items-center justify-between mb-4'>
                        <div>
                            <h3 className='text-lg font-medium'>Buyer Journey</h3>
                            <p className='text-sm text-gray-500'>Conversion through sales stages</p>
                        </div>
                        <button className='p-2 hover:bg-gray-100 rounded-full'>
                            <MoreHorizontal className='h-4 w-4' />
                        </button>
                    </div>
                    <MockDataOverlay position="top" >
                        <AreaChart
                            className='h-64'
                            data={[
                                { stage: 'Prospects', count: 500 },
                                { stage: 'Tours', count: 280 },
                                { stage: 'Offers', count: 140 },
                                { stage: 'Contracts', count: 100 },
                                { stage: 'Closed', count: 85 }
                            ]}
                            index='stage'
                            categories={['count']}
                            colors={['#3b82f6']}
                            valueFormatter={(value) => `${value}`}
                            showLegend={false}
                            showXAxis
                            showYAxis
                        />
                        <div className='mt-4 grid grid-cols-5 gap-2 text-center text-xs'>
                            <div>
                                <div className='font-medium'>Prospects</div>
                                <div className='text-gray-800'>500</div>
                            </div>
                            <div>
                                <div className='font-medium'>Tours</div>
                                <div className='text-gray-800'>280</div>
                                <div className='text-xs text-gray-400'>56%</div>
                            </div>
                            <div>
                                <div className='font-medium'>Offers</div>
                                <div className='text-gray-800'>140</div>
                                <div className='text-xs text-gray-400'>50%</div>
                            </div>
                            <div>
                                <div className='font-medium'>Contracts</div>
                                <div className='text-gray-800'>100</div>
                                <div className='text-xs text-gray-400'>71%</div>
                            </div>
                            <div>
                                <div className='font-medium'>Closed</div>
                                <div className='text-gray-800'>85</div>
                                <div className='text-xs text-gray-400'>85%</div>
                            </div>
                        </div></MockDataOverlay>
                </div>
            </div>

            {/* Fourth Row - Marketing ROI */}
            <div className='grid gap-3 md:grid-cols-1'>
                <div className='bg-white p-4 rounded-lg shadow-sm'>
                    <div className='flex items-center justify-between mb-4'>
                        <div>
                            <h3 className='text-lg font-medium'>Marketing ROI</h3>
                            <p className='text-sm text-gray-500'>Budget vs. closings by channel</p>
                        </div>
                        <button className='p-2 hover:bg-gray-100 rounded-full'>
                            <MoreHorizontal className='h-4 w-4' />
                        </button>
                    </div>
                    <MockDataOverlay position="top" >
                        <BarChart
                            className='h-64'
                            data={[
                                { channel: 'Social', budget: 200000, closings: 50 },
                                { channel: 'Realtors', budget: 150000, closings: 30 },
                                { channel: 'Billboards', budget: 100000, closings: 5 }
                            ]}
                            index='channel'
                            categories={['budget', 'closings']}
                            colors={['#3b82f6', '#10b981']}
                            valueFormatter={(value, category) => (category === 'budget' ? `$${value / 1000}k` : `${value}`)}
                            layout='vertical'
                            showLegend
                            showXAxis
                            showYAxis
                        />
                    </MockDataOverlay>
                </div>
            </div>

            {/* Project Status Overview */}
            <div className="grid gap-3 md:grid-cols-3">
                <Card className="overflow-hidden md:col-span-2 bg-white">
                    <CardHeader className="flex flex-row items-center justify-between py-2">
                        <div>
                            <CardTitle className="text-sm">Project Status Overview</CardTitle>
                            <CardDescription className="text-xs">Sales progress by project</CardDescription>
                        </div>
                        <Button variant="ghost" size="icon" className="h-8 w-8">
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </CardHeader>
                    <CardContent className="p-4">
                        <MockDataOverlay position="top">
                            <div className="space-y-6">
                                <div>
                                    <div className="flex items-center justify-between mb-1">
                                        <div className="flex items-center">
                                            <span className="w-3 h-3 rounded-full bg-blue-500 mr-2"></span>
                                            <span className="text-sm font-medium">Ashleigh Court</span>
                                        </div>
                                        <div className="flex items-center gap-4">
                                            <span className="text-xs text-[#AAAAAA]">120 units</span>
                                            <span className="text-sm">85 sold (71%)</span>
                                        </div>
                                    </div>
                                    <div className="w-full bg-[#E4E4E7] rounded-full h-2">
                                        <div className="bg-blue-500 h-2 rounded-full" style={{ width: "71%" }}></div>
                                    </div>
                                    <div className="flex justify-between mt-1 text-xs text-[#AAAAAA]">
                                        <span>Construction: 65%</span>
                                        <span>Completion: Q3 2025</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="flex items-center justify-between mb-1">
                                        <div className="flex items-center">
                                            <span className="w-3 h-3 rounded-full bg-cyan-500 mr-2"></span>
                                            <span className="text-sm font-medium">Olia Tower</span>
                                        </div>
                                        <div className="flex items-center gap-4">
                                            <span className="text-xs text-[#AAAAAA]">180 units</span>
                                            <span className="text-sm">95 sold (53%)</span>
                                        </div>
                                    </div>
                                    <div className="w-full bg-[#E4E4E7] rounded-full h-2">
                                        <div className="bg-cyan-500 h-2 rounded-full" style={{ width: "53%" }}></div>
                                    </div>
                                    <div className="flex justify-between mt-1 text-xs text-[#AAAAAA]">
                                        <span>Construction: 40%</span>
                                        <span>Completion: Q1 2026</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="flex items-center justify-between mb-1">
                                        <div className="flex items-center">
                                            <span className="w-3 h-3 rounded-full bg-indigo-500 mr-2"></span>
                                            <span className="text-sm font-medium">Peterson Heights</span>
                                        </div>
                                        <div className="flex items-center gap-4">
                                            <span className="text-xs text-[#AAAAAA]">128 units</span>
                                            <span className="text-sm">35 sold (27%)</span>
                                        </div>
                                    </div>
                                    <div className="w-full bg-[#E4E4E7] rounded-full h-2">
                                        <div className="bg-indigo-500 h-2 rounded-full" style={{ width: "27%" }}></div>
                                    </div>
                                    <div className="flex justify-between mt-1 text-xs text-[#AAAAAA]">
                                        <span>Construction: 15%</span>
                                        <span>Completion: Q4 2026</span>
                                    </div>
                                </div>
                            </div>
                        </MockDataOverlay >
                    </CardContent>
                </Card>
                <Card className="overflow-hidden bg-white relative">
                    <CardHeader className="flex flex-row items-center justify-between py-2">
                        <div>
                            <CardTitle className="text-sm">Sales by Project</CardTitle>
                            <CardDescription className="text-xs">Distribution of total sales</CardDescription>
                        </div>
                        <Button variant="ghost" size="icon" className="h-8 w-8">
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </CardHeader>
                    <CardContent className="pt-0">
                        <MockDataOverlay position="top" >
                            <DonutChart
                                className="h-48"
                                data={[
                                    { project: "Ashleigh Court", value: 85 },
                                    { project: "Olia Tower", value: 95 },
                                    { project: "Peterson Heights", value: 35 },
                                ]}
                                category="value"
                                index="project"
                                colors={["#3b82f6", "#06b6d4", "#4f46e5"]}
                                valueFormatter={(value) => `${value} units`}
                                showAnimation
                            />
                            <div className="px-4 pb-4 text-center text-xs">
                                <div className="font-medium">Total Sales: 215 units</div>
                                <div className="text-[#AAAAAA] mt-1">50.2% of total inventory</div>
                            </div>
                        </MockDataOverlay>
                    </CardContent>
                </Card>
            </div>

            {/* Sales Trends and Unit Types */}
            <div className="grid gap-3 md:grid-cols-2">
                <Card className="overflow-hidden bg-white">
                    <CardHeader className="flex flex-row items-center justify-between py-2">
                        <div>
                            <CardTitle className="text-sm">Sales Trend by Project</CardTitle>
                            <CardDescription className="text-xs">Monthly sales over the past year</CardDescription>
                        </div>
                        <Button variant="ghost" size="icon" className="h-8 w-8">
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </CardHeader>
                    <CardContent className="p-4">
                        <MockDataOverlay position="top" >
                            <LineChart
                                className="h-64"
                                data={[
                                    { month: "Jan", ashleigh: 5, olia: 8, peterson: 0 },
                                    { month: "Feb", ashleigh: 7, olia: 6, peterson: 0 },
                                    { month: "Mar", ashleigh: 8, olia: 9, peterson: 0 },
                                    { month: "Apr", ashleigh: 12, olia: 10, peterson: 0 },
                                    { month: "May", ashleigh: 10, olia: 8, peterson: 0 },
                                    { month: "Jun", ashleigh: 9, olia: 12, peterson: 0 },
                                    { month: "Jul", ashleigh: 11, olia: 15, peterson: 0 },
                                    { month: "Aug", ashleigh: 8, olia: 10, peterson: 3 },
                                    { month: "Sep", ashleigh: 6, olia: 8, peterson: 5 },
                                    { month: "Oct", ashleigh: 5, olia: 7, peterson: 8 },
                                    { month: "Nov", ashleigh: 4, olia: 6, peterson: 10 },
                                    { month: "Dec", ashleigh: 0, olia: 6, peterson: 9 },
                                ]}
                                categories={["ashleigh", "olia", "peterson"]}
                                index="month"
                                colors={["#3b82f6", "#06b6d4", "#4f46e5"]}
                                valueFormatter={(value) => `${value} units`}
                                showLegend={true}
                                showXAxis
                                showYAxis
                                showGridLines
                            />
                        </MockDataOverlay>
                    </CardContent>
                </Card>
                <Card className="overflow-hidden bg-white">
                    <CardHeader className="flex flex-row items-center justify-between py-2">
                        <div>
                            <CardTitle className="text-sm">Unit Types by Project</CardTitle>
                            <CardDescription className="text-xs">Distribution of unit types</CardDescription>
                        </div>
                        <Button variant="ghost" size="icon" className="h-8 w-8">
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </CardHeader>
                    <CardContent className="p-4">
                        <MockDataOverlay position="top" >
                            <BarChart
                                className="h-64"
                                data={[
                                    { type: "Studio", ashleigh: 20, olia: 40, peterson: 25 },
                                    { type: "1 Bedroom", ashleigh: 45, olia: 60, peterson: 42 },
                                    { type: "2 Bedroom", ashleigh: 35, olia: 50, peterson: 38 },
                                    { type: "3 Bedroom", ashleigh: 20, olia: 30, peterson: 23 },
                                ]}
                                index="type"
                                categories={["ashleigh", "olia", "peterson"]}
                                colors={["#3b82f6", "#06b6d4", "#4f46e5"]}
                                valueFormatter={(value) => `${value}`}
                                showLegend={true}
                                showXAxis
                                showYAxis
                            />
                        </MockDataOverlay>
                    </CardContent>
                </Card>
            </div>

            {/* Project Timeline */}
            <Card className="overflow-hidden bg-white relative">
                <CardHeader className="flex flex-row items-center justify-between py-2">
                    <div>
                        <CardTitle className="text-sm">Project Timeline</CardTitle>
                        <CardDescription className="text-xs">Key milestones and construction phases</CardDescription>
                    </div>
                    <Button variant="ghost" size="icon" className="h-8 w-8">
                        <MoreHorizontal className="h-4 w-4" />
                    </Button>
                </CardHeader>
                <CardContent className="p-4">
                    <MockDataOverlay position="top" >
                        <div className="space-y-8">
                            <div>
                                <div className="flex items-center justify-between mb-2">
                                    <div className="flex items-center">
                                        <span className="w-3 h-3 rounded-full bg-blue-500 mr-2"></span>
                                        <span className="font-medium">Ashleigh Court</span>
                                    </div>
                                    <div className="text-sm text-[#AAAAAA]">Q1 2024 - Q3 2025</div>
                                </div>
                                <div className="relative">
                                    <div className="absolute top-1/2 left-0 right-0 h-1 -translate-y-1/2 bg-[#E4E4E7]"></div>
                                    <div className="absolute top-1/2 left-0 w-[65%] h-1 -translate-y-1/2 bg-blue-500 rounded-full"></div>
                                    <div className="relative flex justify-between">
                                        <div className="flex flex-col items-center">
                                            <div className="w-4 h-4 rounded-full bg-blue-500 border-2 border-white z-10"></div>
                                            <span className="text-xs mt-2">Groundbreaking</span>
                                            <span className="text-xs text-[#AAAAAA]">Q1 2024</span>
                                        </div>
                                        <div className="flex flex-col items-center">
                                            <div className="w-4 h-4 rounded-full bg-blue-500 border-2 border-white z-10"></div>
                                            <span className="text-xs mt-2">Foundation</span>
                                            <span className="text-xs text-[#AAAAAA]">Q2 2024</span>
                                        </div>
                                        <div className="flex flex-col items-center">
                                            <div className="w-4 h-4 rounded-full bg-blue-500 border-2 border-white z-10"></div>
                                            <span className="text-xs mt-2">Structure</span>
                                            <span className="text-xs text-[#AAAAAA]">Q3 2024</span>
                                        </div>
                                        <div className="flex flex-col items-center">
                                            <div className="w-4 h-4 rounded-full bg-[#E4E4E7] border-2 border-white z-10"></div>
                                            <span className="text-xs mt-2">Interior</span>
                                            <span className="text-xs text-[#AAAAAA]">Q1 2025</span>
                                        </div>
                                        <div className="flex flex-col items-center">
                                            <div className="w-4 h-4 rounded-full bg-[#E4E4E7] border-2 border-white z-10"></div>
                                            <span className="text-xs mt-2">Completion</span>
                                            <span className="text-xs text-[#AAAAAA]">Q3 2025</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </MockDataOverlay>
                </CardContent>
            </Card>

            {/* Detailed Project Information */}
            <div className="mt-6">
                <h2 className="text-xl font-bold mb-3">Detailed Project Information</h2>
                <Card className="bg-white relative">
                    <CardHeader>
                        <CardTitle>Project Specifications</CardTitle>
                        <CardDescription>Key details for each development project</CardDescription>
                    </CardHeader>
                    <CardContent className="pt-0">
                        <MockDataOverlay position="top" >
                            <div className="overflow-x-auto">
                                <table className="w-full">
                                    <thead>
                                        <tr className="border-b">
                                            <th className="text-left py-2 px-4">Project</th>
                                            <th className="text-center py-2 px-4">Location</th>
                                            <th className="text-center py-2 px-4">Total Units</th>
                                            <th className="text-center py-2 px-4">Units Sold</th>
                                            <th className="text-center py-2 px-4">Price Range</th>
                                            <th className="text-center py-2 px-4">Avg. Price/sqft</th>
                                            <th className="text-center py-2 px-4">Construction</th>
                                            <th className="text-center py-2 px-4">Completion</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="border-b">
                                            <td className="py-2 px-4">Ashleigh Court</td>
                                            <td className="text-center py-2 px-4">Downtown</td>
                                            <td className="text-center py-2 px-4">120</td>
                                            <td className="text-center py-2 px-4">85 (71%)</td>
                                            <td className="text-center py-2 px-4">$650K - $1.2M</td>
                                            <td className="text-center py-2 px-4">$875</td>
                                            <td className="text-center py-2 px-4">65%</td>
                                            <td className="text-center py-2 px-4">Q3 2025</td>
                                        </tr>
                                        <tr className="border-b">
                                            <td className="py-2 px-4">Olia Tower</td>
                                            <td className="text-center py-2 px-4">Waterfront</td>
                                            <td className="text-center py-2 px-4">180</td>
                                            <td className="text-center py-2 px-4">95 (53%)</td>
                                            <td className="text-center py-2 px-4">$750K - $1.8M</td>
                                            <td className="text-center py-2 px-4">$950</td>
                                            <td className="text-center py-2 px-4">40%</td>
                                            <td className="text-center py-2 px-4">Q1 2026</td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 px-4">Peterson Heights</td>
                                            <td className="text-center py-2 px-4">Burnaby</td>
                                            <td className="text-center py-2 px-4">128</td>
                                            <td className="text-center py-2 px-4">35 (27%)</td>
                                            <td className="text-center py-2 px-4">$550K - $1.1M</td>
                                            <td className="text-center py-2 px-4">$780</td>
                                            <td className="text-center py-2 px-4">15%</td>
                                            <td className="text-center py-2 px-4">Q4 2026</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </MockDataOverlay>
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}

export default ProjectsTab 