/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { getCorporatDemandNotes, getDemandNotesById, migrateDemandNotes } from 'store/actions/demandNotes'
import { Drawer } from 'ui'

export default function MigrateDemandNotes(props) {
    const [visible, setVisible] = useState(false)
    const [demandNoteId, setDemandNoteId] = useState(props.demandNoteId)
    const [loading, setLoading] = useState(true)
    const [saving, setSaving] = useState(false)

    const [loadingDemandNotes, setLoadingDemandNotes] = useState(true)
    const [demandNote, setDemandNote] = useState(null)

    const [loadingCorporateDemandNotes, setLoadingCorporateDemandNotes] = useState(true)
    const [corporateDemandNotes, setCorporateDemandNotes] = useState([])

    const [mapping, setMapping] = useState({})

    const [selectedCorporateDemandNoteId, setSelectedCorporateDemandNoteId] = useState(null)

    const selectedCorporateDemandNote = corporateDemandNotes.find((item) => item.id === selectedCorporateDemandNoteId)

    useEffect(() => {
        if (!visible) return
        setLoadingCorporateDemandNotes(true)
        getCorporatDemandNotes().then((response) => {
            setCorporateDemandNotes(response)
        }).finally(() => {
            setLoadingCorporateDemandNotes(false)
        })
    }, [visible])

    useEffect(() => {
        if (!visible) return
        setLoadingDemandNotes(true)
        getDemandNotesById(demandNoteId).then((response) => {
            setDemandNote(response)
            const mapping = {}
            response.options.forEach((item) => {
                mapping[item.value] = ''
            })
            setMapping(mapping)
        }).finally(() => {
            setLoadingDemandNotes(false)
        })
    }, [visible])

    function onClose() {
        setVisible(false)
    }

    function saveAndMigrateDemandNotes() {
        const totalUsers = props.totalUsers
        const confirm = window.confirm(`Are you sure you want to migrate for ${totalUsers} users?`)
        if (!confirm) return
        setSaving(true)
        migrateDemandNotes(props.demandNoteId, selectedCorporateDemandNote.id, mapping)
            .then(() => {
                alert('Demand Notes migrated successfully')
            }).finally(() => {
                setVisible(false)
                setSaving(false)
            })
    }

    const allMappingSelected = Object.values(mapping).every((item) => item !== '')
    return (
        <div>
            <button
                disabled={saving}
                onClick={() => setVisible(true)}
                className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
            >
                {saving ? 'Saving...' : 'Migrate Demand Notes'}
            </button>
            <Drawer
                width={'50%'}
                bodyStyle={{
                    padding: '15px',
                    margin: '10px'
                }}
                title={'Migrate Demand Notes'}
                placement='right'
                size='large'
                closable={true}
                onClose={onClose}
                visible={visible}
            >
                {loadingDemandNotes && <p>Loading...</p>}
                {!loadingDemandNotes && demandNote && (
                    <div>
                        <h1 className='text-lg font-bold'>{demandNote?.label}</h1>
                    </div>
                )}
                {loadingCorporateDemandNotes && <p>Loading...</p>}
                {!loadingCorporateDemandNotes && corporateDemandNotes && (
                    <div className='my-4'>
                        <div>
                            Select Corporate Demand Note to migrate to:
                        </div>
                        <select className='w-full'
                            onChange={(e) => {
                                setSelectedCorporateDemandNoteId(e.target.value)
                                const mapping = {}
                                demandNote.options.forEach((item) => {
                                    mapping[item.value] = ''
                                })
                                setMapping(mapping)
                            }}
                        >
                            <option value=''>Select Corporate Demand Note</option>
                            {corporateDemandNotes.map((item) => (
                                <option key={item.id} value={item.id}>{item.label}</option>
                            ))}
                        </select>
                    </div>
                )}
                {/* show mapping to values from demandNotes to corporate demand notes */}
                {selectedCorporateDemandNote && (
                    <div className='my-4'>
                        <div className='font-bold'>
                            Mapping:
                        </div>
                        <div className='flex flex-col gap-y-2 w-full divide-y'>
                            {demandNote.options.map((item) => (
                                <div key={item.value} className='flex items-center justify-between gap-y-2 pt-2 pb-0.5'>
                                    <div>
                                        {item.label}
                                    </div>
                                    <select
                                        className='w-full flex-1 max-w-[300px]'
                                        onChange={(e) => {
                                            setMapping({
                                                ...mapping,
                                                [item.value]: e.target.value
                                            })
                                        }}
                                    >
                                        <option value=''>Select Field</option>
                                        {selectedCorporateDemandNote.options.map((options) => {
                                            return <option key={options.id} value={options.id}>{options.label}</option>
                                        })}
                                    </select>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {mapping && allMappingSelected && (
                    <div>
                        <pre className='text-sm text-gray-400'>
                            {JSON.stringify(mapping, null, 2)}
                        </pre>
                        <button
                            disabled={saving || !allMappingSelected}
                            onClick={() => {
                                saveAndMigrateDemandNotes()
                            }}
                            className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
                        >
                            {saving ? 'Saving...' : 'Migrate Demand Notes'}
                        </button>
                    </div>
                )}
            </Drawer>
        </div>
    )
}
