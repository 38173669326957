import React, { useCallback } from 'react'
import ReactDOM from 'react-dom'
import propTypes from 'prop-types'
import { CheckCircleIcon, ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/solid'
import { Transition } from '@headlessui/react'
import { TCloseCircleIcon } from 'components'
import { classNames } from 'utils'
import CustomButton from 'components/CustomButton'

const modalTypes = {
  success: {
    icon: () => <CheckCircleIcon className='h-12 w-12 text-green-600' />,
    buttonBackgroundColor: 'bg-green-600',
    buttonBackgroundHoverColor: 'bg-green-700',
    ringColor: 'ring-green-500'
  },
  alert: {
    icon: () => <ExclamationCircleIcon className='h-12 w-12 text-yellow-600' />,
    buttonBackgroundColor: 'bg-yellow-600',
    buttonBackgroundHoverColor: 'bg-reyellowd-700',
    ringColor: 'ring-yellow-500'
  },
  danger: {
    icon: () => <XCircleIcon className='h-12 w-12 text-red-500' />,
    buttonBackgroundColor: 'bg-red-500',
    buttonBackgroundHoverColor: 'bg-red-600',
    ringColor: 'ring-red-400'
  },
  custom: {
    icon: () => <TCloseCircleIcon circleBackground='bg-red-100' className='h-6 w-6 text-red-600' />,
    buttonBackgroundColor: 'bg-indigo-600',
    buttonBackgroundHoverColor: 'bg-indigo-700',
    ringColor: 'ring-indigo-500'
  }
}

let modalRoot = document.getElementById('modal')

if (!modalRoot) {
  modalRoot = document.createElement('div')
  modalRoot.setAttribute('id', 'modal')
  modalRoot.className = 'fixed z-9999'
  document.body.appendChild(modalRoot)
} else {
  modalRoot.style = { zIndex: 9999 }
}

const ButtonsWrappers = ({ children }) => (
  <div className='bg-white space-x-4 flex flex-row justify-center px-4 pb-4 h-2/20 items-center'>
    {children}
  </div>
)

const Modal = ({
  showModal, height, children,
  onCancel, canDismiss, modalType,
  renderModalIcon, title, subtitle,
  onOk, cancelText, okText,
  okLoading, extraButtons, fullHeight,
  showPaddingHorizontal,
  fullWidth, cancelButtonClassname, waitAnimationToShow
}) => {
  const ModalIcon = modalTypes[modalType].icon

  const renderModalMessage = useCallback(() => (
    <>
      {
        renderModalIcon && (
          <ModalIcon />
        )
      }
      <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
        {
          title && (
            <h3 className='text-lg leading-6 font-medium text-gray-900' id='modal-headline'>
              {title}
            </h3>
          )
        }
        {subtitle && (
          <div className='mt-2'>
            <p className='text-sm text-gray-500'>
              {subtitle}
            </p>
          </div>
        )}
      </div>
    </>
  ), [renderModalIcon, subtitle, title])

  const renderSimpleModal = useCallback(() => children, [children])

  return ReactDOM.createPortal(
    <Transition show={showModal} className='h-full fixed z-9999 inset-0 overflow-y-auto w-full md:w-auto flex items-center justify-center'>
      <div className={classNames(`${fullHeight && 'h-full'} flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 w-full ${!fullWidth && 'md:w-auto'}`)}>
        <Transition.Child
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
          className='fixed inset-0 transition-opacity w-full md:w-auto'
          aria-hidden='true'
        >
          <div
            onClick={canDismiss && onCancel}
            className='fixed inset-0 transition-opacity'
            aria-hidden='true'
          >
            <div className='absolute inset-0 bg-gray-500 opacity-75' />
          </div>
        </Transition.Child>

        <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>&#8203;</span>
        <Transition.Child
          enter='ease-out duration-300'
          enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          enterTo='opacity-100 translate-y-0 sm:scale-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100 translate-y-0 sm:scale-100'
          leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          className='h-full inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden
          transform transition-all sm:align-middle w-full sm:p-6'
          role='dialog'
          id='firstDiv'
        >
          <div
            id='secondDiv'
            className='h-full inline-block align-bottom bg-white rounded-lg text-left overflow-hidden
            shadow-xl transform transition-all sm:align-middle w-full'
            role='dialog'
            aria-modal='true'
            aria-labelledby='modal-headline'
          >
            <div className={`h-18/20 bg-white overflow-auto pt-5 pb-4 ${showPaddingHorizontal && 'px-4'}`}>
              <div className='h-full flex flex-col items-center justify-center'>
                {
                  modalType !== 'custom'
                    ? renderModalMessage()
                    : renderSimpleModal()
                }
              </div>
            </div>

            <ButtonsWrappers>
              {extraButtons}

              {onCancel && (
                <CustomButton
                  variant='outline'
                  disabled={okLoading}
                  handleClick={onCancel}
                >
                  <span className='font-medium px-4 py-2 text-base flex gap-2 items-center'>
                    {cancelText}
                  </span>
                </CustomButton>
              )}

              {onOk && (
                <CustomButton
                  disabled={okLoading}
                  handleClick={onOk}
                >
                  <span className='font-medium px-4 py-2 text-base flex gap-2 items-center'>
                    {okText}
                  </span>
                </CustomButton>
              )}
            </ButtonsWrappers>

          </div>
        </Transition.Child>
      </div>
    </Transition>,
    modalRoot
  )
}

export default Modal

Modal.defaultProps = {
  canDismiss: true,
  modalType: 'danger',
  renderModalIcon: true,
  title: '',
  subtitle: '',
  cancelText: 'Cancel',
  okText: 'Yes',
  okLoading: false,
  extraButtons: null,
  fullHeight: false,
  fullWidth: false,
  showPaddingHorizontal: true,
  cancelButtonClassname: '',
  waitAnimationToShow: false
}

Modal.propTypes = {
  showModal: propTypes.bool,
  height: propTypes.string,
  children: propTypes.node,
  onCancel: propTypes.func,
  canDismiss: propTypes.bool,
  modalType: propTypes.oneOf(['success', 'alert', 'danger', 'custom']),
  renderModalIcon: propTypes.bool,
  title: propTypes.string,
  subtitle: propTypes.string,
  onOk: propTypes.func,
  cancelText: propTypes.string,
  okText: propTypes.string,
  okLoading: propTypes.bool,
  extraButtons: propTypes.node,
  fullHeight: propTypes.bool,
  fullWidth: propTypes.bool,
  showPaddingHorizontal: propTypes.bool,
  cancelButtonClassname: propTypes.string,
  waitAnimationToShow: propTypes.bool
}

ButtonsWrappers.propTypes = {
  children: propTypes.node
}
