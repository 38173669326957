/* eslint-disable */
// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { getEventPublic } from 'store/actions/eventsActions'
import { useParams } from 'react-router-dom'
import { Badge } from 'components/ShadcnComponents/badge'
import useSearchParams from 'hooks/useSearchParams'
import CustomButton from 'components/CustomButton'
import { RotateCcw, XCircle } from "lucide-react"

const EventPublicForm = () => {
  const { eventId } = useParams()
  const searchParams = useSearchParams()
  const isRegistered = searchParams?.get('isRegistered') || false
  const isFailed = searchParams?.get('isFailed') || false
  const [event, setEvent] = useState(null)
  const [date, setDate] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        setIsLoading(true)
        const data = await getEventPublic(eventId)
        setEvent(data)

        var startTime = new Date(data.startDate)
        var endTime = new Date(data.endDate)
        var formattedStartDate = startTime.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
        var formattedEndDate = endTime.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
        setDate(formattedStartDate + ' - ' + formattedEndDate)
      } catch (error) {
        console.error('Error fetching event details:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchEventDetails()
  }, [eventId])

  return (
    <div
      className="relative z-[1] h-dvh w-dvw overflow-hidden p-3 lg:p-8 bg-cover bg-center bg-no-repeat bg-zinc-100 flex items-center justify-center"
      style={{
        backgroundImage: event?.coverImage?.url ? `url(${event.coverImage.url})` : '/event-detail-hero.png',
        backgroundAttachment: 'fixed'
      }}
    >
      <div className='bg-black/60 z-[2] absolute w-full h-full top-0 left-0' />
      <div className='relative z-[3] w-full max-h-full bg-grey-8 flex flex-col gap-5 md:max-w-4xl md:min-h-[600px] rounded-xl overflow-y-auto'>
        {
          isLoading
            ? (
              <>
                <div className="w-full flex flex-col md:flex-row items-center gap-6 p-3.5">
                  <div className="w-full md:w-[250px] h-[250px] rounded-lg bg-zinc-200 animate-pulse" />
                  <div className='flex-1 flex flex-col gap-4'>
                    <div className='w-full flex items-center justify-between gap-4'>
                      <div className="h-8 w-64 bg-zinc-200 rounded animate-pulse" />
                    </div>
                    <div className='flex flex-wrap gap-2 mt-2'>
                      <div className="h-6 w-32 bg-zinc-200 rounded animate-pulse" />
                      <div className="h-6 w-40 bg-zinc-200 rounded animate-pulse" />
                      <div className="h-6 w-36 bg-zinc-200 rounded animate-pulse" />
                    </div>
                    <div className='flex flex-wrap gap-4'>
                      <div className="h-10 w-32 bg-zinc-200 rounded animate-pulse" />
                      <div className="h-10 w-32 bg-zinc-200 rounded animate-pulse" />
                      <div className="h-10 w-32 bg-zinc-200 rounded animate-pulse" />
                    </div>
                  </div>
                </div>

                <div className="w-full p-3.5">
                  <div className="text-left mb-8">
                    <div className="h-9 w-64 bg-zinc-200 rounded animate-pulse mb-4" />
                    <div className="h-4 w-full md:w-96 bg-zinc-200 rounded animate-pulse" />
                  </div>

                  <div className="gap-4 grid grid-cols-1 md:grid-cols-2">
                    {[...Array(5)].map((_, i) => (
                      <div key={i}>
                        <div className="h-4 w-24 bg-zinc-200 rounded animate-pulse mb-2" />
                        <div className="h-10 w-full bg-zinc-200 rounded animate-pulse" />
                      </div>
                    ))}
                    <div className='col-span-2 flex justify-end'>
                      <div className="h-10 w-32 bg-zinc-200 rounded animate-pulse" />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="w-full flex flex-col md:flex-row items-center gap-6 p-3.5">
                  <img
                    src={event?.heroImage?.url || '/event-detail-image.png'}
                    alt={`${event.name} hero image`}
                    className='object-cover w-full md:w-[250px] h-[250px] rounded-lg'
                    loading='lazy'
                  />
                  <div className='flex-1 flex flex-col gap-4 w-full'>
                    <div className='w-full flex items-center justify-between gap-4 flex-wrap'>
                      <h1 className='text-2xl md:text-3xl text-zinc-950 font-bold mb-0 text-center md:text-left'>
                        {event.name}
                      </h1>
                      {event?.isDeleted && (
                        <Badge variant='failed' className='px-4 py-1 !text-white'>
                          Deleted
                        </Badge>
                      )}
                    </div>
                    <div className='flex flex-wrap justify-center md:justify-start gap-2 mt-2 text-sm text-muted-foreground'>
                      <Badge variant='black' className='px-4 py-1'>
                        {date}
                      </Badge>
                      <Badge variant='black' className='px-4 py-1'>
                        {event.startTime + ' - ' + event.endTime}
                      </Badge>
                      {event?.locationAddress?.length > 0 &&
                        <Badge variant='black' className='px-4 py-1'>
                          {event.locationAddress}
                        </Badge>
                      }
                    </div>
                  </div>
                </div>

                {
                  !event?.isDeleted ? (
                    <div className="w-full flex-1 p-3.5">
                      {isFailed && (
                        <div className="h-full w-full flex flex-col items-center justify-center text-center p-3.5 md:p-8">
                          <h2 className="text-2xl md:text-3xl font-bold text-zinc-900 mb-4">Registration Failed</h2>
                          <p className="text-zinc-600">Please try again.</p>
                          <CustomButton
                            onClick={() => {
                              window.location.href = `${window.location.origin}/public/events/registration/${eventId}`
                            }}
                          >
                            <span className='font-medium py-2 px-3 text-base flex gap-2 items-center'>
                              <RotateCcw className='w-4 h-4' />
                              Try Again
                            </span>
                          </CustomButton>
                        </div>
                      )}
                      {isRegistered && (
                        <div className="h-full w-full flex flex-col items-center justify-center text-center p-3.5 md:p-8">
                          <h2 className="text-2xl md:text-3xl font-bold text-zinc-900 mb-4">Thank You for Registering!</h2>
                          <p className="text-zinc-600">Your registration for {event.name} has been confirmed.</p>
                          <p className="text-zinc-600 mt-2">We look forward to seeing you on {date}.</p>
                        </div>
                      )
                      }
                      {!isRegistered && !isFailed && (
                        <>
                          <div className="text-left mb-8">
                            <h2 className="text-2xl md:text-3xl font-bold text-zinc-900 text-left">Event Registration</h2>
                            <p className="mt-2 text-sm text-zinc-600 text-left">Please fill in your details below to register for the event</p>
                          </div>

                          <form action={`${process.env.REACT_APP_BE_URL}/leads/presale/v2`} method="POST" className="w-full gap-4 grid md:grid-cols-2">
                            <div className='mb-0'>
                              <label for="firstName">First Name</label>
                              <input type='text' name='firstName' placeholder='First Name' className='mb-0' required />
                            </div>
                            <div className='mb-0'>
                              <label for="lastName">Last Name</label>
                              <input type='text' name='lastName' placeholder='Last Name' className='mb-0' required />
                            </div>
                            <div className='mb-0'>
                              <label for="emailAddress">Email</label>
                              <input type='email' name='emailAddress' placeholder='example@gmail.com' className='mb-0' required />
                            </div>
                            <div className='mb-0'>
                              <label for="phoneNumber">Phone</label>
                              <input type='text' name='phone' placeholder='(123) 456-7890' className='mb-0' />
                            </div>
                            <div className='mb-0'>
                              <label for="realtor">RSVP</label>
                              <div className="radio-wrapper mb-0">
                                <div className="radio-group">
                                  <input type="radio" id="rsvp" name="rsvp" value="yes" className='mb-0' />
                                  <label for="realtorYes" className="radio-label">Yes</label>
                                </div>
                                <div className="radio-group">
                                  <input type="radio" id="rsvp" name="rsvp" value="no" className='mb-0' />
                                  <label for="realtorNo" className="radio-label">No</label>
                                </div>
                              </div>
                            </div>
                            <input type="hidden" name="project" value={event.project} />
                            <input type="hidden" name="eventId" value={eventId} />
                            <input type="hidden" name="redirect_success" value={`${window.location.origin}/public/events/registration/${eventId}?isRegistered=true`} />
                            <input type="hidden" name="redirect_existing" value={`${window.location.origin}/public/events/registration/${eventId}?isRegistered=true`} />
                            <input type="hidden" name="redirect_error" value={`${window.location.origin}/public/events/registration/${eventId}?isFailed=true`} />
                            <div className='col-span-1 md:col-span-2 flex justify-center md:justify-end mt-4 mb-0'>
                              <input type="submit" value="Submit" className='w-full md:!w-[200px] mb-0' />
                            </div>
                          </form>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="h-full w-full flex flex-col items-center justify-center text-center p-3.5 md:p-8">
                      <XCircle className="w-16 h-16 text-red-500 mx-auto mb-4" />
                      <h2 className="text-2xl md:text-3xl font-bold text-zinc-900 mb-4">This is event has been deleted</h2>
                      <p className="text-zinc-600">
                        You can not proceed to registration into this event
                      </p>
                    </div>
                  )
                }
              </>
            )
        }
      </div>
    </div>
  )
}

export default EventPublicForm
