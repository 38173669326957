/* eslint-disable */
// @ts-nocheck
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Plus } from 'lucide-react'
import { Divider } from 'antd'
import CustomButton from 'components/CustomButton'
import CustomSelect from 'components/VerticalCardProfile/components/CustomSelect'
import { createOrUpdateEmailTemplates, getEmailTemplates } from 'store/actions/emailTemplateActions'
import { useEmailTemplates } from 'hooks/useEmailTemplates'
import AddNewEmailTemplateModal from 'pages/emailCampaignsBuilder/AddNewEmailTemplateModal'
import { Oval } from 'react-loader-spinner'

const EmailTemplateSelector = ({
  label,
  labelClassName = "",
  value = null,
  onChange,
  hasPreview = false,
  isSetSendGridTemplateId = false
}) => {
  const [templateId, setTemplateId] = useState(null)
  const [isCreatingTemplate, setIsCreatingTemplate] = useState(false)
  const {
    templates,
    isLoading,
    refetch
  } = useEmailTemplates()
  const [showPreview, setShowPreview] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const _createOrUpdateEmailTemplate = useCallback(
    async (params, onSuccess = (res) => { }) => {
      setIsCreatingTemplate(true)
      try {
        const templateData = {
          name: `Untitled - ${new Date().toLocaleString()}`,
          subject: `Untitled - ${new Date().toLocaleString()}`,
          unlayerTemplateName: `Untitled - ${new Date().toLocaleString()}`,
        }

        const res = await createOrUpdateEmailTemplates(templateData)

        setTemplateId(res?.sendGridTemplateId ?? null)
        setSelectedTemplate(res ?? null)
      } catch (error) {
        console.error("Error creating template:", error)
      } finally {
        setIsCreatingTemplate(false)
      }
    },
    []
  )

  const selectedValue = useMemo(() => {
    let newValue = value;

    if (!isSetSendGridTemplateId) {
      newValue = templates?.filter(e => (e?._id === value))?.[0]?.sendGridTemplateId ?? null
    }

    return newValue
  }, [value, isSetSendGridTemplateId, templates])

  return (
    <>
      <CustomSelect
        label={label}
        labelClassName={labelClassName}
        loading={isLoading}
        options={templates}
        value={selectedValue}
        handleChange={(val) => {
          const currentTemplate = templates?.filter(e => (e?.value === val))?.[0]
          const newVal = !isSetSendGridTemplateId ? currentTemplate?._id : val
          onChange(newVal)
          setSelectedTemplate(
            currentTemplate ?? null
          )
        }}
        optionFilterProp="label"
        placeholder="Select email template"
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider className="my-2" />
            <div className="w-full px-2 pb-1">
              <CustomButton className="w-full" handleClick={_createOrUpdateEmailTemplate} disabled={isCreatingTemplate}>
                <span className="p-2 px-3 text-base font-medium flex items-center justify-center gap-2">
                  {
                    isCreatingTemplate
                      ? <Oval height="16" width="16" color="white" />
                      : <Plus className="w-4 h-4" />
                  }
                  Add new template
                </span>
              </CustomButton>
            </div>
          </>
        )}
      />
      {
        hasPreview && selectedTemplate && (
          <div className='my-2 flex flex-col gap-3'>
            <div className='flex w-full justify-end items-center gap-3'>
              <CustomButton
                variant='outline'
                handleClick={() => setShowPreview(!showPreview)}
                className={showPreview ? '!text-blue-600' : '!text-gray-600'}
              >
                <span className='font-medium placeholder py-2 px-4 text-base flex gap-2 items-center'>
                  {showPreview ? 'Hide Preview' : 'Show Preview'}
                </span>
              </CustomButton>
              <CustomButton handleClick={() => setTemplateId(value)}>
                <span className='font-medium placeholder py-2 px-4 text-base flex gap-2 items-center'>
                  Edit email template
                </span>
              </CustomButton>
            </div>
            {selectedTemplate && showPreview && (
              <>
                {
                  selectedTemplate?.unlayerConfig?.html
                    ? (
                      <div
                        className='border border-softBlack30 py-1 px-1'
                        dangerouslySetInnerHTML={{ __html: selectedTemplate?.unlayerConfig?.html }}
                      />
                    )
                    : (
                      <div className='border border-red py-6 px-4 flex items-center justify-center flex-col'>
                        <div className='text-red-500'>NO PREVIEW AVAILABLE</div>
                        <div className='text-gray-500'>
                          PLEASE ADD SOME CONTENT TO THE TEMPLATE TO PREVIEW
                        </div>
                      </div>
                    )
                }
              </>
            )}
          </div>
        )
      }
      {(!isCreatingTemplate && templateId && selectedTemplate?._id) && (
        <AddNewEmailTemplateModal
          show
          onClose={async (_id) => {
            setTemplateId(null)
            await refetch()
            onChange(_id)
          }}
          templateId={selectedTemplate?._id}
          onSuccess={(res) => setSelectedTemplate(res)}
        />
      )}
    </>
  )
}

export default EmailTemplateSelector
