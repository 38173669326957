import React from 'react'
import { Drawer as AntdDrawer } from 'antd'

const Drawer = (props) => {
  return (
    <AntdDrawer {...props} />
  )
}

export default Drawer
