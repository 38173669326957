/* eslint-disable */
// @ts-nocheck
import { api, ApiErrors } from '../../services/api'

export const getSharedInboxSettings = (id = '') =>
  new Promise((resolve, reject) => {
    api.get('/shared-inbox-settings').then((response) => {
      console.log(response)
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const updateSharedInboxSettings = (settings) =>
  new Promise((resolve, reject) => {
    let req = settings._id ? 'put': 'post'
    let url = settings._id ? `/shared-inbox-settings/${settings._id}` : '/shared-inbox-settings'
    api[req](url, settings).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })