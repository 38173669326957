// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { Modal } from '@material-ui/core'
import { Info, Link2, Mail, RefreshCcw, X } from 'lucide-react'
import { Button } from 'components/ShadcnComponents/button'
import { TButton } from 'ui'
import { getUsers, startOAuthAuthorization } from 'store/actions/usersActions'
import { Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import { CHANGE_USER_OBJECT } from 'store/actions/actionTypes'
import { objStore } from 'index'

const ConnectNylasAccount = ({
  onSuccess = () => { },
  nylasProviderToken,
  setNylasProviderToken
}) => {
  const userObject = useSelector((state) => (state.authReducer.userObject))
  const [isOpen, setIsOpen] = useState(false)
  const [requestingAuthorizationURL, setRequestingAuthorizationURL] = useState(false)
  const [isNavigatedToNylas, setIsNavigatedToNylas] = useState(false)
  const [isRefreshingUserObject, setIsRefreshingUserObject] = useState(false)

  useEffect(() => {
    if (userObject?.providerToken) {
      setNylasProviderToken(userObject.providerToken)
    }
  }, [userObject?.providerToken])

  const requestNylasURL = async () => {
    setRequestingAuthorizationURL(true)

    await startOAuthAuthorization()
      .then((url) => {
        setRequestingAuthorizationURL(false)
        setIsOpen(false)
        setIsNavigatedToNylas(true)
        window.open(url, '_blank').focus()
      })
      .catch((err) => console.log(err))
      .finally(() => setRequestingAuthorizationURL(false))
  }

  const handleModalClose = () => {
    setIsOpen(false)
  }

  const refreshUserObject = async () => {
    setIsRefreshingUserObject(true)
    await getUsers(userObject?._id)
      .then((res) => {
        if (res?.providerToken) {
          setNylasProviderToken(res.providerToken)
        }

        objStore.store.dispatch({
          type: CHANGE_USER_OBJECT,
          payload: {
            ...res
          }
        })
      })
      .catch((err) => console.log(err))
      .finally(() => setIsRefreshingUserObject(false))
  }

  if (nylasProviderToken && nylasProviderToken?.length) return null

  return (
    <>
      <Button
        onClick={() => {
          if (!isNavigatedToNylas) {
            setIsOpen(true)
          } else {
            refreshUserObject()
          }
        }}
        disabled={isRefreshingUserObject}
      >
        {
          !isNavigatedToNylas
            ? <Link2 className='w-4 h-4 mr-2' />
            : <RefreshCcw className={`w-4 h-4 mr-2 ${isRefreshingUserObject ? 'animate-spin' : ''}`} />
        }

        {
          !isNavigatedToNylas
            ? 'Connect Nylas Account'
            : 'Sync Nylas Account'
        }
        <Tooltip
          title={
            <p className='font-medium text-softBlack mb-0'>
              {
                !isNavigatedToNylas
                  ? 'Authorize your email with Nylas to seamlessly connect and sync events with your calendar.'
                  : 'Sync your Nylas account to complete the connection and enable calendar integration.'
              }
            </p>
          }
          className='ml-2'
          color='#FFF'
        >
          <Info className='w-4 h-4' />
        </Tooltip>
      </Button>
      {
        isOpen && (
          <Modal
            open={isOpen}
            onClose={handleModalClose}
            aria-labelledby='Connect Nylas Account'
            aria-describedby='Connect Nylas Account Modal'
          >
            <div
              className='relative bg-white rounded-lg shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-[600px] md:max-h-full overflow-y-auto flex flex-col items-center justify-center gap-6 p-[12px] md:!p-[20px] md:!px-[30px]'
            >
              <div className='flex-1 w-full flex flex-col bg-white rounded-lg max-w-full relative items-center justify-center py-4'>
                <button
                  onClick={handleModalClose}
                  className="absolute right-0 top-0 md:-right-2.5 text-gray-500 hover:text-gray-700"
                  aria-label="Close modal"
                >
                  <X className="w-5 h-5" />
                </button>

                <div className='w-20 h-20 rounded-full bg-blue-100 flex items-center justify-center mb-6'>
                  <Mail className='w-10 h-10 text-blue-600' />
                </div>

                <h2 className='text-2xl font-semibold text-center mb-3'>Connect Your Email to Nylas</h2>

                <p className='text-center text-gray-600 mb-8'>
                  Please, press the button below to start your authorization process through our partner{' '}
                  <span className='text-blue-600 font-medium'>Nylas</span>
                </p>

                <TButton
                  loading={requestingAuthorizationURL}
                  onClick={requestNylasURL}
                >
                  Start your authorization process
                </TButton>

                <div className='flex items-center gap-3 text-xl font-medium text-gray-800 mt-4'>
                  <span className='text-2xl'>PxP Platform</span>
                  <span className='text-3xl'>+</span>
                  <img src='https://www.nylas.com/wp-content/themes/nylas/assets/images/logos/nylas_logo_black.svg' className='h-12' alt='' />
                </div>
              </div>
            </div>
          </Modal>
        )
      }
    </>
  )
}

export default ConnectNylasAccount
