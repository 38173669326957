/* eslint-disable */

import { Drawer } from 'antd'
import TableGrid from 'components/TableGrid/Table'
import { ArrowLeft } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { getWorkflowActions, getWorkflowActionsLogs } from 'store/actions/workflowActions'
import ReactTimeAgo from 'react-time-ago'

export default function WorkflowsLogs() {
    const [loading, setLoading] = useState(true)
    const [loadingActions, setLoadingActions] = useState(true)
    const [actions, setActions] = useState([])
    const [workflowActionLogs, setWorkflowActionLogs] = useState([])
    const [workflowId, setWorkflowId] = useState(null)
    const [workflowInstanceId, setWorkflowInstanceId] = useState(null)
    const history = useHistory()
    useEffect(() => {
        getWorkflowActions().then((response) => {
            setActions(response)
        }).finally(() => {
            setLoadingActions(false)
        })
    }, [])

    useEffect(() => {
        const workflowId = new URLSearchParams(window.location.search).get('workflow')
        const workflowInstanceId = new URLSearchParams(window.location.search).get('workflowInstance')
        getWorkflowActionsLogs(workflowId, workflowInstanceId).then((response) => {
            setWorkflowActionLogs(response)
            setWorkflowId(workflowId)
            setWorkflowInstanceId(workflowInstanceId)
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    if (loadingActions || loading) {
        return <div>Loading...</div>
    }

    const cards = [{
        title: 'Total Tasks',
        count: workflowActionLogs.length,
        colors: {
            text: 'text-gray-500',
            bg: 'bg-gray-100'
        }
    },
    {
        title: 'Pending Tasks',
        count: workflowActionLogs.filter((log) => log.status === 'pending').length,
        colors: {
            text: 'text-yellow-500',
            bg: 'bg-yellow-100'
        }
    },
    {
        title: 'Completed Tasks',
        count: workflowActionLogs.filter((log) => log.status === 'completed').length,
        colors: {
            text: 'text-green-500',
            bg: 'bg-green-100'
        }
    },
    {
        title: 'Failed Tasks',
        count: workflowActionLogs.filter((log) => log.status === 'failed').length
        ,
        colors: {
            text: 'text-red-500',
            bg: 'bg-red-100'
        }
    }
    ]

    return (<div>
        <div className='flex items-center justify-between pt-2 px-3'>
            <div>
                <div>
                    <button
                        onClick={() => {
                            const url = `/admin/workflows/logs?workflow=${workflowId}`
                            history.push(url)
                        }}
                        className='flex gap-x-1 items-center'
                    >
                        <ArrowLeft size={18} />   Back
                    </button>
                </div>
                <h1 className='text-2xl'>
                    Workflow Actions Logs
                </h1>
                <div className='flex flex-wrap gap-3'>
                    {cards.map((card, index) => {
                        return <div key={index} className='min-w-[200px] flex flex-col gap-1 p-2  rounded-md border border-border shadow-sm'>
                            <div
                                style={{
                                    backgroundColor: card.colors.bg,
                                    color: card.colors.text
                                }}
                                className='flex flex-col gap-1 p-1.5 rounded-md'
                            >

                                <span className='text-sm'>
                                    {card.title}
                                </span>
                                <span className='text-3xl'>
                                    {card.count}
                                </span>
                            </div>
                        </div>
                    }
                    )}
                </div>
            </div>
            <button>
                <a href={`/admin/workflows/view/?workflow=${workflowId}`}>View Workflow</a>
            </button>
        </div>
        <TableGrid
            pagination={true}
            style={{
                height: '650px',
                minHeight: '650px',
            }}
            columnDefs={[
                {
                    headerName: '(Index) Action',
                    field: 'action',
                    cellRenderer: ({ value, data }) => {
                        const nodeIndex = data?.nodeIndex
                        const action = actions.find((action) => action?.data?.config?.id === value)
                        return <span>
                            ({nodeIndex})  {action?.data?.config?.label}
                        </span>
                    }
                },
                {
                    headerName: 'Actions Inputs',
                    field: 'inputs',
                    cellRenderer: ({ value, data }) => {
                        const action = actions.find((action) => action?.data?.config?.id === data.action)
                        return <ActionsInputs title={action?.data?.config?.label} inputs={value} />
                    },
                },
                {
                    headerName: 'Status',
                    field: 'status',
                    cellRenderer: ({ value }) => {
                        const status = String(value).toLowerCase()
                        return (<div>
                            {status === 'pending' && <span className='bg-yellow-500 px-1.5 py-0.5 text-sm text-white rounded-full capitalize'>{status}</span>}
                            {status === 'completed' && <span className='bg-green-500 px-1.5 py-0.5 text-sm text-white rounded-full capitalize'>{status}</span>}
                            {status === 'failed' && <span className='bg-red-500 px-1.5 py-0.5 text-sm text-white rounded-full capitalize'>{status}</span>}
                        </div>)
                    }
                },
                {
                    headerName: 'Message',
                    field: 'message',
                    cellRenderer: ({ value }) => {
                        return <span className='capitalize'>
                            {value}
                        </span>
                    }
                },
                {
                    headerName: 'Expected to run On',
                    field: 'expectedExecutionAt',
                    cellRenderer: ({ value, data }) => {
                        const date = new Date(value)
                        const isPast = date < new Date()
                        if (data.status === 'pending') {
                            if (isPast) {
                                return <span className='text-red-500'>
                                    Already Passed
                                </span>
                            }
                            return <div className='flex flex-col items-start'>
                                <ReactTimeAgo date={date} locale="en-US" className='p-0 m-0'
                                    style={{
                                        marginBottom: '-10px',
                                        marginTop: '-10px'
                                    }}
                                />
                                <span className='text-gray-500 text-xs'>
                                    {date.toLocaleString()}
                                </span>
                            </div>
                        } else {
                            return <span className='text-green-500'>
                                -
                            </span>
                        }
                    }
                },
                {
                    headerName: 'Last Executed Time',
                    field: 'lastExecutedAt',
                    cellRenderer: ({ value }) => {
                        if (!value) {
                            return 'Not Executed Yet'
                        }
                        return new Date(value).toLocaleString()
                    }
                },
                {
                    headerName: 'Created On',
                    field: 'createdAt',
                    cellRenderer: ({ value }) => {
                        return new Date(value).toLocaleString()
                    }
                },
                {
                    headerName: 'Updated On',
                    field: 'updatedAt',
                    cellRenderer: ({ value }) => {
                        return new Date(value).toLocaleString()
                    }
                },
            ]}
            getData={(filter, pagination, sorting) => {
                return new Promise((resolve) => {
                    const items = workflowActionLogs
                    items.sort((a, b) => {
                        return a?.nodeIndex - b?.nodeIndex
                    })
                    resolve(items.reverse())
                })
            }}
        />
    </div>

    )
}

function ActionsInputs({ title, inputs }) {
    const [isOpen, setIsOpen] = useState(false)
    const keys = Object.keys(inputs)
    function onClose() {
        setIsOpen(false)
    }
    return <div>
        <button
            className='w-full'
            onClick={() => {
                setIsOpen(!isOpen)
            }}>
            View Inputs ( {keys.length} )
        </button>
        <Drawer size={'large'} closable={true} placement="right" onClose={onClose} open={isOpen}>
            <div className='flex justify-end flex-col gap-2 h-full'>
                <div className='flex w-full justify-between'>
                    <h1 className='text-2xl'>
                        {title} - Inputs
                    </h1>
                </div>
                <div className='flex-1 flex'>
                    <pre>
                        {JSON.stringify(inputs, null, 2)}
                    </pre>
                </div>
            </div>
        </Drawer>
    </div>
}