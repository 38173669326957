// @ts-nocheck
/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import AddOrEditSingleContact from 'components/AddOrEditContact';
import AddOrEditBulkContact from 'components/AddBulkContact';
import ContactEditMethodModal from 'components/ContactEditMethodModal';
import { useHistory } from 'react-router-dom';
import AddReferralAgent from 'components/AddReferralAgent/AddReferralAgent';
import useContactContext from 'pages/newContacts/hooks/useContactContext';
import DownloadContactModal from 'components/DownloadContactModal';
import ReportWaitingModal from 'components/ReportDownloadWaitingModal';
import { useSelector } from 'react-redux';
import {
  bindChannel,
  subscribeToChannel,
  unbindChannel,
  unsubscribeToChannel,
} from 'services/pusher';
import { Plus } from 'lucide-react';
import CustomButton from 'components/CustomButton';

const ContactModal = () => {
  const history = useHistory();
  const { refetch } = useContactContext();

  const [isOpenContactEditMethodModal, setIsOpenContactEditMethodModal] =
    useState(false);
  const [isOpenCreateSingleContactModal, setIsOpenCreateSingleContactModal] =
    useState(false);
  const [singleContactModalType, setSingleContactModalType] = useState(null);
  const [isOpenCreateBulkContactModal, setIsOpenCreateBulkContactModal] =
    useState(false);
  const [isOpenReferralAgentModal, setIsOpenReferralAgentModal] =
    useState(false);
  const [isDownloadContactsModal, setIsDownloadContactsModal] = useState(false);
  const [waitingModal, setWaitingModal] = useState(false);
  const userId = useSelector((state) => state.authReducer.userObject);

  const openContactEditMethodModal = () => {
    setIsOpenContactEditMethodModal(true);
  };

  const openContactsDownloadModal = () => {
    setIsDownloadContactsModal(true);
  };

  const openCreateSingleContactModal = (type) => {
    setSingleContactModalType(type);
    setIsOpenCreateSingleContactModal(true);
  };

  const onCreateSingleContact = (_id) => {
    if (history) {
      history.push({
        pathname: '/admin/newcontacts/' + _id + '?tab=activity',
      });
    }
  };

  const onCreateBulkContact = () => {
    setIsOpenCreateBulkContactModal(true);
  };

  const onNewCallBackReport = useCallback((newCall) => {
    if (newCall?.status === 'success') {
      setWaitingModal(false);
    }
  }, [setWaitingModal, userId?._id]);

  useEffect(() => {
    let closeChannel = null;

    try {
      closeChannel = subscribeToChannel(`report-${userId?._id}`);
      if (closeChannel) {
        bindChannel(closeChannel, onNewCallBackReport);
      }
    } catch (error) {
      console.error('Error subscribing to channel:', error);
    }

    return () => {
      try {
        if (closeChannel) {
          unbindChannel(closeChannel, onNewCallBackReport);
        }
        unsubscribeToChannel(`report-${userId?._id}`);
      } catch (error) {
        console.error('Error unsubscribing from channel:', error);
      }
    };
  }, [onNewCallBackReport, userId?._id]);

  return (
    <>
      {isOpenContactEditMethodModal && (
        <ContactEditMethodModal
          show={isOpenContactEditMethodModal}
          setShow={setIsOpenContactEditMethodModal}
          onMethodSelected={(selectedMethod) => {
            if (selectedMethod === 'referral') {
              setIsOpenReferralAgentModal(true);
            } else if (selectedMethod === 'LeadBroker') {
              openCreateSingleContactModal('LeadBroker');
            } else if (selectedMethod === 'Buyer') {
              openCreateSingleContactModal('Buyer');
            } else if (selectedMethod === 'bulk') {
              onCreateBulkContact();
            }
          }}
        />
      )}
      {isOpenCreateBulkContactModal && (
        <AddOrEditBulkContact
          show={isOpenCreateBulkContactModal}
          setShow={setIsOpenCreateBulkContactModal}
          onSuccess={refetch}
        />
      )}
      {isOpenReferralAgentModal && (
        <AddReferralAgent
          show={isOpenReferralAgentModal}
          setShow={setIsOpenReferralAgentModal}
          contact={null}
          onSuccess={''}
        />
      )}
      {isOpenCreateSingleContactModal && (
        <AddOrEditSingleContact
          show={isOpenCreateSingleContactModal}
          setShow={setIsOpenCreateSingleContactModal}
          userType={singleContactModalType}
          contact={null}
          onSuccess={onCreateSingleContact}
          invalideCacheOnUpdate
        />
      )}
      {isDownloadContactsModal && (
        <DownloadContactModal
          show={isDownloadContactsModal}
          handleClose={() => setIsDownloadContactsModal(false)}
          setWaitingModal={setWaitingModal}
        />
      )}
      {waitingModal && (
        <ReportWaitingModal
          waitingModal={waitingModal}
          handleClose={() => setWaitingModal(false)}
        />
      )}
      <CustomButton id='addContactButton' className='col-span-1 md:w-fit' handleClick={openContactEditMethodModal}>
        <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
          <Plus className='w-4 h-4' />
          Add Contact
        </span>
      </CustomButton>
      <CustomButton className='col-span-2 md:w-fit' handleClick={openContactsDownloadModal}>
        <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
          Download Contacts
        </span>
      </CustomButton>
    </>
  );
};

export default ContactModal;
