// @ts-nocheck
/* eslint-disable */
import React, { useRef, useEffect } from 'react'
import { Loader } from '@googlemaps/js-api-loader'

const PurchaserMap = ({ purchaserData, apiKey }) => {
  const mapRef = useRef(null)
  const mapContainerRef = useRef(null)

  useEffect(() => {
    if (!mapContainerRef.current || !purchaserData?.length) return

    const loader = new Loader({
      apiKey,
      version: 'weekly'
    })

    // Default to Vancouver area
    const defaultCenter = { lat: 49.2827, lng: -123.1207 }

    // Process data to standard format - ensure we have city property
    const processedData = purchaserData.map(item => ({
      city: item.city || item.name,
      value: item.value || item.count || 0
    }))

    loader.load().then((google) => {
      const map = new google.maps.Map(mapContainerRef.current, {
        center: defaultCenter,
        zoom: 10,
        mapTypeId: 'roadmap',
        streetViewControl: false,
        disableDefaultUI: true,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        rotateControl: false,
        fullscreenControl: false,
        scrollwheel: false,  // Disable scrolling to zoom
        gestureHandling: "none" // Disable all gestures including pinch to zoom
      })

      mapRef.current = map

      // Create a geocoder for converting city names to coordinates
      const geocoder = new google.maps.Geocoder()
      const bounds = new google.maps.LatLngBounds()

      // Sort cities by value (highest first) to ensure larger cities are processed first
      const sortedCities = [...processedData].sort((a, b) => b.value - a.value)

      // Find max and min values for better scaling
      const maxValue = sortedCities[0]?.value || 1
      const minValue = sortedCities[sortedCities.length - 1]?.value || 1

      // British Columbia context to improve geocoding accuracy
      const regionContext = 'British Columbia, Canada'

      // For each city, geocode and add a marker
      sortedCities.forEach((cityData, index) => {
        if (!cityData.city || cityData.city === 'Unknown') return

        // Add region context for better accuracy
        const cityWithRegion = `${cityData.city}, ${regionContext}`

        geocoder.geocode({ address: cityWithRegion }, (results, status) => {
          if (status === 'OK' && results[0]) {
            const location = results[0].geometry.location

            // Improved logarithmic scale for circle sizes
            // This gives better visual representation for varying data ranges
            // Min radius: 1000m, Max radius: 5000m
            const minRadius = 1000
            const maxRadius = 5000
            
            // Use logarithmic scale: small differences in small values look larger
            // than the same differences in large values
            let normalizedValue = cityData.value / maxValue // 0 to 1 scale
            
            // Logarithmic scale formula (prevents tiny circles for small values)
            const logBase = 10
            const logScale = Math.log(1 + (logBase - 1) * normalizedValue) / Math.log(logBase)
            
            // Calculate the final radius between min and max
            const circleRadius = minRadius + (maxRadius - minRadius) * logScale

            // Color based on value percentile (blue gradient from light to dark)
            const percentile = cityData.value / maxValue
            const colorIntensity = Math.floor(percentile * 100)
            const hue = 220 // Blue hue
            const saturation = 80 // High saturation

            // Create a circle instead of a marker
            const circle = new google.maps.Circle({
              strokeColor: `hsl(${hue}, ${saturation}%, 35%)`,
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: `hsl(${hue}, ${saturation}%, ${Math.max(40, 65 - colorIntensity / 2)}%)`,
              fillOpacity: 0.6,
              map,
              center: location,
              radius: circleRadius,
              zIndex: 100 - index // Higher values on top
            })

            // Scale marker size slightly with value too
            const markerScale = Math.max(10, Math.min(15, 10 + (cityData.value / maxValue) * 8))

            // Create a marker for the city center with the count
            const marker = new google.maps.Marker({
              position: location,
              map,
              title: `${cityData.city}: ${cityData.value} registrants`,
              label: {
                text: cityData.value.toString(),
                color: 'white',
                fontSize: '12px',
                fontWeight: 'bold'
              },
              icon: {
                path: google.maps.SymbolPath.CIRCLE,
                scale: markerScale,
                fillColor: `hsl(${hue}, ${saturation}%, 35%)`,
                fillOpacity: 1,
                strokeWeight: 1,
                strokeColor: '#FFFFFF'
              },
              zIndex: 200 - index // Keep labels above circles
            })

            // Add info window with location info
            const infoWindow = new google.maps.InfoWindow({
              content: `<div style="font-weight:bold;font-size:14px;">${cityData.city}</div><div><strong>${cityData.value}</strong> registrants</div>`
            })

            // Add listeners to both the circle and marker
            google.maps.event.addListener(circle, 'click', () => {
              infoWindow.setPosition(location)
              infoWindow.open(map)
            })

            marker.addListener('click', () => {
              infoWindow.open(map, marker)
            })

            // Extend bounds to include this city
            bounds.extend(location)

            // Only fit bounds after processing all cities
            if (index === sortedCities.length - 1) {
              map.fitBounds(bounds)

              // Adjust zoom if too close
              const listener = google.maps.event.addListener(map, 'idle', () => {
                if (map.getZoom() > 10) {
                  map.setZoom(10)
                }
                google.maps.event.removeListener(listener)
              })
            }
          }
        })
      })
    })

    return () => {
      // Cleanup
      mapRef.current = null
    }
  }, [purchaserData, apiKey])

  return (
    <div className="purchaser-map-container">
      <div
        ref={mapContainerRef}
        style={{
          width: '100%',
          height: '400px',
          borderRadius: '8px',
          border: '1px solid #e5e7eb'
        }}
      />
      {(!purchaserData || purchaserData.length === 0) && (
        <div 
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: 'rgba(255,255,255,0.8)',
            padding: '10px',
            borderRadius: '4px'
          }}
        >
          No purchaser data available
        </div>
      )}
    </div>
  )
}

export default PurchaserMap 