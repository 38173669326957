// @ts-nocheck
/* eslint-disable */
import CustomModal from 'components/Modal';
import React, { useState, useEffect } from 'react';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { api } from 'services/api';
import { useSelector } from 'react-redux';
import { CircularProgress, Modal } from '@material-ui/core';
import CustomButton from 'components/CustomButton';
import moment from 'moment-timezone';

export default function ReportDownloadModal({ show, reportUrl, handleClose, reportName, projectName = '' }) {
  
  const [checkBoxOption, setCheckBoxOption] = useState('project_contacts');
  const projectId = useSelector((state) => state.appReducer.appProject);
  const [isLoading, setIsLoading] = useState(false);
  const handleDownload = async () => {
    let currentDate = moment().format('MM/DD/YYYY');
    try {
      setIsLoading(true);
      const link = document.createElement('a');
      link.href = reportUrl;
      if(reportName === 'Contacts'){
        link.setAttribute('download', `${projectName} - ${reportName} Report - ${currentDate}.xlsx`);
      }else{
        link.setAttribute('download', `contact-22report-${currentDate}.xlsx`);
      }
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setIsLoading(false);
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };
  // useEffect(() => {
  //   const getContactsCount = async (projectId) => {
  //     const response = await api.get(
  //       `/reports/contacts/count?projectId=${projectId}`
  //     );
  //     if (response.ok) {
  //       setContactsCount(response.data);
  //     }
  //   };
  //   if (projectId) {
  //     getContactsCount(projectId);
  //   }
  // }, [projectId]);

  return (
    <Modal onClose={handleClose} open={true} >
      <div className='font-openSans top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 relative  w-[400px] bg-grey-8 p-6 rounded shadow-lg'>
        <button className='absolute right-6 top-4' onClick={handleClose}>
          <CrossIcon />
        </button>
        <div className='flex items-center flex-col gap-y-7 justify-center mt-8'>
          <div className='text-[20px] font-bold'>{reportName} {' '} report is ready</div>
          <CustomButton handleClick={handleDownload} className='md:w-[150px] h-[30px] md:h-[40px]'>
            <span className='font-medium px-3 py-2 text-base flex gap-2 items-center'>
              {isLoading ? <CircularProgress size={20} /> : 'Download'}
            </span>
          </CustomButton>
        </div>
      </div>
    </Modal>
  );
}
