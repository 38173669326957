/* eslint-disable */
// @ts-nocheck
import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  memo,
  useMemo,
  useCallback,
  useRef,
} from "react"
import propTypes from "prop-types"
import moment from "moment"
import { connect } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { AnimatePresence } from "framer-motion"

import {
  getProjectOffers,
  getProjectStatistics,
  cancelOffer as cancelOfferAPI,
  sendOfferContractsToSelectedRoleV1,
} from "store/actions/offerActions";
import { formatMoney } from "utils";
import FadedAnimatedDiv from "components/FadedAnimatedDiv";
import { ADMIN_OFFERS } from "routes";
// import { OfferDocuments } from "components";
import ContractSigningScreen from "components/ContractSigningScreen"
import { message } from "antd";
import standardStyle from "assets/css/standardStyle";
// import Dropdown from './Dropdown'
// import { EyeIcon } from '@heroicons/react/outline'
// import { CloseIcon, EditIcon } from 'components/Icons'
import { ReactComponent as CrossIcon } from "assets/icons/cross.svg";
import DeleteModal from "components/DeleteModal";
import { Modal } from "@material-ui/core";

// import { isMobile } from "react-device-detect"
import PaymentTypeModal from "pages/offer/components/PaymentTypeModal"
import PaymentMethodModal from "pages/offer/components/PaymentMethodModal"
import { AiFillCaretUp } from "react-icons/ai"
import UnitImage from "./../../assets/img/unit-1201.png"
import ContractDetailDrawer from "pages/offer/components/ContractDetailDrawer"
import CustomTable from "components/CustomTable"
import CustomButton from "components/CustomButton"
import { AlertCircle, Ban, Clock, Eye, FileText, Pen, SquarePen } from "lucide-react"
import { OffersV2Table } from "components/OffersV2"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "components/ShadcnComponents/card"
import { getUserActivityOffers } from "store/actions/userActivityActions"
import { getPusherActivityServerKey } from "store/actions/usersActions"
import { bindChannel, subscribeToChannel, unbindChannel, unsubscribeToChannel } from "services/pusher"
import Skeleton from "react-loading-skeleton"
import { LottieWrapper } from 'components'
import tableLoading from 'assets/lottieJsons/tableLoading.json'
import TableWithPagination from "components/TableWithPagination"
import { useGetOffers, useGetUsersOfferActivity } from "hooks/useGetOffers"
import { useDebounce } from "hooks/useDebounce"
import { CONTRACT_TYPES } from "components/ContractSigningScreen/constants"

const LoadingContext = createContext()
const DEFAULT_LIMIT = 10
import { checkV2Inventory } from "utils/checkV2Inventory";
import { getProjects } from "store/actions/projectActions"

export const offerStatus = {
  SoldFirm: { text: "Sold - Firm", backgroundColor: "bg-green-700" },
  Signed: { text: "Signed", backgroundColor: "bg-blue-500" },
  OfferSent: { text: "Offer Sent", backgroundColor: "bg-blue-300" },
  OfferCreated: { text: "Offer Created", backgroundColor: "bg-gray-600" },
  OfferAccepted: { text: "Offer Accepted", backgroundColor: "bg-blue-300" },
  Paid: { text: "Paid", backgroundColor: "bg-green-900" },
  SevenDaysRescission: {
    text: "Seven Days Rescission",
    backgroundColor: "bg-yellow-600",
  },
  OfferRejected: { text: "Offer Rejected", backgroundColor: "bg-red-600" },
  ContractCancelled: { text: "Offer Cancelled", backgroundColor: "bg-red-600" },
};

export const ColoredStatus = ({ status }) => {
  if (offerStatus?.[status]) {
    return (
      <div
        className={`w-fit font-semibold text-xs flex items-center gap-x-1.5 px-3 py-1 rounded-md mx-auto
        ${offerStatus?.[status]?.backgroundColor || "bg-slate-400"
          } text-center bg-opacity-20
      `}
      >
        <div
          className={`w-[9px] h-[9px] ${offerStatus?.[status]?.backgroundColor || "bg-slate-400"
            } rounded-full`}
        />
        {offerStatus?.[status]?.text}
      </div>
    );
  }
  return null;
};

const CardMoneyTextComponent = memo(({ children }) => (
  <div className="text-lg font-medium text-gray-900">{children}</div>
));

const StatisticValue = memo(({ title, value, loadingObject }) => {
  const { gettingProjectOffers, gettingstProjectStatistics } =
    useContext(LoadingContext);

  return (
    <AnimatePresence exitBeforeEnter initial>
      {gettingProjectOffers || gettingstProjectStatistics ? (
        <FadedAnimatedDiv key={`statisticValueLoading${title}`}>
          {loadingObject}
        </FadedAnimatedDiv>
      ) : (
        <FadedAnimatedDiv key={`statisticValue${title}`}>
          {value}
        </FadedAnimatedDiv>
      )}
    </AnimatePresence>
  );
});

const CardComponent2 = ({ IconSrc, title, amount, noOfUnits }) => (
  <div
    className="bg-white rounded"
    style={{ fontFamily: standardStyle.fonts.sfpro }}
  >
    <div className="p-6">
      <div className="w-8 h-6">{IconSrc}</div>
    </div>
    <div className="flex flex-col space-y-2 p-6">
      <div className="text-xs text-softBlack_70 font-medium uppercase">
        {title}
      </div>
      <div className="text-xl text-softBlack font-bold">
        <StatisticValue
          title={title}
          value={formatMoney(amount || 0)}
          loadingObject={
            <CardMoneyTextComponent>loading...</CardMoneyTextComponent>
          }
        />
      </div>
      <div className="text-sm font-bold text-softBlack pt-4">
        <StatisticValue title={title} value={`${noOfUnits ?? "0"} UNITS`} />
      </div>
    </div>
  </div>
);

// const ContractsDetailsHeader = () => {
//   const typesOfContracts = [
//     {
//       id: 'pendingSignature',
//       name: 'Pending signature',
//       color: '#EDDF65',
//       number: 105
//     },
//     {
//       id: 'pendingApproval',
//       name: 'Pending approval',
//       color: '#6D6B6D',
//       number: 47
//     },
//     {
//       id: 'rescission',
//       name: 'Rescission',
//       color: '#C1C0C1',
//       number: 23
//     },
//     {
//       id: 'soldFirm',
//       name: 'Sold firm',
//       color: '#2E2B2E',
//       number: 14
//     }
//   ]
//   return (
//     <div className='w-full bg-white border border-[#E4E4E7] shadow-sm rounded-xl p-6 flex items-center gap-x-10'>
//       <div>
//         <h5 className='text-base font-semibold mb-0'>Total offers</h5>
//         <h2 className='text-[32px] font-bold mb-0'>211 units</h2>
//         <div className='w-fit rounded-md bg-[#D6FABB] flex gap-1 items-center py-1 px-2'>
//           <AiFillCaretUp className='text-[#44AC2A]' />
//           <p className='text-xs font-semibold mb-0'>1.6%</p>
//         </div>
//       </div>
//       <div>
//         <svg width="420" height="28" viewBox="0 0 420 28" fill="none" xmlns="http://www.w3.org/2000/svg">
//           <g clip-path="url(#clip0_52_918)">
//             <mask id="path-2-inside-1_52_918" fill="white">
//               <path d="M0 0H185V28H0V0Z" />
//             </mask>
//             <path d="M0 0H185V28H0V0Z" fill="#EDDF65" />
//             <path d="M182 0V28H188V0H182Z" fill="white" mask="url(#path-2-inside-1_52_918)" />
//             <mask id="path-4-inside-2_52_918" fill="white">
//               <path d="M185 0H294V28H185V0Z" />
//             </mask>
//             <path d="M185 0H294V28H185V0Z" fill="#6D6B6D" />
//             <path d="M291 0V28H297V0H291Z" fill="white" mask="url(#path-4-inside-2_52_918)" />
//             <mask id="path-6-inside-3_52_918" fill="white">
//               <path d="M294 0H373V28H294V0Z" />
//             </mask>
//             <path d="M294 0H373V28H294V0Z" fill="#C1C0C1" />
//             <path d="M370 0V28H376V0H370Z" fill="white" mask="url(#path-6-inside-3_52_918)" />
//             <rect x="373" width="47" height="28" fill="#2E2B2E" />
//           </g>
//           <defs>
//             <clipPath id="clip0_52_918">
//               <rect width="420" height="28" rx="6" fill="white" />
//             </clipPath>
//           </defs>
//         </svg>
//       </div>
//       <div className='flex items-center gap-x-4'>
//         {
//           typesOfContracts.map((type, index) => (
//             <div key={type.id} className={`flex flex-col gap-y-1 px-4 ${index !== 0 ? 'border-l border-[#2E2B2E] border-opacity-25' : ''}`}>
//               <div className='flex items-center gap-1'>
//                 <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
//                   <circle cx="6" cy="6" r="6" fill={type.color} />
//                 </svg>
//                 <h6 className='text-sm mb-0 font-medium'>{type.name}</h6>
//               </div>
//               <p className='text-xl mb-0 font-bold'>{type.number}</p>
//             </div>
//           ))
//         }
//       </div>
//     </div>
//   )
// }

const ActivityItem = ({ activity, isNew }) => {
  const getActivityIcon = (type) => {
    switch (type) {
      case "offer-created":
        return <FileText className="w-4 h-4 text-blue-600" />;
      case "offer-status-changed":
        return <Clock className="w-4 h-4 text-yellow-600" />;
      case "offer-signed":
        return <AlertCircle className="w-4 h-4 text-green-600" />;
      case "deposit-received":
        return <AlertCircle className="w-4 h-4 text-green-600" />;
      case "subject-removed":
        return <Ban className="w-4 h-4 text-red-600" />;
      case 'offer-deleted':
        return <Ban className="w-4 h-4 text-red-600" />;
      default:
        return <FileText className="w-4 h-4 text-blue-600" />;
    }
  };

  const getActivityColor = (type) => {
    switch (type) {
      case 'offer-created':
        return 'bg-blue-100';
      case 'offer-status-changed':
        return 'bg-yellow-100';
      case 'offer-deposits-updated':
        return 'bg-yellow-100';
      case 'offer-subjects-updated':
        return 'bg-yellow-100';
      case 'offer-signed':
        return 'bg-green-100';
      case 'deposit-received':
        return 'bg-green-100';
      case 'subject-removed':
        return 'bg-red-100';
      case 'offer-deleted':
        return 'bg-red-100';
      default:
        return "bg-blue-100";
    }
  };

  const getActivityMessage = (activity) => {
    const buyerName = `${activity.user?.firstName || ""} ${
      activity.user?.lastName || ""
    }`;
    const typeKey = {
      condo: "condoData",
      townhome: "townhomeData",
      lot: "lotData",
    };

    const inventory = activity.offer?.inventory;
    const inventoryType = inventory ? typeKey[inventory?.type] : null;
    const inventoryNumber = inventoryType ? inventory[inventoryType]?.number : null;
    const unitNumber = checkV2Inventory()
      ? inventoryNumber || activity.offer?.unit?.unitNumber
      : activity.offer?.unit?.unitNumber || 'N/A';
      
    switch (activity.activityType) {
      case "offer-created":
        return (
          <div className="text-sm text-black">
            New offer created for unit{" "}
            <span className="font-medium">{unitNumber}</span> with buyer{" "}
            <span className="font-medium">{buyerName}</span>
          </div>
        );
      case "offer-status-changed":
        return (
          <div className="text-sm text-black">
            Offer <span className="font-medium">{unitNumber}</span> status
            changed from{" "}
            <span className="font-medium">{activity.offer?.oldStatus}</span> to{" "}
            <span className="font-medium">{activity?.offer?.status}</span>
          </div>
        );
      case 'offer-deposits-updated':
        return (
          <div className="text-sm text-black">
            Offer <span className="font-medium">{unitNumber}</span> deposits updated
          </div>
        );
      case 'offer-subjects-updated':
        return (
          <div className="text-sm text-black">
            Offer <span className="font-medium">{unitNumber}</span> subjects updated
          </div>
        );
      case 'offer-signed':
        return (
          <div className="text-sm text-black">
            Offer <span className="font-medium">{unitNumber}</span> signed by{" "}
            <span className="font-medium">{buyerName}</span>
          </div>
        );
      case 'offer-deleted':
        return (
          <div className="text-sm text-black">
            Offer <span className="font-medium">{unitNumber}</span> archived
          </div>
        );
      // Add other cases as needed
      default:
        return (
          <p className="text-sm">
            Activity on offer <span className="font-medium">{unitNumber}</span>
          </p>
        );
    }
  };

  return (
    <div
      className={`flex gap-3 transition-all duration-500 ${isNew ? 'animate-slide-in bg-yellow-100 p-1' : ''
        }`}
    >
      <div
        className={`w-8 h-8 rounded-full ${getActivityColor(
          activity.activityType
        )} flex items-center justify-center`}
      >
        {getActivityIcon(activity.activityType)}
      </div>
      <div>
        {getActivityMessage(activity)}
        <p className="text-xs text-gray-500">
          {moment(activity.createdAt).format("MMM DD, YYYY • h:mm A")}
        </p>
      </div>
    </div>
  );
};

const ContractsDetailsHeader = ({
  projectStatistics,
  gettingProjectStatistics = false,
  projectInfo,
  userActivityOffers = [],
  setUserActivityOffers,
  gettingUserOffersActivity = false,
  page,
  setPage = () => { },
  lastPage,
}) => {
  const [newActivityIds, setNewActivityIds] = useState(new Set())
  const [pusherActivityServerKey, setPusherActivityServerKey] = useState(null)

  useEffect(() => {
    getPusherActivityServerKey().then((res) => {
      setPusherActivityServerKey(res.key);
    });
  }, []);

  const onNewCallCallBack = useCallback((newCall) => {
    setUserActivityOffers((prevLogs) => [newCall, ...prevLogs]);
    setNewActivityIds((prev) => new Set(prev).add(newCall._id));
    setTimeout(() => {
      setNewActivityIds((prev) => {
        const updated = new Set(prev);
        updated.delete(newCall._id);
        return updated;
      });
    }, 2000);
  }, []);

  useEffect(() => {
    let callChannel = null;
    callChannel = subscribeToChannel(`offer-${pusherActivityServerKey}`);
    if (callChannel) {
      bindChannel(callChannel, onNewCallCallBack);
    }
    return () => {
      if (callChannel) {
        unbindChannel(callChannel, onNewCallCallBack);
      }
      unsubscribeToChannel(`offer-${pusherActivityServerKey}`);
    };
  }, [pusherActivityServerKey]);

  const containerRef = useRef(null)

  const loadMoreActivities = useCallback(async () => {
    if (gettingUserOffersActivity) return

    if (lastPage > page) {
      setPage((prevPage) => prevPage + 1)
    }
  }, [gettingUserOffersActivity, lastPage])
  const debouncedLoadMore = useDebounce(loadMoreActivities, 300)

  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current
      if (scrollHeight - scrollTop <= clientHeight * 1.5 && lastPage > page) {
        debouncedLoadMore()
      }
    }
  }, [debouncedLoadMore, lastPage])

  useEffect(() => {
    const currentContainer = containerRef.current
    if (currentContainer) {
      currentContainer.addEventListener("scroll", handleScroll)
    }
    return () => {
      if (currentContainer) {
        currentContainer.removeEventListener("scroll", handleScroll)
      }
    }
  }, [handleScroll])

  return (
    <div className="w-full">
      <div className="relative w-full h-[300px] mx-auto overflow-hidden rounded-lg m-4">
        <img
          src={projectInfo.backgroundImage}
          alt={projectInfo.projectName}
          width={1200}
          height={300}
          className="object-cover h-full w-full"
        />
      </div>
      <div className="grid md:grid-cols-2 gap-6 w-full">
        {/* Metrics */}
        <div className="grid grid-cols-1 gap-4">
          <Card className="bg-white flex flex-col justify-center">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                Total Transaction Value
              </CardTitle>
            </CardHeader>
            <CardContent>
              {
                gettingProjectStatistics
                  ? <Skeleton className='rounded-lg' height={32} width={250} />
                  : <div className="text-2xl font-bold">{projectStatistics?.sumTotal > 0 ? formatMoney(projectStatistics?.sumTotal) : "-"}</div>
              }
            </CardContent>
          </Card>
          <Card className="bg-white flex flex-col justify-center">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                Completed Transactions
              </CardTitle>
            </CardHeader>
            <CardContent>
              {
                gettingProjectStatistics
                  ? <Skeleton className='rounded-lg' height={32} width={100} />
                  : <div className="text-2xl font-bold">{projectStatistics?.counterSoldFirm || 0}</div>
              }
            </CardContent>
          </Card>

          <Card className="bg-white flex flex-col justify-center">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                Pending Transactions
              </CardTitle>
            </CardHeader>
            <CardContent>
              {
                gettingProjectStatistics
                  ? <Skeleton className='rounded-lg' height={32} width={100} />
                  : <div className="text-2xl font-bold">
                    {(projectStatistics?.counterTotal || 0) - (projectStatistics?.counterSoldFirm || 0) - (projectStatistics?.counterContractCancelled || 0) - (projectStatistics?.counterOfferRejected || 0)}
                  </div>
              }
            </CardContent>
          </Card>

          <Card className="bg-white flex flex-col justify-center">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                Lost Deals
              </CardTitle>
            </CardHeader>
            <CardContent>
              {
                gettingProjectStatistics
                  ? <Skeleton className='rounded-lg' height={32} width={100} />
                  : <div className="text-2xl font-bold">{projectStatistics?.counterContractCancelled || 0}</div>
              }
            </CardContent>
          </Card>
        </div>

        {/* Recent Activity */}
        <Card className="bg-white">
          <CardHeader>
            <CardTitle>Recent Activity</CardTitle>
          </CardHeader>
          <CardContent className="w-full flex h-[500px] overflow-y-auto text-zinc-400" ref={containerRef}>
            {
              gettingUserOffersActivity && !userActivityOffers?.length
                ? <div
                  key='loadingTable'
                  className='w-full h-full flex items-center justify-center flex-col'
                >
                  <LottieWrapper
                    animation={tableLoading}
                    loop
                    className='h-16 w-16'
                  />
                  <span className='text-base dark:text-white'>loading...</span>
                </div>
                : <>
                  {userActivityOffers?.length > 0 ? (
                    <div className="w-full space-y-4">
                      {userActivityOffers?.map((activity, index) => (
                        <ActivityItem
                          key={activity?._id + index}
                          activity={activity}
                          isNew={newActivityIds.has(activity?._id)}
                        />
                      ))}
                      {gettingUserOffersActivity && (
                        <div className="w-full flex justify-center items-center py-4">
                          <LottieWrapper animation={tableLoading} loop className="h-16 w-16" />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="w-full flex mt-[-80px] justify-center items-center h-full text-zinc-400 text-center">
                      No activity to display. Check back later for updates.
                    </div>
                  )}
                </>
            }
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

const ContractFilterMenus = ({ selectedTab, setSelectedTab }) => {
  // const tabMenus = [
  //   {
  //     id: "tab-1",
  //     name: "All",
  //   },
  //   {
  //     id: "tab-2",
  //     name: "Offers",
  //   },
  //   {
  //     id: "tab-3",
  //     name: "Pending approval",
  //   },
  //   {
  //     id: "tab-4",
  //     name: "Signature",
  //   },
  //   {
  //     id: "tab-5",
  //     name: "Master Report",
  //   },
  // ]

  return (
    <div className="w-full mt-10 flex justify-between items-center">
      <div className="flex items-center gap-x-4">
        <div className="flex items-center gap-x-2 pr-6">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.84008 16.1601C3.84008 9.35593 9.35593 3.84008 16.1601 3.84008C22.9642 3.84008 28.4801 9.35593 28.4801 16.1601C28.4801 22.9642 22.9642 28.4801 16.1601 28.4801C9.35593 28.4801 3.84008 22.9642 3.84008 16.1601Z"
              stroke="#EDDF65"
              stroke-width="3.2"
            />
            <path
              d="M15.04 18.2402H20.8C20.9768 18.2402 21.12 18.3835 21.12 18.5602V21.4402C21.12 21.617 20.9768 21.7602 20.8 21.7602H15.04V18.2402Z"
              fill="#18181B"
            />
            <path
              d="M10.0766 20.3442C9.86761 20.1797 9.86761 19.8204 10.0766 19.6559L15.0829 15.7158C15.2009 15.6229 15.3599 15.7217 15.3599 15.8879L15.3599 17.9625L15.3599 21.9049L15.3599 24.1122C15.3599 24.2784 15.2009 24.3771 15.0829 24.2842L10.0766 20.3442Z"
              fill="#18181B"
            />
            <path
              d="M17.2798 10.2402H11.5198C11.3431 10.2402 11.1998 10.3835 11.1998 10.5602V13.4402C11.1998 13.617 11.3431 13.7602 11.5198 13.7602H17.2798V10.2402Z"
              fill="#18181B"
            />
            <path
              d="M22.2432 12.3442C22.4522 12.1797 22.4522 11.8204 22.2432 11.6559L17.2369 7.7158C17.1189 7.62292 16.96 7.72167 16.96 7.88787L16.96 9.96247L16.96 13.9049L16.96 16.1122C16.96 16.2784 17.1189 16.3771 17.2369 16.2842L22.2432 12.3442Z"
              fill="#18181B"
            />
          </svg>
          <h4 className="text-2xl font-semibold mb-0">Transactions</h4>
        </div>
        {/* <div className="flex items-center gap-x-2 p-1 rounded-lg bg-[#C1C0C1] bg-opacity-20">
          {tabMenus.map((tab) => (
            <div
              key={tab.id}
              onClick={() => setSelectedTab(tab.id)}
              className={`flex items-center px-2 py-1 cursor-pointer rounded-md ${selectedTab === tab.id
                ? "font-bold bg-white shadow-sm"
                : "font-normal text-sm"
                } transition-all`}
            >
              {tab.name}
            </div>
          ))}
        </div> */}
      </div>
      {/* <div className="flex items-stretch gap-x-4">
        <div className="px-4 py-2 flex items-center gap-x-2 rounded bg-white border border-[#2E2B2E] border-opacity-[15%]">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.2001 17.4C8.77605 17.4 7.38398 16.9777 6.19993 16.1866C5.01589 15.3954 4.09303 14.2709 3.54808 12.9553C3.00312 11.6397 2.86053 10.192 3.13835 8.79535C3.41617 7.39869 4.10191 6.11577 5.10886 5.10883C6.11581 4.1019 7.39874 3.41616 8.79542 3.13835C10.1921 2.86054 11.6398 3.00312 12.9554 3.54807C14.2711 4.09302 15.3956 5.01586 16.1867 6.1999C16.9779 7.38393 17.4002 8.77597 17.4002 10.2C17.398 12.1089 16.6388 13.939 15.2889 15.2888C13.9391 16.6386 12.109 17.3979 10.2001 17.4ZM10.2001 4.8C9.13206 4.8 8.08801 5.11671 7.19997 5.71007C6.31194 6.30343 5.6198 7.14679 5.21108 8.13351C4.80236 9.12023 4.69542 10.206 4.90378 11.2535C5.11215 12.301 5.62645 13.2632 6.38167 14.0184C7.13688 14.7736 8.09908 15.2879 9.14659 15.4962C10.1941 15.7046 11.2799 15.5977 12.2666 15.1889C13.2533 14.7802 14.0967 14.0881 14.6901 13.2001C15.2834 12.3121 15.6002 11.268 15.6002 10.2C15.5987 8.76827 15.0293 7.39559 14.0169 6.3832C13.0045 5.37082 11.6318 4.80143 10.2001 4.8Z"
              fill="#1F2A37"
            />
            <path
              d="M20.1002 21C19.8615 20.9999 19.6326 20.9051 19.4639 20.7363L15.8638 17.1363C15.6999 16.9666 15.6092 16.7392 15.6112 16.5032C15.6133 16.2673 15.7079 16.0415 15.8748 15.8747C16.0417 15.7078 16.2674 15.6131 16.5034 15.6111C16.7394 15.609 16.9667 15.6998 17.1364 15.8637L20.7365 19.4637C20.8623 19.5896 20.948 19.7499 20.9827 19.9245C21.0174 20.099 20.9996 20.28 20.9315 20.4444C20.8634 20.6088 20.7481 20.7494 20.6001 20.8483C20.4521 20.9472 20.2782 21 20.1002 21Z"
              fill="#1F2A37"
            />
          </svg>
          <input
            className="border-0 p-0 rounded-none outline-none"
            placeholder="Search..."
          />
        </div>
        <div className="px-4 py-2 flex items-center gap-x-2 rounded bg-white border border-[#2E2B2E] border-opacity-[15%] cursor-pointer">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_52_1001)">
              <path
                d="M3.54157 4.67501C5.2249 6.83334 8.33324 10.8333 8.33324 10.8333V15.8333C8.33324 16.2917 8.70824 16.6667 9.16657 16.6667H10.8332C11.2916 16.6667 11.6666 16.2917 11.6666 15.8333V10.8333C11.6666 10.8333 14.7666 6.83334 16.4499 4.67501C16.8749 4.12501 16.4832 3.33334 15.7916 3.33334H4.1999C3.50824 3.33334 3.11657 4.12501 3.54157 4.67501Z"
                fill="#323232"
              />
            </g>
            <defs>
              <clipPath id="clip0_52_1001">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <p className="mb-0 text-sm font-normal">Filter</p>
        </div>
      </div> */}
    </div>
  );
};

export const MemoTable = ({
  dataSource,
  columns,
  loading,
  idBeingDeleted,
  deleting,
  showTablePagination,
}) => {
  return (
    <CustomTable
      className="rounded-lg"
      dataSource={dataSource}
      columns={columns}
      tailwindTable
      loading={loading}
      idBeingDeleted={idBeingDeleted}
      deleting={deleting}
      isFromContracts
      showTablePagination={showTablePagination}
      noMargin
    />
  );
};

const ActionButton = ({ onClick, openDocumentsModal }) => {
  return (
    <div className="w-fit flex gap-2 items-center">
      <CustomButton handleClick={onClick}>
        <span className="font-normal py-1.5 px-4 text-sm text-center flex items-center gap-2">
          <Eye className="w-4 h-4" />
          <span className="text-sm">View</span>
        </span>
      </CustomButton>
      <CustomButton variant="outline" handleClick={openDocumentsModal}>
        <span className="font-normal py-1.5 px-4 text-sm text-center flex items-center gap-2">
          <Pen className="w-4 h-4" />
          <span className="text-sm">Sign</span>
        </span>
      </CustomButton>
    </div>
  );
};

const ProjectOffers = ({ appProject, userObject, projectInfo }) => {
  const history = useHistory();
  const location = useLocation();
  const transactionId = new URLSearchParams(location.search).get(
    "transactionId"
  )
  const [gettingProjectOffers, setGettingProjectOffers] = useState(true)
  const [gettingProjectStatistics, setGettingProjectStatistics] =
    useState(true)
  const [projectOffers, setProjectOffers] = useState([])
  const [projectStatistics, setProjectStatistics] = useState("")
  const [offerIdShowingDocs, setOfferIdShowingDocs] = useState("")
  const [showDocumentsModal, setShowDocumentsModal] = useState(false)
  const [idBeingDeleted, setIdBeingDeleted] = useState("")
  const [showCancelOfferModal, setShowCancelOfferModal] = useState(false)
  const [cancelingOffer, setCancelingOffer] = useState(false)
  const [showPaymentTypeModal, setShowPaymentTypeModal] = useState(false)
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false)
  const [paymentMethodId, setPaymentMethodId] = useState(null)
  const [successfulPayment, setSuccessfulPayment] = useState(false)
  const [isContractDetailDrawerOpen, setIsContractDetailDrawerOpen] =
    useState(false)
  const [selectedTransactionId, setSelectedTransactionId] = useState()
  const [userActivityOffers, setUserActivityOffers] = useState([])
  const [currentOffersTablePage, setCurrentOffersTablePage] = React.useState(0)
  const [offersTableLimit, setOffersTableLimit] = React.useState(DEFAULT_LIMIT)
  const [currentOffersActivityTablePage, setCurrentOffersActivityTablePage] = React.useState(0)
  const [offersActivityTableLimit] = React.useState(15)
  const [unitVariable, setUnitVariable] = useState('Unit')

  const {
    offers,
    totalRecords: totalOffers,
    isLoading: isOffersLoading,
    refetch: fetchOffers,
    forceRefetch: forceRefetchOffers
  } = useGetOffers(appProject, {
    page: currentOffersTablePage,
    limit: offersTableLimit
  })

  const {
    usersOfferActivity,
    totalRecords: totalUsersOfferActivity,
    isLoading: gettingUserOffersActivity,
    refetch: fetchOffersActivity,
    forceRefetch: forceRefetchOffersActivity
  } = useGetUsersOfferActivity(appProject, {
    version: 1,
    page: currentOffersActivityTablePage,
    limit: offersActivityTableLimit
  })

  useEffect(() => {
    if (!!transactionId) {
      setSelectedTransactionId(transactionId);
      setIsContractDetailDrawerOpen(true);
    }
  }, [transactionId]);

  const openDocumentsModal = ({ id: offerId }) => {
    setOfferIdShowingDocs(offerId);
    setShowDocumentsModal(true);
  };

  // const onEditClick = ({ id: offerId }) => {
  //   history.push({
  //     pathname: ADMIN_OFFER,
  //     state: {
  //       appProject,
  //       offerId
  //     }
  //   })
  // }

  const confirmCancelOffer = ({ id: offerId, status }) => {
    if (status !== "OfferRejected") {
      setIdBeingDeleted(offerId);
      setShowCancelOfferModal(true);
    } else {
      message.warning("Offer already canceled");
    }
  };

  // const options = [
  //   {
  //     title: 'View Documents',
  //     icon: <EyeIcon className='w-6 h-6' />,
  //     onClick: (data) => {
  //       openDocumentsModal(data)
  //     }
  //   },
  //   {
  //     title: 'Edit',
  //     icon: <EditIcon className='w-6 h-6' />,
  //     onClick: (data) => {
  //       onEditClick(data)
  //     }
  //   },
  //   {
  //     title: 'Cancel Offer',
  //     icon: <CloseIcon className='w-6 h-6' />,
  //     onClick: (data) => {
  //       confirmCancelOffer(data)
  //     }
  //   }
  // ]

  // const EditButton = (offer) => {
  //   return (
  //     <Dropdown
  //       options={options.map((option) => {
  //         return { ...option, ...offer }
  //       })}
  //     />
  //   )
  // }

  const tableColumns = [
    {
      field: "createdAt",
      headerName: "Date Registered",
      resizable: true,
      filter: "agDateColumnFilter",
      headerComponent: () => (
        <span className="leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70">Date Registered</span>
      ),
      cellRenderer: (params) => (
        <div className="w-full flex flex-col">
          <p className="mb-0 truncate text-base font-normal">{moment(params.value).format("MM/DD/YYYY")}</p>
        </div>
      ),
      pinned: "left"
    },
    {
      field: "unitNumber",
      headerName: "Unit/Strata",
      resizable: true,
      flex: 1,
      minWidth: 250,
      filter: "agNumberColumnFilter",
      headerComponent: () => (
        <span className="leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70">Unit/Strata</span>
      ),
      cellRenderer: (params) => (
        <div className="w-full min-w-[120px] flex items-center gap-x-4">
          <div className="flex flex-col gap-x-4">
            <h5 className="truncate mb-0 text-lg font-bold"> {unitVariable || 'Unit'} #{params.value}</h5>
          </div>
        </div>
      ),
      pinned: "left"
    },
    {
      field: "buyer",
      headerName: "Primary buyer",
      resizable: true,
      minWidth: 250,
      filter: "agTextColumnFilter",
      headerComponent: () => (
        <span className="leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70">Primary buyer</span>
      ),
      cellRenderer: (params) => <p className="mb-0 text-base font-normal capitalize">{params.value}</p>,
    },
    {
      field: "totalCost",
      headerName: "Purchase price",
      sortable: true,
      filter: "agNumberColumnFilter",
      headerComponent: () => (
        <span className="leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70">Purchase price</span>
      ),
      cellRenderer: (params) => (
        <p className="max-w-[130px] truncate mb-0 text-base font-normal">{formatMoney(params.value)}</p>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      resizable: true,
      minWidth: 220,
      filter: "agTextColumnFilter",
      headerComponent: () => (
        <span className="leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70">Status</span>
      ),
      cellRenderer: (params) => <ColoredStatus status={params.value} />,
    },
    {
      field: "broker",
      headerName: "Broker",
      resizable: true,
      minWidth: 250,
      filter: "agTextColumnFilter",
      headerComponent: () => (
        <span className="leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70">Broker</span>
      ),
      cellRenderer: (params) => <p className="mb-0 text-base font-normal capitalize">{params.value}</p>,
    },
    {
      field: "salesRep",
      headerName: "Sales Rep",
      resizable: true,
      filter: "agTextColumnFilter",
      minWidth: 250,
      headerComponent: () => (
        <span className="leading-none text-sm font-normal text-[#2E2B2E] text-opacity-70">Sales Rep</span>
      ),
      cellRenderer: (params) => <p className="mb-0 text-base font-normal capitalize">{params.value}</p>,
    },
    {
      field: "_id",
      headerName: "",
      sortable: false,
      filter: false,
      minWidth: 250,
      cellRenderer: (params) => {
        return (
          <ActionButton
            onClick={() => {
              history.push(
                `${ADMIN_OFFERS}?transactionId=${params.value}`
              )
            }}
            openDocumentsModal={() =>
              openDocumentsModal({ id: params.value })
            }
          />
        )
      },
    },
  ]

  const getOffersStatistics = useCallback(async () => {
    if (appProject) {
      setGettingProjectStatistics(true);

      await getProjectStatistics(appProject).then((statistics) => {
        const { counters } = statistics;
        setProjectStatistics(counters);
      }).finally(() => {
        setGettingProjectStatistics(false)
      })
    }
  }, [appProject]);

  useEffect(() => {
    getOffersStatistics()
  }, [getOffersStatistics])

  useEffect(() => {
    setGettingProjectOffers(true)
    const tmpProjectOffers = offers
      ?.map((offer) => {
        const {
          buyer: { _id: buyerId, firstName, lastName, email, buyerData },
          unit: { unitNumber, price: unitPrice },
          project: { totalDollarValueCurrencyType },
          totalCost,
          status,
          _id,
          createdAt,
          leadBroker,
          salesRep
        } = offer;

        return {
          _id,
          createdAt,
          buyerId,
          buyer: `${firstName} ${lastName}`.toLowerCase(),
          buyerEmail: email,
          unitNumber,
          saleAmount: formatMoney(unitPrice),
          amountOptionsCost: formatMoney(totalCost),
          totalCost,
          totalDollarValueCurrencyType,
          status,
          broker: leadBroker?.length ? `${leadBroker[0]?.firstName || ""} ${leadBroker[0]?.lastName || ""}` : "-",
          salesRep: salesRep?.length ? `${salesRep?.[0]?.firstName || ""} ${salesRep?.[0]?.lastName || ""}` : "-",
        };
      })
      ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      ?.filter((x) => !x.deleted) || [];

    setProjectOffers(tmpProjectOffers);
    setGettingProjectOffers(false)
  }, [offers])

  useEffect(() => {
    setUserActivityOffers((prev) => [...prev, ...(usersOfferActivity && usersOfferActivity?.length ? usersOfferActivity : [])]);
  }, [usersOfferActivity])

  const values = useMemo(
    () => ({
      gettingProjectOffers,
      gettingstProjectStatistics: gettingProjectStatistics,
    }),
    [gettingProjectOffers, gettingProjectStatistics]
  )

  const offerComplete = useMemo(() => {
    const data = projectOffers.filter((offer) => offer.status === "Paid")

    return data
  }, [projectOffers])

  useEffect(() => {
    getProjects(appProject).then((project) => {
      const { unitVariable } = project;
      if (unitVariable) {
        setUnitVariable(unitVariable || 'Unit');
      }
    });
  }, [appProject]);


  const cancelOffer = () => {
    setShowCancelOfferModal(false);
    setCancelingOffer(true);
    cancelOfferAPI(idBeingDeleted)
      .then(() => {
        setCancelingOffer(false)
        setIdBeingDeleted("")
        // getData()
      })
      .catch(() => setCancelingOffer(false));
  };

  const [selectedTab, setSelectedTab] = useState("tab-1");

  const refetchAllData = () => {
    getOffersStatistics()
    forceRefetchOffers()
    setUserActivityOffers([])
    setCurrentOffersActivityTablePage(0)
    forceRefetchOffersActivity()
  }

  useEffect(() => {
    refetchAllData()
  }, [])

  const onOffersDocumentModalClose = () => {
    setShowDocumentsModal(false)
    refetchAllData()
  }

  const onContractDetailDrawerClose = () => {
    setIsContractDetailDrawerOpen(false)
    history.push(ADMIN_OFFERS)
    setSelectedTransactionId(null)
    refetchAllData()
  }

  return (
    <LoadingContext.Provider value={values}>
      <div className="h-full w-full flex flex-col items-center">
        <Modal
          open={showDocumentsModal}
          onClose={onOffersDocumentModalClose}
          aria-labelledby="Offer Documents"
          aria-describedby="Offer documents list"
        >
          <div
            className='font-openSans relative bg-white py-0 rounded-none md:rounded-lg shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-full md:h-[calc(100%-5px)] overflow-hidden w-screen md:w-[calc(100vw-5px)]'
          >
            <button
              className="absolute right-5 top-4.5 z-[500]"
              onClick={onOffersDocumentModalClose}
            >
              <CrossIcon />
            </button>
            <ContractSigningScreen
              entityId={offerIdShowingDocs}
              modalType={CONTRACT_TYPES.OFFER}
              currentUserType={userObject?.userType}
              setCloseDocument={onOffersDocumentModalClose}
              isShowPaymentButton
              setPrimaryBtnAction={(val) => setShowPaymentTypeModal(val)}
              isGroupedContracts
              sendContracts={sendOfferContractsToSelectedRoleV1}
            />
          </div>
        </Modal>
        <PaymentTypeModal
          {...{
            showPaymentTypeModal,
            setShowPaymentTypeModal,
            setShowPaymentMethodModal,
            setPaymentMethodId,
            setSuccessfulPayment,
          }}
        />
        <PaymentMethodModal
          {...{
            showPaymentMethodModal,
            setShowPaymentMethodModal,
            paymentMethodId,
            successfulPayment,
            setSuccessfulPayment,
            // setShowPaymentModal
          }}
        />
        <DeleteModal
          title="Cancel Offer"
          description="Are you sure you want to cancel this offer? It will be deleted permanently"
          isOpen={showCancelOfferModal}
          cancelButtonTitle="Close"
          setIsOpen={() => {
            setShowCancelOfferModal(false);
            setIdBeingDeleted("");
          }}
          confirmButtonTitle="Yes, cancel it"
          onConfirm={cancelOffer}
        />
        {/* <div className='grid grid-cols1 md:grid-cols-4 gap-4 w-full'>
          <CardComponent2
            IconSrc={
              <svg
                width='32'
                height='32'
                viewBox='0 0 32 32'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M22.6654 5.33301H9.33203V25.333H22.6654V5.33301Z'
                  fill='#2E2B2E'
                />
                <path d='M6.66667 8H4V22.6667H6.66667V8Z' fill='#2E2B2E' />
                <path d='M27.9987 8H25.332V22.6667H27.9987V8Z' fill='#2E2B2E' />
              </svg>
            }
            title='Total Available'
            amount={projectStatistics.sumUnitAvailable}
            noOfUnits={projectStatistics.counterUnitAvailable}
          />

          <CardComponent2
            IconSrc={<UserCheck />}
            title='Total Reserved'
            noOfUnits={projectStatistics.counterUnitReserved}
            amount={projectStatistics.sumUnitReserved}
          />

          <CardComponent2
            IconSrc={
              <svg
                width='33'
                height='32'
                viewBox='0 0 33 32'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M24.5 17.3334H23.5933L20.9267 20.0001H23.4733L25.8333 22.6667H7.16667L9.54 20.0001H12.2733L9.60667 17.3334H8.5L4.5 21.3334V26.6667C4.5 28.1334 5.68667 29.3334 7.15333 29.3334H25.8333C27.3 29.3334 28.5 28.1467 28.5 26.6667V21.3334L24.5 17.3334ZM23.1667 10.6001L16.5667 17.2001L11.8467 12.4801L18.4467 5.88005L23.1667 10.6001ZM17.5133 3.05339L9.02 11.5467C8.5 12.0667 8.5 12.9067 9.02 13.4267L15.62 20.0267C16.14 20.5467 16.98 20.5467 17.5 20.0267L25.98 11.5467C26.5 11.0267 26.5 10.1867 25.98 9.66672L19.38 3.06672C18.8733 2.53339 18.0333 2.53339 17.5133 3.05339Z'
                  fill='#2E2B2E'
                />
              </svg>
            }
            title='Total Offer Created'
            noOfUnits={projectStatistics.counterOfferCreated}
            amount={projectStatistics.sumOfferCreated}
          />

          <CardComponent2
            IconSrc={
              <svg
                width='33'
                height='32'
                viewBox='0 0 33 32'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M14.918 22.6663H17.5846V21.333H18.918C19.6513 21.333 20.2513 20.733 20.2513 19.9997V15.9997C20.2513 15.2663 19.6513 14.6663 18.918 14.6663H14.918V13.333H20.2513V10.6663H17.5846V9.33301H14.918V10.6663H13.5846C12.8513 10.6663 12.2513 11.2663 12.2513 11.9997V15.9997C12.2513 16.733 12.8513 17.333 13.5846 17.333H17.5846V18.6663H12.2513V21.333H14.918V22.6663ZM26.918 5.33301H5.58464C4.10464 5.33301 2.9313 6.51967 2.9313 7.99967L2.91797 23.9997C2.91797 25.4797 4.10464 26.6663 5.58464 26.6663H26.918C28.398 26.6663 29.5846 25.4797 29.5846 23.9997V7.99967C29.5846 6.51967 28.398 5.33301 26.918 5.33301ZM26.918 23.9997H5.58464V7.99967H26.918V23.9997Z'
                  fill='#2E2B2E'
                />
              </svg>
            }
            title='Total Sold Firm'
            noOfUnits={projectStatistics.counterSoldFirm}
            amount={projectStatistics.sumSoldFirm}
          />
        </div> */}
        <ContractsDetailsHeader
          projectStatistics={projectStatistics}
          gettingProjectStatistics={gettingProjectStatistics}
          projectInfo={projectInfo}
          userActivityOffers={userActivityOffers || []}
          setUserActivityOffers={setUserActivityOffers}
          gettingUserOffersActivity={gettingUserOffersActivity}
          page={currentOffersActivityTablePage}
          setPage={setCurrentOffersActivityTablePage}
          lastPage={Math.ceil(totalUsersOfferActivity / offersActivityTableLimit)}
        />
        <ContractFilterMenus
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <ContractDetailDrawer
          openDocumentsModal={openDocumentsModal}
          isContractDetailDrawerOpen={isContractDetailDrawerOpen}
          closeContractDetailDrawer={onContractDetailDrawerClose}
          transactionId={selectedTransactionId}
        />

        {/* {isMobile && filterProjectOffers?.length && (
          <div className='flex flex-col gap-4 mt-5 w-full'>
            {filterProjectOffers.map((offer) => (
              <div className='flex flex-col relative bg-white rounded p-4 gap-4'>
                <div className='text-lg'>
                  <strong>Name:</strong> {offer?.buyer?.name}
                </div>
                <div className='text-lg'>
                  <strong>Email:</strong> {offer?.buyerEmail}
                </div>
                <div className='text-lg'>
                  <strong>Unit:</strong> {offer?.unitNumber}
                </div>
                <div className='text-lg'>
                  <strong>Sales Amount:</strong>{' '}
                  {offer?.totalDollarValueCurrencyType}{' '}
                  {offer?.saleAmount?.props?.value}
                </div>
                <div className='text-lg'>
                  <strong>Status:</strong> {offer?.status}
                </div>
                <div className='absolute top-2 right-2'>
                  <EditButton id={offer._id} status={offer?.status} />
                </div>
              </div>
            ))}
          </div>
        )} */}
        {selectedTab === "tab-5" ? (
          <div className="mt-4 p-2 w-full overflow-x-auto">
            <OffersV2Table />
          </div>
        ) : (
          <div className="py-5 !pt-2 w-full">
            <TableWithPagination
              columns={tableColumns}
              rowData={projectOffers}
              totalCount={totalOffers}
              page={currentOffersTablePage}
              setPage={setCurrentOffersTablePage}
              limit={offersTableLimit}
              setLimit={setOffersTableLimit}
              loading={isOffersLoading || gettingProjectOffers}
              rowHeight={60}
              minHeight={658}
            />
          </div>
        )}
      </div>
    </LoadingContext.Provider>
  );
};

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject,
  userObject: state.authReducer.userObject,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectOffers);

ProjectOffers.propTypes = {
  appProject: propTypes.string,
  userObject: propTypes.shape({
    userType: propTypes.string,
  }),
  cell: propTypes.shape({
    value: propTypes.string,
  }),
  row: propTypes.shape({
    original: propTypes.shape({
      _id: propTypes.string,
    }),
  }),
};

StatisticValue.propTypes = {
  title: propTypes.string,
  value: propTypes.oneOfType([propTypes.object, propTypes.string]),
  loadingObject: propTypes.object,
};

CardComponent2.propTypes = {
  IconSrc: propTypes.node,
  title: propTypes.string,
  amount: propTypes.number,
  noOfUnits: propTypes.number,
};

// MemoTable.propTypes = {
//   dataSource: propTypes.array,
//   columns: propTypes.array,
//   loading: propTypes.bool,
//   idBeingDeleted: propTypes.string,
//   deleting: propTypes.bool
// }
