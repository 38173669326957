/* eslint-disable */
// @ts-nocheck
import { Drawer, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import useGetLists from 'hooks/useGetLists';
import {
  // useParams,
  useHistory
} from 'react-router-dom';
import { createOrUpdateEmailCampaign } from 'store/actions/customEmailCampaignActions';
import { ADMIN_EMAIL_CUSTOM_CAMPAIGNS_BUILDER } from 'routes';
// import useGetProjectBuyers from 'hooks/useGetProjectBuyers';
import { ArrowDownIcon } from 'components';
// import { getEmailTemplates } from 'store/actions/emailTemplateActions';
import CustomButton from 'components/CustomButton';
import EmailTemplateSelector from 'components/EmailTemplateSelector';
import AddNewEmailTemplateModal from 'pages/emailCampaignsBuilder/AddNewEmailTemplateModal';

const CreateEmailCampaignForList = ({ onClose, open, selectedListId }) => {
  const history = useHistory();
  const [emailCampaign, setEmailCampaign] = useState({});
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const { lists, isLoading } = useGetLists();
  // const { loading: loadingBuyers, buyers } = useGetProjectBuyers();
  // const [selectedBuyers, setSelectedBuyers] = useState([]);
  const [formattedList, setFormattedList] = useState([]);
  // const [emailTemplates, setEmailTemplates] = useState([]);
  // const [selectedTemplate, setSelectedTemplate] = useState(null);
  // const [showPreview, setShowPreview] = useState(true);
  const [creatingCampaign, setCreatingCampaign] = useState(false);

  const handleClose = () => {
    setEmailCampaign({})
    onClose()
  }

  const handleSubmit = () => {
    console.log(emailCampaign);
    setCreatingCampaign(true);
    createOrUpdateEmailCampaign({
      ...emailCampaign,
    }).then(() => {
      history.push(ADMIN_EMAIL_CUSTOM_CAMPAIGNS_BUILDER)
    }).finally(() => {
      handleClose()
      setCreatingCampaign(false);
    })
  };

  useEffect(() => {
    setFormattedList(
      lists?.map?.((val) => ({
        label: `${val.title} (a-${val.users.length}, s-${val?.sendGrid?.contacts || 0})`,
        value: val._id,
        id: val._id,
      })) || null
    );

    if (selectedListId) {
      setEmailCampaign({
        ...emailCampaign,
        list: selectedListId,
      })
    }
  }, [lists]);

  // const getAllTemplates = () => {
  //   getEmailTemplates().then((response) => {
  //     setEmailTemplates(response.map((val) => ({
  //       label: val.name,
  //       value: val._id,
  //       html: val?.unlayerConfig?.html
  //     })));
  //   }).catch((error) => {
  //     console.log(error)
  //     setIsLoading(false)
  //   })
  // }

  // useEffect(() => {
  //   getAllTemplates()
  // }, [open])

  return (
    <>
      <Drawer closable={false} placement='right' onClose={handleClose} open={open} className='min-w-[500px] !z-[300]' width={800}>
        <div className='px-8 py-8'>
          <div className='flex items-center justify-between w-full'>
            <h1 className='text-2xl font-semibold h-full items-center m-0 p-0'>Send Email Campaign</h1>
            <div className='flex gap-x-2'>
              <button
                className='text-red-500 text-center text-base font-medium py-2 px-6 rounded flex items-center border border-red-400'
                onClick={handleClose}
                disabled={creatingCampaign}
              >
                Cancel
              </button>
              <CustomButton handleClick={handleSubmit} disabled={creatingCampaign}>
                <span className='font-medium placeholder py-2 px-4 text-base flex gap-2 items-center'>
                  {creatingCampaign ? 'Creating Campaign...' : 'Create Campaign'}
                </span>
              </CustomButton>
            </div>
          </div>
          <div className='flex flex-col gap-y-3 my-10 mb-14'>
            <div className='flex flex-1 flex-col justify-left'>
              <div className='font-bold text-softBlack_70 italic'>
                Name of your Email Campaign
              </div>
              <input
                onChange={(e) =>
                  setEmailCampaign({
                    ...emailCampaign,
                    name: e.target.value,
                  })
                }
                placeholder='Email Campaign Name'
              />
            </div>
            <div className='flex flex-1 flex-col justify-left'>
              <div className='font-bold text-softBlack_70 italic'>
                Email Subject
              </div>
              <input
                onChange={(e) =>
                  setEmailCampaign({
                    ...emailCampaign,
                    subject: e.target.value,
                  })
                }
                placeholder='Email Subject'
              />
            </div>
            <div className='flex-col justify-left '>
              <div className='font-bold text-softBlack_70 italic'>
                Select List
              </div>
              <Select
                suffixIcon={<ArrowDownIcon />}
                showArrow
                disabled={selectedListId}
                getPopupContainer={trigger => trigger.parentNode}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toString()
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(value) =>
                  setEmailCampaign({
                    ...emailCampaign,
                    list: value,
                  })
                }
                value={emailCampaign?.list || 'Select from here'}
                placeholder='Select from here'
                className='rating-select text-sm bg-white border border-softBlack30 rounded text-softBlack placeholder-softBlack70 h-10'
              >
                {formattedList !== [] && formattedList?.map?.((_item) => (
                  <Select.Option
                    value={_item?.value}
                    key={_item.value}
                    label={_item?.label}
                  >
                    <div className='flex gap-2 items-center'>
                      <span className='h-full'>{_item.label}</span>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className='flex-col justify-left'>
              <EmailTemplateSelector
                label='Choose Email Template'
                labelClassName='!font-bold !text-base !text-softBlack_70 !italic !capitalize'
                value={emailCampaign?._id ?? null}
                onChange={(value) => {
                  setEmailCampaign({
                    ...emailCampaign,
                    _id: value,
                  })
                }}
                hasPreview
              />
              {/* <Select
                suffixIcon={<ArrowDownIcon />}
                showArrow
                getPopupContainer={trigger => trigger.parentNode}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toString()
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(value) => {
                  setEmailCampaign({
                    ...emailCampaign,
                    _id: value,
                  })
                  const selectedTemplate = templates.find((val) => val.value === value)
                  setSelectedTemplate(selectedTemplate)
                }}
                value={emailCampaign?._id || 'Select Email Template'}
                placeholder='Select Email Template'
                className='rating-select text-sm bg-white border border-softBlack30 rounded text-softBlack placeholder-softBlack70 h-10'
              >
                {emailTemplates !== [] && emailTemplates?.map?.((_item) => (
                  <Select.Option
                    value={_item?.value}
                    key={_item.value}
                    label={_item?.label}
                  >
                    <div className='flex gap-2 items-center'>
                      <span className='h-full'>{_item.label}</span>
                    </div>
                  </Select.Option>
                ))}
              </Select> */}
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default CreateEmailCampaignForList;
