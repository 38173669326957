/* eslint-disable */
// @ts-nocheck

import React, { useCallback, useEffect, useMemo, useState } from "react"
import { api } from "services/api"
import { DOCUSEAL_ROLES } from "utils"
import { CONTRACT_STATUSES, CONTRACT_TYPES } from "./constants"
import ContractThumbnail from "./components/ContractThumbnail"
import FadedAnimatedDiv from "components/FadedAnimatedDiv"
import LottieWrapper from "components/LottieWrapper"
import loadingContractsAnimation from "assets/lottieJsons/loadingDoc.json"
import SignatureHistoryModal from "./components/SignatureHistoryModal"
import { ContractsCarouselWrapper, ContractsWrapper, ContractWrapper, LeftWrapper, RightWrapper } from "./styles"
import { pdfjs } from "react-pdf"

import { AnimatePresence } from "framer-motion"
import { Divider, Row } from "antd"
import { useSelector } from "react-redux"
import ContractSignedPanel from "./components/ContractSignedPanel"
import { lazy, Suspense } from "react"
const DocusealForm = lazy(() => import("@docuseal/react").then((module) => ({ default: module.DocusealForm })))
const PdfDocument = lazy(() => import("./components/PdfDocument"))
import { ChevronDown, ChevronLeft, CircleCheck, CircleX, ZoomIn, ZoomOut } from "lucide-react"
import CustomButton from "components/CustomButton"
import { Drawer, makeStyles } from "@material-ui/core"
import { isMobile } from "react-device-detect"
import PdfPagination from "./components/PdfPagination"
import { Oval } from "react-loader-spinner";
import RoleSelectionModal from "./components/RoleSelectionModal"

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export const isSelectedContractSignedByUser = (selectedContract, userRole) => {
  if (!selectedContract) {
    return false
  }

  const isSigned =
    selectedContract?.signatures?.filter(
      (signature) => signature.userType === userRole && signature.status === CONTRACT_STATUSES.SIGNED,
    )?.length > 0

  return isSigned
}

const formatNumber = (value) => {
  if (value % 1 !== 0) {
    return value.toFixed(2)
  }
  return value.toString()
}

const ContractSigningScreen = ({
  entityId = null,
  isPublicPath = false,
  contracts = [],
  modalType = null,
  isGroupedContracts = false,
  isShowPaymentButton = false,
  currentUserType = DOCUSEAL_ROLES.BUYER,
  handleGoBack = () => { },
  setCloseDocument = () => { },
  setPrimaryBtnAction = (val) => { },
  sendContracts = (id, role) => { },
}) => {
  const customSections = useSelector((state) => state.appReducer.customSections)

  const [gettingContracts, setGettingContracts] = useState(false)
  const [listOfContracts, setListOfContracts] = useState(contracts)
  const [selectedContract, setSelectedContract] = useState(null)
  const [docusealSignUrl, setDocusealSignUrl] = useState("")
  const [isShowDocusealContractSigningForm, setIsShowDocusealContractSigningForm] = useState(false)
  const [customSectionContractTemplateTypes, setCustomSectionContractTemplateTypes] = useState([])
  const [isShowSignatureHistoryModal, setIsShowSignatureHistoryModal] = useState(false)
  const [selectedContractListId, setSelectedContractListId] = useState(null)
  const [totalSignedContracts, setTotalSignedContracts] = useState(null)
  const [totalUnsignedContracts, setTotalUnsignedContracts] = useState(null)
  const [totalPDFPages, setTotalPDFPages] = useState(0)
  const [pdfPageNumber, setPdfPageNumber] = useState(1)
  const [pdfScale, setPdfScale] = useState(isMobile ? 0.5 : 1)
  const [isRightPanelOpen, setIsRightPanelOpen] = useState(false)
  const [sendingEmail, setSendingEmail] = useState({
    [DOCUSEAL_ROLES.BUYER]: false,
    [DOCUSEAL_ROLES.SALES_REP]: false,
    [DOCUSEAL_ROLES.DEV_ADMIN]: false
  })
  const [isShowRoleSelectionModal, setIsShowRoleSelectionModal] = useState(false)
  const [singularContractVersion, setSingularContractVersion] = useState(1)

  const decreaseSelectedContractPage = (e) => {
    e.stopPropagation()
    if (pdfPageNumber > 1) {
      const newNumber = Number(pdfPageNumber) - 1
      setPdfPageNumber(newNumber)
    }
  }

  const increaseSelectedContractPage = (e) => {
    e.stopPropagation()
    if (pdfPageNumber < totalPDFPages) {
      const newNumber = Number(pdfPageNumber) + 1
      setPdfPageNumber(newNumber)
    }
  }

  const handleLoadSuccess = (pdf) => {
    setTotalPDFPages(pdf._transport._numPages)
  }

  const useStyles = makeStyles((theme) => ({
    hide: {
      display: "none",
    },
    drawer: {
      width: "100%",
      height: "100%",
      flexShrink: 0,
    },
    drawerPaper: {
      width: "100%",
      height: "100%",
      overflow: "hidden",
      boxShadow: "-10px 0px 15px 0px rgba(46, 43, 46, 0.05)",
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
  }))

  const classes = useStyles()

  useEffect(() => {
    if (selectedContract?.signatures?.length) {
      const tempSignatures = selectedContract?.signatures.filter(
        (signature) =>
          signature.userType === currentUserType &&
          [CONTRACT_STATUSES.AWAITING, CONTRACT_STATUSES.AWAITING_SIGNATURE].includes(signature.status),
      )

      if (tempSignatures && tempSignatures.length) {
        const { url } = tempSignatures[0]
        setDocusealSignUrl(url)
      }
    }
  }, [selectedContract, currentUserType])

  const fetchListOfContracts = async () => {
    if (isPublicPath && contracts?.length) {
      setListOfContracts(contracts)
      setSelectedContract(contracts[0])
      return
    }

    if (!entityId || !modalType) return

    const apiConfig = {
      [CONTRACT_TYPES.OFFER]: { url: `/offers/${entityId}/contracts/preview`, method: 'post' },
      [CONTRACT_TYPES.RESERVATION]: { url: `/reservation/contracts/reservation/preview/${entityId}`, method: 'get' },
      [CONTRACT_TYPES.SINGULAR_CONTRACT]: { url: `/singularcontracts/contract/preview/${entityId}`, method: 'get' }
    }

    const apiUrl = apiConfig[modalType].url
    const apiMethod = apiConfig[modalType].method

    if (!apiUrl) return

    setGettingContracts(true)

    try {
      const { data } = await api[apiMethod](apiUrl, {})
      if (data?.contracts?.length) {
        setListOfContracts(data.contracts)
        setSelectedContract(data.contracts[0])
        if (modalType === CONTRACT_TYPES.SINGULAR_CONTRACT) {
          setSingularContractVersion(data.version)
        }
      }
    } catch (err) {
      console.error(err)
    } finally {
      setGettingContracts(false)
    }
  }

  useEffect(() => {
    fetchListOfContracts()
  }, [])

  const canUserSign = useMemo(() => {
    if (!selectedContract) {
      return false
    }

    const currentIndex = selectedContract?.signatures?.findIndex((signature) => signature.userType === currentUserType)

    if (currentIndex === 0) {
      return true
    } else if (currentIndex === -1) {
      return false
    } else {
      return selectedContract?.signatures[currentIndex - 1]?.status === CONTRACT_STATUSES.SIGNED
    }
  }, [selectedContract, currentUserType])

  const canUserSignAsSelectedRole = useCallback((role) => {
    if (!selectedContract) {
      return false
    }

    const currentIndex = selectedContract?.signatures?.findIndex((signature) => signature.userType === role)

    if (currentIndex === 0) {
      return true
    } else if (currentIndex === -1) {
      return false
    } else {
      return selectedContract?.signatures[currentIndex - 1]?.status === CONTRACT_STATUSES.SIGNED
    }
  }, [selectedContract])

  const isUserExists = useMemo(() => {
    if (!selectedContract) {
      return false
    }

    const currentIndex = selectedContract?.signatures?.findIndex((signature) => signature.userType === currentUserType)

    if (currentIndex === -1) {
      return false
    } else {
      return true
    }
  }, [selectedContract, currentUserType])

  const isSelectedUserTypeAvailable = useCallback((selectedUserType) => {
    if (!selectedContract) {
      return false
    }

    return selectedContract?.signatures?.some((signature) => signature.userType === selectedUserType)
  }, [selectedContract])

  const isCurrentUserSignedSelectedContract = useMemo(() => {
    return isSelectedContractSignedByUser(selectedContract, currentUserType)
  }, [selectedContract, currentUserType])

  const isBuyerSignedSelectedContract = useMemo(() => {
    return isSelectedContractSignedByUser(selectedContract, DOCUSEAL_ROLES.BUYER)
  }, [selectedContract])

  const isPrimaryBuyerSignedSelectedContract = useMemo(() => {
    return isSelectedContractSignedByUser(selectedContract, DOCUSEAL_ROLES.PRIMARY_BUYER)
  }, [selectedContract])

  const isSecondaryBuyerSignedSelectedContract = useMemo(() => {
    return isSelectedContractSignedByUser(selectedContract, DOCUSEAL_ROLES.SECONDARY_BUYER)
  }, [selectedContract])

  const isDeveloperSignedSelectedContract = useMemo(() => {
    return isSelectedContractSignedByUser(selectedContract, DOCUSEAL_ROLES.DEV_ADMIN)
  }, [selectedContract])

  const isSalesRepSignedSelectedContract = useMemo(() => {
    return isSelectedContractSignedByUser(selectedContract, DOCUSEAL_ROLES.SALES_REP)
  }, [selectedContract])

  const setDocSigned = useCallback((docusealResponse) => {
    if (docusealResponse.status !== 'completed') return null

    const role = docusealResponse?.role
    const emailOfSigner = docusealResponse?.email

    let contractToSelect = {}
    const tmpContracts = listOfContracts?.map?.((contract) => {
      const tmpContract = { ...contract }
      if (contract.contractTemplateId === selectedContract?.contractTemplateId) {
        tmpContract.signatures = tmpContract?.signatures?.map((signature) => {
          const tmpSignature = { ...signature }

          if (tmpSignature.userType === role && tmpSignature.email === emailOfSigner) {
            tmpSignature.status = CONTRACT_STATUSES.SIGNED
          }

          return tmpSignature
        })
        tmpContract.signed = true

        contractToSelect = { ...tmpContract }
      }
      return tmpContract
    })

    setSelectedContract(contractToSelect)
    setListOfContracts(tmpContracts)
    setIsShowDocusealContractSigningForm(false)
  }, [listOfContracts, selectedContract])

  const onSignDocumentClicked = useCallback(
    async (role) => {
      if (selectedContract) {
        setIsShowDocusealContractSigningForm(true)
        try {
          const tempSignatures = selectedContract?.signatures.filter((signature) => signature.userType === role)
          if (tempSignatures && tempSignatures.length) {
            const { url } = tempSignatures[0]
            setDocusealSignUrl(url)
          }
        } catch (err) {
          console.error(err)
        }
      }
    },
    [selectedContract],
  )

  const isAllSigned = useMemo(() => {
    return listOfContracts?.every((contract) => {
      return contract.signatures?.every((signature) => signature.status === CONTRACT_STATUSES.SIGNED)
    })
  }, [listOfContracts])

  const isAllContractSignedByCurrentUser = useMemo(() => {
    return listOfContracts?.every((contract) => {
      return contract.signatures?.filter((signature) => (signature.userType === currentUserType))?.every((signature) => (signature.status === CONTRACT_STATUSES.SIGNED))
    })
  }, [listOfContracts, currentUserType])

  const isAllSignedForSelectedContract = useMemo(() => {
    if (!selectedContract?.signatures?.length) {
      return false
    }

    return selectedContract?.signatures?.every((signature) => signature.status === CONTRACT_STATUSES.SIGNED)
  }, [selectedContract])

  const contractTypesOption = useMemo(() => {
    if (!(listOfContracts?.length > 0)) {
      return []
    }

    const types = [
      { type: "reservation", label: "Reservation" },
      { type: "offer", label: "Offer" },
      { type: "unit", label: "Unit/Lot" },
      { type: "parking", label: "Parking" },
      { type: "storage", label: "Storage" },
      { type: "upgrades", label: "Upgrades" },
      ...customSectionContractTemplateTypes,
    ]

    const temp = listOfContracts
      ?.map((contract) => ({
        ...contract,
        type: types.find((elm) => elm.type === contract?.contractTemplate?.contractType)?.label || "Other",
      }))
      ?.filter((e) => e?.type?.length > 0)

    if (!isGroupedContracts && temp?.length > 0) {
      let tempTotalSignedContracts = 0
      let tempTotalUnsignedContracts = 0

      const nonGroupContractsTemp = temp?.map((contract, index) => {
        const isSigned = !(contract.signatures.filter((signature) => signature.status !== "signed")?.length > 0)
        if (isSigned) {
          tempTotalSignedContracts++
        } else {
          tempTotalUnsignedContracts++
        }

        return {
          id: `non-grouped-contract-${index}`,
          title: contract.contractTemplate.name,
          items: [{ ...contract }],
          signedCount: contract.signatures.filter((signature) => signature.status === "signed")?.length,
          unsignedCount: contract.signatures.filter((signature) => signature.status !== "signed")?.length,
        }
      })

      setTotalUnsignedContracts(tempTotalUnsignedContracts)
      setTotalSignedContracts(tempTotalSignedContracts)

      if (!selectedContract) {
        setSelectedContract(nonGroupContractsTemp?.[0]?.items?.[0])
      }
      if (!selectedContractListId) {
        setSelectedContractListId(nonGroupContractsTemp?.[0] ?? null)
      }
      return nonGroupContractsTemp
    } else {
      const groupedData = temp?.reduce((acc, curr, index) => {
        const type = curr.type
        if (!acc[type]) {
          acc[type] = { id: `grouped-contract-${index}`, title: type, items: [] }
        }
        acc[type].items.push(curr)
        return acc
      }, {})
      if (groupedData) {
        let tempTotalSignedContracts = 0
        let tempTotalUnsignedContracts = 0

        Object.values(groupedData).forEach((group) => {
          let signedCount = 0
          let unsignedCount = 0

          group.items.forEach((contract) => {
            const isSigned = !(
              contract.signatures.filter((signature) => signature.status !== CONTRACT_STATUSES.SIGNED)?.length > 0
            )
            if (isSigned) {
              tempTotalSignedContracts++
              signedCount++
            } else {
              tempTotalUnsignedContracts++
              unsignedCount++
            }
          })

          group.signedCount = signedCount
          group.unsignedCount = unsignedCount
        })

        setTotalUnsignedContracts(tempTotalUnsignedContracts)
        setTotalSignedContracts(tempTotalSignedContracts)

        const result = Object.values(groupedData)
        if (!selectedContract) {
          setSelectedContract(result?.[0]?.items?.[0])
        }
        if (!selectedContractListId) {
          setSelectedContractListId(result?.[0] ?? null)
        }
        return result
      }
    }

    return []
  }, [
    customSectionContractTemplateTypes,
    listOfContracts,
    isGroupedContracts,
    selectedContract,
    selectedContractListId,
  ])

  const onSelectDoc = useCallback(
    (docProps) => {
      const newSelectedListId =
        contractTypesOption?.find((contractType) =>
          contractType.items.some((contract) => contract.contractId === docProps.contractId),
        ) ?? null
      setPdfPageNumber(1)
      setIsShowDocusealContractSigningForm(false)
      setSelectedContractListId(newSelectedListId)
      setSelectedContract(docProps)
      setIsRightPanelOpen(true)
    },
    [contractTypesOption],
  )

  const onSignNextContractClicked = useCallback(() => {
    if (!listOfContracts?.length) {
      return
    }
    const nextContractToSign =
      listOfContracts?.find((contract) => {
        return contract.signatures?.some((signature) => signature.status !== CONTRACT_STATUSES.SIGNED) ?? null
      }) ?? null
    if (nextContractToSign) {
      onSelectDoc(nextContractToSign)
    }
  }, [listOfContracts, onSelectDoc])

  useEffect(() => {
    if (customSections?.length) {
      const tempCustomSectionMenus = customSections.map((section) => ({
        type: section._id,
        label: section.title,
      }))
      setCustomSectionContractTemplateTypes(tempCustomSectionMenus)
    }
  }, [customSections])

  const signNextContract = useCallback(() => {
    if (!listOfContracts?.length) {
      return
    }
    const nextContractToSign =
      listOfContracts?.filter((contract) => (contract?.contractId !== selectedContract?.contractId))?.find((contract) => {
        return contract.signatures?.some((signature) => (signature.userType === currentUserType && signature.status !== CONTRACT_STATUSES.SIGNED)) ?? null
      }) ?? null
    if (nextContractToSign) {
      onSelectDoc(nextContractToSign)
    }
  }, [listOfContracts, onSelectDoc, selectedContract])

  const sendEmailToUser = async (role) => {
    setSendingEmail((prev) => ({
      ...prev,
      [role]: true
    }))

    try {
      await sendContracts(entityId, role)
    } catch (error) {
      console.log('Error occur while send email to' + ' ' + role, error)
    } finally {
      setSendingEmail((prev) => ({
        ...prev,
        [role]: false
      }))
    }
  }

  const AccordionItem = React.memo(
    ({ item }) => {
      const { id, title, signedCount, unsignedCount, items } = item
      if (selectedContractListId === null) return null
      return (
        <div
          key={`accordion-${id}`}
          className={`self-stretch rounded-lg border-2 flex-col justify-start items-start flex transition-all cursor-pointer
          ${selectedContractListId.id === item.id ? "bg-[#EDDF65]/[35%] border-[#EDDF65]" : "hover:bg-[#EDDF65]/[35%] border-zinc-200 dark:border-zinc-400 hover:border-[#EDDF65]"}
        `}
        >
          <div
            className="w-full justify-between items-center inline-flex gap-2 p-2 xl:px-4 xl:py-3.5"
            onClick={() => {
              setSelectedContract(items?.[0])
              setSelectedContractListId(item)
              setIsShowDocusealContractSigningForm(false)
              setPdfPageNumber(1)
            }}
          >
            <div className="justify-start items-center gap-2 flex">
              <div className="flex-col justify-start items-start gap-0.5 inline-flex">
                <p className="text-zinc-950 text-xs xl:text-sm font-semibold leading-tight mb-0">{title}</p>
              </div>
            </div>
            <div className="flex-1 justify-end items-center gap-1 xl:gap-2 flex">
              <div className="justify-start items-center gap-1 flex">
                {signedCount + unsignedCount === signedCount ? (
                  <CircleCheck className="w-5 h-5 text-green-500" />
                ) : (
                  <CircleX className="w-5 h-5 text-red-500" />
                )}
                {isGroupedContracts && (
                  <p className="text-[10px] xl:text-xs font-normal leading-none truncate mb-0">
                    {signedCount + unsignedCount} contracts
                  </p>
                )}
              </div>
              <ChevronDown
                className={`w-4 h-4 text-[#71717A] dark:text-zinc-100 transition-all ${selectedContractListId.id === item.id ? "rotate-0" : "rotate-180"}`}
              />
            </div>
          </div>
          {selectedContractListId.id === item.id && items?.length > 0 && (
            <div className="w-full px-2 pb-2 pt-0 xl:px-4 xl:py-3.5 xl:pt-0 flex-col justify-start items-start gap-2 xl:gap-4 flex">
              {items?.map?.((contract, index) => {
                return (
                  <ContractThumbnail
                    key={index}
                    contractData={contract}
                    index={index}
                    selected={isPublicPath ? (contract?._id === selectedContract?._id) : (contract?.contractId === selectedContract?.contractId)}
                    onSelectDoc={onSelectDoc}
                    onChangePdfPage={(pageNumber) => setPdfPageNumber(pageNumber)}
                    onLoadSuccess={() => { }}
                    isGroupedContracts={isGroupedContracts}
                    // isAllSigned={isAllSignedForSelectedContract}
                    pdfPageNumber={pdfPageNumber}
                    setPdfPageNumber={setPdfPageNumber}
                  />
                )
              })}
            </div>
          )}
        </div>
      )
    },
    [selectedContractListId, selectedContract],
  )

  const Loader = () => {
    return (
      <FadedAnimatedDiv key="loadingContracts" className="flex flex-col justify-center items-center h-full px-4">
        <LottieWrapper animation={loadingContractsAnimation} loop autoplay className="max-w-lg" />
        <span className="text-xl text-center">We are now working to show you the generated contracts</span>
      </FadedAnimatedDiv>
    )
  }

  const LeftSideContent = () => {
    return (
      <LeftWrapper xs={24} sm={24} md={7} xl={5} className="w-full md:border-r flex flex-col">
        <div className="w-full p-[16px] px-[50px] border-b text-xl font-medium text-center truncate">
          {`Sign ${listOfContracts?.length} ${listOfContracts?.length > 1 ? "contracts" : "contract"}`}
        </div>
        <div className="flex-1 w-full p-[16px] flex-col justify-start items-start gap-2 xl:gap-4 inline-flex overflow-y-auto thin-scrollbar">
          <div className="self-stretch h-9 flex-col justify-start items-start gap-2 flex">
            <p className="text-zinc-950 text-xs xl:text-sm font-semibold leading-tight mb-0">
              {formatNumber((totalSignedContracts / (totalSignedContracts + totalUnsignedContracts)) * 100)}% complete
            </p>
            <div className="self-stretch justify-start items-center gap-3 inline-flex">
              <div className="w-full h-2 relative rounded-lg">
                <div className="w-full h-2 left-0 top-0 absolute bg-zinc-200 dark:bg-zinc-800 rounded" />
                <div
                  style={{
                    width: `${(totalSignedContracts / (totalSignedContracts + totalUnsignedContracts)) * 100}%`,
                  }}
                  className="w-[94.88px] h-2 left-0 top-0 absolute bg-zinc-900 dark:bg-zinc-300 rounded transition-all"
                />
              </div>
            </div>
          </div>
          {contractTypesOption?.map((item) => (
            <AccordionItem key={item.id} item={item} />
          ))}
        </div>
        {
          isPublicPath && isMobile &&
          <div className="w-full flex justify-center items-center gap-3 flex-wrap border-t py-2.5">
            <CustomButton
              handleClick={handleGoBack}
              variant="outline"
            >
              <span className="font-semibold px-4 py-2 rounded text-base flex items-center gap-2">
                Go back
              </span>
            </CustomButton>
          </div>
        }
      </LeftWrapper>
    )
  }

  const RightSideContent = () => {
    return (
      <RightWrapper className="relative overflow-hidden h-full">
        {
          isMobile &&
          <button
            onClick={() => {
              setIsRightPanelOpen(false)
              setIsShowDocusealContractSigningForm(false)
            }}
            className="absolute left-4.5 top-4.5 z-[50]"
          >
            <ChevronLeft className="h-6 w-6" />
          </button>
        }
        <div className="w-full p-[16px] px-[50px] border-b text-xl font-medium text-center truncate">
          {selectedContract?.contractTemplate?.name ?? "Loading..."}
        </div>
        {!isAllSignedForSelectedContract && !isShowDocusealContractSigningForm && (
          <Row
            justify="start"
            style={{
              width: "100%",
              position: "sticky",
              top: 0,
              right: 0,
              zIndex: 10,
            }}
          >
            <div className="w-full flex items-center justify-between gap-1 p-2">
              <div className="h-full flex items-center gap-1 ">
                <CustomButton variant="ghost" className="p-2 hover:!bg-zinc-200/80 transition-all" handleClick={() => setPdfScale(pdfScale + 0.1)}>
                  <ZoomIn className="h-6 w-6" />
                </CustomButton>
                <Divider style={{ height: "60%", background: "black" }} type="vertical" />
                <CustomButton variant="ghost" className="p-2 hover:!bg-zinc-200/80 transition-all" handleClick={() => setPdfScale(pdfScale - 0.1)}>
                  <ZoomOut className="h-6 w-6" />
                </CustomButton>
              </div>
              <PdfPagination
                pdfPageNumber={pdfPageNumber}
                // setPdfPageNumber={setPdfPageNumber}
                pdfNumPages={totalPDFPages}
                decreaseSelectedContractPage={decreaseSelectedContractPage}
                increaseSelectedContractPage={increaseSelectedContractPage}
              />
            </div>
          </Row>
        )}
        <ContractsCarouselWrapper className={`bg-zinc-50 ${!isAllSignedForSelectedContract && !isShowDocusealContractSigningForm ? 'border-y' : 'border-b'}`}>
          <AnimatePresence exitBeforeEnter initial={false} className="absolute">
            <Suspense fallback={<div>Loading...</div>}>
              {isShowDocusealContractSigningForm && docusealSignUrl ? (
                <FadedAnimatedDiv
                  style={{ height: "100%", width: "100%" }}
                  className="overflow-y-auto"
                  key="docusealAnimatedDiv"
                >
                  <DocusealForm
                    src={docusealSignUrl}
                    withTitle={false}
                    withDownloadButton={false}
                    withSendCopyButton={false}
                    allowToResubmit={false}
                    onComplete={(res) => {
                      setDocSigned(res)
                      setIsShowDocusealContractSigningForm(false)
                    }}
                    customCss=".ds { height: 100% !important; }"
                  />
                </FadedAnimatedDiv>
              ) : (
                <FadedAnimatedDiv key="pdfPreview" style={{ height: "100%", width: "100%" }}>
                  <ContractWrapper>
                    {isAllSignedForSelectedContract && (
                      <ContractSignedPanel
                        contract={selectedContract}
                        onCloseScreenClicked={() => {
                          if (isAllSigned) {
                            setCloseDocument()
                          }
                        }}
                        isAllSigned={isAllSigned}
                        onSignNextContractClicked={() => {
                          if (!isAllSigned) {
                            onSignNextContractClicked()
                          }
                        }}
                      />
                    )}
                    {!isAllSignedForSelectedContract && (
                      <div className="w-full flex justify-center items-center overflow-x-auto">
                        <Suspense fallback={<div>Loading...</div>}>
                          <div className="w-fit max-w-full border shadow-md">
                            <PdfDocument
                              pageProps={{ scale: pdfScale }}
                              documentProps={{
                                file: selectedContract?.url,
                              }}
                              pageNumber={pdfPageNumber}
                              onLoadSuccess={handleLoadSuccess}
                            />
                          </div>
                        </Suspense>
                      </div>
                    )}
                  </ContractWrapper>
                </FadedAnimatedDiv>
              )}
            </Suspense>
          </AnimatePresence>
        </ContractsCarouselWrapper>
        <div className="w-full px-4 mt-[10px] md:mt-[20px] flex flex-wrap justify-center items-center gap-3">
          {
            !isPublicPath &&
            <>
              {isShowPaymentButton && (
                <CustomButton
                  handleClick={() => setPrimaryBtnAction(true)}
                >
                  <span className="font-semibold px-4 py-2 rounded text-base flex items-center gap-2">Take Payment</span>
                </CustomButton>
              )}
              {currentUserType === DOCUSEAL_ROLES.SALES_REP && !isDeveloperSignedSelectedContract && isSelectedUserTypeAvailable(DOCUSEAL_ROLES.DEV_ADMIN) && (
                <>
                  <CustomButton
                    handleClick={() => sendEmailToUser(DOCUSEAL_ROLES.DEV_ADMIN)}
                    disabled={sendingEmail?.[DOCUSEAL_ROLES.DEV_ADMIN]}
                  >
                    <span className="font-semibold px-4 py-2 rounded text-base flex items-center gap-2">
                      {sendingEmail?.[DOCUSEAL_ROLES.DEV_ADMIN] && <Oval height="18" width="18" color="white" />}
                      Send to Developer
                    </span>
                  </CustomButton>
                </>
              )}
              {currentUserType === DOCUSEAL_ROLES.DEV_ADMIN && !isSalesRepSignedSelectedContract && isSelectedUserTypeAvailable(DOCUSEAL_ROLES.SALES_REP) && (
                <>
                  <CustomButton
                    handleClick={() => sendEmailToUser(DOCUSEAL_ROLES.SALES_REP)}
                    disabled={sendingEmail?.[DOCUSEAL_ROLES.SALES_REP]}
                  >
                    <span className="font-semibold px-4 py-2 rounded text-base flex items-center gap-2">
                      {sendingEmail?.[DOCUSEAL_ROLES.SALES_REP] && <Oval height="18" width="18" color="white" />}
                      Send to Sales representative
                    </span>
                  </CustomButton>
                </>
              )}
              {singularContractVersion === 1 && [DOCUSEAL_ROLES.SALES_REP, DOCUSEAL_ROLES.DEV_ADMIN].includes(currentUserType) && !isBuyerSignedSelectedContract && isSelectedUserTypeAvailable(DOCUSEAL_ROLES.BUYER) &&
                (
                  <CustomButton
                    handleClick={() => sendEmailToUser(DOCUSEAL_ROLES.BUYER)}
                    disabled={sendingEmail?.[DOCUSEAL_ROLES.BUYER]}
                  >
                    <span className="font-semibold px-4 py-2 rounded text-base flex items-center gap-2">
                      {sendingEmail?.[DOCUSEAL_ROLES.BUYER] && <Oval height="18" width="18" color="white" />}
                      Send to Buyer
                    </span>
                  </CustomButton>
                )
              }
              {singularContractVersion === 2 && [DOCUSEAL_ROLES.SALES_REP, DOCUSEAL_ROLES.DEV_ADMIN].includes(currentUserType) &&
                (
                  <>
                    {!isPrimaryBuyerSignedSelectedContract && isSelectedUserTypeAvailable(DOCUSEAL_ROLES.PRIMARY_BUYER) && (
                      <CustomButton
                        handleClick={() => sendEmailToUser(DOCUSEAL_ROLES.PRIMARY_BUYER)}
                        disabled={sendingEmail?.[DOCUSEAL_ROLES.PRIMARY_BUYER]}
                      >
                        <span className="font-semibold px-4 py-2 rounded text-base flex items-center gap-2">
                          {sendingEmail?.[DOCUSEAL_ROLES.PRIMARY_BUYER] && <Oval height="18" width="18" color="white" />}
                          Send to Primary Buyer
                        </span>
                      </CustomButton>
                    )}
                    {!isSecondaryBuyerSignedSelectedContract && isSelectedUserTypeAvailable(DOCUSEAL_ROLES.SECONDARY_BUYER) && (
                      <CustomButton
                        handleClick={() => sendEmailToUser(DOCUSEAL_ROLES.SECONDARY_BUYER)}
                        disabled={sendingEmail?.[DOCUSEAL_ROLES.SECONDARY_BUYER]}
                      >
                        <span className="font-semibold px-4 py-2 rounded text-base flex items-center gap-2">
                          {sendingEmail?.[DOCUSEAL_ROLES.SECONDARY_BUYER] && <Oval height="18" width="18" color="white" />}
                          Send to Secondary Buyer
                        </span>
                      </CustomButton>
                    )}
                  </>
                )
              }
            </>
          }
          {!isShowDocusealContractSigningForm && (
            <>
              {
                !isCurrentUserSignedSelectedContract
                  ? (
                    <CustomButton
                      handleClick={() => {
                        if (isPublicPath) {
                          if (!canUserSign) {
                            setIsShowSignatureHistoryModal(true)
                          } else {
                            if (currentUserType) {
                              onSignDocumentClicked(currentUserType)
                            }
                          }
                        } else {
                          setIsShowRoleSelectionModal(true)
                        }
                      }}
                    >
                      <span className="font-semibold px-4 py-2 rounded text-base flex items-center gap-2">
                        {
                          isPublicPath ? (
                            isUserExists ? 'Sign Now' : 'View signs status'
                          ) : (
                            'Select Role & Sign'
                          )
                        }
                      </span>
                    </CustomButton>
                  )
                  : <>
                    {
                      !isAllSignedForSelectedContract && (
                        <CustomButton
                          handleClick={() => {
                            if (!isAllContractSignedByCurrentUser) {
                              signNextContract()
                            } else {
                              setIsShowSignatureHistoryModal(true)
                            }
                          }}
                        >
                          <span className="font-semibold px-4 py-2 rounded text-base flex items-center gap-2">
                            {
                              !isAllContractSignedByCurrentUser
                                ? 'Sign next document'
                                : 'View signs status'
                            }
                          </span>
                        </CustomButton>
                      )
                    }
                  </>
              }
            </>
          )}
          {isShowDocusealContractSigningForm && !isCurrentUserSignedSelectedContract && (
            <CustomButton
              handleClick={() => {
                setIsShowDocusealContractSigningForm(false)
              }}
              variant="outline"
            >
              <span className="font-semibold px-4 py-2 rounded text-base flex items-center gap-2">
                Back to Document Viewer
              </span>
            </CustomButton>
          )}
          {
            isPublicPath && !isMobile &&
            <CustomButton
              handleClick={handleGoBack}
              variant="outline"
            >
              <span className="font-semibold px-4 py-2 rounded text-base flex items-center gap-2">
                Go back
              </span>
            </CustomButton>
          }
        </div>
      </RightWrapper>
    )
  }

  return (
    <React.Fragment>
      <AnimatePresence exitBeforeEnter initial={false}>
        {gettingContracts && <Loader />}

        {!gettingContracts && contractTypesOption?.length > 0 && (
          <React.Fragment>
            {isMobile && (
              <Drawer
                open={isRightPanelOpen}
                onClose={() => {
                  setIsRightPanelOpen(false)
                  setIsShowDocusealContractSigningForm(false)
                }}
                variant="persistent"
                anchor="left"
                classes={{ paper: classes.drawerPaper }}
              >
                <div className="w-full h-full">
                  <FadedAnimatedDiv key="contracts" className="h-full" style={{ overflowY: "hidden" }}>
                    <ContractsWrapper>
                      <RightSideContent />
                    </ContractsWrapper>
                  </FadedAnimatedDiv>
                </div>
              </Drawer>
            )}

            <FadedAnimatedDiv key="contracts" className="h-full bg-white" style={{ overflowY: "hidden" }}>
              <ContractsWrapper>
                <LeftSideContent />
                {!isMobile && <RightSideContent />}
              </ContractsWrapper>
            </FadedAnimatedDiv>
            <SignatureHistoryModal
              show={isShowSignatureHistoryModal && currentUserType && selectedContract?.signatures}
              onClose={() => setIsShowSignatureHistoryModal(false)}
              currentUserType={currentUserType}
              signatures={selectedContract?.signatures || []}
              isUserSigned={isAllContractSignedByCurrentUser}
            />
            {
              !isPublicPath && isShowRoleSelectionModal && (
                <RoleSelectionModal
                  open={isShowRoleSelectionModal}
                  onClose={() => setIsShowRoleSelectionModal(false)}
                  roles={selectedContract?.signatures?.map((signature) => ({
                    label: signature.userType,
                    value: signature.userType
                  }))}
                  onSignContract={(role) => {
                    if (!canUserSignAsSelectedRole(role)) {
                      setIsShowSignatureHistoryModal(true)
                    } else {
                      onSignDocumentClicked(role)
                    }
                    setIsShowRoleSelectionModal(false)
                  }}
                />
              )
            }
          </React.Fragment>
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default ContractSigningScreen
