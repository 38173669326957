/* eslint-disable */
// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { Modal } from '@material-ui/core';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { useHistory, useParams } from 'react-router-dom';
import { useGetSendGridSenderAccounts } from 'hooks/useGetSenderAccounts';
import { createOrUpdateEmailCampaign } from 'store/actions/customEmailCampaignActions';
import { ADMIN_EMAIL_CUSTOM_CAMPAIGNS_BUILDER } from 'routes';
import { Select } from 'antd';
import { ArrowDownIcon } from 'components';
import CustomButton from 'components/CustomButton';
import CustomInput from 'components/CustomInput';
import { useGetContactLists } from 'hooks/useGetContactLists';
import { getEmailTemplates } from 'store/actions/emailTemplateActions';
import { Oval } from 'react-loader-spinner';
import EmailTemplateSelector from 'components/EmailTemplateSelector';

const EmailAttendeesModal = ({
  show,
  onSuccess = () => { },
  setShow,
  eventId
}) => {
  const history = useHistory();
  const [emailCampaign, setEmailCampaign] = useState({
    list: null,
    subject: '',
    scheduleAt: null,
    senderAccount: null,
    senderAccountId: null,
  });
  const [scheduleCampaign, setScheduleCampaign] = useState(false);
  const { loading: loadingSenderAccounts, lists: senderAccounts } = useGetSendGridSenderAccounts();
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState(null)
  const [contactGroup, setContactGroup] = useState(null)
  const { contactsLists, getContacts, loading: loadingContacts } = useGetContactLists()
  const [isFetchingEmailTemplates, setIsFetchingEmailTemplates] = useState(true)
  const [emailTemplates, setEmailTemplates] = useState([])
  const [isSendingEmailCampaign, setIsSendingEmailCampaign] = useState(false)

  const fetchData = async () => {
    try {
      const [_, templates] = await Promise.all([
        getContacts(),
        getEmailTemplates()
      ]);

      setEmailTemplates(templates.map(({ _id, name, subject }) => ({
        id: _id,
        name,
        subject
      })));

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsFetchingEmailTemplates(false);
    }
  };

  useEffect(() => {
    if (show) {
      fetchData()
    }
  }, [show])

  const handleClose = () => {
    setShow(false);
    setEmailCampaign({
      list: null,
      subject: '',
      scheduleAt: null,
      senderAccount: null,
      senderAccountId: null,
    })
    setSelectedEmailTemplate(null)
    onSuccess();
  };

  useEffect(() => {
    const arr = []
    const filterFunc = (x) => {
      if (eventId) {
        return x.eventId === eventId
      }
      return true
    }
    contactsLists.filter(filterFunc).forEach((el) => {
      if (!arr.find((item) => el.title === item.name)) {
        arr.push({
          id: el._id,
          name: `${el.title} (${el?.users?.length || 0})`,
          count: el?.users?.length || 0
        })
      }
    })
    setContactGroup(arr)
  }, [contactsLists, eventId])

  const handleSubmit = async () => {
    if (!selectedEmailTemplate) return null

    try {
      setIsSendingEmailCampaign(true);

      await createOrUpdateEmailCampaign({
        ...emailCampaign,
        scheduleAt: scheduleCampaign ? new Date(emailCampaign.scheduleAt) : null,
        _id: selectedEmailTemplate,
      });
    } catch (error) {
      console.error('Error creating/updating email campaign:', error);
    } finally {
      setIsSendingEmailCampaign(false);
      handleClose();
    }
  };

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="Add Or Edit Bulk Contact Modal"
      aria-describedby="Add Or Edit Bulk Contact description"
      className="!z-[100]"
    >
      <div
        className="relative flex flex-col font-openSans bg-white !p-2 md:!p-4 z-[10] rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 !w-[calc(100%-10px)] !h-[calc(100%-10px)] md:!w-fit md:!min-w-[700px] md:!h-fit md:!min-h-[700px] overflow-hidden"
      >
        <div className="flex justify-between gap-4 p-2">
          <h2 className="text-xl font-semibold mb-0">Email Attendees</h2>
          <button onClick={handleClose}>
            <CrossIcon />
          </button>
        </div>
        <div className='flex-1 flex flex-col gap-5 overflow-y-auto p-2 pt-3'>
          <div className='flex-1 flex flex-col gap-5'>
            <div className='flex flex-col justify-left'>
              <EmailTemplateSelector
                label='Select Email Template'
                labelClassName='!font-medium !text-sm !text-softBlack_70 !capitalize'
                value={selectedEmailTemplate ?? null}
                onChange={(value) => {
                  setSelectedEmailTemplate(value)
                  setEmailCampaign({
                    ...emailCampaign,
                    subject: emailTemplates?.find((el) => el.id === value?._id)?.subject,
                  })
                }}
              />
            </div>
            <div className='flex flex-col justify-left'>
              <div className='font-medium text-sm text-softBlack_70 mb-1.5'>
                Name of your Email Campaign
              </div>
              <input
                onChange={(e) =>
                  setEmailCampaign({
                    ...emailCampaign,
                    name: e.target.value,
                  })
                }
                placeholder='Email Campaign Name'
                className='mb-0 border !border-softBlack !rounded-[4px]'
                disabled={isSendingEmailCampaign}
              />
            </div>
            <div className='flex flex-col justify-left'>
              <div className='font-medium text-sm text-softBlack_70 mb-1.5'>
                Email Subject
              </div>
              <input
                value={emailCampaign?.subject}
                onChange={(e) =>
                  setEmailCampaign({
                    ...emailCampaign,
                    subject: e.target.value,
                  })
                }
                placeholder='Email Subject'
                className='mb-0 border !border-softBlack !rounded-[4px]'
                disabled={isSendingEmailCampaign}
              />
            </div>
            <div className='flex flex-col justify-left'>
              <div className='font-medium text-sm text-softBlack_70 mb-1.5'>
                Select Contact Group
              </div>
              <Select
                showArrow
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toString()
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                value={emailCampaign?.list ?? 'Select groups'}
                onChange={(value) => {
                  setEmailCampaign({
                    ...emailCampaign,
                    list: value,
                  })
                }}
                className='ant-picker-input1'
                suffixIcon={<ArrowDownIcon />}
                bordered={false}
                getPopupContainer={(node) => node.parentNode}
                loading={loadingContacts}
                disabled={isSendingEmailCampaign || loadingContacts}
              >
                {contactGroup?.map((el) => (
                  <Select.Option key={el.id} label={el.name} value={el.id}>
                    {el.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className='flex-col justify-left'>
              <div className='font-medium text-sm text-softBlack_70 mb-1.5'>
                Choose Sender Account
              </div>
              <Select
                suffixIcon={<ArrowDownIcon />}
                showArrow
                getPopupContainer={trigger => trigger.parentNode}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toString()
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(value) => {
                  const account = senderAccounts.find((acc) => acc.id === value)
                  setEmailCampaign({
                    ...emailCampaign,
                    senderAccount: `${account?.name} (${account?.email})`,
                    senderAccountId: account?.id,
                  })
                }
                }
                value={emailCampaign?.senderAccount || 'Choose Account to Send From'}
                placeholder='Account to Send Email From'
                className='ant-picker-input1'
                loading={loadingSenderAccounts}
                disabled={isSendingEmailCampaign || loadingSenderAccounts}
              >
                {senderAccounts?.map?.((_item) => (
                  <Select.Option
                    value={_item?.id}
                    key={_item.id}
                    label={`${_item?.name} (${_item?.email})`}
                  >
                    <div className='flex gap-2 items-center'>
                      <span className='h-full'>
                        {_item?.name} ({_item?.email})
                      </span>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className='flex flex-col items-start'>
              <div className='font-medium text-base text-softBlack flex gap-2 items-center'>
                <input
                  type='checkbox'
                  onChange={(e) => {
                    setScheduleCampaign(e.target.checked)
                    setEmailCampaign({
                      ...emailCampaign,
                      scheduleAt: null,
                    })
                  }}
                  className='mb-0 cursor-pointer'
                  disabled={isSendingEmailCampaign}
                />
                Schedule Campaign
              </div>
              {scheduleCampaign && (
                <div className='border rounded-md mt-2 overflow-hidden'>
                  <CustomInput
                    type='datetime-local'
                    className='border border-border'
                    value={emailCampaign.scheduleAt}
                    onChange={(e) => {
                      const date = new Date(e.target.value);
                      if (date < new Date()) {
                        alert('Please select a future date');
                        return;
                      }
                      setEmailCampaign({
                        ...emailCampaign,
                        scheduleAt: e.target.value,
                      })
                    }
                    }
                    disabled={isSendingEmailCampaign}
                  />
                </div>
              )}
            </div>
          </div>
          <div className='flex justify-end gap-2'>
            <CustomButton variant='outline' handleClick={handleClose} disabled={isSendingEmailCampaign}>
              <span className='font-medium py-2 px-6 text-base flex gap-2 items-center'>
                Cancel
              </span>
            </CustomButton>
            <CustomButton handleClick={handleSubmit} disabled={isSendingEmailCampaign || !selectedEmailTemplate}>
              <span className='font-medium py-2 px-6 text-base flex gap-2 items-center'>
                {isSendingEmailCampaign && <Oval color="#00BFFF" height={20} width={20} />}
                {scheduleCampaign && emailCampaign.scheduleAt ? 'Schedule' : 'Send'} Email Campaign
              </span>
            </CustomButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EmailAttendeesModal;
