/* eslint-disable */
// @ts-nocheck
// import CategoryButton from 'components/AdminSideBar/CategoryButton'
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { connect, useSelector } from 'react-redux'
import { PageHeader, TFilledPlusIcon } from 'components'
import { ADMIN_CREATE_EVENT_PAGE, ADMIN_EVENT_PAGE } from 'routes'
import { useHistory, useParams } from 'react-router-dom'
import { getImage, updateAttendees, addEventToCalendar, deleteAttendee } from 'store/actions/eventsActions'
import AddAttendeeModal from './components/AddAttendeeModal'
import CheckinModal from './components/checkinModal'
import CustomButton from 'components/CustomButton'
import { Badge } from 'components/ShadcnComponents/badge'
import { Button } from 'components/ShadcnComponents/button'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from 'components/ShadcnComponents/table'
import { Mail, MessageSquare, Pen, UserPlus, Upload, Code2, Link, CalendarPlus2, Trash2 } from 'lucide-react'
import AddOrEditBulkContact from './components/AttendeeBulkUpload'
import CustomSelect from 'pages/reservation/NewReservation/components/CustomSelect'
import { api } from 'services/api'
import CreateSMSBroadcastModal from 'pages/desktopMode/smsBroadcast/CreateSMSBroadcastModal'
import EmailAttendeesModal from 'pages/desktopMode/Events/EmailAttendeesModal'
import { message } from 'antd'
import ConnectNylasAccount from 'components/ConnectNylasAccountModal'
import { Oval } from 'react-loader-spinner'
import useEventData from 'hooks/useEventData'
import DeleteModal from 'components/DeleteModal'

export const RSVP_STATUSES = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
  { value: 'Pending', label: 'Pending' }
]

const EVENT_TYPES = {
  UPCOMING: 'Upcoming',
  PAST: 'Past',
  ARCHIVED: 'Archived'
}

const CHECKED_IN_STATUS = {
  ATTENDED: 'Attended',
  NOT_SHOWN: 'Not shown',
  NOT_ATTENDED: 'Not attended'
}

const CheckedInStatus = ({ checkInStatus, rsvpStatus }) => {
  let status = '',
    variant = 'default'
  if (rsvpStatus === 'Yes' && checkInStatus === 'Completed') {
    status = CHECKED_IN_STATUS.ATTENDED;
    variant = 'success'
  } else if (rsvpStatus === 'Yes' && checkInStatus !== 'Completed') {
    status = CHECKED_IN_STATUS.NOT_SHOWN;
    variant = 'failed'
  } else if (rsvpStatus !== 'Yes') {
    status = CHECKED_IN_STATUS.NOT_ATTENDED;
    variant = 'pending'
  }
  return <Badge variant={variant} className='px-3.5 py-1 !text-white'>{status}</Badge>
}

export const EventDetailsContent = ({ eventId, asDrawer = false, onEditEvent = () => { }, refreshCount = 0 }) => {
  const appProject = useSelector(state => state.appReducer.appProject)
  const history = useHistory()
  const [heroImage, setHeroImage] = useState()
  const [coverImage, setCoverImage] = useState()
  const [date, setDate] = useState()
  const [isShowCreateAttendeeModal, setIsShowCreateAttendeeModal] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredAttendees, setFilteredAttendees] = useState([])
  const [isShowCheckinModal, setIsShowCheckinModal] = useState(false)
  const [selectedAttendee, setSelectedAttendee] = useState(null)
  const [isOpenBulkImportModal, setIsOpenBulkImportModal] = useState(false)
  const [showSMSBroadCastView, setShowSMSBroadCastView] = useState(false)
  const [isShowEmailAttendeesModal, setIsShowEmailAttendeesModal] = useState(false)
  const [nylasProviderToken, setNylasProviderToken] = useState(null)
  const [isAddingEventToCalendar, setIsAddingEventToCalendar] = useState(false)
  const {
    event,
    attendees,
    stats,
    isLoading,
    refetchAll: refreshData
  } = useEventData(eventId)
  const [deleteToBeAttendee, setDeleteToBeAttendee] = useState(null)
  const [isDeletingAttendee, setIsDeletingAttendee] = useState(false)

  const eventType = useMemo(() => {
    if (!event) return null

    if (event?.isDeleted) return EVENT_TYPES.ARCHIVED

    const endDate = new Date(event.endDate).getTime()
    const currentDate = new Date().getTime()
    if (endDate > currentDate) {
      return EVENT_TYPES.UPCOMING
    }
    return EVENT_TYPES.PAST
  }, [event])

  const leadStatusBadgeColorFinder = useCallback((title) => {
    if (title?.includes('Buyer')) {
      return {
        text: '#03543F',
        bg: '#DEF7EC'
      }
    }
    if (title?.includes('LeadBroker')) {
      return {
        text: '#5521B5',
        bg: '#EDEBFE'
      }
    }
  }, [])

  const LeadStatusBadge = ({ title }) => {
    const badgeColor = leadStatusBadgeColorFinder(title)
    const badgeStyle = {
      backgroundColor: badgeColor?.bg,
      color: badgeColor?.text
    }
    if (badgeColor) {
      return (
        <div
          className='px-3 py-0.5 rounded-md justify-center items-center gap-1 inline-flex'
          style={badgeStyle}
        >
          <div className='text-center text-xs font-normal'>{title}</div>
        </div>
      )
    }
    return null
  }

  useEffect(() => {
    if (eventId) {
      refreshData()
    }
  }, [eventId, refreshCount])

  const filterData = () => {
    if (!attendees && !(attendees?.length > 0)) return null

    if (searchQuery === '' || searchQuery === null || searchQuery === undefined) {
      setFilteredAttendees(attendees)
    }
    const filtered = attendees?.filter(({ user }) => {
      const name = (user?.fullName || '').toLowerCase()
      const email = (user?.email || '').toLowerCase()
      const phone = String(user?.userType === 'Buyer' ? user?.buyerData?.phoneNumber : user?.leadBrokerData?.phoneNumber || '')
      return name.includes(searchQuery.toLowerCase()) || email.includes(searchQuery.toLowerCase()) || phone.toLowerCase().includes(searchQuery.toLowerCase())
    }) ?? []
    setFilteredAttendees(filtered)
  }

  useEffect(() => {
    filterData()
  }, [searchQuery, attendees])

  const updateStatus = (attendee, status) => {
    updateAttendees({ _id: eventId, attendeeId: attendee._id, ...attendee, status }, 'status').then(() => {
      refreshData()
    })
  }

  useEffect(() => {
    if (event) {
      var startTime = new Date(event.startDate)
      var endTime = new Date(event.endDate)
      var formattedStartDate = startTime.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
      var formattedEndDate = endTime.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
      setDate(formattedStartDate + ' - ' + formattedEndDate)
      if (event.heroImage) {
        getImage(event.heroImage).then((res) => setHeroImage(res)).catch(() => setHeroImage())
      }
      if (event.coverImage) {
        getImage(event.coverImage).then((res) => setCoverImage(res)).catch(() => setCoverImage())
      }
    }
  }, [event])

  const copyRSVPFormCode = () => {
    const html = `<form action="${process.env.REACT_APP_BE_URL}/leads/presale/v2" method="POST">
      <label for="firstName">First Name</label><input type='text' name='firstName' placeholder='First Name' />
      <label for="lastName">Last Name</label><input type='text' name='lastName' placeholder='Last Name' />
      <label for="emailAddress">Email</label><input type='email' name='emailAddress' placeholder='example@gmail.com' />
      <label for="phoneNumber">Phone</label><input type='text' name='phone' placeholder='(123) 456-7890' />
      <label for="realtor">RSVP</label>
      <div>
        <div class="radio-wrapper">
          <div class="radio-group">
            <input type="radio" id="rsvp" name="rsvp" value="yes"/>
            <label for="realtorYes" class="radio-label">Yes</label>
          </div>
          <div class="radio-group">
            <input type="radio" id="rsvp" name="rsvp" value="no"/>
            <label for="realtorNo" class="radio-label">No</label>
          </div>
        </div>
      </div>
      <input type="hidden" name="project" value="${event.project}" />
      <input type="hidden" name="eventId" value="${event._id}" />
      <input type="hidden" name="redirect_success" value="${window.location.origin}/event/${eventId}?isRegistered=true" />
      <input type="hidden" name="redirect_existing" value="${window.location.origin}/public/events/registration/${eventId}?isRegistered=true" />
      <input type="hidden" name="redirect_error" value="${window.location.origin}/public/events/registration/${eventId}?isFailed=true" />
      <input type="submit" value="Submit"/>
      </form>`
    // copy to clipboard
    navigator.clipboard.writeText(html)
    message.success('RSVP form HTML code copied to clipboard')
  }

  const _addEventToCalendar = async () => {
    setIsAddingEventToCalendar(true)
    await addEventToCalendar(eventId)
      .then(() => refreshData())
      .catch((err) => console.log(err))
      .finally(() => setIsAddingEventToCalendar(false))
  }

  const handleDeleteAttendee = async () => {
    setIsDeletingAttendee(true)
    await deleteAttendee(eventId, deleteToBeAttendee)
      .then(() => {
        refreshData()
        setDeleteToBeAttendee(null)
      })
      .catch((err) => console.log(err))
      .finally(() => setIsDeletingAttendee(false))
  }

  if (isLoading) {
    return (
      <div className='h-full flex flex-col gap-3 overflow-hidden animate-pulse'>
        {/* Hero Section */}
        <div className='relative z-[1] space-y-4 bg-zinc-300 h-[300px]'>
          <div className='bg-black/60 z-[2] absolute w-full h-full top-0 left-0' />
          <div className='relative z-[3] w-full container flex items-end gap-6 py-6'>
            <div className='bg-zinc-400 w-[250px] h-[250px] rounded-lg' />
            <div className='flex-1 flex flex-col gap-4'>
              <div className='w-full flex items-center justify-between gap-4'>
                <div className='h-8 bg-zinc-400 w-2/3 rounded' />
                <div className='flex gap-x-2'>
                  <div className='w-32 h-10 bg-zinc-400 rounded' />
                  <div className='w-32 h-10 bg-zinc-400 rounded' />
                </div>
              </div>
              <div className='flex flex-wrap gap-2 mt-2'>
                {[1, 2, 3].map((i) => (
                  <div key={i} className='h-6 w-24 bg-zinc-400 rounded-full' />
                ))}
              </div>
              <div className='flex flex-wrap gap-4'>
                {[1, 2, 3].map((i) => (
                  <div key={i} className='h-10 w-32 bg-zinc-400 rounded-full' />
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className='container flex justify-between items-center gap-2 p-0'>
          <div className='flex gap-2'>
            <div className='w-40 h-10 bg-zinc-300 rounded' />
          </div>
          <div className='flex-1 flex justify-end items-center gap-2'>
            <div className='w-48 h-10 bg-zinc-300 rounded' />
            <div className='w-48 h-10 bg-zinc-300 rounded' />
          </div>
        </div>

        {/* Attendees Section */}
        <div className='flex-1 overflow-y-auto pb-4'>
          <div className='container space-y-4 border rounded-lg p-4 bg-white'>
            <div className='flex flex-wrap items-center justify-between gap-4'>
              <div className='h-8 w-32 bg-zinc-300 rounded' />
              <div className='flex flex-wrap items-center gap-2'>
                {[1, 2, 3, 4].map((i) => (
                  <div key={i} className='w-32 h-10 bg-zinc-300 rounded' />
                ))}
              </div>
            </div>
            <div className='py-2'>
              <div className='w-[300px] h-10 bg-zinc-300 rounded' />
            </div>
            <div className='border rounded-lg overflow-hidden'>
              <div className='grid grid-cols-6 gap-4 p-4 bg-zinc-100'>
                {[1, 2, 3, 4, 5, 6].map((i) => (
                  <div key={i} className='h-6 bg-zinc-300 rounded' />
                ))}
              </div>
              {[1, 2, 3, 4, 5].map((row) => (
                <div key={row} className='grid grid-cols-6 gap-4 p-4 border-t border-zinc-200'>
                  {[1, 2, 3].map((col) => (
                    <div key={col} className='h-6 bg-zinc-300 rounded' />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='h-full flex flex-col gap-3 overflow-hidden'>
        <div className='relative z-[1] space-y-4 bg-no-repeat bg-cover' style={{ backgroundImage: `url(${coverImage?.url || '/event-detail-hero.png'})` }}>
          <div className='bg-black/60 z-[2] absolute w-full h-full top-0 left-0' />
          <div className='relative z-[3] w-full container flex items-end gap-6 py-6'>
            <img
              src={heroImage?.url || '/event-detail-image.png'}
              alt={`${event.name} hero image`}
              className='object-cover w-[250px] h-[250px] rounded-lg'
            />
            <div className='flex-1 flex flex-col gap-4'>
              <div className='w-full flex items-center justify-between gap-4'>
                <h1 className='text-3xl text-white font-bold mb-0 flex items-center flex-wrap gap-3'>
                  {event.name}
                  {event && (
                    <Badge
                      variant={eventType === EVENT_TYPES.ARCHIVED ? 'failed' : eventType === EVENT_TYPES.UPCOMING ? 'success' : eventType === EVENT_TYPES.PAST ? 'pending' : 'default'}
                      className='px-4 py-1 !text-white text-base'
                    >
                      {eventType}
                    </Badge>
                  )}
                </h1>
                <div className='flex gap-x-2'>
                  <CreateTheSmartLists eventId={eventId} />
                  <Button
                    variant='secondary'
                    onClick={() => {
                      if (asDrawer) {
                        onEditEvent()
                      } else {
                        history.push(`/admin/events/edit/${eventId}`)
                      }
                    }}
                    className='flex items-center gap-2'
                  >
                    <Pen className='w-4 h-4 mr-2' />
                    Edit Event
                  </Button>
                </div>
              </div>
              <div className='flex flex-wrap gap-2 mt-2 text-sm text-muted-foreground'>
                <Badge variant='white' className='px-4 py-1'>
                  {date}
                </Badge>
                <Badge variant='white' className='px-4 py-1'>
                  {event.startTime + ' - ' + event.endTime}
                </Badge>
                {event?.locationAddress?.length > 0 &&
                  <Badge variant='white' className='px-4 py-1'>
                    {event.locationAddress}
                  </Badge>
                }
              </div>
              <div className='flex flex-wrap gap-4'>
                <Badge variant='black' className='px-4 py-2.5 text-base'>
                  {stats?.totalAttendees} Invited
                </Badge>
                <Badge variant='white' className='px-4 py-2.5 text-base'>
                  {stats?.rsvpYes} RSVP Yes
                </Badge>
                <Badge variant='white' className='px-4 py-2.5 text-base'>
                  {stats?.checkInCompleted} Checked In
                </Badge>
              </div>
            </div>
          </div>
        </div>
        {
          !event?.isDeleted && (
            <div className='container flex justify-between items-center gap-2 p-0'>
              <div>
                <ConnectNylasAccount
                  nylasProviderToken={nylasProviderToken}
                  setNylasProviderToken={setNylasProviderToken}
                />
                {
                  nylasProviderToken && !event?.nylasEventId && (
                    <Button
                      onClick={_addEventToCalendar}
                      disabled={isAddingEventToCalendar}
                      className='flex items-center gap-2'
                    >
                      {
                        isAddingEventToCalendar
                          ? <Oval height='20' width='20' color='white' />
                          : <CalendarPlus2 className='w-4 h-4 mr-2' />
                      }
                      Add event to calender
                    </Button>
                  )
                }
              </div>
              <div className='flex-1 flex justify-end items-center gap-2'>
                <Button
                  variant='white'
                  onClick={copyRSVPFormCode}>
                  <Code2 className='w-4 h-4 mr-2' />
                  Copy RSVP Form Code
                </Button>
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(`${window.location.origin}/public/events/registration/${eventId}`)
                    message.success('Public form link copied to clipboard')
                  }}
                  className='flex items-center gap-2'
                >
                  <Link className='w-4 h-4 mr-2' />
                  Copy Public form link
                </Button>
              </div>
            </div>
          )
        }
        <div className='flex-1 overflow-y-auto pb-4'>
          <div className='container space-y-4 border rounded-lg p-4 bg-white'>
            <div className='flex flex-wrap items-center justify-between gap-4'>
              <h2 className='text-xl font-semibold'>Attendees</h2>
              {
                !event?.isDeleted && (
                  <div className='flex flex-wrap items-center gap-2'>
                    <Button variant='outline' onClick={() => setIsShowEmailAttendeesModal(true)}>
                      <Mail className='w-4 h-4 mr-2' />
                      Email Attendees
                    </Button>
                    <Button variant='outline' onClick={() => setShowSMSBroadCastView(true)}>
                      <MessageSquare className='w-4 h-4 mr-2' />
                      SMS Attendees
                    </Button>
                    {
                      eventType === EVENT_TYPES.UPCOMING && (
                        <>
                          <Button variant='outline' onClick={() => setIsOpenBulkImportModal(true)}>
                            <Upload className='w-4 h-4 mr-2' />
                            import Attendee
                          </Button>
                          <Button onClick={() => setIsShowCreateAttendeeModal(true)}>
                            <UserPlus className='w-4 h-4 mr-2' />
                            Add Attendee
                          </Button>
                        </>
                      )
                    }
                  </div>
                )
              }
            </div>
            <div className='py-2'>
              <input
                type='text'
                placeholder='Search attendees...'
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value)
                  // filterData(attendees)
                }}
                className='w-[300px] p-2 border border-zinc-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
              />
            </div>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Email</TableHead>
                  <TableHead>Phone Number</TableHead>
                  <TableHead>Contact Type</TableHead>
                  <TableHead>RSVP Status</TableHead>
                  <TableHead>Check{eventType !== EVENT_TYPES.UPCOMING ? 'ed' : ''} In Status</TableHead>
                  {
                    eventType === EVENT_TYPES.UPCOMING && (
                      <TableHead>Action</TableHead>
                    )
                  }
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredAttendees?.map((attendee, index) => (
                  <TableRow key={index}>
                    <TableCell>{attendee?.user?.fullName}</TableCell>
                    <TableCell>{attendee?.user?.email}</TableCell>
                    <TableCell>{attendee?.user?.userType === 'Buyer' ? attendee?.user?.buyerData?.phoneNumber : attendee?.user?.leadBrokerData?.phoneNumber}</TableCell>
                    <TableCell>
                      <LeadStatusBadge title={attendee?.user?.userType} />
                    </TableCell>
                    <TableCell>
                      {
                        eventType === EVENT_TYPES.UPCOMING
                          ? <CustomSelect
                            value={attendee?.status}
                            handleChange={(newValue) => updateStatus(attendee, newValue)}
                            options={RSVP_STATUSES}
                            placeholder='Select status'
                            containerClassName='!w-40'
                          />
                          : <>{attendee?.status}</>
                      }
                    </TableCell>
                    <TableCell>
                      {
                        eventType === EVENT_TYPES.UPCOMING
                          ? <Button
                            variant={attendee.checkIn === 'Pending' ? 'default' : 'outline'}
                            size='sm'
                            onClick={() => {
                              setSelectedAttendee(attendee)
                              setIsShowCheckinModal(true)
                            }
                            }
                            disabled={attendee.checkIn === 'Completed'}
                          >
                            {attendee.checkIn === 'Pending' ? 'Check In ' : 'Checked In'}
                          </Button>
                          : <CheckedInStatus checkInStatus={attendee.checkIn} rsvpStatus={attendee.status} />
                      }
                    </TableCell>
                    {
                      eventType === EVENT_TYPES.UPCOMING && (
                        <TableCell>
                          <Button variant='destructive' size='sm' onClick={() => setDeleteToBeAttendee(attendee?._id)}>
                            <Trash2 className='w-4 h-4 mr-2' />
                            Delete
                          </Button>
                        </TableCell>
                      )
                    }
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
      {
        showSMSBroadCastView && (
          <CreateSMSBroadcastModal
            show={showSMSBroadCastView}
            setShow={setShowSMSBroadCastView}
            onSuccess={() => refreshData()}
            filter={{ eventId }}
            project={appProject}
          />
        )
      }
      {
        isShowEmailAttendeesModal && (
          <EmailAttendeesModal
            show={isShowEmailAttendeesModal}
            setShow={setIsShowEmailAttendeesModal}
            eventId={eventId}
          />
        )
      }
      {
        isOpenBulkImportModal && (
          <AddOrEditBulkContact
            show={isOpenBulkImportModal}
            setShow={setIsOpenBulkImportModal}
            onSuccess={() => refreshData()}
            eventId={eventId}
            project={appProject}
          />
        )
      }
      <AddAttendeeModal
        show={isShowCreateAttendeeModal}
        onClose={() => setIsShowCreateAttendeeModal(false)}
        eventId={eventId}
        refreshData={refreshData}
        setIsShowCreateAttendeeModal={setIsShowCreateAttendeeModal}
      />
      <CheckinModal
        show={isShowCheckinModal}
        onClose={() => setIsShowCheckinModal(false)}
        selectedAttendee={selectedAttendee}
        refreshData={refreshData}
        setIsShowCheckinModal={setIsShowCheckinModal}
      />
      <DeleteModal
        title='Confirm deletion?'
        description={`Are you sure? do you want to delete attendee?`}
        confirmButtonTitle='Yes, delete it'
        cancelButtonTitle='No'
        isOpen={!!deleteToBeAttendee}
        setIsOpen={() => setDeleteToBeAttendee(null)}
        onConfirm={handleDeleteAttendee}
        loading={isDeletingAttendee}
        isBtnLoading={isDeletingAttendee}
      />
    </>
  )
}

const EventDetailPage = (props) => {
  const history = useHistory()
  const { eventId } = useParams()

  const RightContent = () => (
    <div className='flex flex-row items-center gap-3'>
      <CustomButton
        variant='outline'
        className='w-fit m-auto'
        handleClick={() => history.push(ADMIN_EVENT_PAGE)}
      >
        <span className='font-medium py-2.5 px-6 text-base flex gap-2 items-center'>
          Back to all Events
        </span>
      </CustomButton>
      <CustomButton
        className='w-fit m-auto'
        handleClick={() => history.push(ADMIN_CREATE_EVENT_PAGE)}
      >
        <span className='font-medium py-2.5 px-6 text-base flex gap-2 items-center'>
          <TFilledPlusIcon className='h-6 w-6' />
          Add New Events
        </span>
      </CustomButton>
    </div>
  )

  return (
    <>
      <PageHeader className='!h-[64px]' title='Events' rightContent={<RightContent />} />
      <div className='h-[calc(100%-64px)]'>
        <EventDetailsContent eventId={eventId} />
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(EventDetailPage)

// Old UI
//    <>
//       <div className='col-span-12 grid grid-cols-12 gap-x-4 px-10 pb-4 '>
//         <div className='col-span-6'>
//           <img
//             src={heroImage?.url || '/event-detail-image.png'}
//             alt={`${event.name} hero image`}
//             className='w-full object-contain h-full lg:h-sm lg:max-h-sm'
//           />
//         </div>
//         <div className='col-span-6 flex flex-col'>
//           <div className='flex justify-between items-center'>
//             <div>
//               <div className='font-bold text-[28px]'>{event.name}</div>
//             </div>
//             <div>
//               <CustomButton handleClick={() => setIsShowCreateAttendeeModal(true)}>
//                 <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
//                   <img src='/person-grp.png' className='invert w-6' />
//                   <div className='font-bold italic text-[14px]'>
//                     Add Attendees
//                   </div>
//                 </span>
//               </CustomButton>
//             </div>
//           </div>
//           <div className='flex justify-between mt-4'>
//             <div className='flex flex-col italic text-[18px]'>
//               <div>{date}</div>
//               <div>{event.startTime + ' - ' + event.endTime}</div>
//             </div>
//             <div>
//               <CustomButton variant='outline' handleClick={() => history.push(`/admin/events/edit/${eventId}`)}>
//                 <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
//                   <img src='/edit-pen-icon.png' />
//                   <div className='text-[14px] font-normal'>Edit Event</div>
//                 </span>
//               </CustomButton>
//             </div>
//           </div>
//           <div className='flex items-center gap-2 mt-4'>
//             <img src='/location-black.png' />
//             <div className='text-[18px]'>{event.locationAddress}</div>
//           </div>
//           <div className='grid col-span-12 grid-cols-12 gap-4 mt-4'>
//             <div className='bg-[#f2f2f1] border-[1px] col-span-4 basis- p-4  border-black rounded-[3px]'>
//               <div className='text-[12px] text-[rgba(46,43,46,0.7)] italic'>
//                 INVITED
//               </div>
//               <div className='text-[16px] text-[#2e2b2e] font-bold'>
//                 300
//               </div>
//             </div>
//             <div className='bg-[#f2f2f1] border-[1px] col-span-4 basis- p-4  border-black rounded-[3px]'>
//               <div className='text-[12px] text-[rgba(46,43,46,0.7)] italic'>
//                 RSVP YES
//               </div>
//               <div className='text-[16px] text-[#2e2b2e] font-bold'>88</div>
//             </div>
//             <div className='bg-[#f2f2f1] border-[1px] col-span-4 basis- p-4  border-black rounded-[3px]'>
//               <div className='text-[12px] text-[rgba(46,43,46,0.7)] italic'>
//                 RSVP YES
//               </div>
//               <div className='text-[16px] text-[#2e2b2e] font-bold'>88</div>
//             </div>
//             <div className='bg-[#f2f2f1] border-[1px] col-span-4 basis- p-4  border-black rounded-[3px]'>
//               <div className='text-[12px] text-[rgba(46,43,46,0.7)] italic'>
//                 RSVP YES
//               </div>
//               <div className='text-[16px] text-[#2e2b2e] font-bold'>88</div>
//             </div>
//             <div className='bg-[#f2f2f1] border-[1px] col-span-4 basis- p-4  border-black rounded-[3px]'>
//               <div className='text-[12px] text-[rgba(46,43,46,0.7)] italic'>
//                 RSVP YES
//               </div>
//               <div className='text-[16px] text-[#2e2b2e] font-bold'>88</div>
//             </div>
//             <div className='bg-[#f2f2f1] border-[1px] col-span-4 basis- p-4  border-black rounded-[3px]'>
//               <div className='text-[12px] text-[rgba(46,43,46,0.7)] italic'>
//                 RSVP YES
//               </div>
//               <div className='text-[16px] text-[#2e2b2e] font-bold'>88</div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className='col-span-12 rounded-b-[4px] grid grid-cols-12 gap-4 px-10 pt-6 pb-6 bg-[#F2F2F1]'>
//         <div className='w-full col-span-12 container mx-auto flex justify-between'>
//           {EVENT_DETAIL_BAR.map((category, index) => (
//             <CategoryButton
//               key={category.title}
//               category={category}
//               selected={selectedCategory === category?.value}
//               setSelectedCategory={setSelectedCategory}
//             >
//               <div className='py-2 border-[1px] border-black font-light italic text-[16px]'>
//                 {category.title}
//               </div>
//             </CategoryButton>
//           ))}
//         </div>
//         <div className='col-span-12 grid grid-cols-12 gap-4'>
//           <div className='col-span-12 grid grid-cols-12 items-center gap-x-4'>
//             <div className='col-span-2 text-[14px] italic font-light'>
//               Omar Bator
//             </div>
//             <div className='col-span-2 text-[14px] italic font-light'>
//               Buyer
//             </div>
//             <div className='  col-span-3 items-center flex w-full  gap-x-6 col-start-8 lg:col-start-9'>
//               <button className='bg-[#B8E04A] py-2 w-full text-[16px] font-bold px-5'>
//                 RSVP YES
//               </button>
//               <button className='bg-[#FFDEAE] py-2 w-full text-[16px] font-bold px-5'>
//                 PENDING
//               </button>
//             </div>
//             <button className='col-span-1  col-start-12'>
//               {/*   <img src='/three-dot-dark.png' className='h-6 w-6 mr-8' /> */}
//             </button>
//           </div>
//           <div className='w-full h-[1px] bg-black col-span-12'></div>
//           <div className='col-span-12 grid grid-cols-12 items-center gap-x-4'>
//             <div className='col-span-2 text-[14px] italic font-light'>
//               Alena Donin
//             </div>
//             <div className='col-span-2 text-[14px] italic font-light'>
//               Broker
//             </div>
//             <div className='col-span-3 items-center justify-between flex w-full   gap-x-6 col-start-8 lg:col-start-9'>
//               <button className='bg-[#B8E04A] py-2 w-full text-[16px] font-bold px-5'>
//                 RSVP YES
//               </button>
//               <button className='bg-[#FFDEAE] py-2 w-full text-[16px] font-bold px-5'>
//                 PENDING
//               </button>
//             </div>
//             <button className='col-span-1 flex  lg:justify-center col-start-12'>
//               <img src='/three-dot-dark.png' className='h-6 w-6 md:mr-8' />
//             </button>
//           </div>
//           <div className='w-full h-[1px] bg-black col-span-12'></div>
//           <div className='col-span-12 grid grid-cols-12 items-center gap-x-4'>
//             <div className='col-span-2 text-[14px] italic font-light'>
//               Lindsey Calzoni
//             </div>
//             <div className='col-span-2 text-[14px] italic font-light'>
//               Sales Rep
//             </div>
//             <div className='  col-span-3 items-center flex w-full  gap-x-6 col-start-8 lg:col-start-9'>
//               <button className='bg-[#B8E04A] py-2 w-full text-[16px] font-bold px-5'>
//                 RSVP YES
//               </button>
//               <button className='bg-[#FFDEAE] py-2 w-full text-[16px] font-bold px-5'>
//                 PENDING
//               </button>
//             </div>
//             <button className='col-span-1  col-start-12'>
//               {/*   <img src='/three-dot-dark.png' className='h-6 w-6 mr-8' /> */}
//             </button>
//           </div>
//           <div className='w-full h-[1px] bg-black col-span-12'></div>
//         </div>
//       </div>
//     </>

// eslint-disable-next-line space-before-function-paren
function CreateTheSmartLists(props) {
  const [eventId] = useState(props.eventId)
  const [isLoading, setIsLoading] = useState(true)
  const [needToCreate, setNeedToCreate] = useState(false)
  const [refreshCount, setRefreshCount] = useState(0)

  useEffect(() => {
    setIsLoading(true)
    api.get(`/events/${eventId}/smart-lists`).then((res) => {
      const smartLists = res.data
      if (smartLists.length !== 4) {
        setNeedToCreate(true)
      }
    }).finally(() => {
      setIsLoading(false)
    })
  }, [eventId, refreshCount])

  // eslint-disable-next-line space-before-function-paren
  function createSmartLists() {
    setIsLoading(true)
    api.post(`/events/${eventId}/smart-lists`, {}).then(() => {
      setNeedToCreate(false)
    }).finally(() => {
      setRefreshCount(refreshCount + 1)
    })
  }

  if (isLoading || !needToCreate) {
    return null
  }
  return (
    <div>
      <Button onClick={() => {
        createSmartLists()
      }}>
        <Mail className='w-4 h-4 mr-2' />
        Create Smart Lists
      </Button>
    </div>
  )
}
