// @ts-nocheck
/* eslint-disable */
import { Drawer, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import useGetLists from 'hooks/useGetLists';
import { useParams, useHistory } from 'react-router-dom';
import { createOrUpdateEmailCampaign } from 'store/actions/customEmailCampaignActions';
import { ADMIN_EMAIL_CUSTOM_CAMPAIGNS_BUILDER } from 'routes';
import CustomSelectBuyerModal from 'components/CustomSelectBuyerModal';
import useGetProjectBuyers from 'hooks/useGetProjectBuyers';
import { ArrowDownIcon } from 'components';
import CustomButton from 'components/CustomButton';
import CustomInput from 'components/CustomInput';
import { useGetSendGridSenderAccounts } from 'hooks/useGetSenderAccounts';

const CreateEmailCampaign = ({ onClose, emailTemplate, open }) => {
  const history = useHistory();
  const [emailCampaign, setEmailCampaign] = useState({
    subject: emailTemplate?.subject || '',
    scheduleAt: null,
    senderAccount: null,
    senderAccountId: null,
  });
  const [scheduleCampaign, setScheduleCampaign] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { lists, isLoading } = useGetLists({ 'with-sendGrid': true });
  const { loading: loadingSenderAccounts, lists: senderAccounts } = useGetSendGridSenderAccounts();
  const { loading: loadingBuyers, buyers } = useGetProjectBuyers();
  const { templateId } = useParams();
  const [selectedBuyers, setSelectedBuyers] = useState([]);
  const [formattedList, setFormattedList] = useState([]);
  const handleSubmit = () => {
    console.log(emailCampaign);
    createOrUpdateEmailCampaign({
      ...emailCampaign,
      scheduleAt: scheduleCampaign ? new Date(emailCampaign.scheduleAt) : null,
      _id: templateId,
    }).then(() => history.push(ADMIN_EMAIL_CUSTOM_CAMPAIGNS_BUILDER));
    onClose();
  };

  useEffect(() => {
    if (!lists?.length) return

    const baseList = lists?.map?.((val) => {
      const unsubscribeGroupId = val?.sendGrid?.unsubscribeGroupId;
      const totalUnsubscribedUsers = val?.users?.filter?.((user) => {
        const groups = user?.sendGrid?.unsubscribedGroups;
        return groups?.includes?.(unsubscribeGroupId);
      })?.length;
      return {
        ...val,
        totalUsers: val?.users?.length,
        totalUnsubscribedUsers,
        totalSubscribedUsers: val?.users?.length - totalUnsubscribedUsers,
      }
    })
    const _formattedList = baseList?.map?.((val) => ({
      label: `${val.title} (a-${val.users?.length}, s-${val.sendGrid?.contacts || 0}), UnSub-${val.totalUnsubscribedUsers}, Sub-${val.totalSubscribedUsers}`,
      value: val._id,
      id: val._id,
    })) || null
    setFormattedList(_formattedList);
  }, [lists]);

  return (
    <>
      <Drawer closable={false} placement='right' onClose={onClose} open={open} width={500}>
        <div className='px-0 py-12'>
          <h1 className='text-2xl font-semibold'>Create an Email Campaign</h1>
          <div className='flex flex-col gap-6 my-10 mb-14'>
            <div className='flex flex-1 flex-col justify-left'>
              <div className='font-bold text-softBlack_70 italic'>
                Name of your Email Campaign
              </div>
              <input
                onChange={(e) =>
                  setEmailCampaign({
                    ...emailCampaign,
                    name: e.target.value,
                  })
                }
                placeholder='Email Campaign Name'
              />
            </div>
            <div className='flex flex-1 flex-col justify-left'>
              <div className='font-bold text-softBlack_70 italic'>
                Email Subject
              </div>
              <input
                value={emailCampaign?.subject}
                onChange={(e) =>
                  setEmailCampaign({
                    ...emailCampaign,
                    subject: e.target.value,
                  })
                }
                placeholder='Email Subject'
              />
            </div>
            <div className='flex-col justify-left '>
              <div className='font-bold text-softBlack_70 italic'>
                Select Lists
              </div>
              {isLoading && <div>Loading...</div>}
              {!isLoading && (
                <Select
                  suffixIcon={<ArrowDownIcon />}
                  showArrow
                  getPopupContainer={trigger => trigger.parentNode}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(value) =>
                    setEmailCampaign({
                      ...emailCampaign,
                      list: value,
                    })
                  }
                  value={emailCampaign?.list || 'Select from here'}
                  placeholder='Select from here'
                  className='rating-select text-sm bg-white border border-softBlack30 rounded text-softBlack placeholder-softBlack70 h-10'
                >
                  {formattedList !== [] && formattedList?.map?.((_item) => (
                    <Select.Option
                      value={_item?.value}
                      key={_item.value}
                      label={_item?.label}
                    >
                      <div className='flex gap-2 items-center'>
                        <span className='h-full'>{_item.label}</span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              )}
            </div>

            <div className='flex-col justify-left '>
              <div className='font-bold text-softBlack_70 italic'>
                Choose Sender Account
              </div>
              <Select
                suffixIcon={<ArrowDownIcon />}
                showArrow
                getPopupContainer={trigger => trigger.parentNode}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toString()
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(value) => {
                  const account = senderAccounts.find((acc) => acc.id === value)
                  setEmailCampaign({
                    ...emailCampaign,
                    senderAccount: `${account?.name} (${account?.email})`,
                    senderAccountId: account?.id,
                  })
                }
                }
                value={emailCampaign?.senderAccount || 'Choose Account to Send From'}
                placeholder='Account to Send Email From'
                className='rating-select text-sm bg-white border border-softBlack30 rounded text-softBlack placeholder-softBlack70 h-10'
              >
                {senderAccounts?.map?.((_item) => (
                  <Select.Option
                    value={_item?.id}
                    key={_item.id}
                    label={`${_item?.name} (${_item?.email})`}
                  >
                    <div className='flex gap-2 items-center'>
                      <span className='h-full'>
                        {_item?.name} ({_item?.email})
                      </span>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className='flex flex-col items-start'>
              <div className='font-bold text-softBlack_70 italic'>
                <input
                  type='checkbox'
                  onChange={(e) => {
                    setScheduleCampaign(e.target.checked)
                    setEmailCampaign({
                      ...emailCampaign,
                      scheduleAt: null,
                    })
                  }}
                /> Schedule Campaign
              </div>
              {scheduleCampaign && (
                <div>
                  <CustomInput
                    type='datetime-local'
                    className='border border-border'
                    value={emailCampaign.scheduleAt}
                    onChange={(e) => {
                      const date = new Date(e.target.value);
                      if (date < new Date()) {
                        alert('Please select a future date');
                        return;
                      }
                      setEmailCampaign({
                        ...emailCampaign,
                        scheduleAt: e.target.value,
                      })
                    }
                    }
                  />
                </div>
              )}
            </div>
            {selectedBuyers.length > 0 && (
              <div className='flex flex-1 flex-col justify-left'>
                <div className='font-bold text-softBlack_70 italic'>
                  {selectedBuyers.length} Additional Buyers{' '}
                </div>
              </div>
            )}
            <CustomButton handleClick={() => setIsModalOpen(true)}>
              <span className='font-medium py-2 px-6 text-base flex gap-2 items-center'>
                Select Additional Buyers
              </span>
            </CustomButton>
          </div>
          <div className='flex justify-end gap-2'>
            <CustomButton variant='outline' handleClick={onClose}>
              <span className='font-medium py-2 px-6 text-base flex gap-2 items-center'>
                Cancel
              </span>
            </CustomButton>
            <CustomButton handleClick={handleSubmit}>
              <span className='font-medium py-2 px-6 text-base flex gap-2 items-center'>
                {scheduleCampaign && emailCampaign.scheduleAt ? 'Schedule' : 'Send'} Campaign
              </span>
            </CustomButton>
          </div>
        </div>
      </Drawer>
      {
        isModalOpen && (
          <CustomSelectBuyerModal
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            loading={loadingBuyers}
            buyers={buyers}
            selectedBuyers={selectedBuyers}
            setSelectedBuyers={(buyers) => {
              setSelectedBuyers(buyers);
              setEmailCampaign({
                ...emailCampaign,
                users: buyers,
              });
            }}
          />
        )
      }
    </>
  );
};

export default CreateEmailCampaign;
