/*eslint-disable */
// @ts-nocheck
import TableGrid from "components/TableGrid/Table"
import useGetLists, { useGetListsAll } from "hooks/useGetLists"
import { useGetUsersCounts } from "hooks/useGetUsers"
import useProjectId from "hooks/useProjectId"
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import CreateListDialog from "./createList"
import useContactContext from "pages/newContacts/hooks/useContactContext"
import { EditIcon, Star } from "lucide-react"
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "components/ShadcnComponents/tabs"
import { TabWrapper } from "pages/whiteCapDashboard"
import { Drawer, message } from "antd"
import { updateFavouriteList } from "store/actions/usersActions"
import TableWithPagination from "components/TableWithPagination"
import CustomButton from 'components/CustomButton'
import { api } from 'services/api'
import { Select } from 'ui'
import { classNames } from 'utils'
import { getProjects } from 'store/actions/projectActions'
import Button from 'components/Buttons'

const DEFAULT_LIMIT = 10

const TABS = [
  {
    id: "favourite",
    name: "Favourite",
  },
  {
    id: "all",
    name: "All",
  },
  // {
  //   id: "other",
  //   name: "Other",
  // },
]

export default function ListsManagement() {
  const gridRef = React.useRef(null);
  const { projectId } = useProjectId()
  const { view } = useContactContext()
  const [activeTab, setActiveTab] = React.useState(TABS[0].id)
  const [currentPage, setCurrentPage] = React.useState(0)
  const [limit, setLimit] = React.useState(DEFAULT_LIMIT)
  const [deletingList, setDeletingList] = useState({})

  const {
    lists,
    totalLists,
    isLoading: isLoadingList,
    refetch: refetchLists,
    forceRefetch,
    totalSmartList
  } = useGetLists({
    page: currentPage,
    limit,
    projectId: view === "corporate" ? undefined : projectId,
    isFavourite:
      activeTab === "favourite"
        ? true
        // : activeTab === "other"
        //   ? false
        : undefined
  })

  const history = useHistory()
  const { count: usersCounts, isLoading: isLoadingCounts } =
    useGetUsersCounts(projectId)

  useEffect(() => {
    refetchLists()
  }, [refetchLists, projectId])

  const handleFavouriteChange = async (listId, updatedStatus) => {
    await updateFavouriteList(listId, updatedStatus);
    forceRefetch();
  };
  const deleteListById = async (listId) => {
    setDeletingList((prev) => ({ ...prev, [listId]: true }))
    await api.delete(`/lists/${listId}`).then(() => {
      refetchLists()
    }).catch(() => {
      alert('Failed to delete list')
    })
      .finally(() => {
        setDeletingList((prev) => ({ ...prev, [listId]: false }))
      })
  }

  const columns = useMemo(() => {
    const result = [
      {
        headerName: "",
        width: 60,
        field: "title",
        resizable: true,
        cellRenderer: (params) => {
          return (
            <div className="flex gap-x-2">
              <Star
                size={24}
                fill={params.data.isFavourite ? "#f59f0a" : "none"}
                className="text-yellow-500 cursor-pointer"
                onClick={() => handleFavouriteChange(params.data._id, !params.data.isFavourite)}
              />
            </div>
          )
        },
      },
      {
        headerName: "#",
        width: 100,
        field: "_id",
        resizable: true,
        cellRenderer: (params) => {
          const listId = params.data._id
          return (
            <div className="flex gap-x-2">
              <button
                className='py-0.5 px-1.5 text-sm bg-red-500 text-white rounded-md'
                onClick={() => {
                  if (deletingList[listId]) return
                  const confirmDelete = window.confirm('Are you sure you want to delete this list permanently ? \n This action cannot be undone.')
                  if (confirmDelete) {
                    deleteListById(listId)
                  }
                }
                }
              >
                {deletingList[listId] ? 'Deleting...' : 'Delete'}
              </button>
            </div>
          )
        },
      },
      {
        headerName: "List Name",
        field: "title",
        resizable: true,
        flex: 1,
        minWidth: 400,
        cellRenderer: (params) => {
          return (
            <div className="flex flex-row gap-x-1">
              <div>
                <button
                  className="bg-gray-500 text-white border border-border px-2  py-1 rounded-full text-xs"
                  onClick={() => {
                    history.push(
                      `${window.location.pathname}/${params.data._id}`
                    )
                  }}
                >
                  View
                </button>
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  history.push(`${window.location.pathname}/${params.data._id}`)
                }}
              >
                {params.value}
              </div>
            </div>
          )
        },
      },
      {
        headerName: "List Type",
        field: "listType",
        resizable: true,
        cellRenderer: ({ value }) => {
          return (
            <div>
              {value === "static" && (
                <div className="bg-gray-500 text-white border border-border px-2  py-1 rounded-full text-xs">
                  Standard
                </div>
              )}
              {value === "smart" && (
                <div className="bg-blue-500 text-white border border-border px-2  py-1 rounded-full text-xs">
                  Smart
                </div>
              )}
            </div>
          )
        },
      },
      {
        headerName: "Project",
        width: 200,
        field: "project",
        resizable: true,
        cellRenderer: (params) => {
          const project = params?.data?.projectId
          return <div className="flex gap-x-2">{project?.projectName}</div>
        },
      },
      {
        headerName: "Total Contacts",
        field: "users",
        valueGetter: (params) => params?.data?.users?.length || 0,
        resizable: true,
      },
      {
        headerName: "SendGrid",
        field: "sendGrid",
        valueGetter: (params) => params?.data?.sendGrid?.contacts || 0,
        resizable: true,
        cellRenderer: (params) => {
          const sendGrid = params?.data?.sendGrid
          if (!sendGrid) return <div>Not synced</div>
          const value = sendGrid?.contacts || 0
          const listId = sendGrid?.listId
          return <div>
            {!listId &&
              <div>{value}</div>
            }
            {listId && (
              <a href={`https://mc.sendgrid.com/contacts/lists/${listId}`} target="_blank" rel="noreferrer" className="text-blue-500 underline">{value}</a>
            )}
          </div >
        }
      },
      {
        headerName: "Unsubscribe group",
        field: "sendGrid",
        width: 250,
        valueGetter: (params) => params?.data?.sendGrid?.unsubscribeGroupId || '',
        resizable: true,
        cellStyle: { padding: '0px' },
        cellRenderer: (params) => {
          return <SelectUnsubscribeGroup params={params} refetchLists={refetchLists} />
        }
      },
      {
        headerName: "Created On",
        field: "createdAt",
        sortable: true,
        resizable: true,
        cellRenderer: ({ value }) => {
          return new Date(value).toLocaleString()
        },
      },
      {
        headerName: "Updated On",
        field: "updatedAt",
        sortable: true,
        resizable: true,
        cellRenderer: ({ value }) => {
          return new Date(value).toLocaleString()
        },
      },
    ]

    if (view === "project") {
      return result.filter((item) => item.headerName !== "Project")
    }
    return result
  }, [view])

  useEffect(() => {
    setCurrentPage(0)
  }, [activeTab])

  return (
    <SendGridGroupsProvider >
      <div className="px-5 sm:px-10 h-full flex flex-col py-4 gap-3 bg-siteColor">
        <div className="flex w-full justify-between items-center">
          <div className="max-w-136">
            <TabWrapper
              tabMenus={TABS}
              setSelectedTab={setActiveTab}
              selectedTab={activeTab}
            />
          </div>
          <div className='flex flex-row gap-x-1 items-center'>
            <CreateListDialog
              isCorporate={view === "corporate"}
              refreshData={() => {
                refetchLists()
              }}
            />
            <ManageSendGridGroups />
            <BulkAssignSubGroups onAssigned={() => {
              refetchLists()
            }} />
          </div>
        </div>
        <div className="grid grid-cols-3 sm:grid-cols-4 gap-x-4">
          {/* design card view tailwind css */}
          <div className="flex flex-col gap-y-2 py-4 px-4 shadow-sm bg-white rounded-md">
            <div className="font-semibold text-gray-600 text-xl">
              Total Contacts
            </div>
            <div className="flex items-start font-bold text-xl">
              {
                isLoadingCounts
                  ? <div className="items-start font-bold text-xl text-transparent animate-pulse rounded-md bg-zinc-200 w-[80px]">...</div>
                  : <div className="items-start font-bold text-xl">{usersCounts}</div>
              }
            </div>
          </div>
          <div className="flex flex-col gap-y-2 py-4 px-4 shadow-sm bg-white rounded-md">
            <div className="font-semibold text-gray-600 text-xl">Total Lists</div>
            {
              isLoadingList
                ? <div className="items-start font-bold text-xl text-transparent animate-pulse rounded-md bg-zinc-200 w-[80px]">...</div>
                : <div className="items-start font-bold text-xl">{totalLists}</div>
            }
          </div>
          <div className="flex flex-col gap-y-2 py-4 px-4 shadow-sm bg-white rounded-md">
            <div className="font-semibold text-gray-600 text-xl">Smart Lists</div>
            <div className="flex items-start font-bold text-xl">
              {
                isLoadingList
                  ? <div className="items-start font-bold text-xl text-transparent animate-pulse rounded-md bg-zinc-200 w-[80px]">...</div>
                  : <div className="items-start font-bold text-xl">{totalSmartList}</div>
              }
            </div>
          </div>
        </div>
        <div className='flex-1 w-full flex flex-col gap-4 pt-2 pb-4'>
          <TableWithPagination
            columns={columns}
            rowData={lists}
            totalCount={totalLists}
            page={currentPage}
            setPage={setCurrentPage}
            limit={limit}
            setLimit={setLimit}
            loading={isLoadingList}
            setRef={(ref) => {
              gridRef.current = ref
            }}
          />
        </div>
      </div>
    </SendGridGroupsProvider>
  )
}

const SendGridGroupsContext = createContext()

function SendGridGroupsProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false)
  const [sendGridGroups, setSendGridGroups] = useState([])
  const [reload, setReload] = useState(0)

  const loadGroups = useCallback((force) => {
    if (!force) {
      if (sendGridGroups.length > 0 || isLoading) return // Avoid re-fetching if cached
    }
    setIsLoading(true)
    api.get("/sendgrid/groups")
      .then((res) => {
        if (Array.isArray(res.data)) {
          setSendGridGroups(res.data)
        } else {
          setSendGridGroups([{
            id: '0',
            name: 'Please Create group in SendGrid',
            description: '-',
            unsubscribeGroupId: 'N/A',
          }])
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [isLoading])

  useEffect(() => {
    loadGroups()
  }, [loadGroups, reload])

  return (
    <SendGridGroupsContext.Provider value={{
      isLoading,
      reload: () => {
        loadGroups(true)
      },
      sendGridGroups
    }}>
      {children}
    </SendGridGroupsContext.Provider>
  )
}



function SelectUnsubscribeGroup(data) {
  const { isLoading, reload, sendGridGroups } = useContext(SendGridGroupsContext)
  const { params, refetchLists } = data
  const _id = params?.data?._id
  const sendGrid = params?.data?.sendGrid
  const value = sendGrid?.unsubscribeGroupId || ''
  const current = sendGridGroups.find(group => String(group.id) === String(value))
  if (isLoading) return <div>Loading...</div>
  let classes = ''
  if (!current) {
    classes = 'bg-red-200 text-red-500 px-2'
  }
  return <div className={classNames('w-full flex items-center gap-x-1', classes)}>
    <Select
      value={{
        label: current?.name || 'Select Group',
        value: value
      }}
      onChange={(groupId) => {
        api.patch(`/lists/${params.data._id}/update-unsubscribe-group`, {
          unsubscribeGroupId: groupId
        }).then(() => {
          message.success("Updated successfully")
          refetchLists()
        })
          .catch(() => {
            message.error("Failed to update")
          })
      }}>
      {sendGridGroups?.map(group => <option className='p-2' key={group.id} value={group.id}>{group.name}</option>)}
    </Select>
  </div >
}


function BulkAssignSubGroups(props) {
  const [open, setOpen] = useState(false)
  const [loadingProjects, setLoadingProjects] = useState(false)
  const [projects, setProjects] = useState([])
  const { sendGridGroups, reload, isLoading: isLoadingUnsubscribe } = useContext(SendGridGroupsContext)
  const [assigning, setAssigning] = useState(false)
  const [override, setOverride] = useState(false)


  const [mapping, setMapping] = useState({})

  useEffect(() => {
    if (!open) return
    setLoadingProjects(true)
    getProjects().then((res) => {
      setProjects(res)
      const mapping = {
        'corporate-view': ''
      }
      res.forEach(project => {
        mapping[project._id] = project?.sendGridUnsubscribeGroupId || ''
      })
      console.log(mapping)
      setMapping(mapping)
    }).finally(() => {
      setLoadingProjects(false)
    })
  }, [open])

  const mappingOptions = useMemo(() => {
    const options = [
      {
        label: 'Corporate View',
        value: 'corporate-view'
      }
    ];
    projects.map(project => {
      options.push({
        label: project.projectName,
        value: project._id
      })
    })
    return options ?? []
  }, [projects])

  const allValid = mappingOptions.every(project => mapping[project.value] !== '') || false

  const assignTheGroups = () => {
    const data = {
      projects: mapping,
      override: override
    }
    setAssigning(true)
    api.post('/lists/assign-unsubscribe-groups', data).then((data) => {
      reload()
      alert('Groups assigned successfully')
      if (props?.onAssigned && typeof props?.onAssigned === 'function') {
        props?.onAssigned()
      }
      setOpen(false)
    }).catch((e) => {
      alert('Failed to assign groups')
    }).finally(() => {
      setAssigning(false)
    })
  }

  return <div>
    <button
      onClick={() => setOpen(e => !e)}
      className="bg-black text-white px-4 py-2 rounded-lg font-semibold"
    >
      Assign Unsubscribe Groups
    </button>
    <Drawer open={open} onClose={() => setOpen(false)} minWidth={"50%"} width={"60%"}>
      {isLoadingUnsubscribe && <div>Loading...</div>}
      {!isLoadingUnsubscribe && <div>

        {sendGridGroups.length === 0 && <div>
          <div className='bg-red-200 text-red-500 p-2'>Please create groups in SendGrid</div>
        </div>}
        <div className="flex flex-col">
          <h1 className="text-2xl font-semibold">
            Bulk Assign Unsubscribe Groups
          </h1>
          <p>
            Assign SendGrid Unsubscribe Groups to the all list in the project and corporate view
          </p>
        </div>
        <div className='flex flex-col border-collapse'>
          <div>
            <div className='flex  justify-between my-1 border border-gray-200 p-2'>
              <div className='flex items-center gap-x-1 px-1'>
                <div className='font-semibold min-w-8 text-left'>#</div>
                <div>Project</div>
              </div>
              <div>SendGrid Un-Subscribe Group</div>
            </div>
            {loadingProjects && <div>Loading...</div>}
            {!loadingProjects && (
              <div className='flex justify-between my-1 border border-gray-200 p-2 flex-col divide-y'>
                {mappingOptions.map((project, index) => {
                  return <div key={project.value} className='flex  justify-between my-1 p-2'>
                    <div className='flex items-center gap-x-1 px-1'>
                      <div className='font-semibold min-w-8 text-left'>{index + 1}</div>
                      <div>{project.label}</div>
                    </div>
                    <div>
                      <select
                        value={mapping[project.value]}
                        onChange={(event) => {
                          setMapping((prev) => ({ ...prev, [[project.value]]: event.target.value }))
                        }}
                      >
                        <option value=''>Select Group</option>
                        {sendGridGroups?.map(group => {
                          return <option className='p-2' key={group.id} value={group.id}>{group.name}</option>
                        })}
                      </select>
                    </div>
                  </div>
                })}
              </div>
            )}
          </div>
        </div>
        <div>
          {(!loadingProjects && allValid) && (
            <div className='flex flex-col gap-y-2'>
              <div className='flex gap-x-1 items-center my-2'>
                <input
                  id='override'
                  type="checkbox"
                  disabled={loadingProjects}
                  checked={override}
                  onChange={(e) => setOverride(e.target.checked)}
                />
                <label
                  htmlFor='override'
                  className="block text-sm font-medium text-gray-700 my-0.5">
                  Override The Subscription group for all lists
                </label>
              </div>
              <div>
                <button
                  disabled={!allValid || loadingProjects}
                  className="bg-black text-white px-4 py-2 rounded-lg font-semibold"
                  onClick={() => {
                    assignTheGroups()
                  }}>
                  {assigning ? 'Assigning...' : 'Assign Groups'}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      }
    </Drawer>
  </div>
}

function ManageSendGridGroups() {
  const [open, setOpen] = useState(false)
  const { sendGridGroups, reload, isLoading } = useContext(SendGridGroupsContext)

  return <div>
    <button
      onClick={() => setOpen(true)}
      className="bg-black text-white px-4 py-2 rounded-lg font-semibold"
    >
      Manage unsubscribe Groups
    </button>
    <Drawer open={open} onClose={() => setOpen(false)} minWidth={"50%"} width={"60%"}>
      <div>
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-semibold">Manage SendGrid Groups</h1>
          <div className='flex flex-row gap-x-1 items-center'>
            <Button
              disabled={isLoading}
              size='Small'
              label={'Reload'}
              handleClick={() => {
                reload()
              }}>
            </Button>
            <CreateSendGridGroup onCreated={() => {
              reload()
            }} />
          </div>
        </div>
        {isLoading && <div>Refreshing Data...</div>}
        <div className='flex flex-col border-collapse'>
          <div>
            <div className='flex  justify-between my-1 border border-gray-200 p-2'>
              <div className='flex items-center gap-x-1 px-1'>
                <div className='font-semibold min-w-8 text-left'>#</div>
                <div>Title</div>
              </div>
              <div>Group Id</div>
            </div>
            {sendGridGroups?.map((group, index) => {
              return <div key={group.id} className='flex  justify-between my-1 border border-gray-200 p-2'>
                <div className='flex items-center gap-x-1 px-1'>
                  <div className='font-semibold min-w-8 text-left'>{index + 1}</div>
                  <div>{group.name}</div>
                </div>
                <div className='flex items-center gap-x-2.5'>
                  <div>{group.id}</div>
                  <EditSendGridGroup
                    title={group.name}
                    groupId={group.id}
                    onUpdated={() => {
                      reload()
                    }} />
                  <CustomButton
                    onClick={() => {
                      const confirmDelete = window.confirm('Are you sure you want to delete this group permanently ? \n This action cannot be undone.')
                      if (confirmDelete) {
                        api.delete(`/sendgrid/groups/${group.id}`).then(() => {
                          reload()
                          alert('Group deleted successfully')
                        }).catch(() => {
                          alert('Failed to delete group')
                        })
                      }
                    }}
                    className='bg-red-500 text-white px-2 py-1 rounded-md text-xs'
                  >
                    Delete
                  </CustomButton>
                </div>
              </div>
            })}
          </div>
        </div>
      </div>
    </Drawer>
  </div>
}


function CreateSendGridGroup(props) {
  const [visible, setVisible] = useState(false)
  const [title, setTitle] = useState('')
  const [creating, setCreating] = useState(false)

  useEffect(() => {
    if (visible) {
      setTitle('')
    }
  }, [visible])

  function createGroup() {
    setCreating(true)
    api.post('/sendgrid/groups', { title })
      .then(() => {
        alert('Group created successfully')
      })
      .catch(() => {
        alert('Failed to create group')
      })
      .finally(() => {
        if (props?.onCreated && typeof props?.onCreated === 'function') {
          props?.onCreated()
        }
        setCreating(false)
        setVisible(false)
      })
  }

  return (
    <div>
      <CustomButton
        onClick={() => setVisible(true)}
        type="primary"
        className="bg-black text-white px-4 py-2 rounded-lg font-semibold"
      >
        Create Group
      </CustomButton>
      <Drawer
        title="Create Unsubscribe Group"
        width={720}
        onClose={() => setVisible(false)}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <div>
          <div className="flex flex-col gap-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Group Title
              </label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <CustomButton
                onClick={createGroup}
                type="primary"
                loading={creating}
                className="bg-black text-white px-4 py-2 rounded-lg font-semibold"
              >
                Create Group
              </CustomButton>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  )
}

function EditSendGridGroup(props) {
  const groupId = props?.groupId
  const name = props?.title ?? ''
  const [visible, setVisible] = useState(false)
  const [title, setTitle] = useState(name)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (visible) {
      setTitle(name)
    }
  }, [visible])

  function createGroup() {
    setSaving(true)
    api.post(`/sendgrid/groups/${groupId}`, { title })
      .then(() => {
        alert('Group updated successfully')
      })
      .catch(() => {
        alert('Failed to update group')
      })
      .finally(() => {
        if (props?.onUpdated && typeof props?.onUpdated === 'function') {
          props?.onUpdated()
        }
        setSaving(false)
        setVisible(false)
      })
  }

  return (
    <div>
      <CustomButton
        onClick={() => setVisible(true)}
        type="secondary"
        className="bg-black text-white px-4 py-2 rounded-lg font-semibold"
      >
        <EditIcon size={16} />
        Edit
      </CustomButton>
      <Drawer
        title="Update Unsubscribe Group"
        width={720}
        onClose={() => setVisible(false)}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <div>
          <div className="flex flex-col gap-y-4">
            <div>
              <div>
                ({groupId})
              </div>
              <label className="block text-sm font-medium text-gray-700">
                Group Title
              </label>
              <input
                defaultValue={props?.title}
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <CustomButton
                onClick={createGroup}
                type="primary"
                loading={saving}
                className="bg-black text-white px-4 py-2 rounded-lg font-semibold"
              >
                Save Group
              </CustomButton>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  )
}