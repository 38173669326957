/* eslint-disable */
// @ts-nocheck
import { useQuery } from "@tanstack/react-query"
import { getEvents, getEventsAttendees, getEventStats } from "store/actions/eventsActions"

export const useEventData = (eventId) => {
  const eventQuery = useQuery(["event", eventId], () => getEvents(eventId), {
    enabled: !!eventId,
    staleTime: 5 * 60 * 1000,
  })

  const attendeesQuery = useQuery(["attendees", eventId], () => getEventsAttendees(eventId), {
    enabled: !!eventId,
    staleTime: 5 * 60 * 1000,
  })

  const statsQuery = useQuery(["stats", eventId], () => getEventStats(eventId), {
    enabled: !!eventId,
    staleTime: 5 * 60 * 1000,
  })

  const isLoading = eventQuery.isLoading || attendeesQuery.isLoading || statsQuery.isLoading

  const refetchAll = () => {
    eventQuery.refetch()
    attendeesQuery.refetch()
    statsQuery.refetch()
  }

  return {
    event: eventQuery.data,
    attendees: attendeesQuery.data,
    stats: statsQuery.data,
    isLoading,
    refetchAll,
  }
}

export default useEventData
