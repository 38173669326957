// @ts-nocheck
import React from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import CreateOrUpdateEmailTemplate from '../CreateOrUpdateEmailTemplate'

const EditEmailTemplate = () => {
  const { templateId } = useParams()

  return (
    <CreateOrUpdateEmailTemplate templateId={templateId} />
  )
}

export default EditEmailTemplate
