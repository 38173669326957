// @ts-nocheck
/* eslint-disable */
import React from 'react'
import AdminWebsiteTrafficReport from 'pages/desktopMode/websiteTrafficReport'

const WebsiteTrafficTab = ({ userObject }) => {
    return (
        <AdminWebsiteTrafficReport isDashboard={true} />
    )
}

export default WebsiteTrafficTab 