/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { PageHeader, TFilledPlusIcon } from 'components'
import CategoryButton from 'components/AdminSideBar/CategoryButton'
import CustomButton from 'components/CustomButton'
import { CalendarX, Eye, MapPin, Trash2 } from 'lucide-react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ADMIN_CREATE_EVENT_PAGE } from 'routes'
import { getImage } from 'store/actions/eventsActions'
import { useGetEvents } from 'hooks/useGetEvents'
import DeleteModal from 'components/DeleteModal'

const EventCardSkeleton = () => {
  return (
    <div className="border-[1px] overflow-hidden col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-4 relative z-1 pb-2.5 rounded-[12px] border-zinc-100 bg-white">
      {/* Hero section with shimmer */}
      <div className="relative z-3 text-white grid grid-cols-12 items-center gap-3 p-3 w-full bg-zinc-100">
        <div className="w-full h-full bg-white/70 absolute top-0 -z-1" />

        {/* Profile image placeholder */}
        <div className="col-span-4 h-full flex justify-center overflow-hidden">
          <div className="h-[90px] w-[90px] rounded-full bg-zinc-200 relative overflow-hidden">
            <div className="absolute inset-0 -z-10 bg-gradient-to-r from-transparent via-white to-transparent animate-shimmer" />
          </div>
        </div>

        {/* Event details placeholders */}
        <div className="col-span-7 text-black space-y-2">
          <div className="h-6 w-full bg-zinc-200 rounded relative overflow-hidden">
            <div className="absolute inset-0 -z-10 bg-gradient-to-r from-transparent via-white to-transparent animate-shimmer" />
          </div>
          <div className="h-4 w-3/4 bg-zinc-200 rounded relative overflow-hidden">
            <div className="absolute inset-0 -z-10 bg-gradient-to-r from-transparent via-white to-transparent animate-shimmer" />
          </div>
          <div className="h-4 w-2/3 bg-zinc-200 rounded relative overflow-hidden">
            <div className="absolute inset-0 -z-10 bg-gradient-to-r from-transparent via-white to-transparent animate-shimmer" />
          </div>
          <div className="flex items-center gap-2">
            <MapPin className="w-4 h-4 text-zinc-300" />
            <div className="h-4 w-full bg-zinc-200 rounded relative overflow-hidden">
              <div className="absolute inset-0 -z-10 bg-gradient-to-r from-transparent via-white to-transparent animate-shimmer" />
            </div>
          </div>
        </div>
      </div>

      {/* Event stats section */}
      <div className="relative z-3 px-[10px] pt-[8px]">
        <div className="h-6 w-2/3 bg-zinc-200 rounded mb-2 relative overflow-hidden">
          <div className="absolute inset-0 -z-10 bg-gradient-to-r from-transparent via-white to-transparent animate-shimmer" />
        </div>
        <div className="w-full h-[1px] bg-zinc-200"></div>

        {/* Stats boxes */}
        <div className="flex justify-between mt-3 gap-4">
          {[1, 2, 3].map((i) => (
            <div key={i} className="bg-zinc-50 border-[1px] p-2 w-full border-zinc-200 rounded-md">
              <div className="h-4 w-1/2 bg-zinc-200 mb-1 rounded relative overflow-hidden">
                <div className="absolute inset-0 -z-10 bg-gradient-to-r from-transparent via-white to-transparent animate-shimmer" />
              </div>
              <div className="h-6 w-1/3 bg-zinc-200 rounded relative overflow-hidden">
                <div className="absolute inset-0 -z-10 bg-gradient-to-r from-transparent via-white to-transparent animate-shimmer" />
              </div>
            </div>
          ))}
        </div>

        {/* Buttons placeholders */}
        <div className="flex items-center gap-2.5 w-full mt-3">
          <div className="h-10 w-full bg-zinc-200 rounded relative overflow-hidden flex-1">
            <div className="absolute inset-0 -z-10 bg-gradient-to-r from-transparent via-white to-transparent animate-shimmer" />
          </div>
          <div className="h-10 w-full bg-zinc-200 rounded relative overflow-hidden flex-1">
            <div className="absolute inset-0 -z-10 bg-gradient-to-r from-transparent via-white to-transparent animate-shimmer" />
          </div>
        </div>
      </div>
    </div>
  )
}

const EventDashboard = (props) => {
  const history = useHistory()
  const {
    allEvents,
    isFetchingEvents,
    EVENT_CATEGORY,
    deleteEvent,
    isEventDeleting,
    eventToBeDeleted,
    setEventToBeDeleted
  } = useGetEvents()
  const [selectedCategory, setSelectedCategory] = useState("upcoming")

  const RightContent = () => (
    <div className='flex flex-row items-center'>
      <CustomButton
        className='w-fit m-auto'
        handleClick={() => history.push(ADMIN_CREATE_EVENT_PAGE)}
      >
        <span className='font-medium py-2.5 px-6 text-base flex gap-2 items-center'>
          <TFilledPlusIcon className='h-6 w-6' />
          Add New Events
        </span>
      </CustomButton>
    </div>
  )

  const EventCard = (event) => {
    const [heroImage, setHeroImage] = useState()
    const [coverImage, setCoverImage] = useState()
    const [date, setDate] = useState()

    useEffect(() => {
      if (event) {
        var startTime = new Date(event.startDate)
        var endTime = new Date(event.endDate)
        var formattedStartDate = startTime.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
        var formattedEndDate = endTime.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
        setDate(formattedStartDate + ' - ' + formattedEndDate)
        if (event.heroImage) {
          getImage(event.heroImage).then((res) => setHeroImage(res)).catch(() => setHeroImage())
        }
        if (event.coverImage) {
          getImage(event.coverImage).then((res) => setCoverImage(res)).catch(() => setCoverImage())
        }
      }
    }, [event])

    return (
      <div
        className="overflow-hidden col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-4 relative z-1 rounded-[12px] bg-white border shadow-sm"
      >
        <div
          className="relative z-3 text-white grid grid-cols-12 items-center gap-3 p-3 w-full bg-no-repeat bg-cover"
          style={{ backgroundImage: `url(${coverImage?.url || '/event-detail-hero.png'})` }}
        >
          <div
            className="w-full h-full bg-white/70 absolute top-0 -z-1"
          />
          <div className='col-span-4 h-full flex justify-center overflow-hidden'>
            <img className='h-[90px] w-[90px] rounded-full object-cover' src={heroImage?.url || '/event-detail-image.png'} alt='Hero image' />
          </div>
          <div className='h-full col-span-7 text-black'>
            <div className="font-bold text-xl truncate">{event.name}</div>
            <div className="text-sm font-medium truncate mt-1">{date}</div>
            <div className="text-sm font-medium truncate">
              {event.startTime + ' - ' + event.endTime}
            </div>
            {
              event?.locationAddress && (
                <div className="flex items-center gap-2 mt-1">
                  <MapPin className='w-4 h-4' />
                  <div className='flex-1 truncate text-sm font-medium'>{event.locationAddress}</div>
                </div>
              )
            }
          </div>
        </div>
        <div className="relative z-3 p-[10px]">
          <div className="font-bold mb-2 text-[20px]">
            Event Attendee Status
          </div>
          <div className="w-full h-[1px] bg-[#2e2b2e]"></div>
          <div className="flex justify-between mt-3 gap-4">
            <div className="bg-zinc-50 border-[1px] p-2 w-full border-softBlack_70 rounded-md">
              <div className="text-[13px] text-[rgba(46,43,46,0.7)] italic">
                Invited
              </div>
              <div className="text-[20px] text-[#2e2b2e] font-bold">{event?.stats?.totalAttendees}</div>
            </div>
            <div className="bg-zinc-50 border-[1px] p-2  w-full border-softBlack_70 rounded-md">
              <div className="text-[13px] text-[rgba(46,43,46,0.7)] italic">
                RSVP
              </div>
              <div className="text-[20px] text-[#2e2b2e] font-bold">{event?.stats?.rsvpYes}</div>
            </div>
            <div className="bg-zinc-50 border-[1px] p-2  w-full  border-softBlack_70 rounded-md">
              <div className="text-[13px] text-[rgba(46,43,46,0.7)] italic">
                Attended
              </div>
              <div className="text-[20px] text-[#2e2b2e] font-bold">{event?.stats?.checkInCompleted}</div>
            </div>
          </div>
          <div className="flex items-center gap-2.5 w-full mt-3">
            {/* <CustomButton>
              <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
                <img src="/person-grp.png" className='invert' />
                <div className="font-bold italic text-[14px]">
                  Check In Guests
                </div>
              </span>
            </CustomButton> */}
            <CustomButton
              // variant='outline'
              handleClick={() => history.push(`/admin/events/preview/${event._id}`)}
              className='flex-1'
            >
              <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
                <Eye className='w-5 h-5' />
                <div className="text-[14px] font-normal">View Event</div>
              </span>
            </CustomButton>
            {
              !event?.isDeleted && (
                <CustomButton
                  variant='danger_outline'
                  handleClick={() => setEventToBeDeleted(event)}
                  className='flex-1'
                >
                  <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
                    <Trash2 className='w-5 h-5' />
                    <div className="text-[14px] font-normal">Delete</div>
                  </span>
                </CustomButton>
              )
            }
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <PageHeader className='!h-[64px]' title='Events' rightContent={<RightContent />} />
      <div className='mx-auto px-4 sm:px-6 lg:px-8 h-full bg-siteColor'>
        <div className=" flex flex-col items-center justify-center w-full">
          <div className="w-full container mx-auto flex flex-col mt-6 justify-between">
            <div className="flex w-full justify-between bg-white rounded-xl overflow-hidden border border-softBlack p-1.5">
              {EVENT_CATEGORY.map((category, index) => (
                <CategoryButton
                  key={category.title}
                  category={category}
                  selected={selectedCategory === category?.value}
                  setSelectedCategory={setSelectedCategory}
                  className='focus:!outline-none focus:!ring-0'
                >
                  <div className={`py-1 font-bold text-lg transition-all ${selectedCategory === category?.value ? 'shadow-md' : 'hover:!bg-zinc-200'}`}>
                    {category.title}
                  </div>
                </CategoryButton>
              ))}
            </div>
          </div>
          <div className="grid grid-cols-12 justify-between gap-6 flex-wrap my-6 w-full container mx-auto">
            {
              isFetchingEvents
                ? <>
                  {
                    Array.from(Array(3).keys()).map((_, index) => (
                      <EventCardSkeleton key={index} />
                    ))
                  }
                </>
                : <>
                  {!allEvents[selectedCategory]?.length
                    ? <div className="col-span-12 flex flex-col items-center justify-center p-8 px-14 rounded-xl bg-white text-center">
                      <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-zinc-100 mb-4">
                        <CalendarX className="w-8 h-8 text-zinc-500" />
                      </div>

                      <h3 className="text-xl font-bold text-zinc-800 mb-2">No Events Found</h3>
                      <p className="text-zinc-500 mb-6">There are no events to display for this selection.</p>

                      <RightContent />
                    </div>
                    : <>
                      {
                        allEvents[selectedCategory].map((event) => (
                          <EventCard {...event} key={event._id} />
                        ))
                      }
                    </>
                  }
                </>
            }
          </div>
        </div>
      </div>
      {
        eventToBeDeleted && (
          <DeleteModal
            title='Confirm event deletion?'
            description={`Are you sure? do you want to delete '${eventToBeDeleted?.name ?? '-'}' event?`}
            confirmButtonTitle='Yes, delete it'
            cancelButtonTitle='No'
            isOpen={!!(eventToBeDeleted && eventToBeDeleted?._id)}
            setIsOpen={() => setEventToBeDeleted(null)}
            onConfirm={deleteEvent}
            isBtnLoading={isEventDeleting}
          />
        )
      }
    </>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(EventDashboard)
