/* eslint-disable */
// @ts-nocheck
import React from 'react'
import * as Yup from 'yup'
import { message } from 'antd'
import { getDevelopers } from 'store/actions/developersActions'
import useUpdateUsers from 'hooks/useUpdateUsers'
import CustomButton from 'components/CustomButton'
import { Oval } from 'react-loader-spinner'
import AdminPermissionDuringCreation from 'pages/adminPermissionDuringCreation'
import BackButton from 'pages/pwaMode/components/BackButton'
import { CgChevronLeft } from 'react-icons/cg'
import { Select } from 'antd'
import { getProjects } from 'store/actions/projectActions'
import CustomSelect from 'pages/offer/components/CustomSelect'
import Checkbox from 'components/Checkbox'

const FormInput = ({ label, labelClassName, ...props }) => {
  return (
    <label>
      <div className={`mb-1 font-semibold ${labelClassName}`}>{label}</div>
      <input
        className={`placeholder:text-gray-300 rounded-none ${props.className}`}
        {...props}
      />
    </label>
  )
}

const NewDevAdmin = ({ devAdmin = {}, cancel, update }) => {
  const { createOrUpdateUser } = useUpdateUsers()
  const [formValues, setFormValues] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    userType: 'DeveloperAdmin',
    projectPermissions: [],
    corporateProjectShow: true
  })
  const [step, setStep] = React.useState(1)
  const [projects, setProjects] = React.useState([])
  const [developers, setDevelopers] = React.useState([])
  const [selectedDeveloper, setSelectedDeveloper] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)

  const schema = {
    email: Yup.string().required(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required()
  }

  React.useEffect(() => {
    getDevelopers().then((result) => {
      setDevelopers([
        { _id: '0', companyName: 'Select Developer Company' },
        ...result
      ])
    })

    getProjects().then((result) => {
      setProjects(result || [])
    })

    if (devAdmin?._id) {
      setFormValues({
        firstName: devAdmin?.firstName ?? '',
        lastName: devAdmin?.lastName ?? '',
        email: devAdmin?.email ?? '',
        _id: devAdmin?._id ?? '',
        permissions: devAdmin?.permissions ?? {},
        userType: devAdmin?.userType ?? 'DeveloperAdmin',
        projectPermissions: devAdmin?.projectPermissions ?? [],
        corporateProjectShow: devAdmin?.corporateProjectShow !== undefined ? devAdmin?.corporateProjectShow : true
      })
      setSelectedDeveloper(
        devAdmin?.developerAdminData?.developerCompany?._id ?? ''
      )
    }
  }, [])

  const reset = () => {
    update()
    cancel()
    setIsLoading(false)
    setFormValues({
      firstName: '',
      lastName: '',
      email: '',
      projectPermissions: [],
      corporateProjectShow: false
    })
    setSelectedDeveloper('')
  }

  const handleStep2 = async () => {
    // const isValid = await Yup.object()
    //   .shape(schema)
    //   .isValid({ ...formValues })
    //   const isEmailValid = await Yup.string().email().isValid(formValues.email)
    //   if (!isEmailValid) {
    //     message.error('please enter valid email.')
    //     setIsLoading(false)
    //     return
    //   }

    //   if (!(selectedDeveloper?.length > 0) && selectedDeveloper === '0') {
    //   message.error('please select developer company')
    //   setIsLoading(false)
    //   return
    // }
    setStep(2)
  }

  const onSubmit = async (permissions = {}) => {
    setIsLoading(true)
    const isValid = await Yup.object()
      .shape(schema)
      .isValid({ ...formValues })

    if (!isValid) {
      message.error('please fill all required fields')
      setIsLoading(false)
      return
    }

    const isEmailValid = await Yup.string().email().isValid(formValues.email)
    if (!isEmailValid) {
      message.error('please enter valid email.')
      setIsLoading(false)
      return
    }

    if (!(selectedDeveloper?.length > 0) && selectedDeveloper === '0') {
      message.error('please select developer company')
      setIsLoading(false)
      return
    }


    const params = {
      ...devAdmin,
      email: formValues.email,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      userType: 'DeveloperAdmin',
      developerAdminData: {
        developerCompany: selectedDeveloper
      },
      permissions: permissions || {},
      projectPermissions: formValues.projectPermissions,
      corporateProjectShow: formValues.corporateProjectShow
    }

    createOrUpdateUser(params, false, false)
      .then(() => {
        message.success('Successfully created developer admin')
        reset()
      })
      .catch(() => {
        message.error('Failed to create developer admin')
        reset()
      })
  }

  return (
    <div className='bg-gray-100'>
      <div className='p-6 flex justify-between items-center bg-white'>
        <span className='font-bold text-3xl'>New Developer Admins</span>
        <CustomButton
          variant='ghost'
          className='hover:bg-zinc-300'
          handleClick={cancel}
        >
          <span className='p-2 px-4 text-base font-semibold flex items-center gap-2'>
            Cancel
          </span>
        </CustomButton>
      </div>
      <div className='border-b-2 border-black' />
      {step === 2 && (
        <div className='px-6 pt-4'>
          <button
            onClick={() => {
              setStep(1)
            }}
            className='flex content-center items-center font-semibold text-base'
          >
            <CgChevronLeft className='w-8 h-8' />
            <span className='ml-[19.45px]'>BACK</span>
          </button>
        </div>
      )}
      {step === 1 && (
        <div className='flex-1 m-6 p-6 rounded'>
          <h1 className='font-black text-md pt-3'>Personal Information</h1>
          <div className='border-b-2 border-black my-2' />
          <h2>Please enter the details about the new developer admin</h2>
          <div
            className='grid grid-cols-2  gap-6 border-0 p-0 mt-10 rounded-none shadow-none'
            onSubmit={onSubmit}
          >
            <FormInput
              label='FIRST NAME *'
              labelClassName='text-xs'
              placeholder='Enter first name'
              value={formValues.firstName}
              onChange={(e) =>
                setFormValues((prev) => {
                  return { ...prev, firstName: e?.target?.value }
                })
              }
            />
            <FormInput
              label='LAST NAME *'
              labelClassName='text-xs'
              placeholder='Enter last name'
              value={formValues.lastName}
              onChange={(e) =>
                setFormValues((prev) => {
                  return { ...prev, lastName: e?.target?.value }
                })
              }
            />
            <FormInput
              label='EMAIL *'
              labelClassName='text-xs'
              placeholder='Enter email'
              value={formValues.email}
              onChange={(e) =>
                setFormValues((prev) => {
                  return { ...prev, email: e?.target?.value }
                })
              }
              disabled={!!devAdmin?.id}
            />
            <label>
              <div className='text-xs mb-[5px] font-semibold'>
                DEVELOPER COMPANY *
              </div>
              <select
                value={selectedDeveloper}
                onChange={(e) => setSelectedDeveloper(e.target.value)}
                className='border-gray-400 border w-full h-11'
                disabled={!!devAdmin?.id}
              >
                {developers.map((developer, index) => (
                  <option key={index} value={developer._id}>
                    {developer.companyName}
                  </option>
                ))}
              </select>
            </label>
            <div className="col-span-1">
              <div className='text-xs mb-[5px] font-semibold'>
                ASSIGN PROJECTS
              </div>
              <CustomSelect
                inputClasses={'bg-white'}
                multiple={true}
                options={projects.map((project) => ({
                  name: project.projectName,
                  id: project._id,
                  value: project.projectName
                }))}
                toIterateField='name'
                placeholder="Select projects to assign"
                selectedOption={formValues.projectPermissions || []}
                setSelectedOption={(values) => {
                  setFormValues(prev => ({
                    ...prev,
                    projectPermissions: values
                  }))
                }
                }
                isDarkFont
              />
              
            </div>
            <div className="col-span-1 flex items-center gap-2">
              <Checkbox
                checked={formValues.corporateProjectShow}
                handleChange={(e) => 
                  setFormValues(prev => ({
                    ...prev,
                    corporateProjectShow: !prev.corporateProjectShow
                  }))
                }
              />
              <div className="text-sm">
                Show corporate project?
              </div>
            </div>
            <div className='col-span-2 flex justify-end'>
              <CustomButton
                handleClick={handleStep2}
                disabled={isLoading || !(selectedDeveloper?.length > 0)}
              >
                <span className='p-2 px-4 text-base font-semibold flex items-center gap-2'>
                  {isLoading && <Oval height='20' width='20' color='white' />}
                  {devAdmin?._id ? 'Next' : 'Next'}
                </span>
              </CustomButton>
            </div>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className='flex-1 m-6 p-6 rounded'>
          <h1 className='font-black text-md pt-3'>Set Permissions</h1>
          <div className='border-b-2 border-black mt-2' />
          <AdminPermissionDuringCreation
            onSubmit={onSubmit}
            selectedUserObject={formValues}
            setSelectedUserObject={setFormValues}
            step={step}
            setStep={setStep}
          />
        </div>
      )}
    </div>
  )
}

export default NewDevAdmin
