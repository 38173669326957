/* eslint-disable */
// @ts-nocheck
import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect
} from 'react'
import { api, ApiErrors, getTokenRequestHeader } from 'services/api'
import mixpanel, { MIXPANEL_EVENTS } from 'services/mixpanel'
import { ReactComponent as DownIcon } from 'assets/icons/down.svg'
import { formatMoney, getOrdinalName, sanitizeCode } from 'utils'
import { Select } from 'antd'
import RichTextField from 'components/RichTextField'
import useGetCommunicationTemplatesByType from 'hooks/useGetCommunicationTemplatesByType'
import { useGetContactLists } from 'hooks/useGetContactLists'
import CustomButton from 'components/CustomButton'
import { Button } from 'components/ShadcnComponents/button'
import CustomModal from 'ui/customModal'
import TableGrid from 'components/TableGrid/Table'
import { getUsersPagination } from 'store/actions/usersActions'
import { Oval } from 'react-loader-spinner'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom'

const COST_PER_SEGMENT = 0.0079
const COST_PER_SEGMENT_FORMATTED = '$0.0079'
const SEGMENT_SIZE = 140
const FRACTIONAL_PART = 5

function CreateSMSBroadcast({ filter }) {
  const history = useHistory()
  const { contactsLists, getContacts } = useGetContactLists()
  const [smsBroadcastDetails, setSmsBroadcastDetails] = useState({
    message: '',
    campaignName: ''
  })
  const editorRef = useRef(null)
  const [selectedContactGroupId, setSelectedContactGroupId] = useState(null)
  const [contactGroup, setContactGroup] = useState(null)
  const [extraContacts, setExtraContacts] = useState([])
  const { communicationTemplates, getCommunicationTemplates } = useGetCommunicationTemplatesByType('sms')
  const [isSendingSMSBroadcast, setIsSendingSMSBroadcast] = useState(false)

  useEffect(() => {
    getContacts()
    getCommunicationTemplates()
  }, [])

  useEffect(() => {
    const arr = []
    const filterFunc = (x) => {
      if (filter?.eventId) {
        return x.eventId === filter.eventId
      }
      return true
    }
    contactsLists.filter(filterFunc).forEach((el) => {
      if (!arr.find((item) => el.title === item.name)) {
        arr.push({
          id: el._id,
          name: `${el.title} (${el?.users?.length || 0})`,
          count: el?.users?.length || 0
        })
      }
    })
    setContactGroup(arr)
  }, [contactsLists])

  const sendSMSBroadcast = useCallback(() => {
    const toastId = toast.loading('Sending SMS broadcast...')
    setIsSendingSMSBroadcast(true)

    const cleanMessage =
      editorRef.current?.getEditor?.().getText?.().trim?.() ?? ''

    return new Promise((resolve, reject) => {
      const token = getTokenRequestHeader()

      // @ts-ignore
      api.setHeader('Authorization', token)

      api
        .post('sms/list', {
          ...smsBroadcastDetails,
          message: cleanMessage,
          listId: selectedContactGroupId,
          contacts: extraContacts ?? []
        })
        .then((response) => {
          if (response.ok) {
            resolve(response)
            // Track sms broadcast send event
            mixpanel.track(MIXPANEL_EVENTS.SMS_BROADCAST_SEND)
            toast.dismiss(toastId)
            toast.success('SMS broadcast sent successfully')
            history.push(`/admin/sms-broadcast/${response?.data?.campaignId ?? ''}`)
          }
        })
        .catch((err) => {
          console.error(err)
          toast.dismiss(toastId)
          toast.error('SMS broadcast failed, an error occurred while sending SMS broadcast.')
        })
        .finally(() => setIsSendingSMSBroadcast(false))
    })
  }, [
    selectedContactGroupId,
    extraContacts,
    smsBroadcastDetails
  ])

  const smsCostDetails = useMemo(() => {
    const totalCharacterCount =
      editorRef.current?.getEditor?.().getText?.().trim?.()?.length || 0
    const segmentsCount = Math.ceil(totalCharacterCount / SEGMENT_SIZE)
    let totalRecipients = 0

    if (selectedContactGroupId && contactGroup?.length) {
      totalRecipients +=
        contactGroup?.filter(
          (group) => group?.id === selectedContactGroupId
        )?.[0]?.count || 0
    }

    if (extraContacts?.length) {
      totalRecipients += extraContacts.length
    }

    const costPerRecipient = (segmentsCount * COST_PER_SEGMENT).toFixed(
      FRACTIONAL_PART
    )
    const totalCost = (costPerRecipient * totalRecipients).toFixed(
      FRACTIONAL_PART
    )

    return {
      totalCharacterCount,
      segmentsCount,
      totalRecipients,
      costPerRecipient,
      totalCost
    }
  }, [
    editorRef,
    contactGroup,
    selectedContactGroupId,
    extraContacts,
    smsBroadcastDetails
  ])

  const isSubmitButtonDisabled = useMemo(() => {
    const cleanMessage = editorRef.current?.getEditor?.().getText?.().trim?.() ?? ''

    return !selectedContactGroupId ||
      !(contactGroup?.filter((e) => e?.id === selectedContactGroupId)?.[0]?.count) ||
      !smsBroadcastDetails.message.length ||
      !smsBroadcastDetails.campaignName.length ||
      isSendingSMSBroadcast ||
      !cleanMessage ||
      !cleanMessage?.length
  }, [editorRef, selectedContactGroupId, contactGroup, smsBroadcastDetails?.message, smsBroadcastDetails?.campaignName, isSendingSMSBroadcast])

  const setSMSTemplate = useCallback((content = '', title = '') => {
    if (editorRef.current?.getEditor) {
      const editorInstance = editorRef.current.getEditor();

      if (editorInstance.setHTML) {
        editorInstance.setHTML(content);
      } else if (editorInstance.clipboard?.dangerouslyPasteHTML) {
        editorInstance.clipboard.dangerouslyPasteHTML(content);
      } else if (editorInstance.root) {
        editorInstance.root.innerHTML = content;
      }
    }

    setSmsBroadcastDetails({
      message: content,
      campaignName: title,
    });
  }, [editorRef])

  return (
    <div className='overflow-y-auto hide-scrollbar'>
      <div className='w-full h-fit pt-4 grid grid-cols-2 gap-x-6'>
        <div className='min-h-full'>
          {/* <h1 className='text-4xl font-bold'>SMS Broadcast</h1> */}
          <div className='flex flex-col gap-y-5'>
            <div>
              <p className='text-base font-medium mb-1'>Message Title *</p>
              <input
                placeholder='Type your title here.'
                value={smsBroadcastDetails?.campaignName ?? ''}
                onChange={(e) =>
                  setSmsBroadcastDetails({
                    ...smsBroadcastDetails,
                    campaignName: e.target.value
                  })
                }
              />
            </div>
            <div>
              <p className='text-base font-medium mb-1'>Your Message *</p>
              <RichTextField
                editorRef={editorRef}
                onChangeText={(value) =>
                  setSmsBroadcastDetails({
                    ...smsBroadcastDetails,
                    message: value
                  })
                }
                text={smsBroadcastDetails?.message ?? ''}
                placeholder={'Type text here...'}
                height='410px'
                style={{
                  height: '410px'
                }}
                hasFileAttachments={false}
              />
              <div
                className='w-full p-2 bg-white mt-[43px] text-sm lowercase'
                style={{
                  borderWidth: '1px',
                  borderTopWidth: '0px',
                  borderColor: '#ccc'
                }}
              >
                {smsCostDetails?.segmentsCount * SEGMENT_SIZE -
                  smsCostDetails?.totalCharacterCount || SEGMENT_SIZE}{' '}
                characters remaining in{' '}
                {getOrdinalName(
                  smsCostDetails?.segmentsCount > 0
                    ? smsCostDetails?.segmentsCount - 1
                    : smsCostDetails?.segmentsCount
                )}{' '}
                segment
              </div>
            </div>
          </div>
        </div>
        <div className='min-h-full flex flex-col gap-4'>
          <div className='flex flex-col gap-y-5'>
            <div>
              <p className='text-base font-medium mb-1'>Contact Groups *</p>
              <Select
                showArrow
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toString()
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                value={selectedContactGroupId ?? 'Select groups'}
                onChange={(value) => {
                  setSelectedContactGroupId(value)
                }}
                className='ant-picker-input1'
                suffixIcon={<DownIcon />}
                bordered={false}
                getPopupContainer={(node) => node.parentNode}
              >
                {contactGroup?.map((el) => (
                  <Select.Option key={el.id} label={el.name} value={el.id}>
                    {el.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className='w-full flex justify-between items-center gap-3'>
              <div className='flex-1'>
                {extraContacts?.length > 0 && (
                  <div>Added {extraContacts?.length} contacts</div>
                )}
              </div>
              <ModelAddContacts
                existing={extraContacts}
                onAdded={(contacts) => {
                  if (!contacts || !contacts.length || !Array.isArray(contacts)) {
                    return
                  }
                  const updatedContacts = [...extraContacts, ...contacts]
                  const uniqueContacts = updatedContacts.filter(
                    (contact, index, self) =>
                      index === self.findIndex((t) => t === contact)
                  )
                  setExtraContacts(uniqueContacts)
                }}
                disabled={isSendingSMSBroadcast}
              />
            </div>
          </div>
          <div className='space-y-4'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4.5 bg-white border p-4 !rounded-lg'>
              <SummaryCard
                title='Total Characters'
                value={smsCostDetails?.totalCharacterCount}
              />
              <SummaryCard
                title='Message Segments'
                value={`${smsCostDetails?.segmentsCount}${
                  smsCostDetails?.segmentsCount > 0
                    ? ` x ${COST_PER_SEGMENT_FORMATTED}`
                    : ''
                }`}
              />
              <SummaryCard
                title='Cost per Recipient'
                value={formatMoney(smsCostDetails?.costPerRecipient, '$')}
              />
              <SummaryCard
                title='Total Campaign Cost'
                value={formatMoney(smsCostDetails?.totalCost, '$')}
              />
            </div>

            <div className='bg-white !rounded-lg border p-4'>
              <h2 className='text-lg font-semibold text-gray-900 mb-2.5'>
                Cost Breakdown
              </h2>
              <div className='space-y-3'>
                <CostRow
                  label='Message Length'
                  value={`${smsCostDetails?.totalCharacterCount} characters`}
                />
                <CostRow
                  label='Segments Required'
                  value={`${smsCostDetails?.segmentsCount}${
                    smsCostDetails?.segmentsCount > 0
                      ? ` (${smsCostDetails?.segmentsCount} x ${COST_PER_SEGMENT_FORMATTED})`
                      : ''
                  }`}
                />
                <CostRow
                  label='Recipients'
                  value={smsCostDetails?.totalRecipients}
                />
                <CostRow
                  label='Cost per Recipient'
                  value={formatMoney(smsCostDetails?.costPerRecipient, '$')}
                />

                <div className='pt-3 border-t'>
                  <CostRow
                    label='Total Cost'
                    value={formatMoney(smsCostDetails?.totalCost, '$')}
                    isTotal
                  />
                  <div className='mt-2 text-sm text-gray-500'>
                    {COST_PER_SEGMENT} per {SEGMENT_SIZE} characters x{' '}
                    {smsCostDetails?.segmentsCount} segments x{' '}
                    {smsCostDetails?.totalRecipients} recipients
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='w-full flex'>
            <CustomButton
              handleClick={sendSMSBroadcast}
              disabled={isSubmitButtonDisabled}
              className='!rounded-lg !w-full'
            >
              <span className="font-medium py-2.5 px-6 text-lg flex gap-2 items-center">
                {isSendingSMSBroadcast && <Oval height="18" width="18" color="white" />}
                Send Campaign ({formatMoney(smsCostDetails?.totalCost, '$')})
              </span>
            </CustomButton>
          </div>
        </div>
      </div>
      <div className='mt-4 w-full flex flex-col overflow-hidden'>
        <h3 className='text-2xl font-semibold mb-3'>Template Messages</h3>
        <div className='flex-1 grid grid-cols-3 gap-4 overflow-y-auto thin-scrollbar'>
          <>
            {!communicationTemplates?.length && (
              <h6 className='col-span-2 h-full text-lg flex justify-center items-center'>
                SMS template not found
              </h6>
            )}
          </>
          {communicationTemplates?.length > 0 && (
            <>
              {communicationTemplates.map(({ title, content }) => (
                <div className='p-4 col-span-1 flex flex-col rounded border border-softBlack_70/20 bg-white h-[350px] overflow-hidden'>
                  <h6 className='text-lg'>{title}</h6>
                  <div
                    className='flex-1 *:mb-0 overflow-y-auto thin-scrollbar'
                    dangerouslySetInnerHTML={{
                      __html: sanitizeCode(content) ?? ''
                    }}
                  />
                  <CustomButton
                    className='mt-3'
                    handleClick={() => setSMSTemplate(content, title)}
                  >
                    <span className='font-medium py-2 px-6 text-base flex gap-2 items-center'>
                      Copy
                    </span>
                  </CustomButton>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

// eslint-disable-next-line space-before-function-paren
function ModelAddContacts({ onAdded, existing, disabled = false }) {
  const [show, setShow] = React.useState(false)
  const handleClose = (contacts) => {
    setShow(false)
    if (onAdded && typeof onAdded === 'function') {
      onAdded(contacts)
    }
  }
  const tableRef = React.useRef({
    reloadTableData: () => { }
  })
  const [searchFilter, setSearchFilter] = React.useState('')
  useEffect(() => {
    if (!show) {
      if (onAdded && typeof onAdded === 'function') {
        onAdded([])
      }
    }
  }, [onAdded, show])
  return (
    <div className='w-fit'>
      <Button onClick={() => setShow(true)} disabled={disabled}>
        Add Contacts
      </Button>
      <CustomModal
        showModal={show}
        onCancel={handleClose}
      >
        <div className='w-[100%] min-w-[600px] sm:min-w-[900px]'>
          <div className='flex justify-between w-full'>
            <div>
              Add Contacts to the list
            </div>
            <div>
              <button onClick={handleClose} className='bg-red-500 hover:bg-red-700 text-white font-bold py-0.5 px-2.5 rounded'>
                Cancel
              </button>
            </div>
          </div>
          <div>
            <input
              type='text'
              placeholder='Search by Name or email'
              className='w-full border border-gray-300 rounded-md px-2 my-1'
              value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)} />
          </div>
          <div>
            <TableGrid
              multiple
              style={{ height: '470px', minHeight: '470px' }}
              allowSelect
              allowSelectAll
              rowSelectKey='id'
              columnDefs={[
                {
                  headerName: 'Email',
                  field: 'email',
                  sortable: true,
                  checkboxSelection: true,
                  flex: 1,
                  resizable: true
                },
                {
                  headerName: 'Name',
                  field: 'firstName',
                  sortable: true,
                  resizable: true,
                  cellRenderer: ({ data }) => {
                    return `${data.firstName} ${data.lastName}`
                  }
                },
                {
                  headerName: 'Phone',
                  field: 'buyerData.phoneNumber',
                  cellRenderer: ({ value }) => {
                    return value ?? 'N/A'
                  }
                },
                {
                  headerName: 'User Type',
                  field: 'userType',
                  sortable: true,
                  cellRenderer: ({ value }) => {
                    const userType = value
                    const availableTags = [
                      ['buyer', 'bg-blue-500 text-white'],
                      ['DeveloperAdmin', 'bg-red-500 text-white'],
                      ['CoopBroker', 'bg-green-500 text-white'],
                      ['LeadBroker', 'bg-green-500 text-white'],
                      ['SalesRep', 'bg-orange-500 text-white']
                    ]
                    return <div>
                      {/* eslint-disable-next-line array-callback-return */}
                      {availableTags.map(([tag, color]) => {
                        if (userType.toLowerCase() === tag.toLowerCase()) {
                          return (
                            <div className={
                              `capitalize text-gray-800 border border-gray-300 px-2 py-1 rounded-full text-xs ${color}`
                            }>
                              {tag}
                            </div>
                          )
                        }
                      })}
                    </div>
                  }
                }
              ]}
              actions={[
                {
                  label: 'Add Selected',
                  progressLabel: 'Adding...',
                  onlyWhenSelected: true,
                  apply: async (selectedRows) => {
                    handleClose(selectedRows)
                  }
                }
              ]}
              getData={async (
                filter,
                pagination,
                sorting
              ) => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise(async (resolve) => {
                  const searchText = {}
                  let search = searchFilter
                  if (search) {
                    if (!isNaN(Number(search))) {
                      search = ''
                    }
                    if (search) {
                      if (!searchText?.$or) {
                        searchText.$or = []
                      }
                      searchText.$or = [
                        { email: { $regex: search, $options: 'i' } },
                        { firstName: { $regex: search, $options: 'i' } },
                        { lastName: { $regex: search, $options: 'i' } }
                      ]
                    }
                  }
                  const { docs: users } = await getUsersPagination({
                    ...searchText,
                    ...filter
                  }, {
                    ...pagination,
                    sort: sorting
                  }, existing)
                  const list = users.filter(e => {
                    return !existing.find(i => i === e.id)
                  })
                  resolve(list)
                })
              }} >
              {(params) => {
                tableRef.current = params
              }}
            </TableGrid>
          </div>
        </div>
      </CustomModal>
    </div>
  )
}

const CostRow = ({ label, value, isTotal = false }) => {
  return (
    <div className="flex justify-between items-center">
      <div className={`text-sm ${isTotal ? 'font-semibold' : 'text-gray-600'}`}>
        {label}
      </div>
      <div className={`text-sm font-medium text-black ${isTotal ? 'font-semibold' : ''}`}>
        {value}
      </div>
    </div>
  )
}

const SummaryCard = ({ title, value }) => {
  return (
    <div className="bg-white rounded-lg">
      <div className="text-sm text-gray-600">{title}</div>
      <div className="text-lg font-semibold mt-1">{value}</div>
    </div>
  )
}

export default CreateSMSBroadcast
