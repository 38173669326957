import React, { useState, useEffect, useRef } from 'react'
import { Users, ArrowUp, ArrowDown, Clock, Star, MapPin } from 'lucide-react'
import { LineChart, DonutChart, BarChart, PieChart, AreaChart, ChartExportMenu } from './charts'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ShadcnComponents/card'
import { Button } from 'components/ShadcnComponents/button'
import NeighbourhoodMap from './NeighbourhoodMap'

const LeadsTab = ({ userObject, selectedPeriod = 'week', project = 'all', customStartDate, customEndDate, selectedProject, onLoad }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [leadsData, setLeadsData] = useState({
    // Dashboard summary data
    leadsCount: 0,
    averageScore: 0,
    conversionRate: 0,
    hotLeadsCount: 0,
    avgTimeToFirstContact: 0,

    // Trend data
    leadsTrend: [],
    brokersTrend: [],

    // Distribution data
    leadSources: [],
    scoreDistribution: [],
    projectInterest: [],
    neighbourhoodData: [],

    // Funnel data
    conversionFunnel: [],

    // Scoring data
    scoringFactors: [],

    // Chart display flags
    showPeriodComparison: true,
    periodInfo: { periodType: selectedPeriod },

    // Growth/change metrics
    leadsCountGrowth: 0,
    scoreGrowth: 5, // Default value for demo
    conversionRateGrowth: 3.2, // Default value for demo
    contactTimeGrowth: -5 // Default value for demo, negative is good (faster response)
  })

  // Create a ref for the neighbourhood map
  const neighbourhoodMapRef = useRef(null)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)

      // Create an object to store all API performance metrics
      const apiPerformance = {
        totalStartTime: performance.now(),
        totalEndTime: null,
        totalDuration: null,
        apiCalls: []
      }

      try {
        // Helper function to fetch from API endpoints
        const fetchAPI = async (url, additionalParams = {}) => {
          const baseUrl = process.env.REACT_APP_BE_URL || ''

          // Initialize params with all potential properties
          const params = {
            ...additionalParams,
            timeRange: selectedPeriod
          }

          // Add custom date parameters if selectedPeriod is 'custom'
          if (selectedPeriod === 'custom' && customStartDate && customEndDate) {
            params.startDate = customStartDate
            params.endDate = customEndDate
          }

          // Add project filter if selected
          if (selectedProject) {
            params.project = selectedProject
          }

          console.log('params', params)

          const queryString = new URLSearchParams(params).toString()
          const fullUrl = `${baseUrl}${url}?${queryString}`

          const token = localStorage.getItem('pxp.accounts.accessToken')
          const headers = {
            'Content-Type': 'application/json'
          }

          if (token) {
            headers.Authorization = `Bearer ${token}`
          }

          // Start measuring API call time
          const apiStartTime = performance.now()

          // Track the individual API call
          const apiCallInfo = {
            url,
            params,
            startTime: apiStartTime,
            endTime: null,
            duration: null,
            status: 'pending'
          }

          // Add to the performance tracking
          apiPerformance.apiCalls.push(apiCallInfo)

          try {
            const response = await fetch(fullUrl, {
              method: 'GET',
              headers
            })

            const endTime = performance.now()
            apiCallInfo.endTime = endTime
            apiCallInfo.duration = endTime - apiStartTime

            if (!response.ok) {
              apiCallInfo.status = 'error'
              apiCallInfo.errorDetails = `HTTP error! Status: ${response.status}`
              throw new Error(`HTTP error! Status: ${response.status}`)
            }

            const result = await response.json()

            if (!result.success) {
              apiCallInfo.status = 'error'
              apiCallInfo.errorDetails = result.error || 'API request failed'
              throw new Error(result.error || 'API request failed')
            }

            apiCallInfo.status = 'success'
            apiCallInfo.dataSize = JSON.stringify(result.data).length

            return result.data
          } catch (error) {
            if (apiCallInfo.status !== 'error') {
              apiCallInfo.status = 'error'
              apiCallInfo.errorDetails = error.message
            }
            throw error
          }
        }

        console.log('Starting to fetch dashboard data...')

        // Determine previous period for comparison
        const getPreviousPeriod = () => {
          switch (selectedPeriod) {
            case 'today':
              return 'yesterday'
            case 'week':
            case '7d':
              return 'prev-week'
            case 'month':
              return 'prev-month'
            case 'quarter':
              return 'prev-quarter'
            case 'year':
              return 'prev-year'
            case 'custom':
              // For custom periods, the backend should handle the calculation
              return 'prev-custom'
            default:
              return 'prev-week'
          }
        }

        // Fetch data from multiple endpoints in parallel
        const [
          summaryData,
          leadsTrendData,
          brokersTrendData,
          leadSourcesData,
          conversionFunnelData,
          scoreDistributionData,
          projectInterestData,
          scoringFactorsData,
          neighbourhoodData,
          // Add a request to get the previous period data for comparison
          prevPeriodSummaryData
        ] = await Promise.all([
          fetchAPI('/leads2/dashboard-summary'),
          fetchAPI('/leads2/trend-data', { type: 'leads' }),
          fetchAPI('/leads2/trend-data', { type: 'brokers' }),
          fetchAPI('/leads2/distribution-data', { type: 'sources', limit: '5' }),
          fetchAPI('/leads2/conversion-funnel'),
          fetchAPI('/leads2/distribution-data', { type: 'scores' }),
          fetchAPI('/leads2/inventory-interest', { groupBy: 'project' }),
          fetchAPI('/leads2/scoring-factors'),
          fetchAPI('/leads2/neighbourhood-distribution'),
          // Get the previous period summary by adding a comparison param
          fetchAPI('/leads2/dashboard-summary', { comparison: getPreviousPeriod() })
        ])
        console.log('leadsTrendData', leadsTrendData)
        console.log('brokersTrendData', brokersTrendData)
        console.log('leadSourcesData', leadSourcesData)
        console.log('scoreDistributionData', scoreDistributionData)
        console.log('projectInterestData', projectInterestData)
        console.log('scoringFactorsData', scoringFactorsData)
        console.log('summaryData', summaryData)
        console.log('conversionFunnelData', conversionFunnelData)
        console.log('neighbourhoodData', neighbourhoodData)
        console.log('prevPeriodSummaryData', prevPeriodSummaryData)

        // Format trend data
        const formattedLeadsTrend = leadsTrendData.map(item => ({
          day: item.date,
          leads: item.count
        }))

        const formattedBrokersTrend = brokersTrendData.map(item => ({
          day: item.date,
          brokers: item.count
        }))

        // Format sources data
        const formattedSources = leadSourcesData.map(item => ({
          source: item.source || 'Unknown',
          value: item.value
        }))

        // Format score distribution data
        const formattedScoreDistribution = scoreDistributionData.map(item => {
          // Use the server-provided scoreValue if available, otherwise extract it ourselves
          let scoreNumber = item.scoreValue || '0'

          // If scoreValue wasn't provided, extract it from the range
          if (!item.scoreValue && item.range && typeof item.range === 'string') {
            // Match first 2 digits if they exist
            const match = item.range.match(/^(\d{2})/)
            if (match) {
              scoreNumber = match[1]
            }
          }

          return {
            range: scoreNumber, // Just use the numeric part for the chart axis
            fullRange: item.range || 'Unknown', // Keep the full string for tooltips/details
            count: item.count
          }
        }).sort((a, b) => parseInt(a.range) - parseInt(b.range)) // Sort numerically

        // Format project interest data
        const formattedProjectInterest = projectInterestData.map(item => ({
          project: item.project || 'Unknown',
          value: item.count
        }))

        // Update the conversionFunnel data to include the leads count
        conversionFunnelData.unshift({
          stage: 'New Leads',
          count: summaryData.leadsCount
        })

        // Calculate leads change percentage from trend data
        const leadsChangePercent = calculateChangePercentage(formattedLeadsTrend)

        // Calculate score change (absolute value, not percentage)
        const currentScore = summaryData.averageScore ? Math.round(summaryData.averageScore) : 0
        const scoreChange = calculateScoreChange(currentScore, prevPeriodSummaryData)

        // Calculate conversion rate change
        const currentConversionRate = summaryData.conversionRate ? parseFloat(summaryData.conversionRate.toFixed(1)) : 0
        const conversionRateChange = calculateConversionRateChange(currentConversionRate, prevPeriodSummaryData)

        // Calculate time to first contact change
        const currentContactTime = summaryData.avgTimeToFirstContact ? Math.round(summaryData.avgTimeToFirstContact) : 0
        const contactTimeChange = calculateContactTimeChange(currentContactTime, prevPeriodSummaryData)

        // Combine all data into a single state object
        setLeadsData({
          // Dashboard summary
          leadsCount: summaryData.leadsCount || 0,
          averageScore: currentScore,
          conversionRate: currentConversionRate,
          hotLeadsCount: summaryData.hotLeadsCount || 0,
          avgTimeToFirstContact: currentContactTime,

          // Trend data
          leadsTrend: formattedLeadsTrend,
          brokersTrend: formattedBrokersTrend,

          // Distribution data
          leadSources: formattedSources,
          scoreDistribution: formattedScoreDistribution,
          projectInterest: formattedProjectInterest,
          neighbourhoodData,

          // Funnel data
          conversionFunnel: conversionFunnelData,

          // Scoring data
          scoringFactors: scoringFactorsData,

          // Chart display flags
          showPeriodComparison: true,
          periodInfo: { periodType: selectedPeriod },

          // Growth metrics - now calculated dynamically
          leadsCountGrowth: leadsChangePercent,
          scoreGrowth: scoreChange,
          conversionRateGrowth: conversionRateChange,
          contactTimeGrowth: contactTimeChange
        })
      } catch (error) {
        console.error('Error fetching dashboard data:', error)
        // Set default/empty values on error
        setLeadsData(prevData => ({
          ...prevData,
          error: error.message
        }))
      } finally {
        // Complete performance tracking
        apiPerformance.totalEndTime = performance.now()
        apiPerformance.totalDuration = apiPerformance.totalEndTime - apiPerformance.totalStartTime

        // Calculate some statistics
        const successfulCalls = apiPerformance.apiCalls.filter(call => call.status === 'success')
        const totalApiTime = successfulCalls.reduce((sum, call) => sum + call.duration, 0)

        // Log comprehensive performance data
        console.log('Dashboard Performance Metrics:')
        console.log(`Total Time: ${apiPerformance.totalDuration.toFixed(2)}ms`)
        console.log(`API Call Time: ${totalApiTime.toFixed(2)}ms`)
        console.log(`Processing Time: ${(apiPerformance.totalDuration - totalApiTime).toFixed(2)}ms`)
        console.log(`Success Rate: ${successfulCalls.length}/${apiPerformance.apiCalls.length} calls`)

        // Log individual API calls sorted by duration
        console.table(
          apiPerformance.apiCalls
            .sort((a, b) => b.duration - a.duration)
            .map(call => ({
              API: call.url,
              Status: call.status,
              'Time (ms)': call.duration ? call.duration.toFixed(2) : 'N/A'
            }))
        )

        setIsLoading(false)
        // Notify parent component that loading is complete
        if (typeof onLoad === 'function') {
          onLoad()
        }
      }
    }

    fetchData()
  }, [selectedPeriod, customStartDate, customEndDate, selectedProject, onLoad])

  // Function to calculate percentage change
  const calculateChangePercentage = (data) => {
    if (!data || data.length < 2) return 0

    const currentPeriod = data[data.length - 1].leads || 0
    const previousPeriod = data[data.length - 2].leads || 0

    if (previousPeriod === 0) return 0
    return ((currentPeriod - previousPeriod) / previousPeriod * 100).toFixed(1)
  }

  // Function to calculate change in score (absolute value, not percentage)
  const calculateScoreChange = (currentScore, prevPeriodData) => {
    // Get previous period score from the data if available
    if (!prevPeriodData || !prevPeriodData.length) return 0

    // Extract the average score from the previous period data if available
    // Otherwise default to the current score (no change)
    const prevScore = prevPeriodData.averageScore ? Math.round(prevPeriodData.averageScore) : currentScore

    // Return the absolute change in score points
    return currentScore - prevScore
  }

  // Function to calculate conversion rate change
  const calculateConversionRateChange = (currentRate, prevPeriodData) => {
    // Get previous period conversion rate from the data if available
    if (!prevPeriodData) return 0

    // Extract the conversion rate from the previous period data if available
    // Otherwise default to the current rate (no change)
    const prevRate = prevPeriodData.conversionRate ? parseFloat(prevPeriodData.conversionRate.toFixed(1)) : currentRate

    // Return the percentage point change in conversion rate
    return parseFloat((currentRate - prevRate).toFixed(1))
  }

  // Function to calculate time to first contact change percentage
  const calculateContactTimeChange = (currentTime, prevPeriodData) => {
    // Get previous period time from the data if available
    if (!prevPeriodData) return 0

    // Extract the time to first contact from the previous period data if available
    // Otherwise default to the current time (no change)
    const prevTime = prevPeriodData.avgTimeToFirstContact ? Math.round(prevPeriodData.avgTimeToFirstContact) : currentTime

    if (prevTime === 0) return 0

    // Return the percentage change (negative is good - faster response time)
    return parseFloat((((currentTime - prevTime) / prevTime) * 100).toFixed(1))
  }

  // Format date for display
  const formatDate = (dateString) => {
    if (!dateString) return ''

    // Create date object and adjust for timezone issues by setting time to noon
    const date = new Date(dateString)
    date.setHours(12, 0, 0, 0)

    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    })
  }

  // Get previous period label for comparison text
  const getPreviousPeriodLabel = () => {
    switch (selectedPeriod) {
      case 'today':
        return 'yesterday'
      case 'week':
      case '7d':
        return 'last week'
      case 'month':
        return 'last month'
      case 'quarter':
        return 'last quarter'
      case 'year':
        return 'last year'
      case 'custom':
        return 'previous period'
      default:
        return 'previous period'
    }
  }

  return (
        <>
        <div className='flex flex-col gap-2'>
          {/* Period indicator */}
        {selectedPeriod === 'custom' && (
            <div className="bg-blue-50 p-2 rounded-lg mb-1">
                <p className="text-sm text-blue-600">
                    <span className="font-medium">Custom Period:</span> {formatDate(customStartDate)} - {formatDate(customEndDate)}
                </p>
            </div>
        )}
            {/* Top Row - Key Metrics */}
            <div className="grid gap-2 md:grid-cols-4">
                <Card className="overflow-hidden bg-white">
                    <CardHeader className="flex flex-row items-center justify-between py-2">
                        <CardTitle className="text-sm font-medium text-[#1F2937]">New Leads</CardTitle>
                        <Users className="h-4 w-4 text-[#222222]" />
                    </CardHeader>
                    <CardContent className="py-2">
                            <div className="text-xl font-bold text-[#1F2937]">
                            {isLoading ? 'Loading...' : leadsData.leadsCount.toString()}
                            </div>
                            <p className="text-xs text-[#1F2937] mt-1 flex items-center">
                            {leadsData.showPeriodComparison && selectedPeriod !== 'all' && (
                                <>
                                    <span className={`${leadsData.leadsCountGrowth >= 0 ? 'text-emerald-500' : 'text-red-500'} flex items-center mr-1`}>
                                        {leadsData.leadsCountGrowth >= 0
                                          ? (
                                        <ArrowUp className="h-3 w-3 mr-1" />
                                            )
                                          : (
                                        <ArrowDown className="h-3 w-3 mr-1" />
                                            )}
                                        {Math.abs(leadsData.leadsCountGrowth)}%
                                </span>
                                    <span>vs {getPreviousPeriodLabel()}</span>
                                </>
                            )}
                            </p>
                    </CardContent>
                </Card>
                <Card className="overflow-hidden bg-white">
                    <CardHeader className="flex flex-row items-center justify-between py-2">
                        <CardTitle className="text-sm font-medium text-[#1F2937]">Avg. Lead Score</CardTitle>
                        <Star className="h-4 w-4 text-[#222222]" />
                    </CardHeader>
                    <CardContent className="py-2">
                            <div className="text-xl font-bold text-[#1F2937]">
                            {isLoading ? 'Loading...' : `${leadsData.averageScore}`}
                            </div>
                            <p className="text-xs text-[#1F2937] mt-1 flex items-center">
                            {leadsData.showPeriodComparison && selectedPeriod !== 'all' && (
                                <>
                                <span className={`${leadsData.scoreGrowth >= 0 ? 'text-emerald-500' : 'text-red-500'} flex items-center mr-1`}>
                                        {leadsData.scoreGrowth >= 0
                                          ? <ArrowUp className="h-3 w-3 mr-1" />
                                          : <ArrowDown className="h-3 w-3 mr-1" />
                                        }
                                        {Math.abs(leadsData.scoreGrowth)}
                                </span>
                                    <span>points vs {getPreviousPeriodLabel()}</span>
                                </>
                            )}
                            </p>
                    </CardContent>
                </Card>
                <Card className="overflow-hidden bg-white">
                    <CardHeader className="flex flex-row items-center justify-between py-2">
                        <CardTitle className="text-sm font-medium text-[#1F2937]">Conversion Rate</CardTitle>
                        <ArrowUp className="h-4 w-4 text-[#222222]" />
                    </CardHeader>
                    <CardContent className="py-2">
                            <div className="text-xl font-bold text-[#1F2937]">
                            {isLoading ? 'Loading...' : `${leadsData.conversionRate}%`}
                            </div>
                            <p className="text-xs text-[#1F2937] mt-1 flex items-center">
                            {leadsData.showPeriodComparison && selectedPeriod !== 'all' && (
                                <>
                                <span className={`${leadsData.conversionRateGrowth >= 0 ? 'text-emerald-500' : 'text-red-500'} flex items-center mr-1`}>
                                    {leadsData.conversionRateGrowth >= 0
                                      ? <ArrowUp className="h-3 w-3 mr-1" />
                                      : <ArrowDown className="h-3 w-3 mr-1" />
                                    }
                                    {Math.abs(leadsData.conversionRateGrowth)}%
                                </span>
                                    <span>vs {getPreviousPeriodLabel()}</span>
                                </>
                            )}
                            </p>
                    </CardContent>
                </Card>
                <Card className="overflow-hidden bg-white">
                    <CardHeader className="flex flex-row items-center justify-between py-2">
                        <CardTitle className="text-sm font-medium text-[#1F2937]">Avg. Time to First Contact</CardTitle>
                        <Clock className="h-4 w-4 text-[#222222]" />
                    </CardHeader>
                    <CardContent className="py-2">
                            <div className="text-xl font-bold text-[#1F2937]">
                            {isLoading ? 'Loading...' : `${(leadsData.avgTimeToFirstContact / 3600000).toFixed(1)} hrs`}
                            </div>
                            <p className="text-xs text-[#1F2937] mt-1 flex items-center">
                            {leadsData.showPeriodComparison && selectedPeriod !== 'all' && (
                                <>
                                <span className={`${leadsData.contactTimeGrowth <= 0 ? 'text-emerald-500' : 'text-red-500'} flex items-center mr-1`}>
                                    {leadsData.contactTimeGrowth <= 0
                                      ? (
                                    <ArrowDown className="h-3 w-3 mr-1" />
                                        )
                                      : (
                                    <ArrowUp className="h-3 w-3 mr-1" />
                                        )}
                                    {Math.abs(leadsData.contactTimeGrowth)}%
                                </span>
                                    <span>{leadsData.contactTimeGrowth <= 0 ? 'faster' : 'slower'} than {getPreviousPeriodLabel()}</span>
                                </>
                            )}
                            </p>
                    </CardContent>
                </Card>
            </div>
   {/* Fourth Row - Neighbourhood Map */}
   <Card className="overflow-hidden bg-white">
                <CardHeader className="flex flex-row items-center justify-between py-2">
                    <div>
                        <CardTitle className="text-sm font-medium">Lead Geography by Neighbourhood</CardTitle>
                        <CardDescription className="text-xs">Where are leads located? Hover over dots for details.</CardDescription>
                    </div>
                    <div className="flex items-center">

                        <Button variant="ghost" size="icon" className="h-8 w-8 ml-2">
                            <MapPin className="h-4 w-4" />
                        </Button>
                    </div>
                </CardHeader>
                <CardContent className="p-0 pt-0" id="neighbourhood-map">
                    {!isLoading && leadsData.neighbourhoodData && leadsData.neighbourhoodData.length > 0
                      ? (
                        <NeighbourhoodMap
                            ref={neighbourhoodMapRef}
                            neighbourhoodData={leadsData.neighbourhoodData}
                            apiKey="AIzaSyBHMODCze9G5Stpr33UH557958DH7r5SdY"
                        />
                        )
                      : (
                        <div className="flex justify-center items-center h-64">
                            {isLoading ? 'Loading neighbourhood data...' : 'No neighbourhood data available'}
                        </div>
                        )}
                </CardContent>
            </Card>
            {/* Second Row - Charts */}
            <div className="grid gap-2 md:grid-cols-3">
                <Card className="overflow-hidden bg-white">
                    <CardHeader className="flex flex-row items-center justify-between py-2">
                        <div>
                            <CardTitle className="text-sm">Lead Registration Trend</CardTitle>
                            <CardDescription className="text-xs">
                                {selectedPeriod === 'today'
                                  ? 'New leads today'
                                  : selectedPeriod === 'week' || selectedPeriod === '7d'
                                    ? 'New leads this week'
                                    : selectedPeriod === 'month' || selectedPeriod === '30d'
                                      ? 'New leads this month'
                                      : 'New leads over time'}
                            </CardDescription>
                        </div>
                        <ChartExportMenu
                            data={!isLoading && leadsData.leadsTrend.length > 0 ? leadsData.leadsTrend : []}
                            chartTitle="Lead Registration Trend"
                            standalone={false}
                        />
                    </CardHeader>
                    <CardContent className="p-0 pt-0">
                            <LineChart
                                className="h-48"
                                data={!isLoading && leadsData.leadsTrend.length > 0 ? leadsData.leadsTrend : []}
                                categories={['leads']}
                                index="day"
                                colors={['#3b82f6']}
                                valueFormatter={(value) => `${value} leads`}
                                showLegend={false}
                                isEmpty={isLoading || leadsData.leadsTrend.length === 0}
                                chartTitle="Lead Registration Trend"
                            />
                    </CardContent>
                </Card>
                <Card className="overflow-hidden bg-white">
                    <CardHeader className="flex flex-row items-center justify-between py-2">
                        <div>
                            <CardTitle className="text-sm">Lead Interest by Project</CardTitle>
                            <CardDescription className="text-xs">Which projects leads are interested in</CardDescription>
                        </div>
                        <ChartExportMenu
                            data={!isLoading && leadsData.projectInterest.length > 0 ? leadsData.projectInterest : []}
                            chartTitle="Lead Interest by Project"
                            standalone={false}
                        />
                    </CardHeader>
                    <CardContent className="p-0 pt-0">
                            <PieChart
                                className="h-48"
                            data={!isLoading && leadsData.projectInterest.length > 0 ? leadsData.projectInterest : []}
                                category="value"
                                index="project"
                                colors={['#3b82f6', '#06b6d4', '#4f46e5']}
                                valueFormatter={(value) => `${value}`}
                            isEmpty={isLoading || leadsData.projectInterest.length === 0}
                            chartTitle="Lead Interest by Project"
                            />
                    </CardContent>
                </Card>
                <Card className="overflow-hidden bg-white">
                    <CardHeader className="flex flex-row items-center justify-between py-2">
                        <div>
                            <CardTitle className="text-sm">Lead Registration Sources</CardTitle>
                            <CardDescription className="text-xs">How leads are entering the system</CardDescription>
                        </div>
                        <ChartExportMenu
                            data={!isLoading && leadsData.leadSources.length > 0 ? leadsData.leadSources : []}
                            chartTitle="Lead Registration Sources"
                            standalone={false}
                        />
                    </CardHeader>
                    <CardContent className="p-0 pt-0">
                            <DonutChart
                                className="h-48"
                            data={!isLoading && leadsData.leadSources.length > 0 ? leadsData.leadSources : []}
                                category="value"
                                index="source"
                                colors={['#3b82f6', '#06b6d4', '#4f46e5', '#7c3aed', '#8b5cf6']}
                                valueFormatter={(value) => `${value}%`}
                            isEmpty={isLoading || leadsData.leadSources.length === 0}
                            chartTitle="Lead Registration Sources"
                            />
                    </CardContent>
                </Card>

            </div>

            {/* Third Row */}
            <div className="grid gap-2 md:grid-cols-2">
                <Card className="overflow-hidden bg-white">
                    <CardHeader className="flex flex-row items-center justify-between py-2">
                        <div>
                            <CardTitle className="text-sm">Lead Score Distribution</CardTitle>
                            <CardDescription className="text-xs">Client lead scores</CardDescription>
                        </div>
                        <ChartExportMenu
                            data={!isLoading && leadsData.scoreDistribution.length > 0 ? leadsData.scoreDistribution : []}
                            chartTitle="Lead Score Distribution"
                            standalone={false}
                        />
                    </CardHeader>
                    <CardContent className="p-0 pt-0">
                            <BarChart
                                className="h-48"
                            data={!isLoading && leadsData.scoreDistribution.length > 0 ? leadsData.scoreDistribution : []}
                                index="range"
                                categories={['count']}
                                layout="horizontal"
                                colors={['#3b82f6']}
                                valueFormatter={(value) => `${value}`}
                                showLegend={false}
                                showXAxis={true}
                                showYAxis={true}
                                isEmpty={isLoading || leadsData.scoreDistribution.length === 0}
                                chartTitle="Lead Score Distribution"
                                customTooltip={(props) => {
                                  if (!props.payload || !props.payload.length) return null
                                  const data = props.payload[0].payload
                                  return (
                                        <div className="bg-white p-2 border shadow-sm rounded">
                                            <p className="font-semibold">{data.fullRange}</p>
                                            <p>Count: {data.count}</p>
                                        </div>
                                  )
                                }}
                            />
                    </CardContent>
                </Card>
                <Card className="overflow-hidden bg-white">
                    <CardHeader className="flex flex-row items-center justify-between py-2">
                        <div>
                            <CardTitle className="text-sm">Lead to Prospect Conversion</CardTitle>
                            <CardDescription className="text-xs">Conversion funnel stages</CardDescription>
                        </div>
                        <ChartExportMenu
                            data={!isLoading && leadsData.conversionFunnel.length > 0 ? leadsData.conversionFunnel : []}
                            chartTitle="Lead to Prospect Conversion"
                            standalone={false}
                        />
                    </CardHeader>
                    <CardContent className="p-0 pt-0">
                            <AreaChart
                                className="h-48"
                            data={!isLoading && leadsData.conversionFunnel.length > 0 ? leadsData.conversionFunnel : []}
                                index="stage"
                                categories={['count']}
                                colors={['#3b82f6']}
                                valueFormatter={(value) => `${value}`}
                                showLegend={false}
                            showXAxis={true}
                            showYAxis={true}
                            isEmpty={isLoading || leadsData.conversionFunnel.length === 0}
                            chartTitle="Lead to Prospect Conversion"
                            />
                    </CardContent>
                </Card>
            </div>

            </div>
        </>
  )
}

export default LeadsTab
