/*eslint-disable */
// @ts-nocheck
import React from 'react'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { DayPicker } from 'react-day-picker'

const Calendar = ({ className, classNames, showOutsideDays = true, ...props }) => {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={`p-3 ${className}`}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-sm font-medium',
        nav: 'space-x-1 flex items-center',
        nav_button: 'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 focus:outline-none focus:ring-0',
        nav_button_previous: 'absolute left-1 focus:outline-none focus:ring-0',
        nav_button_next: 'absolute right-1 focus:outline-none focus:ring-0',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell: 'text-zinc-500 rounded-md w-9 font-normal text-[0.8rem]',
        row: 'flex w-full mt-2',
        cell: 'h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-zinc-100/50 [&:has([aria-selected])]:bg-zinc-100 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day: 'h-9 w-9 p-0 font-normal aria-selected:opacity-100 focus:outline-none focus:ring-0',
        day_range_end: 'day-range-end',
        day_selected:
          'bg-zinc-900 text-zinc-50 hover:bg-zinc-900 hover:text-zinc-50 focus:bg-zinc-900 focus:text-zinc-50 focus:outline-none focus:ring-0 rounded-lg',
        day_today: 'bg-zinc-100 text-zinc-900 rounded-lg focus:outline-none focus:ring-0',
        day_outside:
          'day-outside text-zinc-500 opacity-50 aria-selected:bg-zinc-100/50 aria-selected:text-zinc-500 aria-selected:opacity-30 focus:outline-none focus:ring-0',
        day_disabled: 'text-zinc-500 opacity-50 focus:outline-none focus:ring-0',
        day_range_middle: 'aria-selected:bg-zinc-100 aria-selected:text-zinc-900',
        day_hidden: 'invisible',
        ...classNames
      }}
      components={{
        IconLeft: () => <ChevronLeft className='h-4 w-4' />,
        IconRight: () => <ChevronRight className='h-4 w-4' />,
        ...props.components
      }}
      {...props}
    />
  )
}

Calendar.displayName = 'Calendar'

export { Calendar }
