/* eslint multiline-ternary: ["error", "never"] */
// @ts-nocheck
/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Building, DollarSign, Percent, Home, MoreHorizontal, Users, ArrowUp, ArrowDown, Phone, Mail, Clock, Calendar, Star, ChevronDown } from 'lucide-react'
import KPICard from './components/KPICard'
import { LineChart, DonutChart, BarChart, PieChart, AreaChart, MockDataOverlay } from './components/charts'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ShadcnComponents/card'
import { Button } from 'components/ShadcnComponents/button'
import LeadsTab from './components/LeadsTab'
import GoogleAdsTab from './components/GoogleAdsTab'
import ProjectsTab from './components/ProjectsTab'
import OutreachTab from './components/OutreachTab'
import WebsiteTrafficTab from './components/WebsiteTrafficTab'
import SalesDashboardTab from './components/SalesDashboardTab'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"

// Project selector component
const ProjectSelector = ({ selectedProject, onChange }) => {
    // Static projects instead of fetching from API
    const projects = [
        { _id: 'Ashleigh', projectName: 'Ashleigh' },
        { _id: 'Frame', projectName: 'Frame' }
    ];

    return (
        <div className="relative">
            <div className="inline-flex items-center">
                <label htmlFor="project-select" className="mr-2 text-sm font-medium text-gray-700">
                    Project:
                </label>
                <select
                    id="project-select"
                    value={selectedProject}
                    onChange={(e) => onChange(e.target.value)}
                    className="block px-4 py-2 min-w-[180px] border bg-beige-400 border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                    <option value="">All Projects</option>
                    {projects.map(project => (
                        <option key={project._id} value={project._id}>
                            {project.projectName}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

// Period selector component
const TimePeriodSelector = ({ selectedPeriod, onChange, customDateRange, onCustomDateChange }) => {
    const [showDatePicker, setShowDatePicker] = useState(false);
    const datePickerRef = useRef(null);

    // Handle click outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
                setShowDatePicker(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Format date for display in the button
    const formatDateRange = (range) => {
        const formatDate = (date) => {
            const [year, month] = date.split('-');
            const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            return `${monthNames[parseInt(month) - 1]} ${year}`;
        };
        return `${formatDate(range.startDate)} - ${formatDate(range.endDate)}`;
    };

    // Handle date change in the custom range
    const handleDateChange = (type, event) => {
        const [year, month] = event.target.value.split('-');
        // Set the date to the first day of the month for start date
        // or the last day of the month for end date
        const date = new Date(year, month - 1);
        if (type === 'endDate') {
            date.setMonth(date.getMonth() + 1);
            date.setDate(0); // This will set to the last day of the selected month
        } else {
            date.setDate(1); // This will set to the first day of the selected month
        }

        const formattedDate = date.toISOString().split('T')[0];
        const updatedRange = {
            ...customDateRange,
            [type]: formattedDate
        };
        onCustomDateChange(updatedRange);
    };

    // Toggle the date picker
    const toggleDatePicker = (event) => {
        event.stopPropagation();
        setShowDatePicker(!showDatePicker);
    };

    // Apply the custom date range
    const applyCustomRange = (event) => {
        event.stopPropagation();
        onChange('custom');
        setShowDatePicker(false);
    };

    // Generate month options
    const getMonthOptions = () => {
        const months = [];
        for (let i = 1; i <= 12; i++) {
            const month = i.toString().padStart(2, '0');
            const monthName = new Date(2000, i - 1).toLocaleString('default', { month: 'short' });
            months.push({ value: month, label: monthName });
        }
        return months;
    };

    // Generate year options (last 5 years)
    const getYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = currentYear; i >= currentYear - 5; i--) {
            years.push(i.toString());
        }
        return years;
    };

    return (
        <div className="relative flex flex-row gap-2">
                <button
                    type="button"
                    className={`px-3 py-1 text-sm font-medium bg-beige border rounded-md border-l border-gray-200 ${selectedPeriod === 'today' ? 'bg-blue-50 text-blue-600' : 'text-gray-700'}`}
                    onClick={() => onChange('today')}
                >
                    Today
                </button>
                <button
                    type="button"
                    className={`px-3 py-1 text-sm font-medium bg-beige border rounded-md border-l border-gray-200 ${selectedPeriod === '7d' ? 'bg-blue-50 text-blue-600' : 'text-gray-700'}`}
                    onClick={() => onChange('7d')}
                >
                    Week
                </button>
                <button
                    type="button"
                    className={`px-3 py-1 text-sm font-medium bg-beige border rounded-md border-l border-gray-200 ${selectedPeriod === '30d' ? 'bg-blue-50 text-blue-600' : 'text-gray-700'}`}
                    onClick={() => onChange('30d')}
                >
                    Month
                </button>
                <button
                    type="button"
                    className={`px-3 py-1 text-sm font-medium bg-beige border rounded-md border-l border-gray-200 ${selectedPeriod === 'ytd' ? 'bg-blue-50 text-blue-600' : 'text-gray-700'}`}
                    onClick={() => onChange('ytd')}
                >
                    YTD
                </button>
                <button
                    type="button"
                    className={`px-3 py-1 text-sm font-medium bg-beige border rounded-md border-l border-gray-200 ${selectedPeriod === 'all' ? 'bg-blue-50 text-blue-600' : 'text-gray-700'}`}
                    onClick={() => onChange('all')}
                >
                    All Time
                </button>
                <button
                    type="button"
                    className={`px-3 py-1 text-sm font-medium bg-beige border rounded-md border-l border-gray-200 flex items-center ${selectedPeriod === 'custom' ? 'bg-blue-50 text-blue-600' : 'text-gray-700'}`}
                    onClick={toggleDatePicker}
                >
                    {selectedPeriod === 'custom'
                        ? formatDateRange(customDateRange)
                        : 'Custom Range'}
                    <ChevronDown className="ml-1 h-4 w-4" />
                </button>

            {/* Custom Date Range Picker */}
            {showDatePicker && (
                <div
                    ref={datePickerRef}
                    className="absolute right-0 mt-2 p-4 bg-white rounded-md shadow-lg border border-gray-200 z-100 w-[500px]"
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className="flex gap-4">
                        <div className="flex-1">
                            <label className="block text-sm font-medium text-gray-700 mb-1">Start Month</label>
                            <div className="flex gap-2">
                                <select
                                    className="flex-1 px-3 py-2 border border-gray-300 rounded-md"
                                    value={customDateRange.startDate.split('-')[1]}
                                    onChange={(e) => {
                                        const [year] = customDateRange.startDate.split('-');
                                        handleDateChange('startDate', { target: { value: `${year}-${e.target.value}` } });
                                    }}
                                >
                                    {getMonthOptions().map(month => (
                                        <option key={month.value} value={month.value}>
                                            {month.label}
                                        </option>
                                    ))}
                                </select>
                                <select
                                    className="flex-1 px-3 py-2 border border-gray-300 rounded-md"
                                    value={customDateRange.startDate.split('-')[0]}
                                    onChange={(e) => {
                                        const [, month] = customDateRange.startDate.split('-');
                                        handleDateChange('startDate', { target: { value: `${e.target.value}-${month}` } });
                                    }}
                                >
                                    {getYearOptions().map(year => (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="flex-1">
                            <label className="block text-sm font-medium text-gray-700 mb-1">End Month</label>
                            <div className="flex gap-2">
                                <select
                                    className="flex-1 px-3 py-2 border border-gray-300 rounded-md"
                                    value={customDateRange.endDate.split('-')[1]}
                                    onChange={(e) => {
                                        const [year] = customDateRange.endDate.split('-');
                                        handleDateChange('endDate', { target: { value: `${year}-${e.target.value}` } });
                                    }}
                                >
                                    {getMonthOptions().map(month => (
                                        <option key={month.value} value={month.value}>
                                            {month.label}
                                        </option>
                                    ))}
                                </select>
                                <select
                                    className="flex-1 px-3 py-2 border border-gray-300 rounded-md"
                                    value={customDateRange.endDate.split('-')[0]}
                                    onChange={(e) => {
                                        const [, month] = customDateRange.endDate.split('-');
                                        handleDateChange('endDate', { target: { value: `${e.target.value}-${month}` } });
                                    }}
                                >
                                    {getYearOptions().map(year => (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end mt-4">
                        <button
                            type="button"
                            className="px-4 py-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-md mr-2"
                            onClick={toggleDatePicker}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md"
                            onClick={applyCustomRange}
                        >
                            Apply
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

// Custom Tab Button
const TabButton = ({ active, onClick, children }) => {
    return (
        <button
            className={`px-4 py-1 text-sm font-medium text-gray-500 w-full ${
                active 
                ? 'shadow-sm border-gray-500 bg-beige-500 text-gray-900 rounded-md' 
                : 'bg-gray-100 hover:bg-gray-100'
            }`}
            onClick={onClick}
        >
            {children}
        </button>
    )
}

// Get tab title based on active tab
const getTabTitle = (activeTab) => {
    switch (activeTab) {
        case 'leads':
            return 'Lead Registration Dashboard';
        case 'googleads':
            return 'Google Ads Dashboard';
        case 'websitetraffic':
            return 'Website Traffic Dashboard';
        case 'sales':
            return 'Sales Dashboard';
        case 'projects':
        default:
            return 'Project Overview Dashboard';
    }
}

const DashboardV2 = ({ userObject }) => {
    const [activeTab, setActiveTab] = useState('sales')
    const [selectedPeriod, setSelectedPeriod] = useState('all')
    const [selectedProject, setSelectedProject] = useState('');
    const [customDateRange, setCustomDateRange] = useState({
        startDate: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0], // Default to last 30 days
        endDate: new Date().toISOString().split('T')[0] // Today
    });

    // Function to handle period changes
    const handlePeriodChange = (period) => {
        setSelectedPeriod(period)
    }

    // Function to handle project changes
    const handleProjectChange = (projectId) => {
        setSelectedProject(projectId)
    }

    // Function to handle custom date range changes
    const handleCustomDateChange = (range) => {
        setCustomDateRange(range)
    }

    // Function to render the active tab content
    const renderTabContent = () => {
        // Get date params for custom range if selected
        const dateParams = selectedPeriod === 'custom'
            ? { customStartDate: customDateRange.startDate, customEndDate: customDateRange.endDate }
            : {};

        switch (activeTab) {
            case 'leads':
                return <LeadsTab selectedPeriod={selectedPeriod} userObject={userObject} selectedProject={selectedProject} {...dateParams} />;
            case 'googleads':
                return <GoogleAdsTab selectedPeriod={selectedPeriod} userObject={userObject} {...dateParams} />;
            case 'websitetraffic':
                return <WebsiteTrafficTab userObject={userObject} />;
            default:
            case 'sales':
                return <SalesDashboardTab selectedPeriod={selectedPeriod} userObject={userObject} selectedProject={selectedProject} {...dateParams} />;
            case 'projects':

                return <ProjectsTab selectedPeriod={selectedPeriod} userObject={userObject} {...dateParams} />;
        }
    }

    return (
        <div className='flex flex-col gap-4 p-6 min-h-[calc(100vh-64px)]'>
            {/* Custom Tab Navigation */}
            <div className="flex w-full bg-gray-100">
                <TabButton
                    active={activeTab === 'leads'}
                    onClick={() => setActiveTab('leads')}
                >
                    Lead Registration
                </TabButton>
                <TabButton
                    active={activeTab === 'googleads'}
                    onClick={() => setActiveTab('googleads')}
                >
                    Google Ads
                </TabButton>
                <TabButton
                    active={activeTab === 'websitetraffic'}
                    onClick={() => setActiveTab('websitetraffic')}
                >
                    Website Traffic
                </TabButton>
                <TabButton
                    active={activeTab === 'sales'}
                    onClick={() => setActiveTab('sales')}
                >
                    Sales
                </TabButton>
            </div>

            {/* Dashboard Header */}
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-2 mb-1">
                <h1 className="text-2xl font-bold">{getTabTitle(activeTab)}</h1>
                <div className="flex flex-col md:flex-row items-start md:items-center gap-2">
                    {(activeTab === 'sales' || activeTab === 'leads') && (
                        <ProjectSelector
                            selectedProject={selectedProject}
                            onChange={handleProjectChange}
                        />
                    )}
                    {activeTab !== 'websitetraffic' && (
                        <TimePeriodSelector
                            selectedPeriod={selectedPeriod}
                            onChange={handlePeriodChange}
                            customDateRange={customDateRange}
                            onCustomDateChange={handleCustomDateChange}
                        />
                    )}
                </div>
            </div>

            {/* Dashboard Content */}
            <div className="flex-1">
                {renderTabContent()}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardV2)
