import { message } from 'antd'
import { api, ApiErrors } from 'services/api'

export const getContractTemplates = (id = '') => new Promise((resolve, reject) => {
  api.get(`/contracttemplates/${id}`)
    .then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const getProjectContractTemplates = (id = '') => new Promise((resolve, reject) => {
  api.get(`/contracttemplates/projects/${id}`)
    .then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const createOrUpdateContractTemplate = (props) => new Promise((resolve, reject) => {
  const { _id, ...params } = props

  // If id, so we have to update the record, or we're gonna create
  const verb = _id ? 'put' : 'post'
  const url = _id ? `contracttemplates/${_id}` : 'contracttemplates'

  api[verb](url, params)
    .then((response) => {
      if (response.ok) {
        message.success(`Contract was ${_id ? 'successfully updated' : 'created successfully'}`)
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const deleteContractTemplate = (id) => new Promise((resolve, reject) => {
  api.delete(`/contracttemplates/${id}`)
    .then((response) => {
      if (response.ok) {
        message.success('Contract deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const getDocuSealContractTemplateByProjectId = (_id = '', contractType = '') => new Promise((resolve, reject) => {
  const params = contractType?.length > 0 ? `?contractType=${contractType}` : ''

  api.get(`/docuseal-templates/project/${_id}${params}`)
    .then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const getDocuSealContractTemplateById = (id = '') => new Promise((resolve, reject) => {
  api.get(`/docuseal-templates/${id}`)
    .then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const createOrUpdateDocuSealContractTemplate = (props) => new Promise((resolve, reject) => {
  const { id, ...params } = props

  // If id, so we have to update the record, or we're gonna create
  const verb = id ? 'put' : 'post'
  const url = id ? `docuseal-templates/${id}` : 'docuseal-templates'

  api[verb](url, params)
    .then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const signDocuSealContractTemplate = (props) => new Promise((resolve, reject) => {
  const { templateId, ...params } = props
  api.post(`/docuseal-templates/sign/${templateId}`, params)
    .then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const getMergeFields = (projectId, contractType, extraParamsString = '') => new Promise((resolve, reject) => {
  api.get(`/contract-templates/merge-field/${projectId}/${contractType}${extraParamsString}`)
    .then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const getAllContractsByEmail = (email) =>
  new Promise((resolve, reject) => {
    api.get(`contracts/all/email?email=${email}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
