// @ts-nocheck
/* eslint-disable */

import React, { useContext, useEffect, useMemo, useState } from 'react'
import LargeUnitCard from 'components/LargeUnitCard'
import SelectUnit from './SelectUnit'
import { CreateOfferContext } from '../context/CreateOfferContext'
import useGetProjectDepositOptions from 'hooks/useGetProjectDepositOptions'
import BottomActions from './BottomActions'
import { useSelector } from 'react-redux'
import { getProjects } from 'store/actions/projectActions'

const Step1 = (props) => {
  const { setStep } = props

  const { selectedUnit, setSelectedUnit, setDepositOptions } = useContext(CreateOfferContext)
  const [unitVariable, setUnitVariable] = useState('Unit')

  const onSelect = (unit) => {
    if (unit.status !== 'SoldCommitted') {
      setSelectedUnit(unit)
    }
  }

  const onBack = () => {
    setSelectedUnit({})
  }

  const isSelected = Object.values(selectedUnit).length > 0

  // Select deposit option functionality
  const { depositOptions } = useGetProjectDepositOptions()
  const [selectedDepositOption, setSelectedDepositOption] = useState(null)

  useEffect(() => {
    if (depositOptions?.length > 0) {
      setSelectedDepositOption(depositOptions[0]._id)
    }
  }, [depositOptions])

  const selectedDepositOptionData = useMemo(() => {
    const result = depositOptions?.filter(
      (item) => item._id === selectedDepositOption
    )?.[0]
    return result
  }, [depositOptions, selectedDepositOption])

  const discountPrice = useMemo(() => {
    return (
      selectedUnit?.price *
      (selectedDepositOptionData?.discountPercentage / 100)
    )
  }, [selectedUnit, selectedDepositOptionData])

  const totalPrice = () => {
    return selectedUnit?.price - discountPrice || 0
  }

  const discountedPrice = useMemo(() => {
    if (!selectedDepositOptionData) {
      return null
    }
    return {
      discountPercentage: discountPrice,
      optionPercentages: selectedDepositOptionData?.optionPercentages.map(
        (item) => {
          return totalPrice() * (item / 100)
        }
      )
    }
  }, [discountPrice, selectedDepositOptionData])

  useEffect(() => {
    if (discountedPrice) {
      const newDepositOption = {
        optionName: selectedDepositOptionData?.depositOptionName,
        discount: {
          percentage: selectedDepositOptionData?.discountPercentage,
          value: discountPrice
        },
        installments: [
          ...selectedDepositOptionData?.optionPercentages.map((item, index) => {
            return {
              name: `Deposit ${index + 1}`,
              percentage: item,
              value: discountedPrice?.optionPercentages?.[index]
            }
          })
        ]
      }
      setDepositOptions({
        ...newDepositOption,
        id: selectedDepositOptionData._id
      })
    }
  }, [discountedPrice])

  const appProject = useSelector((state) => state.appReducer.appProject)

  useEffect(() => {
    getProjects(appProject).then((project) => {
      const { unitVariable } = project;
      if (unitVariable) {
        setUnitVariable(unitVariable || 'Unit');
      }
    });
  }, [appProject]);

  return (
    <div className='h-full flex flex-col gap-6'>
      <div className='pb-4 border-b-2 border-softBlack mb-4'>
        <p className='my-0 text-xl font-bold'>
          {isSelected ? `${unitVariable} Details` : `Select ${unitVariable}`}
        </p>
      </div>
      {!isSelected && <SelectUnit onSelect={onSelect} />}
      {isSelected && (
        <>
          <LargeUnitCard
            imgsrc={selectedUnit?.floorPlan?.image}
            unitName={`#${selectedUnit?.unitNumber}`}
            noOfBedrooms={selectedUnit?.numberOfBedrooms}
            noOfBathrooms={selectedUnit?.numberOfBathrooms}
            balcony={selectedUnit?.balcony}
            noOfParkingSlot={selectedUnit?.floorPlan?.parkingQty}
            building={selectedUnit?.building?.name}
            price={selectedUnit?.price}
          />
          <BottomActions
            onBack={onBack}
            onNext={() => setStep((prev) => prev + 1)}
            hasYPadding
          />
        </>
      )
      }{' '}
    </div>
  )
}

export default Step1
