/* eslint multiline-ternary: ["error", "never"] */
// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react'

import { Card, CardContent, CardHeader, CardTitle, CardDescription } from 'components/ShadcnComponents/card'
import { LineChart, BarChart, PieChart, ChartExportMenu } from './charts'
import { ArrowUp, ArrowDown, MoreHorizontal } from 'lucide-react'
import { Button } from 'components/ShadcnComponents/button'
import PurchaserMap from './PurchaserMap'

const SalesDashboardTab = ({ selectedPeriod, userObject, customStartDate, customEndDate, selectedProject }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [salesData, setSalesData] = useState({
    totalSales: 0,
    salesGrowth: 0,
    activeDeals: 0,
    conversionRate: 0,
    avgDealSize: 0,
    rescissionRate: '0.0',
    unitStatus: [],
    bedroomDistribution: [],
    salesFunnel: {
      registrations: 0,
      prospects: 0,
      visits: 0,
      contracts: 0,
      firm: 0,
      rescinded: 0
    },
    monthlySales: [],
    topRealtors: [],
    purchaserGeography: [],
    purchaserGeographyMeta: null,
    salesCosts: {
      avgIncentive: 0,
      avgRealtorFee: 0,
      avgBonus: 0,
      totalCosts: 0,
      costPercentage: 0,
      incentivePercent: 0,
      realtorFeePercent: 0,
      bonusPercent: 0
    },
    inventorySummary: {
      soldFirm: 0,
      available: 0,
      percentSold: 0,
      revenueSold: 0,
      revenueAvail: 0,
      costPerHome: 0,
      totalSqft: 0,
      soldSqft: 0,
      netPpsfSold: 0,
      netPpsfAvail: 0,
      blendedPpsf: 0
    },
    unitsSold: 0
  })

  const purchaserMapRef = useRef(null)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        // Helper function to fetch and parse JSON from API
        const fetchAPI = async (url, params) => {
          const baseUrl = process.env.REACT_APP_BE_URL || ''

          // Add custom date parameters if selectedPeriod is 'custom'
          if (selectedPeriod === 'custom' && customStartDate && customEndDate) {
            params.customStartDate = customStartDate
            params.customEndDate = customEndDate
          }

          // Add project filter if selected
          if (selectedProject) {
            params.projectId = selectedProject
          }

          const queryParams = new URLSearchParams(params).toString()
          const token = localStorage.getItem('pxp.accounts.accessToken')

          const headers = {
            'Content-Type': 'application/json'
          }

          if (token) {
            headers.Authorization = `Bearer ${token}`
          }

          const response = await fetch(`${baseUrl}${url}?${queryParams}`, {
            method: 'GET',
            headers
          })

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`)
          }
          return response.json()
        }

        // Fetch data from multiple endpoints in parallel
        const [
          kpisResponse,
          inventoryResponse,
          bedroomsResponse,
          funnelResponse,
          transactionsResponse,
          realtorsResponse,
          geographyResponse,
          costsResponse
        ] = await Promise.all([
          fetchAPI('/sales/dashboard/kpis', { period: selectedPeriod }),
          fetchAPI('/sales/inventory/by-status', { period: selectedPeriod }),
          fetchAPI('/sales/bedroom-distribution', { period: selectedPeriod }),
          fetchAPI('/sales/funnel', { period: selectedPeriod }),
          fetchAPI('/sales/transactions/by-time', { period: selectedPeriod, interval: 'month' }),
          fetchAPI('/sales/top-realtors', { period: selectedPeriod, limit: 5 }),
          fetchAPI('/sales/purchaser-geography', {
            period: selectedPeriod,
            // Explicitly include custom dates even if they're added by fetchAPI
            // This ensures they're always sent for the purchaser geography endpoint
            ...(selectedPeriod === 'custom'
              ? {
                  customStartDate,
                  customEndDate
                }
              : {})
          }),
          fetchAPI('/sales/costs-breakdown', { period: selectedPeriod })
        ])
        console.log(geographyResponse)
        if (inventoryResponse?.details && inventoryResponse.details.length > 0) {
          console.log('First unit status entry:', inventoryResponse.details[0])
          // Look for Avesdos specific fields
          const unitFields = Object.keys(inventoryResponse.details[0])
          console.log('Available fields in unit status:', unitFields)
        }

        // Variables for unit status processing
        let unitStatus = []
        let soldCount = 0
        let availableCount = 0
        let soldPercentage = 0
        let revenueAvail = 0

        // Use inventory details directly from the API
        if (Array.isArray(inventoryResponse.details) && inventoryResponse.details.length > 0) {
          // Format for the PieChart component
          unitStatus = inventoryResponse.details.map(item => ({
            name: item.status,
            value: item.units,
            netPrice: item.netPrice
          }))

          // Calculate sold and available counts
          soldCount = unitStatus.find(item =>
            item.name === 'Sold Firm' || item.name === 'SoldFirm')?.value || 0

          availableCount = unitStatus.find(item =>
            item.name === 'Available' || item.name === 'Open')?.value || 0

          // Calculate available revenue from available units
          const availableUnit = unitStatus.find(item =>
            item.name === 'Available' || item.name === 'Open')

          console.log('Available units data:', availableUnit)
          console.log('All unit status data:', unitStatus)

          // Use the netPrice directly from the Available unit in the inventory response
          if (availableUnit && availableUnit.netPrice) {
            revenueAvail = availableUnit.netPrice
            console.log('Using available units netPrice for revenueAvail:', revenueAvail)
          } else {
            // Fallback to KPI value from backend
            revenueAvail = kpisResponse.revenueAvailable || 0
            console.log('Using fallback revenueAvail:', revenueAvail)
          }

          // Calculate percentage
          const totalUnits = unitStatus.reduce((sum, item) => sum + item.value, 0)
          soldPercentage = totalUnits > 0 ? Math.round((soldCount / totalUnits) * 100) : 0
        }

        // Transform transactions data to correct format for monthly sales chart
        const monthlySales = transactionsResponse.map(item => {
          // Format month name
          const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
          const monthIndex = item._id.month ? item._id.month - 1 : 0
          const month = monthNames[monthIndex]

          return {
            month,
            count: item.count,
            totalValue: item.totalValue
          }
        })

        // Calculate rescission rate
        const rescissionRate = (funnelResponse.rescinded / (funnelResponse.firm + funnelResponse.rescinded)) * 100

        // Adapt the sales costs response to match expected format
        const adaptedCostsResponse = {
          // Extract realtor commission from costs array or default to 0
          avgRealtorFee: costsResponse.costs?.find(item => item.name === 'Realtor Commission')?.value || 0,
          // Marketing fees becomes incentives in our UI
          avgIncentive: costsResponse.costs?.find(item => item.name === 'Marketing Fees')?.value || 0,
          // Other fees becomes bonuses in our UI
          avgBonus: costsResponse.costs?.find(item => item.name === 'Other Fees')?.value || 0,
          // Get total costs from the new totalCosts field
          totalCosts: costsResponse.totalCosts?.total || 0,
          // Calculate cost percentage relative to total sales
          costPercentage: costsResponse.totalSales > 0
            ? ((costsResponse.totalCosts?.total || 0) / costsResponse.totalSales * 100)
            : 0
        }

        // Calculate breakdown percentages
        if (adaptedCostsResponse.totalCosts > 0) {
          // For the percentage breakdown chart, use the total costs and calculate percentage of each type
          const totalRealtorFee = (costsResponse.costs?.find(item => item.name === 'Realtor Commission')?.value || 0) * (costsResponse.totalCosts?.count || 1)
          const totalIncentive = (costsResponse.costs?.find(item => item.name === 'Marketing Fees')?.value || 0) * (costsResponse.totalCosts?.count || 1)
          const totalBonus = (costsResponse.costs?.find(item => item.name === 'Other Fees')?.value || 0) * (costsResponse.totalCosts?.count || 1)

          adaptedCostsResponse.realtorFeePercent = (totalRealtorFee / adaptedCostsResponse.totalCosts) * 100
          adaptedCostsResponse.incentivePercent = (totalIncentive / adaptedCostsResponse.totalCosts) * 100
          adaptedCostsResponse.bonusPercent = (totalBonus / adaptedCostsResponse.totalCosts) * 100
        } else {
          adaptedCostsResponse.realtorFeePercent = 0
          adaptedCostsResponse.incentivePercent = 0
          adaptedCostsResponse.bonusPercent = 0
        }

        // Combine all data
        setSalesData({
          ...kpisResponse,
          unitStatus,
          bedroomDistribution: bedroomsResponse,
          salesFunnel: funnelResponse,
          rescissionRate: rescissionRate.toFixed(1),
          monthlySales,
          topRealtors: realtorsResponse,
          purchaserGeography: (geographyResponse.data.data || geographyResponse).map(item => ({
            ...item,
            // Ensure city name is properly formatted (remove extra whitespace, etc)
            city: item.city ? item.city.trim() : 'Unknown'
          })),
          purchaserGeographyMeta: geographyResponse.meta || null,
          salesCosts: adaptedCostsResponse,
          inventorySummary: {
            // Map KPI response values to inventory summary properties or use our calculated values
            soldFirm: soldCount || 0,
            available: availableCount || 0,
            percentSold: soldPercentage || 0,
            revenueSold: kpisResponse.totalRevenue || 0,
            revenueAvail,
            costPerHome: kpisResponse.costPerHome || 0,
            totalSqft: kpisResponse.totalSquareFootage || 0,
            soldSqft: kpisResponse.soldSquareFootage || 0,
            availableSqft: kpisResponse.availableSquareFootage || 0,
            netPpsfSold: kpisResponse.netPPSFSold || 0,
            netPpsfAvail: kpisResponse.netPPSFAvailable || 0,
            blendedPpsf: kpisResponse.blendedPPSF || 0
          }
        })
      } catch (error) {
        console.error('Error fetching dashboard data:', error)
        // Handle error state here
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [selectedPeriod, userObject, customStartDate, customEndDate, selectedProject])

  // Loading state
  if (isLoading) {
    return <div className="flex justify-center items-center h-64">Loading sales dashboard...</div>
  }

  const renderNoDataMessage = () => (
        <div className="p-4 bg-gray-50 rounded-lg text-center">
            <p className="text-gray-500">No data available for the selected time period.</p>
            <p className="text-sm text-gray-400 mt-1">Try selecting a different time period or check back later.</p>
        </div>
  )

  // Format date for display
  const formatDate = (dateString) => {
    if (!dateString) return ''

    // Create date object and adjust for timezone issues by setting time to noon
    const date = new Date(dateString)
    date.setHours(12, 0, 0, 0)

    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    })
  }

  // Get period label for display
  const getPeriodLabel = () => {
    switch (selectedPeriod) {
      case 'today':
        return 'Today'
      case '7d':
        return 'Last 7 Days'
      case '30d':
        return 'Last 30 Days'
      case 'ytd':
        return 'Year to Date'
      case 'all':
        return 'All Time'
      case 'custom':
        return `${formatDate(customStartDate)} - ${formatDate(customEndDate)}`
      default:
        return ''
    }
  }

  // Get previous period label based on current period
  const getPreviousPeriodLabel = (periodType) => {
    switch (periodType) {
      case 'today':
        return 'yesterday'
      case '7d':
        return 'previous 7 days'
      case '30d':
        return 'previous month'
      case 'ytd':
        return 'last year'
      case 'custom':
        return 'prior period'
      default:
        return 'prior period'
    }
  }

  // Format currency for display
  const formatCurrency = (value, unit = '', decimals = 0) => {
    if (value === null || value === undefined) return '$0'

    if (value >= 1000000) {
      return `$${(value / 1000000).toFixed(2)}M`
    } else if (value >= 1000) {
      return `$${(value / 1000).toFixed(decimals)}K`
    } else {
      return `$${value.toLocaleString(undefined, { maximumFractionDigits: decimals })}${unit}`
    }
  }

  console.log('salesData', salesData)

  return (
    <div className="flex flex-col gap-2">
        {/* Period indicator */}
        {selectedPeriod === 'custom' && (
            <div className="bg-blue-50 p-2 rounded-lg mb-1">
                <p className="text-sm text-blue-600">
                    <span className="font-medium">Custom Period:</span> {formatDate(customStartDate)} - {formatDate(customEndDate)}
                </p>
            </div>
        )}

        {/* Top Row - Key Metrics */}
        <div className="grid gap-2 md:grid-cols-4">
            <Card className="overflow-hidden bg-white">
                <CardHeader className="flex flex-row items-center justify-between py-2">
                    <CardTitle className="text-sm font-medium text-[#1F2937]">Units Sold</CardTitle>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-[#222222]">
                        <path d="M3 9h18v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9Z" />
                        <path d="M3 9V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v4" />
                        <path d="M12 12h.01" />
                    </svg>
                </CardHeader>
                <CardContent className="py-2">
                    <div className="text-xl font-bold text-[#1F2937]">{salesData.unitsSold || 0}</div>
                    <p className="text-xs text-[#1F2937] mt-1 flex items-center">
                        {salesData.showPeriodComparison
                          ? (
                            <>
                                <span className={`${salesData.soldUnitsGrowth >= 0 ? 'text-emerald-500' : 'text-red-500'} flex items-center mr-1`}>
                                    {salesData.soldUnitsGrowth >= 0
                                      ? (
                                        <ArrowUp className="h-3 w-3 mr-1" />
                                        )
                                      : (
                                        <ArrowDown className="h-3 w-3 mr-1" />
                                        )}
                                    {Math.abs(salesData.soldUnitsGrowth).toFixed(1)}%
                                </span>
                                <span>
                                    {(salesData.soldUnitsGrowth >= 0 ? 'increase' : 'decrease') + ' from ' + (salesData.periodInfo ? getPreviousPeriodLabel(salesData.periodInfo.periodType) : 'prior period')}
                                </span>
                            </>
                            )
                          : (
                            <></>
                            )}
                    </p>
                </CardContent>
            </Card>
            <Card className="overflow-hidden bg-white">
                <CardHeader className="flex flex-row items-center justify-between py-2">
                    <CardTitle className="text-sm font-medium text-[#1F2937]">Available Units</CardTitle>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-[#222222]">
                        <path d="M12 2v20" />
                        <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a2.5 2.5 0 0 1 0 5H6" />
                        <path d="M12 18V6" />
                    </svg>
                </CardHeader>
                <CardContent className="py-2">
                    <div className="text-xl font-bold text-[#1F2937]">{salesData.inventorySummary.available || 0}</div>
                    <p className="text-xs text-[#1F2937] mt-1 flex items-center">
                        {salesData.showPeriodComparison
                          ? (
                            <>
                                <span className="text-red-500 flex items-center mr-1">
                                    <ArrowDown className="h-3 w-3 mr-1" />
                                    {salesData.availableUnitsGrowth
                                      ? Math.abs(salesData.availableUnitsGrowth).toFixed(1)
                                      : (100 - (salesData.inventorySummary.percentSold || 0)).toFixed(1)}%
                                </span>
                                <span>
                                    {salesData.availableUnitsGrowth
                                      ? (salesData.availableUnitsGrowth > 0 ? 'increase' : 'decrease') + ' from prior period'
                                      : 'of total inventory'}
                                </span>
                            </>
                            )
                          : (
                            <></>
                            )}
                    </p>
                </CardContent>
            </Card>
            <Card className="overflow-hidden bg-white">
                <CardHeader className="flex flex-row items-center justify-between py-2">
                    <CardTitle className="text-sm font-medium text-[#1F2937]">Revenue Sold</CardTitle>
                    <ArrowUp className="h-4 w-4 text-[#222222]" />
                </CardHeader>
                <CardContent className="py-2">
                    <div className="text-xl font-bold text-[#1F2937]">{formatCurrency(salesData.inventorySummary.revenueSold)}</div>
                    <p className="text-xs text-[#1F2937] mt-1 flex items-center">
                        {salesData.showPeriodComparison
                          ? (
                            <span className="text-emerald-500 flex items-center mr-1">
                                <ArrowUp className="h-3 w-3 mr-1" />
                                {salesData.salesGrowth ? salesData.salesGrowth.toFixed(1) : '0.0'}%
                                <span className="ml-1">growth rate</span>
                            </span>
                            )
                          : (
                            <></>
                            )}
                    </p>
                </CardContent>
            </Card>
            <Card className="overflow-hidden bg-white">
                <CardHeader className="flex flex-row items-center justify-between py-2">
                    <CardTitle className="text-sm font-medium text-[#1F2937]">Revenue Available</CardTitle>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-[#222222]">
                        <circle cx="12" cy="12" r="10" />
                        <path d="M16 8h-6.5a2.5 2.5 0 0 0 0 5h3a2.5 2.5 0 0 1 0 5H6" />
                        <path d="M12 18V6" />
                    </svg>
                </CardHeader>
                <CardContent className="py-2">
                    <div className="text-xl font-bold text-[#1F2937]">{formatCurrency(salesData.inventorySummary.revenueAvail)}</div>
                    <p className="text-xs text-[#1F2937] mt-1 flex items-center">
                        {salesData.showPeriodComparison && salesData.availableRevenueGrowth
                          ? (
                            <>
                                <span className={`${salesData.availableRevenueGrowth < 0 ? 'text-emerald-500' : 'text-red-500'} flex items-center mr-1`}>
                                    {salesData.availableRevenueGrowth < 0
                                      ? (
                                        <ArrowDown className="h-3 w-3 mr-1" />
                                        )
                                      : (
                                        <ArrowUp className="h-3 w-3 mr-1" />
                                        )}
                                    {Math.abs(salesData.availableRevenueGrowth).toFixed(1)}%
                                </span>
                                <span>
                                    {(salesData.availableRevenueGrowth > 0 ? 'increase' : 'decrease') + ' from prior period'}
                                </span>
                            </>
                            )
                          : (
                            <></>
                            )}
                    </p>
                </CardContent>
            </Card>
        </div>

        {/* Second Row - Monthly Sales Over Time */}
             {/* Seventh Row - Purchaser Geography */}
        <div className="w-full grid gap-2 md:grid-cols-2">
            <div className="bg-white rounded-lg border shadow-sm">
                <div className="flex flex-row items-center justify-between p-3">
                    <div>
                        <h3 className="text-2xl font-semibold leading-none tracking-tight">Purchaser Geography</h3>
                        <p className="text-sm text-muted-foreground">Location of property purchasers</p>
                    </div>
                    <div className="flex items-center">

                    </div>
                </div>
                <div className="p-3 pt-0" id="purchaser-map">
                    {salesData.purchaserGeography && salesData.purchaserGeography.length > 0
                      ? (
                        <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
                          <div>
                            <PurchaserMap
                              ref={purchaserMapRef}
                              purchaserData={salesData.purchaserGeography}
                              apiKey="AIzaSyBHMODCze9G5Stpr33UH557958DH7r5SdY"
                            />
                          </div>

                        </div>
                        )
                      : renderNoDataMessage()}
                </div>
            </div>

            <div className="bg-white rounded-lg border shadow-sm">
                <div className="flex flex-row items-center justify-between p-6">
                    <div>
                        <h3 className="text-2xl font-semibold leading-none tracking-tight">Monthly Sales Value</h3>
                        <p className="text-sm text-muted-foreground">Total sales value by month</p>
                    </div>
                    <div className="flex items-center">
                        <ChartExportMenu
                            data={salesData.monthlySales && salesData.monthlySales.length > 0 ? salesData.monthlySales : []}
                            chartTitle="Monthly Sales Value"
                            standalone={false}
                        />
                    </div>
                </div>
                <div className="p-6 pt-0">
                    {salesData.monthlySales && salesData.monthlySales.length > 0
                      ? (
                        <LineChart
                            data={salesData.monthlySales}
                            index="month"
                            categories={['totalValue']}
                            className="h-[300px]"
                            valueFormatter={(value) => formatCurrency(value)}
                            chartTitle="Monthly Sales Value"
                        />
                        )
                      : renderNoDataMessage()}
                </div>
            </div>
        </div>

            {/* Sales Funnel Section */}
            <div className="w-full">
                <div className="bg-white rounded-lg border shadow-sm">
                    <div className="flex flex-row items-center justify-between p-6">
                        <div>
                            <h3 className="text-2xl font-semibold leading-none tracking-tight">Sales Funnel</h3>
                            <p className="text-sm text-muted-foreground">Conversion through sales stages</p>
                        </div>
                        <div className="flex items-center">
                            <ChartExportMenu
                                data={(salesData.salesFunnel.firm > 0 || salesData.salesFunnel.contracts > 0)
                                  ? [
                                      { stage: 'Registrations', value: salesData.salesFunnel.registrations },
                                      { stage: 'Prospects', value: salesData.salesFunnel.prospects },
                                      { stage: 'Visits', value: salesData.salesFunnel.visits },
                                      { stage: 'Offers', value: salesData.salesFunnel.contracts },
                                      { stage: 'Firm', value: salesData.salesFunnel.firm }
                                    ]
                                  : []}
                                chartTitle="Sales Funnel"
                                standalone={false}
                            />
                        </div>
                    </div>
                    <div className="p-6 pt-0" id="sales-funnel-chart">
                        {(salesData.salesFunnel.firm > 0 || salesData.salesFunnel.contracts > 0)
                          ? (
                            <>
                                <div className="grid grid-cols-5 gap-2">
                                    <div className="bg-indigo-500 p-3 rounded text-white text-center relative group">
                                        <p className="text-xs">Registrations</p>
                                        <p className="text-lg font-bold">{salesData.salesFunnel.registrations.toLocaleString()}</p>
                                        <div className="absolute invisible group-hover:visible bg-gray-800 text-white text-xs rounded p-2 w-48 bottom-full left-1/2 transform -translate-x-1/2 mb-2 z-10">
                                            Initial interest in the development from potential buyers
                                        </div>
                                    </div>
                                    <div className="bg-indigo-400 p-3 rounded text-white text-center relative group">
                                        <p className="text-xs">Prospects</p>
                                        <p className="text-lg font-bold">{salesData.salesFunnel.prospects.toLocaleString()}</p>
                                        <div className="absolute invisible group-hover:visible bg-gray-800 text-white text-xs rounded p-2 w-48 bottom-full left-1/2 transform -translate-x-1/2 mb-2 z-10">
                                            Qualified leads who have shown specific interest
                                        </div>
                                        {salesData.salesFunnel.registrations > 0 && (
                                            <p className="text-xs mt-1">
                                                {((salesData.salesFunnel.prospects / salesData.salesFunnel.registrations) * 100).toFixed(1)}% conversion
                                            </p>
                                        )}
                                    </div>
                                    <div className="bg-indigo-300 p-3 rounded text-white text-center relative group">
                                        <p className="text-xs">Visits</p>
                                        <p className="text-lg font-bold">{salesData.salesFunnel.visits.toLocaleString()}</p>
                                        <div className="absolute invisible group-hover:visible bg-gray-800 text-white text-xs rounded p-2 w-48 bottom-full left-1/2 transform -translate-x-1/2 mb-2 z-10">
                                            Prospects who have toured available units or the sales center
                                        </div>
                                        {salesData.salesFunnel.prospects > 0 && (
                                            <p className="text-xs mt-1">
                                                {((salesData.salesFunnel.visits / salesData.salesFunnel.prospects) * 100).toFixed(1)}% conversion
                                            </p>
                                        )}
                                    </div>
                                    <div className="bg-indigo-200 p-3 rounded text-center relative group">
                                        <p className="text-xs">Offers</p>
                                        <p className="text-lg font-bold">{salesData.salesFunnel.contracts.toLocaleString()}</p>
                                        <div className="absolute invisible group-hover:visible bg-gray-800 text-white text-xs rounded p-2 w-48 bottom-full left-1/2 transform -translate-x-1/2 mb-2 z-10">
                                            Formal offers accepted by the developer
                                        </div>
                                        {salesData.salesFunnel.visits > 0 && (
                                            <p className="text-xs mt-1">
                                                {((salesData.salesFunnel.contracts / salesData.salesFunnel.visits) * 100).toFixed(1)}% conversion
                                            </p>
                                        )}
                                    </div>
                                    <div className="bg-indigo-100 p-3 rounded text-center relative group">
                                        <p className="text-xs">Firm</p>
                                        <p className="text-lg font-bold">{salesData.salesFunnel.firm.toLocaleString()}</p>
                                        <div className="absolute invisible group-hover:visible bg-gray-800 text-white text-xs rounded p-2 w-48 bottom-full left-1/2 transform -translate-x-1/2 mb-2 z-10">
                                            Finalized sales that have gone firm
                                        </div>
                                        {salesData.salesFunnel.contracts > 0 && (
                                            <p className="text-xs mt-1">
                                                {((salesData.salesFunnel.firm / salesData.salesFunnel.contracts) * 100).toFixed(1)}% conversion
                                            </p>
                                        )}
                                    </div>
                                </div><div className="flex justify-between px-10 mt-1 mb-3">
                                    <div className="w-full border-t border-indigo-300 mt-2"></div>
                                    <div className="px-2">→</div>
                                    <div className="w-full border-t border-indigo-300 mt-2"></div>
                                    <div className="px-2">→</div>
                                    <div className="w-full border-t border-indigo-300 mt-2"></div>
                                    <div className="px-2">→</div>
                                    <div className="w-full border-t border-indigo-300 mt-2"></div>
                                </div>

                                <div className="grid grid-cols-2 gap-3 mt-3">
                                    <div className="p-3 bg-red-50 rounded">
                                        <p className="text-sm text-red-800 flex justify-between">
                                            <span>Rescission Rate:</span>
                                            <span className="font-bold">{salesData.rescissionRate}%</span>
                                        </p>
                                        <p className="text-xs text-gray-600 mt-1">
                                            {salesData.salesFunnel.rescinded} units had offers accepted but did not go firm
                                        </p>
                                    </div>
                                    <div className="p-3 bg-green-50 rounded">
                                        <p className="text-sm text-green-800 flex justify-between">
                                            <span>Overall Conversion Rate:</span>
                                            <span className="font-bold">
                                                {salesData.salesFunnel.registrations > 0
                                                  ? ((salesData.salesFunnel.firm / salesData.salesFunnel.registrations) * 100).toFixed(1)
                                                  : '0.0'}%
                                            </span>
                                        </p>
                                        <p className="text-xs text-gray-600 mt-1">
                                            Rate of registrations that convert to firm sales
                                        </p>
                                    </div>
                                </div>
                            </>
                            )
                          : renderNoDataMessage()}
                    </div>
                </div>
            </div>

        {/* Third Row - Additional Metrics */}
        <div className="grid gap-2 md:grid-cols-4">
            <Card className="overflow-hidden bg-white">
                <CardHeader className="flex flex-row items-center justify-between py-2">
                    <CardTitle className="text-sm font-medium text-[#1F2937]">Cost/Home Sold</CardTitle>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-[#222222]">
                        <circle cx="12" cy="12" r="10" />
                        <path d="M16 8h-6.5a2.5 2.5 0 0 0 0 5h3a2.5 2.5 0 0 1 0 5H6" />
                        <path d="M12 18V6" />
                    </svg>
                </CardHeader>
                <CardContent className="py-2">
                    <div className="text-xl font-bold text-[#1F2937]">{formatCurrency(salesData.inventorySummary.costPerHome)}</div>
                    <p className="text-xs text-[#1F2937] mt-1 flex items-center">
                        {salesData.showPeriodComparison
                          ? (
                            <>
                                <span className={`${salesData.costPerHomeGrowth < 0 ? 'text-emerald-500' : 'text-red-500'} flex items-center mr-1`}>
                                    {salesData.costPerHomeGrowth < 0
                                      ? (
                                        <ArrowDown className="h-3 w-3 mr-1" />
                                        )
                                      : (
                                        <ArrowUp className="h-3 w-3 mr-1" />
                                        )}
                                    {Math.abs(salesData.costPerHomeGrowth).toFixed(1)}%
                                </span>
                                <span>
                                    {(salesData.costPerHomeGrowth > 0 ? 'increase' : 'decrease') + ' from prior period'}
                                </span>
                            </>
                            )
                          : (
                            <></>
                            )}
                    </p>
                </CardContent>
            </Card>
            <Card className="overflow-hidden bg-white">
                <CardHeader className="flex flex-row items-center justify-between py-2">
                    <CardTitle className="text-sm font-medium text-[#1F2937]">Net PPSF Sold</CardTitle>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-[#222222]">
                        <rect width="18" height="18" x="3" y="3" rx="2" />
                        <path d="M3 9h18" />
                        <path d="M9 21V9" />
                    </svg>
                </CardHeader>
                <CardContent className="py-2">
                    <div className="text-xl font-bold text-[#1F2937]">${salesData.inventorySummary.netPpsfSold ? salesData.inventorySummary.netPpsfSold.toFixed(0) : '0'}</div>
                    <p className="text-xs text-[#1F2937] mt-1 flex items-center">
                        {salesData.showPeriodComparison
                          ? (
                            <>
                                <span className={`${salesData.netPPSFSoldGrowth >= 0 ? 'text-emerald-500' : 'text-red-500'} flex items-center mr-1`}>
                                    {salesData.netPPSFSoldGrowth >= 0
                                      ? (
                                        <ArrowUp className="h-3 w-3 mr-1" />
                                        )
                                      : (
                                        <ArrowDown className="h-3 w-3 mr-1" />
                                        )}
                                    {Math.abs(salesData.netPPSFSoldGrowth).toFixed(1)}%
                                </span>
                                <span>
                                    {'from ' + (salesData.periodInfo ? getPreviousPeriodLabel(salesData.periodInfo.periodType) : 'prior period')}
                                </span>
                            </>
                            )
                          : (
                            <></>
                            )}
                    </p>
                </CardContent>
            </Card>
            <Card className="overflow-hidden bg-white">
                <CardHeader className="flex flex-row items-center justify-between py-2">
                    <CardTitle className="text-sm font-medium text-[#1F2937]">Net PPSF Available</CardTitle>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-[#222222]">
                        <rect width="18" height="18" x="3" y="3" rx="2" />
                        <path d="M3 9h18" />
                        <path d="M9 21V9" />
                    </svg>
                </CardHeader>
                <CardContent className="py-2">
                    <div className="text-xl font-bold text-[#1F2937]">${salesData.inventorySummary.netPpsfAvail ? salesData.inventorySummary.netPpsfAvail.toFixed(0) : '0'}</div>
                    <p className="text-xs text-[#1F2937] mt-1 flex items-center">
                        {salesData.showPeriodComparison
                          ? (
                            <>
                                <span className={`${salesData.netPPSFAvailGrowth >= 0 ? 'text-emerald-500' : 'text-red-500'} flex items-center mr-1`}>
                                    {salesData.netPPSFAvailGrowth >= 0
                                      ? (
                                        <ArrowUp className="h-3 w-3 mr-1" />
                                        )
                                      : (
                                        <ArrowDown className="h-3 w-3 mr-1" />
                                        )}
                                    {Math.abs(salesData.netPPSFAvailGrowth).toFixed(1)}%
                                </span>
                                <span>
                                    {'from ' + (salesData.periodInfo ? getPreviousPeriodLabel(salesData.periodInfo.periodType) : 'prior period')}
                                </span>
                            </>
                            )
                          : (
                            <></>
                            )}
                    </p>
                </CardContent>
            </Card>
            <Card className="overflow-hidden bg-white">
                <CardHeader className="flex flex-row items-center justify-between py-2">
                    <CardTitle className="text-sm font-medium text-[#1F2937]">Blended PPSF</CardTitle>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-[#222222]">
                        <rect width="18" height="18" x="3" y="3" rx="2" />
                        <path d="M3 9h18" />
                        <path d="M9 21V9" />
                    </svg>
                </CardHeader>
                <CardContent className="py-2">
                    <div className="text-xl font-bold text-[#1F2937]">${salesData.inventorySummary.blendedPpsf ? salesData.inventorySummary.blendedPpsf.toFixed(0) : '0'}</div>
                    <p className="text-xs text-[#1F2937] mt-1 flex items-center">
                        {salesData.showPeriodComparison
                          ? (
                            <>
                                <span className={`${salesData.blendedPPSFGrowth >= 0 ? 'text-emerald-500' : 'text-red-500'} flex items-center mr-1`}>
                                    {salesData.blendedPPSFGrowth >= 0
                                      ? (
                                        <ArrowUp className="h-3 w-3 mr-1" />
                                        )
                                      : (
                                        <ArrowDown className="h-3 w-3 mr-1" />
                                        )}
                                    {Math.abs(salesData.blendedPPSFGrowth).toFixed(1)}%
                                </span>
                                <span>
                                    {'from ' + (salesData.periodInfo ? getPreviousPeriodLabel(salesData.periodInfo.periodType) : 'prior period')}
                                </span>
                            </>
                            )
                          : (
                            <></>
                            )}
                    </p>
                </CardContent>
            </Card>
        </div>

        {/* Fourth Row - Square Footage Metrics */}
        <div className="grid gap-2 md:grid-cols-3">
            <Card className="overflow-hidden bg-white">
                <CardHeader className="flex flex-row items-center justify-between py-2">
                    <CardTitle className="text-sm font-medium text-[#1F2937]">Total Square Footage</CardTitle>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-[#222222]">
                        <rect width="18" height="18" x="3" y="3" rx="2" />
                        <path d="M3 9h18" />
                        <path d="M9 21V9" />
                    </svg>
                </CardHeader>
                <CardContent className="py-2">
                    <div className="text-xl font-bold text-[#1F2937]">{salesData.inventorySummary.totalSqft ? salesData.inventorySummary.totalSqft.toLocaleString() : '0'} sqft</div>
                    <p className="text-xs text-[#1F2937] mt-1">Total project area</p>
                </CardContent>
            </Card>
            <Card className="overflow-hidden bg-white">
                <CardHeader className="flex flex-row items-center justify-between py-2">
                    <CardTitle className="text-sm font-medium text-[#1F2937]">Square Footage Sold</CardTitle>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-[#222222]">
                        <rect width="18" height="18" x="3" y="3" rx="2" />
                        <path d="M3 9h18" />
                        <path d="M9 21V9" />
                    </svg>
                </CardHeader>
                <CardContent className="py-2">
                    <div className="text-xl font-bold text-[#1F2937]">
                        {salesData.inventorySummary.soldSqft ? salesData.inventorySummary.soldSqft.toLocaleString() : '0'} sqft
                    </div>
                    <p className="text-xs text-[#1F2937] mt-1 flex items-center">
                        {salesData.showPeriodComparison
                          ? (
                            <>
                                <span className={`${salesData.soldSquareFootageGrowth >= 0 ? 'text-emerald-500' : 'text-red-500'} flex items-center mr-1`}>
                                    {salesData.soldSquareFootageGrowth >= 0
                                      ? (
                                        <ArrowUp className="h-3 w-3 mr-1" />
                                        )
                                      : (
                                        <ArrowDown className="h-3 w-3 mr-1" />
                                        )}
                                    {Math.abs(salesData.soldSquareFootageGrowth).toFixed(1)}%
                                </span>
                                <span>
                                    {(salesData.soldSquareFootageGrowth >= 0 ? 'increase' : 'decrease') + ' from ' + (salesData.periodInfo ? getPreviousPeriodLabel(salesData.periodInfo.periodType) : 'prior period')}
                                </span>
                            </>
                            )
                          : (
                            <></>
                            )}
                    </p>
                </CardContent>
            </Card>
            <Card className="overflow-hidden bg-white">
                <CardHeader className="flex flex-row items-center justify-between py-2">
                    <CardTitle className="text-sm font-medium text-[#1F2937]">Square Footage Available</CardTitle>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-[#222222]">
                        <rect width="18" height="18" x="3" y="3" rx="2" />
                        <path d="M3 9h18" />
                        <path d="M9 21V9" />
                    </svg>
                </CardHeader>
                <CardContent className="py-2">
                    <div className="text-xl font-bold text-[#1F2937]">
                        {salesData.inventorySummary.availableSqft ? salesData.inventorySummary.availableSqft.toLocaleString() : '0'} sqft
                    </div>
                    <p className="text-xs text-[#1F2937] mt-1 flex items-center">
                        {salesData.showPeriodComparison
                          ? (
                            <>
                                <span className={`${salesData.availableSquareFootageGrowth >= 0 ? 'text-red-500' : 'text-emerald-500'} flex items-center mr-1`}>
                                    {salesData.availableSquareFootageGrowth >= 0
                                      ? (
                                        <ArrowUp className="h-3 w-3 mr-1" />
                                        )
                                      : (
                                        <ArrowDown className="h-3 w-3 mr-1" />
                                        )}
                                    {Math.abs(salesData.availableSquareFootageGrowth).toFixed(1)}%
                                </span>
                                <span>
                                    {(salesData.availableSquareFootageGrowth >= 0 ? 'increase' : 'decrease') + ' from ' + (salesData.periodInfo ? getPreviousPeriodLabel(salesData.periodInfo.periodType) : 'prior period')}
                                </span>
                            </>
                            )
                          : (
                            <></>
                            )}
                    </p>
                </CardContent>
            </Card>
        </div>

        {/* Fifth Row - Unit Status and Bedroom Distribution */}
        <div className="grid gap-2 md:grid-cols-2">
            <div className="bg-white rounded-lg border shadow-sm">
                <div className="flex flex-row items-center justify-between p-3">
                    <div>
                        <h3 className="text-2xl font-semibold leading-none tracking-tight">Unit Status</h3>
                        <p className="text-sm text-muted-foreground">Distribution of units by current status</p>
                    </div>
                    <div className="flex items-center">
                        <ChartExportMenu
                            data={salesData.unitStatus && salesData.unitStatus.length > 0 ? salesData.unitStatus : []}
                            chartTitle="Unit Status"
                            standalone={false}
                        />
                    </div>
                </div>
                <div className="p-3 pt-0">
                    {salesData.unitStatus && salesData.unitStatus.length > 0
                      ? (
                        <PieChart
                            data={salesData.unitStatus}
                            category="value"
                            index="name"
                            className="h-[300px]"
                            chartTitle="Unit Status"
                        />
                        )
                      : renderNoDataMessage()}
                </div>
            </div>

            <div className="bg-white rounded-lg border shadow-sm">
                <div className="flex flex-row items-center justify-between p-3">
                    <div>
                        <h3 className="text-2xl font-semibold leading-none tracking-tight">Bedroom Distribution</h3>
                        <p className="text-sm text-muted-foreground">Units by bedroom count</p>
                    </div>
                    <div className="flex items-center">
                        <ChartExportMenu
                            data={salesData.bedroomDistribution && salesData.bedroomDistribution.length > 0 ? salesData.bedroomDistribution : []}
                            chartTitle="Bedroom Distribution"
                            standalone={false}
                        />
                    </div>
                </div>
                <div className="p-3 pt-0">
                    {salesData.bedroomDistribution && salesData.bedroomDistribution.length > 0
                      ? (
                        <PieChart
                            data={salesData.bedroomDistribution}
                            category="value"
                            index="name"
                            className="h-[300px]"
                            chartTitle="Bedroom Distribution"
                        />
                        )
                      : renderNoDataMessage()}
                </div>
            </div>
        </div>

        {/* Sixth Row - Top Performing Realtors */}
        <div className="w-full grid gap-2 md:grid-cols-2">
            <div className="bg-white rounded-lg border shadow-sm">
                <div className="flex flex-row items-center justify-between p-3">
                    <div>
                        <h3 className="text-xl font-semibold leading-none tracking-tight">Top Performing Realtors</h3>
                        <p className="text-xs text-muted-foreground">Sales volume by realtor</p>
                    </div>
                    <div className="flex items-center">
                        <ChartExportMenu
                            data={salesData.topRealtors && salesData.topRealtors.length > 0 ? salesData.topRealtors : []}
                            chartTitle="Top Performing Realtors"
                            standalone={false}
                        />
                    </div>
                </div>
                <div className="p-3 pt-0">
                    {salesData.topRealtors && salesData.topRealtors.length > 0
                      ? (
                        <BarChart
                            data={salesData.topRealtors}
                            categories={['value']}
                            index="name"
                            className="h-[300px]"
                            chartTitle="Top Performing Realtors"
                        />
                        )
                      : renderNoDataMessage()}
                </div>
            </div>
            <div className="bg-white rounded-lg border shadow-sm">
              <div className="flex flex-row items-center justify-between p-3">
                    <div>
                        <h3 className="text-xl font-semibold leading-none tracking-tight">Monthly Unit Sales</h3>
                        <p className="text-xs text-muted-foreground">Number of units sold by month</p>
                    </div>
                    <div className="flex items-center">
                        <ChartExportMenu
                            data={salesData.monthlySales && salesData.monthlySales.length > 0 ? salesData.monthlySales : []}
                            chartTitle="Monthly Unit Sales"
                            standalone={false}
                        />
                    </div>
                </div>
                    {salesData.monthlySales && salesData.monthlySales.length > 0
                      ? (
                      <BarChart
                          data={salesData.monthlySales}
                          index="month"
                          categories={['count']}
                          className="h-[300px]"
                          layout="horizontal"
                          valueFormatter={(value) => `${value} units`}
                          chartTitle="Monthly Unit Sales"
                      />
                        )
                      : renderNoDataMessage()}
                </div>
            </div>

        {/* Eighth Row - Sales Costs Breakdown */}
        <Card className="overflow-hidden bg-white">
          <CardHeader className="flex flex-row items-center justify-between py-2">
            <div>
              <CardTitle className="text-sm">Sales Costs Breakdown</CardTitle>
              <CardDescription className="text-xs">Analysis of sales-related expenses</CardDescription>
            </div>
            <Button variant="ghost" size="icon" className="h-8 w-8">
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </CardHeader>
          <CardContent className="p-4" id="sales-costs-breakdown-chart">
            <div className="grid grid-cols-3 gap-4">
              <div className="bg-red-50 p-4 rounded text-center">
                <p className="text-sm text-gray-500">Avg. Incentive Per Deal</p>
                <p className="text-xl font-bold">{formatCurrency(salesData.salesCosts.avgIncentive)}</p>
              </div>
              <div className="bg-blue-50 p-4 rounded text-center">
                <p className="text-sm text-gray-500">Avg. Realtor Fee</p>
                <p className="text-xl font-bold">{formatCurrency(salesData.salesCosts.avgRealtorFee)}</p>
              </div>
              <div className="bg-green-50 p-4 rounded text-center">
                <p className="text-sm text-gray-500">Avg. Bonus Per Deal</p>
                <p className="text-xl font-bold">{formatCurrency(salesData.salesCosts.avgBonus)}</p>
              </div>
            </div>

            <div className="mt-6">
              <h4 className="text-sm font-medium mb-2">Cost Breakdown per Unit</h4>
              <div className="h-8 bg-gray-200 rounded-lg overflow-hidden flex">
                <div className="h-full bg-red-400 text-white flex items-center justify-center text-xs"
                  style={{ width: `${salesData.salesCosts.incentivePercent}%` }}>
                  Incentives
                </div>
                <div className="h-full bg-blue-400 text-white flex items-center justify-center text-xs"
                  style={{ width: `${salesData.salesCosts.realtorFeePercent}%` }}>
                  Realtor Fees
                </div>
                <div className="h-full bg-green-400 text-white flex items-center justify-center text-xs"
                  style={{ width: `${salesData.salesCosts.bonusPercent}%` }}>
                  Bonuses
                </div>
              </div>
              <div className="flex justify-between mt-2 text-xs">
                <span className="text-red-500">{salesData.salesCosts.incentivePercent.toFixed(1)}% of costs</span>
                <span className="text-blue-500">{salesData.salesCosts.realtorFeePercent.toFixed(1)}% of costs</span>
                <span className="text-green-500">{salesData.salesCosts.bonusPercent.toFixed(1)}% of costs</span>
              </div>
            </div>

            <div className="mt-6 p-4 bg-yellow-50 rounded-lg">
              <h4 className="text-sm font-medium mb-2">Total Cost Analysis</h4>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-gray-500">Total Sales Costs</p>
                  <p className="text-xl font-bold">{formatCurrency(salesData.salesCosts.totalCosts)}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Cost as % of Revenue</p>
                  <p className="text-xl font-bold">{salesData.salesCosts.costPercentage.toFixed(1)}%</p>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
    </div>
  )
}

export default SalesDashboardTab
