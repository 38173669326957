/* eslint multiline-ternary: ["error", "never"] */
// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useGetAPI } from 'hooks/useGenericAPIs'
import {
  getCorporateDemandNotesReport,
  getLast30DaysContacts,
  getLeadsCountByProject,
  getProjectContactWiseList,
  getRatingScoreCountsAllProjects
} from 'store/actions/usersActions'
import moment from 'moment'
import placeHolderBg from '../../assets/img/bg.png'
import { BarChartShadCN } from 'components/Charts/BarChart'
import { PieChartShadCN } from 'components/Charts/PieChart'
import { BarListShadCN } from 'components/Charts/BarList'
import Skeleton from 'react-loading-skeleton'
import KPICard from 'pages/dashboardv2/components/KPICard'
import { AreaChart, LineChart } from 'recharts'
import { DonutChart } from '@tremor/react'
import { Building, DollarSign, Home, MoreHorizontal, Percent } from 'lucide-react'

const ShadCNWrapper = ({ children, className = '' }) => (
  <div
    className={`rounded-xl border bg-card text-card-foreground shadow bg-white overflow-hidden ${className}`}
  >
    {children}
  </div>
)

const ChartSkeleton = ({ className = 'min-h-[300px]' }) => (
  <div className={`w-full ${className} bg-white rounded-xl p-4`}>
    <div className='flex flex-col gap-4'>
      <div className='flex justify-between items-center'>
        <Skeleton width={200} height={24} />
        <Skeleton width={100} height={24} />
      </div>
      <Skeleton height={250} />
      <div className='flex gap-4'>
        <Skeleton width={80} height={20} />
        <Skeleton width={80} height={20} />
        <Skeleton width={80} height={20} />
      </div>
    </div>
  </div>
)

const CardUI = ({ title, number, Icon }) => (
  <ShadCNWrapper>
    <div className='p-6 flex flex-row items-center justify-between space-y-0 pb-2'>
      <h3 className='tracking-tight text-sm font-medium truncate'>{title}</h3>
      {Icon}
    </div>
    <div className='p-6 pt-0'>
      <div className='text-2xl font-bold'>{number}</div>
    </div>
  </ShadCNWrapper>
)

const HorizontalLine = ({ className }) => (
  <div className={`w-full h-[1px] bg-zinc-200 ${className}`} />
)

const CorporateDashboard = (props) => {
  const projectId = useSelector((state) => state.appReducer.appProject)
  const {
    data: fetchedBuyerBrokerCount,
    fetch: fetchBrokerBuyerCount,
    loading: isBrokerBuyerCountLoading
  } = useGetAPI()

  const [last30DaysContacts, setLast30DaysContacts] = useState([])
  // const [isLeadsLoading, setIsLeadsLoading] = useState(true)
  const [projectBackgroundImage] = useState(placeHolderBg)
  const [leadsCountData, setLeadsCountData] = useState([])
  const [ratingScoreCounts, setRatingScoreCounts] = useState([])
  const [demandNotesReport, setDemandNotesReport] = useState([])
  const [showAllDemandNotes, setShowAllDemandNotes] = useState(false)
  const [isChartsLoading, setIsChartsLoading] = useState({
    leadsCount: true,
    ratingScore: true,
    demandNotes: true,
    projectContacts: true,
    last30Days: true
  })

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const leadsCountRes = await getLeadsCountByProject('')
        const mapped = leadsCountRes.map((item, index) => ({
          name: item._id || 'Unassigned',
          value: item.count || 0,
          fill: `hsl(var(--chart-${index + 1}))`
        }))
        setLeadsCountData(mapped)
      } catch (error) {
        console.error('Error fetching leads count:', error)
      } finally {
        setIsChartsLoading((prev) => ({ ...prev, leadsCount: false }))
      }

      try {
        const ratingRes = await getRatingScoreCountsAllProjects()
        const mapped = ratingRes.map((item, index) => ({
          name: item?._id || 'Unassigned',
          value: item.count || 0,
          fill: `hsl(var(--chart-${index + 1}))`
        }))
        setRatingScoreCounts(mapped)
      } catch (error) {
        console.error('Error fetching rating scores:', error)
      } finally {
        setIsChartsLoading((prev) => ({ ...prev, ratingScore: false }))
      }

      try {
        const demandRes = await getCorporateDemandNotesReport()
        const mapped = demandRes.map((item) => ({
          name: item?.name,
          data: item?.values.map((item, i) => {
            const arrayKey = Object.keys(item)
            return {
              name: arrayKey[0],
              value: item[arrayKey[0]],
              fill: `hsl(var(--chart-${i + 1}))`
            }
          })
        }))
        setDemandNotesReport(mapped)
      } catch (error) {
        console.error('Error fetching demand notes:', error)
      } finally {
        setIsChartsLoading((prev) => ({ ...prev, demandNotes: false }))
      }
    }

    fetchChartData()
  }, [])

  useEffect(() => {
    fetchBrokerBuyerCount('/users/reports/count/contacts/all')
  }, [projectId])

  const [projectContacts, setProjectContacts] = useState([])

  useEffect(() => {
    const fetchProjectContacts = async () => {
      try {
        const response = await getProjectContactWiseList()
        const mapped = response.map((item, index) => ({
          ...item,
          fill: `hsl(var(--chart-${index + 1}))`
        }))
        setProjectContacts(mapped)
      } catch (error) {
        console.error('Error fetching project contacts:', error)
      } finally {
        setIsChartsLoading((prev) => ({ ...prev, projectContacts: false }))
      }
    }

    fetchProjectContacts()
  }, [])

  useEffect(() => {
    const fetchLast30DaysContacts = async () => {
      try {
        const response = await getLast30DaysContacts()
        setLast30DaysContacts([
          ...response.map((elm) => ({
            ...elm,
            realtors: elm?.leadBrokers || 0
          }))
        ])
      } catch (error) {
        console.error('Error fetching last 30 days contacts:', error)
      } finally {
        setIsChartsLoading((prev) => ({ ...prev, last30Days: false }))
      }
    }
    fetchLast30DaysContacts()
  }, [])

  const ChartLoading = () => (
    <div className='w-full h-full min-h-[300px] flex items-center justify-center bg-white rounded-xl'>
      <div className='animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900'></div>
    </div>
  )

  return (
    <div className='flex justify-center min-h-[calc(100vh-64px)]'>
      <div className='z-[-1] fixed top-0 left-0 h-full overflow-hidden'>
        <img
          src={
            projectBackgroundImage?.length > 0
              ? projectBackgroundImage
              : placeHolderBg
          }
          className='object-cover w-screen h-full'
          loading='lazy'
        />
        <div className='absolute top-0 left-0 w-screen h-full bg-black/50' />
      </div>
      <div className='w-full my-10 px-4 md:px-8 max-w-[1440px] flex flex-col gap-6 relative'>
        <div className='w-full gap-6 lg:gap-y-0 flex flex-col lg:flex-row justify-between'>
          <div className='w-full lg:flex-1 flex flex-col gap-y-8 overflow-hidden'>
            <div className='flex w-full gap-y-4 flex-col md:flex-row gap-x-8 justify-between'>
              <div className='w-full gap-y-4 grid grid-cols-3 gap-x-8'>
                {isBrokerBuyerCountLoading ? (
                  <>
                    <Skeleton className='rounded-xl' height={120} />
                    <Skeleton className='rounded-xl' height={120} />
                    <Skeleton className='rounded-xl' height={120} />
                  </>
                ) : (
                  <>
                    <CardUI
                      title='TOTAL CONTACTS'
                      number={fetchedBuyerBrokerCount?.total || 0}
                      Icon={<img src='/total-sale.svg' className='w-5 h-5' />}
                    />
                    <CardUI
                      title='LEADS'
                      number={fetchedBuyerBrokerCount?.buyer || 0}
                      Icon={<img src='/unit-sold.svg' className='w-5 h-5' />}
                    />
                    <CardUI
                      title='REALTORS'
                      number={fetchedBuyerBrokerCount?.leadBroker || 0}
                      Icon={<img src='/avg-price.svg' className='w-5 h-5' />}
                    />
                  </>
                )}
              </div>
            </div>
            {/* <div className='w-fit max-w-[400px]'>
                  <TabWrapper
                    tabMenus={tabMenus2}
                    setSelectedTab={setSelectedTab2}
                    selectedTab={selectedTab2}
                  />
                </div> */}
            {isChartsLoading.last30Days ? (
              <ChartSkeleton />
            ) : (
              <BarChartShadCN
                title={'Last 30 Days Contacts'}
                description={
                  moment(new Date())
                    .subtract(31, 'days')
                    .format('MMM DD YYYY') +
                  ' - ' +
                  moment(new Date()).format('MMM DD YYYY')
                }
                data={last30DaysContacts}
                XAxisDataKey={'date'}
                XAxisTickFormatter={(value) => moment(value).format('MMM DD')}
                dataKey1={'buyers'}
                dataKey2={'realtors'}
                tickStyle={{
                  fontSize: 10
                }}
                angle={-45}
                textAnchor='end'
                height={50}
                interval={0}
              />
            )}
            <div className='grid grid-cols-12 w-full gap-6'>
              <div className='col-span-12 md:col-span-6 xl:col-span-4'>
                {isChartsLoading.projectContacts ? (
                  <ChartSkeleton />
                ) : (
                  <PieChartShadCN
                    data={projectContacts}
                    dataKey='value'
                    nameKey='name'
                    title='Contacts by Project'
                    footer={
                      <div className='flex flex-wrap mt-4 items-center gap-2 text-sm'>
                        {projectContacts.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className='flex flex-row items-center gap-2'
                            >
                              <div
                                className='w-3 h-3 rounded-full'
                                style={{ backgroundColor: item.fill }}
                              />
                              <div>{item.name}</div>
                            </div>
                          )
                        })}
                      </div>
                    }
                  />
                )}
              </div>
              <div className='flex flex-col col-span-12 md:col-span-6 xl:col-span-4 gap-y-2'>
                {isChartsLoading.leadsCount ? (
                  <ChartSkeleton />
                ) : (
                  <BarChartShadCN
                    barWidth={40}
                    title={'Leads by Status'}
                    data={leadsCountData}
                    XAxisDataKey={'name'}
                    // XAxisTickFormatter={(value) =>
                    //   moment(value).format("MMM DD")
                    // }
                    angle={45}
                    textAnchor='start'
                    dataKey1={'value'}
                    tickStyle={{
                      fontSize: 12
                    }}
                    height={100}
                    interval={0}
                  />
                )}
              </div>
              <div className='flex flex-col col-span-12 md:col-span-6 xl:col-span-4 gap-y-2'>
                {isChartsLoading.ratingScore ? (
                  <ChartSkeleton className='min-h-[400px]' />
                ) : (
                  <BarListShadCN
                    ChartContainerProps={{
                      className: 'max-h-[400px]'
                    }}
                    data={ratingScoreCounts}
                    dataKey1={'name'}
                    XAxisDataKey={'name'}
                    dataKey2={'value'}
                    interval={0}
                    title={'Rating Source Report'}
                    tickStyle={{
                      fontSize: 12
                    }}
                  />
                )}
              </div>
            </div>
            <HorizontalLine className='my-4' />
            <div className='grid grid-cols-12 gap-x-6 gap-y-4'>
              {isChartsLoading.demandNotes ? (
                <>
                  <div className='col-span-6'>
                    <ChartSkeleton />
                  </div>
                  <div className='col-span-6'>
                    <ChartSkeleton />
                  </div>
                  <div className='col-span-6'>
                    <ChartSkeleton />
                  </div>
                </>
              ) : (
                demandNotesReport
                  .slice(0, showAllDemandNotes ? demandNotesReport.length : 6)
                  .map((item, index) => {
                    return (
                      <div key={index} className='col-span-12 md:col-span-6 xl:col-span-4'>
                        <BarListShadCN
                          data={item.data}
                          dataKey1={'name'}
                          XAxisDataKey={'name'}
                          dataKey2={'value'}
                          interval={0}
                          title={item.name}
                          tickStyle={{
                            fontSize: 12
                          }}
                        />
                      </div>
                    )
                  })
              )}
            </div>
            {demandNotesReport.length > 6 && (
              <div className='flex justify-center mt-4'>
                <button
                  onClick={() => setShowAllDemandNotes(!showAllDemandNotes)}
                  className='bg-volt text-softBlack px-4 py-2 rounded-md'
                >
                  {showAllDemandNotes ? 'Show Less' : 'Show More'}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-6 p-6 min-h-[calc(100vh-64px)]'>
      {/* Top Row - Key Metrics */}
{/*       <div className="grid gap-4 md:grid-cols-4">
        <KPICard
          title="Total Units Sold"
          value="85 / 120"
          icon={Building}
          trend={{
            value: 10,
            text: "units since last week",
            isPositive: true
          }}
          progress={{
            percentage: 70,
            text: "70% occupancy"
          }}
        />
        <KPICard
          title="Revenue to Date"
          value="$12,500,000"
          icon={DollarSign}
          trend={{
            value: 5,
            text: "from last month",
            isPositive: true
          }}
        />
        <KPICard
          title="Sales Conversion Rate"
          value="25%"
          icon={Percent}
          trend={{
            value: 3,
            text: "from last month",
            isPositive: true
          }}
        />
        <KPICard
          title="Average Price per Unit"
          value="$850,000"
          icon={Home}
          trend={{
            value: 2,
            text: "from last month",
            isPositive: false
          }}
        />
      </div> */}

      {/* Second Row - Charts */}
  {/*     <div className="grid gap-4 md:grid-cols-3">
        <div className="bg-white p-4 rounded-lg shadow-sm">
          <div className="flex items-center justify-between mb-4">
            <div>
              <h3 className="text-lg font-medium">Sales Over Time</h3>
              <p className="text-sm text-gray-500">Units sold per week</p>
            </div>
            <button className="p-2 hover:bg-gray-100 rounded-full">
              <MoreHorizontal className="h-4 w-4" />
            </button>
          </div>
          <LineChart
            className="h-64"
            data={[
              { week: "Week 1", sales: 5 },
              { week: "Week 2", sales: 8 },
              { week: "Week 3", sales: 12 },
              { week: "Week 4", sales: 10 },
              { week: "Week 5", sales: 15 },
              { week: "Week 6", sales: 18 }
            ]}
            categories={["sales"]}
            index="week"
            colors={["#3b82f6"]}
            valueFormatter={(value) => `${value} units`}
            showLegend={false}
          />
        </div>

        <div className="bg-white p-4 rounded-lg shadow-sm">
          <div className="flex items-center justify-between mb-4">
            <div>
              <h3 className="text-lg font-medium">Lead Sources</h3>
              <p className="text-sm text-gray-500">Distribution of leads</p>
            </div>
            <button className="p-2 hover:bg-gray-100 rounded-full">
              <MoreHorizontal className="h-4 w-4" />
            </button>
          </div>
          <DonutChart
            className="h-64"
            data={[
              { source: "Direct", value: 30 },
              { source: "Referral", value: 40 },
              { source: "Social", value: 30 }
            ]}
            category="value"
            index="source"
            valueFormatter={(value) => `${value}%`}
          />
        </div>

        <div className="bg-white p-4 rounded-lg shadow-sm">
          <div className="flex items-center justify-between mb-4">
            <div>
              <h3 className="text-lg font-medium">Sales Pipeline</h3>
              <p className="text-sm text-gray-500">Conversion stages</p>
            </div>
            <button className="p-2 hover:bg-gray-100 rounded-full">
              <MoreHorizontal className="h-4 w-4" />
            </button>
          </div>
          <AreaChart
            className="h-64"
            data={[
              { stage: "Leads", count: 100 },
              { stage: "Qualified", count: 70 },
              { stage: "Proposal", count: 40 },
              { stage: "Negotiation", count: 30 },
              { stage: "Closed", count: 20 }
            ]}
            categories={["count"]}
            index="stage"
            valueFormatter={(value) => `${value}`}
          />
        </div>
      </div> */}
    </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CorporateDashboard)
