// @ts-nocheck
/* eslint-disable */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import PageHeader from 'components/PageHeader'
import PagesWrapper from 'components/PagesWrapper'
import { 
  Card, 
  CardContent
} from 'components/ShadcnComponents/card'
import { Button } from 'components/ShadcnComponents/button'

const SalesRepAssignSettings = () => {
  const [selectedSource, setSelectedSource] = useState('registration')
  const [selectedMethod, setSelectedMethod] = useState('skill-based')
  const [notifyManager, setNotifyManager] = useState(true)
  const [notifySalesRep, setNotifySalesRep] = useState(true)
  
  return (
    <PagesWrapper>
      <PageHeader 
        title="Sales Rep Assignment Settings" 
        showParameters={false}
        showImage={false}
      />
      
      <div className="px-4 py-6 flex justify-center">
        <div className="max-w-2xl w-full">
          <Card className="mb-6 p-4 mt-6 shadow-sm flex flex-col gap-8 bg-white">
            <CardContent className="p-0">
              <h2 className="text-xl font-medium mb-1">Lead Source Configuration</h2>
              <p className="text-sm text-gray-500 mb-4">Configure how leads are assigned based on their source</p>
              <div className="grid grid-cols-3 gap-2">
                <button 
                  className={`py-2.5 px-4 text-center rounded-md text-sm font-medium ${selectedSource === 'registration' ? 'bg-black text-white' : 'bg-gray-100 text-gray-800'}`}
                  onClick={() => setSelectedSource('registration')}
                >
                  Registration Forms
                </button>
                <button 
                  className={`py-2.5 px-4 text-center rounded-md text-sm font-medium ${selectedSource === 'csv' ? 'bg-black text-white' : 'bg-gray-100 text-gray-800'}`}
                  onClick={() => setSelectedSource('csv')}
                >
                  CSV Uploads
                </button>
                <button 
                  className={`py-2.5 px-4 text-center rounded-md text-sm font-medium ${selectedSource === 'api' ? 'bg-black text-white' : 'bg-gray-100 text-gray-800'}`}
                  onClick={() => setSelectedSource('api')}
                >
                  API Imports
                </button>
              </div>
            </CardContent>
            <CardContent className="p-0">
              <h2 className="text-lg font-medium mb-4">Assignment Method</h2>
              
              <div className="space-y-3">
                <div 
                  className={`border rounded-md p-4 cursor-pointer ${selectedMethod === 'manual' ? 'border-black' : 'border-gray-200'}`}
                  onClick={() => setSelectedMethod('manual')}
                >
                  <div className="flex items-start">
                    <div className="flex h-5 items-center mr-3">
                      <input
                        type="radio"
                        checked={selectedMethod === 'manual'}
                        onChange={() => setSelectedMethod('manual')}
                        className="h-4 w-4 border-gray-300 text-black focus:ring-black"
                      />
                    </div>
                    <div>
                      <label className="font-medium text-gray-900 block">Manual Assignment</label>
                      <p className="text-gray-500 text-sm mt-1">Manager reviews and manually assigns each lead to sales representatives</p>
                    </div>
                  </div>
                </div>

                <div 
                  className={`border rounded-md p-4 cursor-pointer ${selectedMethod === 'round-robin' ? 'border-black' : 'border-gray-200'}`}
                  onClick={() => setSelectedMethod('round-robin')}
                >
                  <div className="flex items-start">
                    <div className="flex h-5 items-center mr-3">
                      <input
                        type="radio"
                        checked={selectedMethod === 'round-robin'}
                        onChange={() => setSelectedMethod('round-robin')}
                        className="h-4 w-4 border-gray-300 text-black focus:ring-black"
                      />
                    </div>
                    <div>
                      <label className="font-medium text-gray-900 block">Round Robin Assignment</label>
                      <p className="text-gray-500 text-sm mt-1">Automatically distribute leads equally among all sales representatives</p>
                    </div>
                  </div>
                </div>

                <div 
                  className={`border rounded-md p-4 cursor-pointer ${selectedMethod === 'skill-based' ? 'border-black' : 'border-gray-200'}`}
                  onClick={() => setSelectedMethod('skill-based')}
                >
                  <div className="flex items-start">
                    <div className="flex h-5 items-center mr-3">
                      <input
                        type="radio"
                        checked={selectedMethod === 'skill-based'}
                        onChange={() => setSelectedMethod('skill-based')}
                        className="h-4 w-4 border-gray-300 text-black focus:ring-black"
                      />
                    </div>
                    <div>
                      <label className="font-medium text-gray-900 block">Skill-Based Matching</label>
                      <p className="text-gray-500 text-sm mt-1">Automatically assign leads based on matching sales rep language skills and other attributes</p>
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
            <div className="border-t border-gray-200 w-full"></div>
            <CardContent className="p-0">
              <h2 className="text-xl font-medium mb-4">Notification Settings</h2>
              
              <div className="space-y-4">
                <Card className="flex items-center p-3 justify-between">
                  <div>
                    <div className="font-medium">Notify Manager of New Leads</div>
                    <p className="text-sm text-gray-500">Send email notifications to managers when new leads are received</p>
                  </div>
                  <div className="relative inline-block w-10 align-middle select-none">
                    <input 
                      type="checkbox" 
                      checked={notifyManager}
                      onChange={() => setNotifyManager(!notifyManager)}
                      className="sr-only"
                      id="toggle-manager"
                    />
                    <label 
                      htmlFor="toggle-manager"
                      className={`block overflow-hidden h-6 rounded-full cursor-pointer ${notifyManager ? 'bg-black' : 'bg-gray-300'}`}
                    >
                      <span 
                        className={`block h-6 w-6 rounded-full bg-white transform transition-transform ${notifyManager ? 'translate-x-4' : 'translate-x-0'}`}
                      ></span>
                    </label>
                  </div>
                </Card>

                <Card className="flex items-center p-3 justify-between">
                  <div>
                    <div className="font-medium">Notify Sales Rep When Assigned</div>
                    <p className="text-sm text-gray-500">Send email notifications to sales reps when leads are assigned to them</p>
                  </div>
                  <div className="relative inline-block w-10 align-middle select-none">
                    <input 
                      type="checkbox" 
                      checked={notifySalesRep}
                      onChange={() => setNotifySalesRep(!notifySalesRep)}
                      className="sr-only"
                      id="toggle-sales-rep"
                    />
                    <label 
                      htmlFor="toggle-sales-rep"
                      className={`block overflow-hidden h-6 rounded-full cursor-pointer ${notifySalesRep ? 'bg-black' : 'bg-gray-300'}`}
                    >
                      <span 
                        className={`block h-6 w-6 rounded-full bg-white transform transition-transform ${notifySalesRep ? 'translate-x-4' : 'translate-x-0'}`}
                      ></span>
                    </label>
                  </div>
                </Card>
              </div>
            </CardContent>
            </Card>
          <div className="flex justify-end space-x-3 mt-4">
            <Button variant="outline" className="px-4 py-2 h-auto">Cancel</Button>
            <Button className="px-4 py-2 h-auto">Save Changes</Button>
          </div>
        </div>
      </div>
    </PagesWrapper>
  )
}

const mapStateToProps = (state) => ({
  // Add any state mappings here
})

const mapDispatchToProps = {
  // Add any dispatch mappings here
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesRepAssignSettings)
