/* eslint-disable */
// @ts-nocheck
import { useCallback, useEffect, useMemo, useState } from "react"
import { EmailEditor } from "react-email-editor"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import CreateEmailCampaign from "../editEmailTemplate/CreateEmailCampaign"
import { createOrUpdateEmailTemplates, getEmailTemplates } from "store/actions/emailTemplateActions"
import SendTestEmail from "../editEmailTemplate/SendTestEmail"
import { EyeIcon, Mail, SendHorizontal } from "lucide-react"
import MergeFieldsAndTemplates from "../editEmailTemplate/MergeFieldsAndTemplates"
import EmailTemplatePreview from "../TemplatePreview"
import CustomButton from "components/CustomButton"
import CustomInput from "components/CustomInput"
import { Oval } from "react-loader-spinner"
import { getMergeFields } from "store/actions/contractTemplateActions"
import { useSelector } from "react-redux"

const CreateOrUpdateEmailTemplate = ({
  templateId = null,
  onBack = null,
  goBackBtnText = "Go back",
  showContentAsModal = false,
  onSuccess = () => { }
}) => {
  const appProject = useSelector((state) => state.appReducer.appProject)
  const history = useHistory()
  const [template, setTemplate] = useState(null)
  const [isSliderOpen, setIsSliderOpen] = useState(false)
  const [isEmailsPreviewOpen, setIsEmailsPreviewOpen] = useState(false)
  const [isSendTestEmailOpen, setIsSendTestEmailOpen] = useState(false)
  const [emailEditor, setEmailEditor] = useState(null)
  const [isTemplateLoading, setIsTemplateLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [availableMergeFields, setAvailableMergeFields] = useState([])

  const _getMergeFields = useCallback(async () => {
    if (appProject) {
      await getMergeFields(appProject, "emailTemplate")
        .then((res) => {
          setAvailableMergeFields(res)
        })
        .catch((error) => {
          console.error("error occur at getMergeFields: ", error)
        })
    }
  }, [appProject])

  useEffect(() => {
    _getMergeFields()
  }, [_getMergeFields])

  const mergeTags = useMemo(() => {
    if (!availableMergeFields?.length) return {}

    return availableMergeFields.reduce((acc, field) => {
      acc[field.key] = {
        ...field,
        key: `{{${field.key}}}`,
      }
      return acc
    }, {})
  }, [availableMergeFields])

  const fetchEmailTemplateById = useCallback(async () => {
    if (!templateId) return
    setIsTemplateLoading(true)

    try {
      const response = await getEmailTemplates(templateId)
      setTemplate(response.data)
    } catch (error) {
      console.error("Error fetching/creating template:", error)
      setTemplate(null)
    } finally {
      setIsTemplateLoading(false)
    }
  }, [templateId])

  useEffect(() => {
    fetchEmailTemplateById()
  }, [fetchEmailTemplateById])

  const editTemplate = useCallback(async () => {
    if (emailEditor && template?._id) {
      setIsSaving(true)
      await emailEditor.exportHtml(async (data) => {
        await createOrUpdateEmailTemplates({
          ...template,
          unlayerConfig: data,
        })
          .then((res) => onSuccess(res))
          .catch((err) => { console.log(err) })
          .finally(() => setIsSaving(false))
      })
    }
  }, [emailEditor, template])

  const onLoad = useCallback((editor) => {
    setEmailEditor(editor)
  }, [])

  useEffect(() => {
    if (emailEditor && template?.unlayerConfig?.design) {
      emailEditor.loadDesign(template.unlayerConfig.design)
    }
  }, [emailEditor, template])

  useEffect(() => {
    if (emailEditor && mergeTags) {
      emailEditor.setMergeTags(mergeTags)
    }
  }, [emailEditor, mergeTags])

  const memoizedEmailTemplate = useMemo(() => {
    if (!template) return null
    return {
      _id: template._id,
      name: template.name,
      subject: template.subject,
    }
  }, [template])

  const RightContent = useMemo(
    () => () => (
      <>
        <CustomButton
          variant="ghost"
          handleClick={() => {
            onBack ? onBack(template?.sendGridTemplateId) : history.goBack()
          }}
          disabled={isSaving}
        >
          <span className="font-medium py-2 px-6 text-base flex gap-2 items-center">{goBackBtnText}</span>
        </CustomButton>
        {!showContentAsModal && (
          <>
            <CustomButton
              variant="outline"
              handleClick={() => setIsSliderOpen(true)}
              disabled={isSaving || !(template?.name && template?.subject)}
            >
              <span className="font-medium py-2 px-6 text-base flex gap-2 items-center">Create Campaign</span>
            </CustomButton>
          </>
        )}
        <CustomButton variant="outline" handleClick={() => setIsSendTestEmailOpen(true)} disabled={isSaving}>
          <span className="font-medium py-2 px-6 text-base flex gap-2 items-center">
            <SendHorizontal size={16} />
            Test
          </span>
        </CustomButton>
        <CustomButton variant="outline" handleClick={() => setIsEmailsPreviewOpen(true)} disabled={isSaving}>
          <span className="font-medium py-2 px-6 text-base flex gap-2 items-center">
            <EyeIcon size={16} />
            Preview
          </span>
        </CustomButton>
        <CustomButton handleClick={editTemplate} disabled={isSaving || !(template?.name && template?.subject)}>
          <span className="font-medium py-2 px-6 text-base flex gap-2 items-center">
            {isSaving && <Oval height="18" width="18" color="white" />}
            Save
          </span>
        </CustomButton>
      </>
    ),
    [template, isSaving, showContentAsModal, onBack, history, goBackBtnText, editTemplate]
  )

  if (isTemplateLoading) {
    return (
      <div className="w-full h-full bg-white flex flex-col">
        {/* Header */}
        <div className="border-b border-zinc-200 p-4 flex justify-between items-center">
          <div className="h-8 w-64 bg-zinc-200 animate-pulse rounded"></div>
          <div className="flex space-x-2">
            <div className="h-8 w-20 bg-zinc-200 animate-pulse rounded"></div>
            <div className="h-8 w-32 bg-zinc-200 animate-pulse rounded"></div>
            <div className="h-8 w-20 bg-zinc-200 animate-pulse rounded"></div>
            <div className="h-8 w-24 bg-zinc-200 animate-pulse rounded"></div>
            <div className="h-8 w-20 bg-zinc-200 animate-pulse rounded"></div>
          </div>
        </div>

        {/* Main content area */}
        <div className="flex-1 flex">
          {/* Left sidebar */}
          <div className="w-[350px] border-r border-zinc-200 p-4 space-y-4">
            <div>
              <div className="h-4 w-32 bg-zinc-200 animate-pulse rounded mb-2"></div>
              <div className="h-10 w-full bg-zinc-200 animate-pulse rounded"></div>
            </div>
            <div className="border-b border-zinc-200 pb-4">
              <div className="h-4 w-32 bg-zinc-200 animate-pulse rounded mb-2"></div>
              <div className="h-10 w-full bg-zinc-200 animate-pulse rounded"></div>
            </div>
            <div className="flex space-x-2 pt-4">
              <div className="h-8 w-28 bg-zinc-200 animate-pulse rounded"></div>
              <div className="h-8 w-28 bg-zinc-200 animate-pulse rounded"></div>
            </div>
            <div className="h-10 w-full bg-zinc-200 animate-pulse rounded"></div>

            {/* Recipient fields */}
            <div className="space-y-3 pt-1">
              {[...Array(8)].map((_, i) => (
                <div key={i} className="flex justify-between items-center">
                  <div className="h-4 w-32 bg-zinc-200 animate-pulse rounded"></div>
                  <div className="h-8 w-20 bg-zinc-200 animate-pulse rounded"></div>
                </div>
              ))}
            </div>
          </div>

          {/* Main editor area */}
          <div className="flex-1 p-4">
            <div className="border border-zinc-200 rounded-md h-[500px] flex flex-col items-center p-8 space-y-6">
              <div className="h-8 w-32 bg-zinc-200 animate-pulse rounded"></div>
              <div className="h-4 w-3/4 bg-zinc-200 animate-pulse rounded"></div>
              <div className="h-4 w-2/3 bg-zinc-200 animate-pulse rounded"></div>
              <div className="h-4 w-1/2 bg-zinc-200 animate-pulse rounded"></div>
              <div className="space-y-2 w-full pl-8">
                <div className="flex items-center">
                  <div className="h-3 w-3 bg-zinc-200 animate-pulse rounded-full mr-2"></div>
                  <div className="h-4 w-3/4 bg-zinc-200 animate-pulse rounded"></div>
                </div>
                <div className="flex items-center">
                  <div className="h-3 w-3 bg-zinc-200 animate-pulse rounded-full mr-2"></div>
                  <div className="h-4 w-2/3 bg-zinc-200 animate-pulse rounded"></div>
                </div>
              </div>
              <div className="h-4 w-1/4 bg-zinc-200 animate-pulse rounded"></div>
              <div className="h-4 w-1/3 bg-zinc-200 animate-pulse rounded"></div>
              <div className="h-4 w-1/4 bg-zinc-200 animate-pulse rounded"></div>
            </div>
          </div>

          {/* Right sidebar */}
          <div className="w-[425px] border-l border-zinc-200 p-4">
            <div className="grid grid-cols-3 gap-4">
              {[...Array(9)].map((_, i) => (
                <div key={i} className="flex flex-col items-center justify-center border border-zinc-200 p-4 h-24">
                  <div className="h-8 w-8 bg-zinc-200 animate-pulse rounded mb-2"></div>
                  <div className="h-4 w-16 bg-zinc-200 animate-pulse rounded"></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (!template) {
    return (
      <div className="h-full w-full bg-white rounded-lg p-8 flex flex-col items-center justify-center text-center">
        <div className="bg-zinc-100 rounded-full p-4 mb-4">
          <Mail className="h-12 w-12 text-zinc-400" />
        </div>

        {templateId ? (
          <>
            <h2 className="text-xl font-semibold text-zinc-800 mb-2">No Email Templates Found</h2>
            <p className="text-zinc-500 mb-6 max-w-md">
              We couldn't find any email templates matching your criteria. Try creating a new template or adjusting your
              search.
            </p>
          </>
        ) : (
          <>
            <h2 className="text-xl font-semibold text-zinc-800 mb-2">
              An Error Occurred while creating the email template
            </h2>
            <p className="text-zinc-500 mb-6 max-w-md">Please try again later some time.</p>
          </>
        )}

        <div className="w-full flex justify-center items-center gap-4">
          <CustomButton
            variant="outline"
            handleClick={() => {
              onBack ? onBack(template?.sendGridTemplateId) : history.goBack()
            }}
            disabled={isSaving}
          >
            <span className="font-medium py-2 px-6 text-base flex gap-2 items-center">{goBackBtnText}</span>
          </CustomButton>
          {!templateId && (
            <CustomButton handleClick={fetchEmailTemplateById} disabled={isTemplateLoading}>
              <span className="font-medium py-2 px-6 text-base flex gap-2 items-center">
                {isTemplateLoading && <Oval height="18" width="18" color="white" />}
                Try again
              </span>
            </CustomButton>
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="flex flex-col relative h-full bg-white overflow-hidden">
        <div className="p-3 w-full flex items-center justify-between bg-white border-b gap-3 overflow-hidden">
          <h1 className="mb-0 font-semibold md:font-bold text-2xl truncate">
            {templateId ? "Edit" : "Create"} Email Template{template?.name ? `: ${template?.name}` : ""}
          </h1>
          <div className="flex-1 flex items-center justify-end gap-3">
            <RightContent />
          </div>
        </div>
        <div className="w-full flex flex-row flex-1 overflow-hidden">
          <div className="w-[350px] h-full flex flex-col bg-grey-8 border-r overflow-hidden">
            <div className="flex flex-col gap-3 border-b p-3">
              <CustomInput
                label="Email template Name *"
                placeholder="Enter email template name"
                labelClassName="mb-[6px] font-medium"
                value={template.name ?? ""}
                onChange={(e) => {
                  setTemplate({
                    ...template,
                    name: e.target.value,
                    unlayerTemplateName: e.target.value,
                  })
                }}
                type="text"
                disabled={isSaving}
                containerClasses="w-full"
                classes="!m-0"
              />
              <CustomInput
                label="Email Subject *"
                placeholder="Enter Subject"
                labelClassName="mb-[6px] font-medium"
                value={template.subject ?? ""}
                onChange={(e) => {
                  setTemplate({
                    ...template,
                    subject: e.target.value,
                  })
                }}
                type="text"
                disabled={isSaving}
                containerClasses="w-full"
                classes="!m-0"
              />
            </div>
            <div className="w-full flex-1 overflow-hidden">
              <MergeFieldsAndTemplates mergeTags={availableMergeFields} />
            </div>
          </div>
          {template && (
            <EmailEditor
              options={{
                displayMode: "email",
                mergeTags: {
                  "board.name": {
                    name: "Board Name",
                    value: "{{board.name}}",
                    sample: "{{board.name}}",
                  },
                },
              }}
              onReady={onLoad}
              appearance={{
                theme: "modern_light",
              }}
            />
          )}
        </div>
      </div>
      <CreateEmailCampaign
        onClose={() => setIsSliderOpen(false)}
        open={isSliderOpen}
        emailTemplate={memoizedEmailTemplate}
      />
      <SendTestEmail
        emailSubject={template?.subject}
        templateId={templateId}
        onClose={() => setIsSendTestEmailOpen(false)}
        open={isSendTestEmailOpen}
      />
      <EmailTemplatePreview
        selectedTemplate={template}
        open={isEmailsPreviewOpen}
        onClose={() => {
          setIsEmailsPreviewOpen(false)
        }}
      />
    </>
  )
}

export default CreateOrUpdateEmailTemplate

