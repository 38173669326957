import React, { useRef, useState } from 'react'
import {
  Bar,
  BarChart as RechartsBarChart,
  Line,
  LineChart as RechartsLineChart,
  Pie,
  PieChart as RechartsPieChart,
  Area,
  AreaChart as RechartsAreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell
} from 'recharts'
import { FileSpreadsheet, Image, MoreHorizontal } from 'lucide-react'

// Empty data message component
const EmptyDataMessage = ({ message = 'No data available for the selected time period.' }) => (
  <div className="h-full w-full flex items-center justify-center">
    <p className="text-gray-500 text-sm">{message}</p>
  </div>
)

// Loading skeleton for charts
const ChartSkeleton = () => (
  <div className="h-full w-full animate-pulse">
    <div className="h-full w-full bg-gray-200 rounded-md"></div>
  </div>
)

// Mock data indicator overlay - a centered message over the chart
export const MockDataOverlay = (props) => {
  // If position is "top", this component wraps its children
  if (props && props.position === 'top' && props.children) {
    return (
      <div className="relative">
        {props.children}
        <div className="absolute inset-0 flex items-center justify-center pointer-events-none z-10">
          <div className="absolute inset-0 bg-white/80 backdrop-blur-sm"></div>
          <div className="bg-white px-5 py-2.5 rounded shadow-md text-gray-700 font-medium border border-gray-200 text-center z-20">
          <p className="text-gray-500">No data available for the selected time period.</p>
          <p className="text-sm text-gray-400 mt-1">Try selecting a different time period or check back later.</p>
          </div>
        </div>
      </div>
    )
  }

  // Default behavior for charts and KPI cards
  return (
    <div className="absolute inset-0 flex items-center justify-center pointer-events-none z-10">
      <div className="absolute inset-0 bg-white/80 backdrop-blur-md"></div>
      <div className="bg-white px-5 py-2.5 rounded shadow-md text-gray-700 font-medium border border-gray-200 text-center z-20">
      <p className="text-gray-500">No data available for the selected time period.</p>
      <p className="text-sm text-gray-400 mt-1">Try selecting a different time period or check back later.</p>
      </div>
    </div>
  )
}

// ChartExportMenu component
const ChartExportMenu = ({ chartRef = null, data, chartTitle, className = '', standalone = false }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isExporting, setIsExporting] = useState(false)
  const menuRef = useRef(null)

  // Store references to all charts on the page to use for export
  const [chartRefs, setChartRefs] = useState([])

  // Find chart elements on mount and store them for export
  React.useEffect(() => {
    // Function to find chart container elements
    const findChartElements = () => {
      // Look for charts with matching titles or chart containers with data that matches
      const chartContainers = Array.from(document.querySelectorAll('.recharts-wrapper'))
        .filter(el => {
          const parentContainer = el.closest('[data-chart-title]')
          // Filter based on attributes or position
          return parentContainer && parentContainer.getAttribute('data-chart-title') === chartTitle
        })

      if (chartContainers.length > 0) {
        setChartRefs(chartContainers)
      }
    }

    // Find charts on mount and whenever the chart title changes
    findChartElements()

    return () => {
      setChartRefs([])
    }
  }, [chartTitle])

  // Close menu when clicking outside
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const exportAsCSV = () => {
    if (isExporting) return

    try {
      // Convert the chart data to CSV
      let csvContent = 'data:text/csv;charset=utf-8,'

      // Get all keys from the data objects for headers
      const allKeys = new Set()
      data.forEach(item => {
        Object.keys(item).forEach(key => allKeys.add(key))
      })

      // Create headers
      const headers = Array.from(allKeys)
      csvContent += headers.join(',') + '\n'

      // Add data rows
      data.forEach(item => {
        const row = headers.map(header => {
          const value = item[header]
          return value === undefined ? '' : typeof value === 'string' ? `"${value}"` : value
        })
        csvContent += row.join(',') + '\n'
      })

      // Create download link
      const encodedUri = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', `${chartTitle.replace(/\s+/g, '_')}_data.csv`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      setIsOpen(false)
    } catch (error) {
      console.error('Error exporting CSV:', error)
    } finally {
      setIsExporting(false)
    }
  }

  const exportAsImage = () => {
    if (isExporting) return

    try {
      // Find a chart element to export
      let chartContainer = null

      // First priority: use explicitly provided chart ref
      if (chartRef && chartRef.current) {
        chartContainer = chartRef.current
      } else if (chartRefs.length > 0) {
        chartContainer = chartRefs[0].closest('.recharts-wrapper') ||
                         chartRefs[0].parentElement.closest('.relative') ||
                         chartRefs[0]
      } else {
        // Look for chart elements with matching title attribute or content
        const allCharts = Array.from(document.querySelectorAll('[data-chart-title]'))

        for (const chart of allCharts) {
          if (chart.getAttribute('data-chart-title') === chartTitle) {
            const rechartsWrapper = chart.querySelector('.recharts-wrapper') ||
                                   chart.querySelector('.relative')
            if (rechartsWrapper) {
              chartContainer = rechartsWrapper
              break
            } else {
              // If we found the chart container but no wrapper, use the container itself
              chartContainer = chart
              break
            }
          }
        }

        // Look for map elements with specific IDs
        if (!chartContainer) {
          const mapContainers = ['#neighbourhood-map', '#purchaser-map', '#sales-funnel-chart', '#sales-costs-breakdown-chart']

          for (const selector of mapContainers) {
            const container = document.querySelector(selector)
            if (container) {
              const title = container.getAttribute('data-chart-title') ||
                           container.parentElement.querySelector('.text-sm, .text-2xl')?.textContent

              if (!title || title === chartTitle ||
                  title.includes(chartTitle) ||
                  chartTitle.includes(title)) {
                // For map containers, get the entire container
                chartContainer = container

                // For maps, we need to use the inner container that has the actual map
                const mapDiv = container.querySelector('.purchaser-map-container, .neighbourhood-map-container')
                if (mapDiv) {
                  chartContainer = mapDiv
                }

                break
              }
            }
          }
        }

        // Look for chart elements with matching parent card title
        if (!chartContainer) {
          const cardTitles = Array.from(document.querySelectorAll('.text-sm, .text-2xl'))

          for (const title of cardTitles) {
            if (title.textContent === chartTitle) {
              const card = title.closest('.overflow-hidden, .bg-white')
              if (card) {
                // Look for any recharts elements directly
                const chartElement = card.querySelector('.recharts-wrapper') ||
                                     card.querySelector('.recharts-surface') ||
                                     card.querySelector('.relative') ||
                                     card.querySelector('svg') ||
                                     card.querySelector('.purchaser-map-container') ||
                                     card.querySelector('.neighbourhood-map-container')

                if (chartElement) {
                  chartContainer = chartElement
                  break
                } else {
                  // If we found the card but no chart element, use the card's content
                  const content = card.querySelector('.p-0, .p-3, .p-4, .p-6') || card
                  chartContainer = content
                  break
                }
              }
            }
          }
        }

        // Last resort: look for any chart with the title in its ancestor hierarchy
        if (!chartContainer) {
          // Find all charts on the page
          const allChartElements = Array.from(document.querySelectorAll('.recharts-wrapper, .recharts-surface, svg, .purchaser-map-container, .neighbourhood-map-container'))

          for (const element of allChartElements) {
            // Check if any parent has our title
            let parent = element.parentElement
            let found = false
            while (parent) {
              if (parent.textContent && parent.textContent.includes(chartTitle)) {
                chartContainer = element
                found = true
                break
              }
              parent = parent.parentElement
            }
            if (found) break
          }
        }
      }

      // If we found a chart element, export it using html2canvas
      if (chartContainer) {
        import('html2canvas').then(html2canvas => {
          html2canvas.default(chartContainer, {
            backgroundColor: '#FFFFFF',
            scale: 2, // Higher resolution
            logging: true // Enable logging for debugging
          }).then(canvas => {
            // Create download link
            const link = document.createElement('a')
            link.download = `${chartTitle.replace(/\s+/g, '_')}_chart.png`
            link.href = canvas.toDataURL('image/png')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)

            setIsOpen(false)
            setIsExporting(false)
          }).catch(error => {
            console.error('html2canvas error:', error)
            // Fall back to table export on error
            exportAsTable()
          })
        }).catch(error => {
          console.error('Error importing html2canvas:', error)
          // Fall back to table export on import error
          exportAsTable()
        })
      } else {
        console.log('No chart container found, falling back to table export')
        exportAsTable()
      }
    } catch (error) {
      console.error('Error exporting image:', error)
      exportAsTable()
    }
  }

  // Separate table export as a fallback method
  const exportAsTable = () => {
    // Create a new canvas element
    const canvas = document.createElement('canvas')
    canvas.width = 800
    canvas.height = 500
    const ctx = canvas.getContext('2d')

    // Set background
    ctx.fillStyle = '#FFFFFF'
    ctx.fillRect(0, 0, canvas.width, canvas.height)

    // Draw title
    ctx.fillStyle = '#333333'
    ctx.font = 'bold 20px Arial'
    ctx.textAlign = 'center'
    ctx.fillText(chartTitle, canvas.width / 2, 40)

    // Draw a table representation of the data
    ctx.font = '14px Arial'
    ctx.textAlign = 'left'

    // Get all keys from the data objects
    const allKeys = new Set()
    data.forEach(item => {
      Object.keys(item).forEach(key => allKeys.add(key))
    })
    const headers = Array.from(allKeys)

    // Draw headers
    const cellPadding = 10
    const cellHeight = 30
    const tableStartY = 80
    const tableWidth = canvas.width - 100
    const cellWidth = tableWidth / headers.length

    // Draw table header
    ctx.fillStyle = '#F3F4F6'
    ctx.fillRect(50, tableStartY, tableWidth, cellHeight)

    // Draw header text
    ctx.fillStyle = '#333333'
    ctx.font = 'bold 14px Arial'
    headers.forEach((header, index) => {
      ctx.fillText(
        header,
        50 + index * cellWidth + cellPadding,
        tableStartY + cellHeight - cellPadding
      )
    })

    // Draw data rows
    data.forEach((row, rowIndex) => {
      const y = tableStartY + (rowIndex + 1) * cellHeight

      // Alternating row colors
      ctx.fillStyle = rowIndex % 2 === 0 ? '#FFFFFF' : '#F9FAFB'
      ctx.fillRect(50, y, tableWidth, cellHeight)

      // Draw cell values
      ctx.fillStyle = '#333333'
      ctx.font = '14px Arial'
      headers.forEach((header, colIndex) => {
        const value = row[header] !== undefined ? row[header].toString() : ''
        ctx.fillText(
          value,
          50 + colIndex * cellWidth + cellPadding,
          y + cellHeight - cellPadding
        )
      })
    })

    // Create border
    ctx.strokeStyle = '#E5E7EB'
    ctx.lineWidth = 1
    ctx.strokeRect(50, tableStartY, tableWidth, cellHeight * (data.length + 1))

    // Create download link
    const link = document.createElement('a')
    link.download = `${chartTitle.replace(/\s+/g, '_')}_data.png`
    link.href = canvas.toDataURL('image/png')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    setIsOpen(false)
    setIsExporting(false)
  }

  return (
    <div className={`relative ${className}`} ref={menuRef}>
      <button
        className="p-1 rounded-full hover:bg-gray-100 focus:outline-none"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
        disabled={isExporting}
      >
        <MoreHorizontal className="h-4 w-4" />
      </button>

      {isOpen && (
        <div className="absolute right-0 z-10 mt-1 w-48 rounded-md shadow-lg bg-white border border-gray-200">
          {isExporting
            ? (
            <div className="py-6 flex flex-col items-center justify-center">
              <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-500 mb-2"></div>
              <p className="text-sm text-gray-600">Exporting...</p>
            </div>
              )
            : (
            <div className="py-1" role="menu">
              <button
                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 group"
                onClick={() => {
                  setIsExporting(true)
                  exportAsCSV()
                }}
                disabled={isExporting}
              >
                <FileSpreadsheet className="h-4 w-4 mr-2 text-gray-400 group-hover:text-gray-500" />
                Export as CSV
              </button>
              <button
                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 group"
                onClick={() => {
                  setIsExporting(true)
                  exportAsImage()
                }}
                disabled={isExporting}
              >
                <Image className="h-4 w-4 mr-2 text-gray-400 group-hover:text-gray-500" />
                Export as Image
              </button>
            </div>
              )}
        </div>
      )}
    </div>
  )
}

export { ChartExportMenu }

export const BarChart = ({
  data,
  index,
  categories,
  colors = ['#3b82f6', '#10b981'],
  valueFormatter = (value) => value.toString(),
  layout = 'vertical',
  showLegend = true,
  showXAxis = true,
  showYAxis = true,
  className,
  isLoading = false,
  isEmpty = false,
  chartTitle = 'Chart',
  customTooltip = null
}) => {
  // Ensure layout is either "vertical" or "horizontal"
  const chartLayout = layout === 'vertical' ? 'vertical' : 'horizontal'
  const chartRef = useRef(null)

  if (isLoading) return <ChartSkeleton />
  if (isEmpty || !data || data.length === 0) {
    return (
      <div className={`${className} h-full`}>
        <EmptyDataMessage />
      </div>
    )
  }

  return (
    <div className={`${className} relative`} ref={chartRef} data-chart-title={chartTitle}>
      <ResponsiveContainer width='100%' height='100%'>
        <RechartsBarChart
          data={data}
          layout={chartLayout}
          margin={{
            top: 20,
            right: 30,
            left: chartLayout === 'vertical' ? 100 : 20,
            bottom: 20
          }}
        >
          {showXAxis && (
            <XAxis
              type={chartLayout === 'vertical' ? 'number' : 'category'}
              dataKey={chartLayout === 'vertical' ? undefined : index}
              tick={{ fontSize: 12 }}
              axisLine={{ stroke: '#E4E4E7' }}
              tickLine={{ stroke: '#E4E4E7' }}
              tickFormatter={chartLayout === 'vertical' ? valueFormatter : undefined}
            />
          )}
          {showYAxis && (
            <YAxis
              type={chartLayout === 'vertical' ? 'category' : 'number'}
              dataKey={chartLayout === 'vertical' ? index : undefined}
              tick={{ fontSize: 12 }}
              axisLine={{ stroke: '#E4E4E7' }}
              tickLine={{ stroke: '#E4E4E7' }}
              width={chartLayout === 'vertical' ? 100 : undefined}
            />
          )}
          <CartesianGrid strokeDasharray='3 3' stroke='#E4E4E7' />
          <Tooltip
            formatter={(value, name) => [valueFormatter(value), name]}
            contentStyle={{
              backgroundColor: 'white',
              borderColor: '#E4E4E7',
              borderRadius: '6px',
              fontSize: '12px'
            }}
            content={customTooltip}
          />
          {showLegend && <Legend wrapperStyle={{ fontSize: '12px' }} />}
          {categories.map((category, index) => (
            <Bar
              key={index}
              dataKey={category}
              fill={colors[index % colors.length]}
              radius={[4, 4, 0, 0]}
              barSize={20}
            />
          ))}
        </RechartsBarChart>
      </ResponsiveContainer>
    </div>
  )
}

export const LineChart = ({
  data,
  categories,
  index,
  colors = ['#3b82f6'],
  valueFormatter = (value) => value.toString(),
  showLegend = true,
  showXAxis = true,
  showYAxis = true,
  showGridLines = true,
  className,
  isLoading = false,
  isEmpty = false,
  chartTitle = 'Chart'
}) => {
  const chartRef = useRef(null)

  if (isLoading) return <ChartSkeleton />
  if (isEmpty || !data || data.length === 0) {
    return (
      <div className={`${className} h-full`}>
        <EmptyDataMessage />
      </div>
    )
  }

  return (
    <div className={`${className} relative`} ref={chartRef} data-chart-title={chartTitle}>
      <ResponsiveContainer width='100%' height='100%'>
        <RechartsLineChart data={data} margin={{ top: 10, right: 10, left: 10, bottom: 10 }}>
          {showXAxis && (
            <XAxis
              dataKey={index}
              tick={{ fontSize: 12 }}
              axisLine={{ stroke: '#E4E4E7' }}
              tickLine={{ stroke: '#E4E4E7' }}
            />
          )}
          {showYAxis && (
            <YAxis
              tick={{ fontSize: 12 }}
              axisLine={{ stroke: '#E4E4E7' }}
              tickLine={{ stroke: '#E4E4E7' }}
            />
          )}
          {showGridLines && <CartesianGrid strokeDasharray='3 3' stroke='#E4E4E7' />}
          <Tooltip
            formatter={(value, name) => [valueFormatter(value), name]}
            contentStyle={{
              backgroundColor: 'white',
              borderColor: '#E4E4E7',
              borderRadius: '6px',
              fontSize: '12px'
            }}
          />
          {showLegend && <Legend wrapperStyle={{ fontSize: '12px' }} />}
          {categories.map((category, index) => (
            <Line
              key={index}
              type='monotone'
              dataKey={category}
              stroke={colors[index % colors.length]}
              activeDot={{ r: 6 }}
              strokeWidth={2}
            />
          ))}
        </RechartsLineChart>
      </ResponsiveContainer>
    </div>
  )
}

export const PieChart = ({
  data,
  category,
  index,
  colors = ['#3b82f6', '#06b6d4', '#4f46e5', '#7c3aed'],
  valueFormatter = (value) => value.toString(),
  className,
  isLoading = false,
  isEmpty = false,
  chartTitle = 'Chart'
}) => {
  const chartRef = useRef(null)

  if (isLoading) return <ChartSkeleton />
  if (isEmpty || !data || data.length === 0) {
    return (
      <div className={`${className} h-full`}>
        <EmptyDataMessage />
      </div>
    )
  }

  const RADIAN = Math.PI / 180
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, name }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text
        x={x}
        y={y}
        fill='white'
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline='central'
        fontSize={12}
        fontWeight='bold'
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }

  return (
    <div className={`${className} relative`} ref={chartRef} data-chart-title={chartTitle}>
      <ResponsiveContainer width="100%" height="100%">
        <RechartsPieChart>
          <Pie
            data={data}
            cx='50%'
            cy='50%'
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius='80%'
            dataKey={category}
            nameKey={index}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
          </Pie>
          <Tooltip
            formatter={(value) => [valueFormatter(value), data.find(item => item[category] === value)?.[index]]}
            contentStyle={{
              backgroundColor: 'white',
              borderColor: '#E4E4E7',
              borderRadius: '6px',
              fontSize: '12px'
            }}
          />
          <Legend
            layout='horizontal'
            verticalAlign='bottom'
            align='center'
            wrapperStyle={{ fontSize: '12px', paddingTop: '10px' }}
          />
        </RechartsPieChart>
      </ResponsiveContainer>
    </div>
  )
}

export const DonutChart = ({
  data,
  category,
  index,
  colors = ['#3b82f6', '#06b6d4', '#4f46e5', '#7c3aed'],
  valueFormatter = (value) => value.toString(),
  showAnimation = true,
  className,
  isLoading = false,
  isEmpty = false,
  chartTitle = 'Chart'
}) => {
  const chartRef = useRef(null)

  if (isLoading) return <ChartSkeleton />
  if (isEmpty || !data || data.length === 0) {
    return (
      <div className={`${className} h-full`}>
        <EmptyDataMessage />
      </div>
    )
  }

  return (
    <div className={`${className} relative`} ref={chartRef} data-chart-title={chartTitle}>
      <ResponsiveContainer width="100%" height="100%">
        <RechartsPieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius="60%"
            outerRadius="80%"
            dataKey={category}
            nameKey={index}
            paddingAngle={2}
            isAnimationActive={showAnimation}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
          </Pie>
          <Tooltip
            formatter={(value) => [valueFormatter(value), data.find(item => item[category] === value)?.[index]]}
            contentStyle={{
              backgroundColor: 'white',
              borderColor: '#E4E4E7',
              borderRadius: '6px',
              fontSize: '12px'
            }}
          />
          <Legend
            layout="horizontal"
            verticalAlign="bottom"
            align="center"
            wrapperStyle={{ fontSize: '12px', paddingTop: '10px' }}
          />
        </RechartsPieChart>
      </ResponsiveContainer>
    </div>
  )
}

export const AreaChart = ({
  data,
  index,
  categories,
  colors = ['#3b82f6'],
  valueFormatter = (value) => value.toString(),
  showLegend = true,
  showXAxis = true,
  showYAxis = true,
  className,
  isLoading = false,
  isEmpty = false,
  chartTitle = 'Chart'
}) => {
  const chartRef = useRef(null)

  if (isLoading) return <ChartSkeleton />
  if (isEmpty || !data || data.length === 0) {
    return (
      <div className={`${className} h-full`}>
        <EmptyDataMessage />
      </div>
    )
  }

  return (
    <div className={`${className} relative`} ref={chartRef} data-chart-title={chartTitle}>
      <ResponsiveContainer width='100%' height='100%'>
        <RechartsAreaChart data={data} margin={{ top: 10, right: 10, left: 10, bottom: 10 }}>
          <CartesianGrid strokeDasharray='3 3' stroke='#E4E4E7' />
          {showXAxis && (
            <XAxis
              dataKey={index}
              tick={{ fontSize: 12 }}
              axisLine={{ stroke: '#E4E4E7' }}
              tickLine={{ stroke: '#E4E4E7' }}
            />
          )}
          {showYAxis && (
            <YAxis
              tick={{ fontSize: 12 }}
              axisLine={{ stroke: '#E4E4E7' }}
              tickLine={{ stroke: '#E4E4E7' }}
            />
          )}
          <Tooltip
            formatter={(value, name) => [valueFormatter(value), name]}
            contentStyle={{
              backgroundColor: 'white',
              borderColor: '#E4E4E7',
              borderRadius: '6px',
              fontSize: '12px'
            }}
          />
          {showLegend && <Legend wrapperStyle={{ fontSize: '12px' }} />}
          {categories.map((category, index) => (
            <Area
              key={index}
              type='monotone'
              dataKey={category}
              fill={colors[index % colors.length]}
              stroke={colors[index % colors.length]}
              fillOpacity={0.4}
            />
          ))}
        </RechartsAreaChart>
      </ResponsiveContainer>
    </div>
  )
}
