/* eslint-disable */
// @ts-nocheck
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
  useContext,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import { MENU_CATEGORY } from "./constants";

import CategoryButton from "./CategoryButton";
import MenuItem from "./MenuItem";

import { getProjects } from "store/actions/projectActions";
import { UPDATE_CUSTOM_SECTIONS } from "store/actions/actionTypes";
import useSideBarMenuV2 from "./useSideBarMenuV2";
import useCorporateSideBarMenu from "./useCorporateSideBarMenu";
import {
  ADMIN_CREATE_PROJECT,
  ADMIN_DASHBOARD_CORPORATE,
  ADMIN_DASHBOARD_SALESREP,
  ADMIN_DASHBOARD_WHITECAP,
  ADMIN_PROJECT_MANAGEMENT,
  CORPORATE_VIEW,
  LOGOUT,
} from "routes";
import { isMobile } from "react-device-detect";
import CollapseSideBarButton from "components/CollpaseSidebarButton";
import { Switch } from "ui";
import { HOVER_TOGGLER } from "store/actions/actionTypes";
import standardStyle from "assets/css/standardStyle";
import { ChevronDown, Plus } from "lucide-react";
import { Dropdown, Space } from "antd";
import useContactContext from "pages/newContacts/hooks/useContactContext";
import { TLogoutIcon } from "components/Icons";
import CustomModal from "ui/customModal";
import CustomButton from "components/CustomButton";
import { useIntercom } from 'react-use-intercom'
import { ContactContext } from "pages/contacts/context/Contacts";
import mixpanel, { MIXPANEL_EVENTS } from "services/mixpanel";

const AdminSideBar = (props) => {
  const didMount = useRef(false);
  let initAppProjectId = null;
  const { projects, changeAppProject, appProject, handleLogout } = props;
  const userType = useSelector(
    (state) => state.authReducer.userObject.userType
  );
  const user = useSelector((state) => state.authReducer.userObject);
  const { view, setView } = useContactContext();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { SIDE_BAR_MENU_FILTERED, getCategory } = useSideBarMenuV2();
  const { CORPORATE_SIDE_BAR_MENU, getCategory: getCorporateCategory } = useCorporateSideBarMenu();
  const [isCorporateView, setIsCorporateView] = useState(
    location.pathname.includes('corporate') ? true : false
  );
  const activeCategory = isCorporateView
    ? getCorporateCategory()
    : getCategory(location.pathname);
  const [selectedCategory, setSelectedCategory] = useState(
    activeCategory?.value || MENU_CATEGORY[0].value
  );
  const [selectedItem, setSelectedItem] = useState(location.pathname);

  const [projectSelectedTab, setProjectSelectedTab] = useState(
    ADMIN_PROJECT_MANAGEMENT
  );
  const [selectedGeneralTab, setSelectedGeneralTab] = useState(
    userType === "SalesRep"
      ? ADMIN_DASHBOARD_SALESREP
      : ADMIN_DASHBOARD_WHITECAP
  );
  const [selectedProject, setSelectedProject] = useState(null);
  const show = useSelector((state) => state.appReducer.show);
  const isHover = useSelector((state) => state.appReducer.isHover);
  const { setFilters, LIMIT, setPage } = useContactContext();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const handleHoverToggle = () => {
    dispatch({ type: HOVER_TOGGLER, payload: isHover ? false : true });
  };
  const { shutdown } = useIntercom()

  useEffect(() => {
    if (selectedCategory === "project") {
      setSelectedItem(projectSelectedTab);
      history.push({
        pathname: projectSelectedTab,
      });
    } else {
      setSelectedItem(selectedGeneralTab);
      history.push({
        pathname: selectedGeneralTab,
      });
    }
  }, [selectedCategory]);

  useEffect(() => {
    getProjects(appProject).then((project) => {
      const { customSections = [] } = project;
      dispatch({
        type: UPDATE_CUSTOM_SECTIONS,
        payload: customSections?.filter((e) => !e?.isDeleted),
      });
    });
    setSelectedProject(
      projects.filter((_project) => appProject === _project?._id)[0]
    );
  }, [appProject]);

  useEffect(() => {
    if (selectedCategory === "project") {
      setSelectedItem(selectedItem);
      history.push({
        pathname: selectedItem,
      });
    } else {
      setSelectedItem(selectedItem);
      const params = new URLSearchParams(location.search).toString();
      history.push({
        pathname: selectedItem,
        search: params,
      });
    }
  }, []);

  useEffect(() => {
    if (selectedCategory === "general") {
      if (location.search.includes("tab")) {
        setSelectedItem("/admin/contacts");
      }
    }
    if(location.pathname.includes('corporate')) {
      setView('corporate');
    }
  }, []);

  useEffect(() => {
    if (selectedItem.indexOf("project") > -1 ) {
      setProjectSelectedTab(selectedItem);
    } else {
      setSelectedGeneralTab(selectedItem);
    }
  }, [selectedItem]);

  const filteredCategories = MENU_CATEGORY.filter((category) => {
    if (category.key === "general") {
      return SIDE_BAR_MENU_FILTERED.general.length > 0;
    } else if (category.key === "project") {
      return SIDE_BAR_MENU_FILTERED.project.length > 0;
    }
    return true;
  });
  const totalCategories = filteredCategories.length;

  useEffect(() => {
    if (appProject !== initAppProjectId) {
      if (!didMount.current) {
        initAppProjectId = appProject;
        didMount.current = true;
      }
    }
  }, [appProject]);

  useEffect(() => {
    if (!didMount.current) return;
    if (appProject) return;
    if (!projects || !projects.length) return;
    changeAppProject({
      projectId: projects?.[0]?._id,
      developerId: projects?.[0]?.developerId,
      newProject: false,
    });
  }, [appProject, projects, changeAppProject]);

  const onChangeAppProject = (project) => {
    if (project === 'corporate') {
      setIsCorporateView(true);
      history.push(CORPORATE_SIDE_BAR_MENU.corporate[0].route);
      setView('corporate');
    } else {
      if(view === 'corporate') {
        history.push(userType === 'SalesRep' ? ADMIN_DASHBOARD_SALESREP : ADMIN_DASHBOARD_WHITECAP);
      }
      setView('project');
      const projectId = project._id;
      const developerId = projects.filter(
        (project) => project._id === projectId
      )[0].developerId;
      changeAppProject({ projectId, developerId, newProject: false });
      setPage(1);
      setFilters({
        limit: LIMIT,
        page: 0,
        project: projectId,
        sortType: "desc",
      });
      setIsCorporateView(false)
      const newCategory = getCategory(location.pathname)?.value || MENU_CATEGORY[0].value;
      setSelectedCategory(newCategory);
    }
  };

  const logout = useCallback(() => {
    handleLogout();
    history.push(LOGOUT);
    shutdown();
  }, [handleLogout, history]);

  const confirmLogoutModal = useCallback(() => {
    return (
      <CustomModal
        showModal={showLogoutModal}
        onCancel={() => setShowLogoutModal(false)}
        onOk={logout}
        minHeight={false}
      >
        <div className="min-w-[95%] md:min-w-[600px] md:min-h-[250px] flex flex-col gap-3">
          <h2 className="text-2xl mb-0">Are you sure?</h2>
          <h4 className="text-xl mb-2">Do you want to quit?</h4>
          <div className="flex-1 w-full mt-2 flex justify-center md:justify-end items-end gap-3">
            <CustomButton
              variant="ghost"
              handleClick={() => setShowLogoutModal(false)}
              className="min-w-[150px]"
            >
              <span className="font-medium py-2 px-4 text-base flex gap-2 items-center">
                Cancel
              </span>
            </CustomButton>
            <CustomButton variant="danger" handleClick={logout} className="min-w-[150px]">
              <span className="font-medium py-2 px-4 text-base flex gap-2 items-center">
                Yes
              </span>
            </CustomButton>
          </div>
        </div>
      </CustomModal>
    );
  }, [logout, showLogoutModal, setShowLogoutModal]);

  const createEditPage = (_id, pathname) => {
    history.push({
      pathname,
      state: {
        _id,
      },
    });
  };

  const items = useMemo(() => {
    const projectsUI = projects.map((project, index) => {
      return {
        key: index,
        label: (
          <div
            className="flex items-center gap-2 min-w-[260px]"
            onClick={(e) => {
              onChangeAppProject(project)

              mixpanel.setCustomDetails({
                project: project._id
              })

              mixpanel.track(MIXPANEL_EVENTS.PROJECT_SWITCHED)
            }}
          >
            <div className="h-9 w-9 flex justify-center text-lg items-center bg-softBlack text-white rounded-md">
              {project?.projectName?.[0]}
            </div>
            <h2 className="text-softBlack text-lg font-medium mb-0">
              {project?.projectName?.length >= 25
                ? project?.projectName.slice(0, 25) + "..."
                : project?.projectName}
            </h2>
          </div>
        ),
      };
    });
    const additionalItems = [
      ...(userType === "DeveloperAdmin"
        ? [
            {
              key: "create-project",
              label: (
                <span
                  className="flex items-center gap-2 text-base font-medium"
                  onClick={(e) => {
                    changeAppProject({
                      projectId: projects?.[0]?._id,
                      developerId: projects?.[0]?.developerId,
                      newProject: true,
                    });
                    createEditPage("", ADMIN_CREATE_PROJECT);
                  }}
                >
                  <Plus className="w-5 h-5" />
                  Create new project
                </span>
              ),
            },
          ]
        : []),
      {
        type: "divider",
      },
      ...((userType === "DeveloperAdmin" || userType === 'SalesRep') && user?.corporateProjectShow === true || user?.corporateProjectShow === undefined
        ? [
            {
              key: "corporate-view",
              label: (
                <div
                  className="flex items-center gap-2 min-w-[260px]"
                  onClick={(e) => {
                    onChangeAppProject('corporate');
                    mixpanel.track(MIXPANEL_EVENTS.PROJECT_SWITCHED, {
                      project: 'corporate'
                    })
                  }}
                >
                  <div className="h-9 w-9 flex justify-center text-lg items-center bg-softBlack text-white rounded-md">
                    C
                  </div>
                  <h2 className="text-softBlack text-lg font-medium mb-0">
                    Corporate View
                  </h2>
                </div>
              ),
            },
          ]
        : []),
      {
        type: user?.corporateProjectShow === true ? "divider" : null,
      },
      {
        key: "logout",
        label: (
          <span
            className="flex items-center gap-2 text-base font-medium"
            onClick={() => setShowLogoutModal(true)}
          >
            <TLogoutIcon className="w-5 h-5" />
            Log out
          </span>
        ),
      },
    ];
    return [...projectsUI, ...additionalItems];
  }, [projects, onChangeAppProject]);

  const {
    closeContactDetailDrawer,
    state
  } = useContactContext();

  const {
    setSelectedContact,
  } = useContext(ContactContext);

  const handleContainerClick = (event) => {
    if (!state.isContactDetailDrawerOpen) return;
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete('_id');
      const newSearch = searchParams.toString();
      const newPath = history.location.pathname 
      closeContactDetailDrawer(newPath);
      setSelectedContact(null);
  };


  return (
    <div
      onClick={handleContainerClick}
      className={`h-full overflow-hidden bg-white ${
        isMobile ? "w-full" : "w-70.25"
      } ml-0 relative transition-all ease-in-out ${
        show && !isMobile ? "border-r shadow-lg" : ""
      } flex flex-col`}
    >
      <div className="flex flex-col p-3 overflow-hidden">
        <CollapseSideBarButton />
        {selectedProject !== null && (
          <Dropdown
            menu={{ items }}
            trigger={["click"]}
            placement="bottomLeft"
            className="cursor-pointer"
          >
            <Space className="flex items-center justify-between gap-2 p-1 px-2 hover:bg-zinc-100 transition-all cursor-pointer rounded-md mr-8 overflow-hidden">
              <div className="flex items-center gap-2 truncate">
              <div className="h-6 w-6 flex justify-center text-base items-center bg-softBlack text-white rounded-[4px]">
                {isCorporateView ? 'C' : selectedProject?.projectName?.[0]}
              </div>
              <h2 className="text-softBlack text-base font-medium mb-0 truncate">
                {isCorporateView
                  ? 'Corporate View'
                  : selectedProject?.projectName?.length >= 19
                  ? selectedProject?.projectName.slice(0, 18) + "..."
                  : selectedProject?.projectName}
              </h2>
              </div>
              <ChevronDown className="w-5 h-5" />
            </Space>
          </Dropdown>
        )}
        {totalCategories > 1 && !isCorporateView && (
          <div className="mt-4 rounded h-9">
            {filteredCategories.map((category, index) => (
              <CategoryButton
                key={category.title}
                category={category}
                selected={selectedCategory === category?.value}
                setSelectedCategory={setSelectedCategory}
                totalCategories={totalCategories}
              >
                {category.title}
              </CategoryButton>
            ))}
          </div>
        )}
      </div>

      <div className="flex-1 overflow-y-auto thin-scrollbar p-3 pt-0">
        {isCorporateView ? (
          // Render simple sidebar for corporate view
          CORPORATE_SIDE_BAR_MENU.corporate.map((_menu, menuIndex) => (
            <MenuItem
              menu={_menu}
              key={menuIndex}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
            />
          ))
        ) : (
          // Render regular sidebar for other views
          SIDE_BAR_MENU_FILTERED[selectedCategory].map((_section, index) => (
            <div key={index}>
              <div className="flex flex-col">
                {_section?.menu?.map?.((_menu, menuIndex) =>
                  _menu?.key === "team" && userType === "SalesRep" ? null : (
                    <MenuItem
                      menu={_menu}
                      key={menuIndex}
                      selectedItem={selectedItem}
                      setSelectedItem={setSelectedItem}
                    />
                  )
                )}
              </div>
              <div
                className={`my-2 h-px w-full bg-softBlack30 ${
                  index === SIDE_BAR_MENU_FILTERED?.[selectedCategory]?.length - 1
                    ? "invisible"
                    : "visible"
                }`}
              />
            </div>
          ))
        )}
      </div>
      {/* hover toggler button */}
      <div className="p-3 flex items-center justify-between border-t">
        <h1 className="text-softBlack text-base font-semibold mb-0">
          Hover toggler
        </h1>
        <Switch
          checked={isHover}
          onChange={() => handleHoverToggle()}
          style={{
            background: isHover && standardStyle.colors.softBlack,
            outline: "1px solid black",
          }}
        />
      </div>
      {confirmLogoutModal()}
    </div>
  );
};

export default AdminSideBar;
