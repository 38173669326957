/* eslint-disable */
// @ts-nocheck
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { getEmailTemplates } from 'store/actions/emailTemplateActions'

export function useEmailTemplates() {
  const {
    data: templates = [],
    isLoading,
    error,
    refetch
  } = useQuery({
    queryKey: ['emailTemplates'],
    queryFn: async () => {
      const response = await getEmailTemplates()
      return response
        .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
        .map((elm) => ({
          ...elm,
          label: elm.name,
          value: elm.sendGridTemplateId
        }))
    },
  })

  return {
    templates,
    isLoading,
    error,
    refetch
  }
}
