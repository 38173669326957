// @ts-nocheck
/* eslint-disable */
import React from 'react'
import AdminGoogleAdsReports from 'pages/desktopMode/googleAdsReport'
const GoogleAdsTab = ({ userObject, selectedPeriod, customStartDate, customEndDate}) => {
    return (
        <AdminGoogleAdsReports isDashboard={true} selectedPeriod={selectedPeriod} customStartDate={customStartDate} customEndDate={customEndDate} userObject={userObject} />
    )
}

export default GoogleAdsTab 