/* eslint-disable */
// @ts-nocheck
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query"
import { useState, useMemo } from "react"
import { getEvents, deleteEvent as deleteEventApi } from "store/actions/eventsActions"

export const EVENT_CATEGORY = [
  { title: "Upcoming", key: "upcoming", value: "upcoming" },
  { title: "Past", key: "past", value: "past" },
  { title: "Archived", key: "archived", value: "archived" },
]

export const useGetEvents = () => {
  const queryClient = useQueryClient()
  const [isEventDeleting, setIsEventDeleting] = useState(false)
  const [eventToBeDeleted, setEventToBeDeleted] = useState(null)

  const {
    data: events,
    isLoading,
    error,
  } = useQuery(["get-all-events"], () => getEvents(), {
    staleTime: 5 * 60 * 1000,
  })

  const deleteEventMutation = useMutation({
    mutationFn: deleteEventApi,
    onMutate: () => setIsEventDeleting(true),
    onSettled: (_, error, eventId) => {
      setIsEventDeleting(false)
      setEventToBeDeleted(null)
      if (!error) {
        queryClient.invalidateQueries(["get-all-events"])
      }
    },
  })

  const categorizedEvents = useMemo(() => {
    if (!events || !events?.length) return { upcoming: [], past: [], archived: [] }

    const currentDate = new Date().getTime()
    return events.reduce(
      (acc, event) => {
        const endDate = new Date(event.endDate).getTime()
        if (event.isDeleted) {
          acc.archived.push(event)
        } else if (endDate > currentDate) {
          acc.upcoming.push(event)
        } else {
          acc.past.push(event)
        }
        return acc
      },
      { upcoming: [], past: [], archived: [] },
    )
  }, [events])

  const refetchEvents = () => {
    queryClient.invalidateQueries(["get-all-events"])
  }

  const deleteEvent = () => {
    if (!eventToBeDeleted?._id) return

    setIsEventDeleting(true)
    deleteEventMutation.mutate(eventToBeDeleted._id)
  }

  return {
    allEvents: categorizedEvents,
    isFetchingEvents: isLoading,
    error,
    refetchEvents,
    EVENT_CATEGORY,
    deleteEvent,
    isEventDeleting,
    eventToBeDeleted,
    setEventToBeDeleted
  }
}
