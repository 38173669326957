/* eslint-disable */

import { Shield, Save } from "lucide-react"
import { Switch } from "antd"
import { useState } from "react"
import CustomButton from "components/CustomButton"
import standardStyle from "assets/css/standardStyle"

const CustomSwitch = ({ ...props }) => {
  return (
    <Switch
      style={{
        background: props.checked && standardStyle.colors.softBlack,
      }}
      {...props}
    />
  )
}

const ProjectLeadsAssignmentSettings = () => {
  // State for form values
  const [activeSource, setActiveSource] = useState("registration")
  const [assignmentMethod, setAssignmentMethod] = useState("skill-based")
  const [toggleStates, setToggleStates] = useState({
    notifyManager: true,
    notifySalesRep: true,
    privateAssignments: true,
    privateEmails: true,
  })

  // Data for tabs
  const tabOptions = [
    { id: "registration", label: "Registration Forms" },
    { id: "csv", label: "CSV Uploads" },
    { id: "api", label: "API Imports" },
  ]

  // Data for assignment methods
  const assignmentMethods = [
    {
      id: "manual",
      title: "Manual Assignment",
      description: "Manager reviews and manually assigns each lead to sales representatives",
    },
    {
      id: "round-robin",
      title: "Round Robin Assignment",
      description: "Automatically distribute leads equally among all sales representatives",
    },
    {
      id: "skill-based",
      title: "Skill-Based Matching",
      description: "Automatically assign leads based on matching sales rep language skills and other attributes",
    },
  ]

  // Data for notification settings
  const notificationSettings = [
    {
      id: "notifyManager",
      title: "Notify Manager of New Leads",
      description: "Send email notifications to managers when new leads are received",
    },
    {
      id: "notifySalesRep",
      title: "Notify Sales Rep When Assigned",
      description: "Send email notifications to sales reps when leads are assigned to them",
    },
  ]

  // Data for privacy settings
  const privacySettings = [
    {
      id: "privateAssignments",
      title: "Private Lead Assignments",
      description: "Hide information about which leads are assigned to which sales representatives",
    },
    {
      id: "privateEmails",
      title: "Private Email Access",
      description: "Restrict sales representatives from viewing emails sent by other team members",
    },
  ]

  // Handle toggle change
  const handleToggleChange = (id, checked) => {
    setToggleStates((prev) => ({
      ...prev,
      [id]: checked,
    }))
  }

  return (
    <div className="min-h-screen p-6 bg-[#F6F5F1] container mx-auto">
      <h1 className="text-2xl font-semibold text-zinc-900 mb-6">Lead Assignment Settings</h1>

      {/* Lead Source Configuration Card */}
      <div className="bg-white rounded-xl shadow-sm mb-6">
        <div className="p-6 pb-3">
          <h2 className="text-lg font-medium text-zinc-900">Lead Source Configuration</h2>
          <p className="text-sm text-[#666666] mt-1">Configure how leads are assigned based on their source</p>
        </div>

        {/* Tabs */}
        <div className="bg-zinc-100 rounded-md mx-6 p-1.5 flex gap-2">
          {tabOptions.map((tab) => (
            <button
              key={tab.id}
              className={`py-1.5 flex-1 text-center text-sm font-medium transition-all rounded-md focus:outline-none focus:ring-0
                  ${activeSource === tab.id
                  ? "!bg-white text-zinc-900 shadow-md"
                  : "text-zinc-700 hover:bg-gray-100"
                }`}
              onClick={() => setActiveSource(tab.id)}
            >
              {tab.label}
            </button>
          ))}
        </div>

        {/* Assignment Method */}
        <div className="p-6">
          <h3 className="text-base font-medium text-zinc-900 mb-4">Assignment Method</h3>

          <div className="space-y-3">
            {assignmentMethods.map((method) => (
              <div key={method.id} className="border border-zinc-800 rounded-lg p-3">
                <div className="flex items-start">
                  <input
                    type="radio"
                    id={method.id}
                    name="assignmentMethod"
                    value={method.id}
                    checked={assignmentMethod === method.id}
                    onChange={() => setAssignmentMethod(method.id)}
                    className="mt-1 mr-3"
                  />
                  <label htmlFor={method.id} className="flex-1 cursor-pointer m-0">
                    <div className="font-medium text-zinc-900">{method.title}</div>
                    <div className="text-sm text-zinc-800 mt-1">{method.description}</div>
                  </label>
                </div>
              </div>
            ))}
          </div>

          {/* Divider */}
          <div className="border-t border-zinc-400 my-6"></div>

          {/* Notification Settings */}
          <h3 className="text-base font-medium text-zinc-900 mb-4">Notification Settings</h3>

          <div className="space-y-4">
            {notificationSettings.map((setting) => (
              <div key={setting.id} className="flex items-center justify-between border border-zinc-800 rounded-lg p-3">
                <div className="flex-1">
                  <div className="font-medium text-zinc-900">{setting.title}</div>
                  <div className="text-sm text-[#666666] mt-1">{setting.description}</div>
                </div>
                <CustomSwitch
                  checked={toggleStates[setting.id]}
                  onChange={(checked) => handleToggleChange(setting.id, checked)}
                  className="ml-4"
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Privacy Settings Card */}
      <div className="bg-white rounded-xl shadow-sm mb-6">
        <div className="p-6 pb-3">
          <div className="flex items-center">
            <Shield className="h-5 w-5 mr-2 text-zinc-900" />
            <h2 className="text-lg font-medium text-zinc-900 mb-0">Privacy Settings</h2>
          </div>
          <p className="text-sm text-[#666666] mt-1">Control visibility of lead assignments and email communication</p>
        </div>

        <div className="p-6">
          <div className="space-y-4">
            {privacySettings.map((setting) => (
              <div key={setting.id} className="flex items-center justify-between border border-zinc-800 rounded-lg p-3">
                <div className="flex-1">
                  <div className="font-medium text-zinc-900">{setting.title}</div>
                  <div className="text-sm text-[#666666] mt-1">{setting.description}</div>
                </div>
                <CustomSwitch
                  checked={toggleStates[setting.id]}
                  onChange={(checked) => handleToggleChange(setting.id, checked)}
                  className="ml-4"
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Save Button */}
      <div className="flex justify-end">
        <CustomButton
          handleClick={() => { }}
        // disabled={}
        >
          <span className="flex items-center rounded px-4 py-2 text-sm font-medium">
            {/* {isSendingSMSBroadcast && <Oval height="18" width="18" color="white" />} */}
            <Save size={16} className="mr-2" />
            Save Settings
          </span>
        </CustomButton>
      </div>
    </div>
  )
}

export default ProjectLeadsAssignmentSettings

