// @ts-nocheck
import React, { useMemo, useState, useCallback, useEffect } from 'react'
import propTypes from 'prop-types'
import './style/style.css'
import { AdminPageWrapper, PageHeader } from 'components'
import { TFilledPlusIcon } from 'components/Icons'
import { connect } from 'react-redux'
import { Table } from 'ui'
import { formatDate } from 'utils'
import standardStyle from 'assets/css/standardStyle'
import { ReactComponent as UserCheck } from 'assets/icons/user-check.svg'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import CustomButton from 'components/CustomButton'
import { useHistory, withRouter } from 'react-router-dom'
import PauseResumeQueue from 'components/QueueManage'
import mixpanel, { MIXPANEL_EVENTS } from 'services/mixpanel'
import CreateSMSBroadcast from './CreateSMSBroadcast'
import { api, ApiErrors, getTokenRequestHeader } from 'services/api'

const TextField = ({ value }) => (
  <span className='font-medium text-base text-softBlack capitalize'>
    {value}
  </span>
)

const CardComponent = ({ IconSrc, title, count }) => (
  <div
    className='bg-white rounded'
    style={{ fontFamily: standardStyle.fonts.sfpro }}
  >
    <div className='p-6'>
      <div className='w-8 h-6'>{IconSrc}</div>
    </div>
    <div className='flex flex-col space-y-2 p-6'>
      <div className='text-xs text-softBlack_70 font-medium uppercase'>
        {title}
      </div>
      <div className='text-xl text-softBlack font-bold'>
        {count}
      </div>
    </div>
  </div>
)

// const demoTemplateMessages = [
//   {
//     title: 'Exclusive Event Invitation',
//     message: 'You are invited! Join us at our VIP Real Estate Event to explore exclusive offers and insights. RSVP now and be a part of something special. Details at www.1818pacifica.com'
//   },
//   {
//     title: 'Weekend Promo Extravaganza',
//     message: 'This weekend only! Special promotional deals on select properties. Do not miss out on these incredible savings. Visit www.1818pacifica.com for more information.'
//   },
//   {
//     title: 'Sales Launch Alert',
//     message: 'Be the first to know! Our latest property sales launch is happening soon. Exclusive early access for SMS subscribers. Stay updated at www.1818pacifica.com'
//   },
//   {
//     title: 'Discover Your Dream Home',
//     message: 'Your dream home awaits. Browse our latest listings and find the perfect match for your lifestyle. Start your journey at www.1818pacifica.com'
//   },
//   {
//     title: 'Presentation Centre Opening',
//     message: 'we are excited to announce the opening of our new Presentation Centre! Come and explore the future of living. For more details, visit www.1818pacifica.com'
//   },
//   {
//     title: 'Limited-Time Offer',
//     message: 'Act fast! Limited-time offer on our premium properties. Secure your dream home before its too late. Exclusive details at www.1818pacifica.com'
//   }
// ]

const SMSBroadcast = (props) => {
  const { appProject } = props
  const history = useHistory()
  const [smsBroadcastList, setSmsBroadcastList] = useState([])
  const [showCreateSMSBroadcast, setShowCreateSMSBroadcast] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const getAllSMSBroadcastList = useCallback(() => {
    return new Promise((resolve, reject) => {
      setIsLoading(true)
      const token = getTokenRequestHeader()
      // @ts-ignore
      api.setHeader('Authorization', token)

      api.get('sms/campaigns').then((response) => {
        if (response.ok) {
          resolve(response.data)
          setSmsBroadcastList(
            response.data.map((elm) => {
              const users = elm?.users ?? []
              const validNumbers = users?.filter((item) => {
                return item?.phoneNumber
              })
              const invalid = users?.filter((item) => {
                return !item?.phoneNumber
              })
              const uniqueReportUsers = elm?.report
                ?.map((e) => e.user?.toString())
                .filter((v, i, a) => a.indexOf(v) === i)
              const statusIndicatesDelivered = ['delivered', 'sent']
              const deliveredCount = elm?.report?.filter((message) =>
                statusIndicatesDelivered.includes(message.status)
              ).length
              const failedCount = elm?.report?.filter(
                (message) => !statusIndicatesDelivered.includes(message.status)
              ).length
              return {
                id: elm._id,
                broadcastName: elm.campaignName,
                sentDate: elm.createdAt || elm.updatedAt,
                totalUsers: users?.length || elm.report.length || 0,
                validUsers:
                  validNumbers?.length || uniqueReportUsers.length || 0,
                invalidUsers: invalid?.length || 0,
                numberOfRecipients: elm.report.length,
                delivered: deliveredCount,
                failed: failedCount
              }
            })
          )
        } else {
          ApiErrors(response)
          reject(response)
        }
        setIsLoading(false)
      })
    })
  }, [])

  useEffect(() => {
    getAllSMSBroadcastList()
  }, [getAllSMSBroadcastList])

  const RightContent = () => (
    <div className='flex flex-row items-center'>
      <PauseResumeQueue queue='sms-queue' />
      <CustomButton
        variant={showCreateSMSBroadcast ? 'outline' : 'default'}
        handleClick={() => {
          setShowCreateSMSBroadcast(!showCreateSMSBroadcast)

          if (!showCreateSMSBroadcast) {
            // Track sms broadcast create event
            mixpanel.track(MIXPANEL_EVENTS.SMS_BROADCAST_CREATE)
          }
        }}
        disabled={!appProject}
      >
        <span className='font-medium py-2 px-6 text-base flex gap-2 items-center'>
          {
            showCreateSMSBroadcast
              ? <>Cancel</>
              : <>
                <TFilledPlusIcon className='h-6 w-6' />
                Create
              </>
          }
        </span>
      </CustomButton>
    </div>
  )

  const tableColumns = useMemo(
    () => [
      {
        Title: (
          <span className='flex items-center'>
            <span className='leading-none'>Broadcast Name</span>
          </span>
        ),
        accessor: 'broadcastName',
        dataIndex: 'broadcastName',
        Cell: (props) => {
          const original = props.row.original
          return <div className='flex items-center gap-x-1'>
            <button
              className='bg-gray-200 border border-border px-3 py-0.5 rounded-full'
              onClick={() => {
                history.push(`/admin/sms-broadcast/${original.id}`)
              }}
            >
              View Report
            </button>
            <TextField value={props.cell.value} />
          </div>
        }
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Date Sent</span>
          </span>
        ),
        accessor: 'sentDate',
        dataIndex: 'sentDate',
        Cell: (props) => <TextField value={formatDate(props.cell.value)} />
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Users Selected</span>
          </span>
        ),
        accessor: 'totalUsers',
        dataIndex: 'totalUsers',
        Cell: (props) => <TextField value={props.cell.value} />
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Invalid Numbers</span>
          </span>
        ),
        accessor: 'invalidUsers',
        dataIndex: 'invalidUsers',
        Cell: (props) => {
          return <>
            {props.cell.value === 0 && (
              <span className='text-green-300'>-</span>
            )}
            {props.cell.value !== 0 && (
              <span className='text-red-500'>
                {props.cell.value}
              </span>
            )}
          </>
        }
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Delivered</span>
          </span>
        ),
        accessor: 'delivered',
        dataIndex: 'delivered',
        Cell: (props) => <TextField value={props.cell.value} />
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Failed</span>
          </span>
        ),
        accessor: 'failed',
        dataIndex: 'failed',
        Cell: (props) => <TextField value={props.cell.value} />
      }
    ],
    [history]
  )

  const SMSBroadcastTable = useCallback(() => {
    return (
      <div className='mt-5 w-full'>
        <Table
          className='rounded-lg'
          dataSource={smsBroadcastList}
          columns={tableColumns}
          tailwindTable
          loading={isLoading}
        />
      </div>
    )
  }, [smsBroadcastList, tableColumns, isLoading])

  const countRecipientsDeliveredAndFailed = useMemo(() => {
    let totalDelivered = 0
    let totalFailed = 0
    let totalRecipients = 0

    smsBroadcastList.forEach((elm) => {
      totalDelivered += elm.delivered
      totalFailed += elm.failed
      totalRecipients += elm.numberOfRecipients
    })

    return {
      totalDelivered,
      totalFailed,
      totalRecipients
    }
  }, [smsBroadcastList])

  return (
    <>
      <PageHeader
        title={`${showCreateSMSBroadcast ? 'Create ' : ''}SMS Broadcast`}
        rightContent={<RightContent />}
        className='!h-16'
      />
      <AdminPageWrapper style={{ height: 'calc(100vh - 164px)' }} fullscreen>
        {
          showCreateSMSBroadcast
            ? <>
              <CreateSMSBroadcast/>
            </>
            : <div className='h-full w-full flex flex-col mt-6 items-center'>
              <div className='grid grid-cols1 md:grid-cols-4 gap-4 w-full'>
                <CardComponent
                  IconSrc={
                    <svg
                      width='32'
                      height='32'
                      viewBox='0 0 32 32'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M22.6654 5.33301H9.33203V25.333H22.6654V5.33301Z'
                        fill='#2E2B2E'
                      />
                      <path d='M6.66667 8H4V22.6667H6.66667V8Z' fill='#2E2B2E' />
                      <path d='M27.9987 8H25.332V22.6667H27.9987V8Z' fill='#2E2B2E' />
                    </svg>
                  }
                  title='Total SMS Broadcast'
                  count={smsBroadcastList.length}
                />
                <CardComponent
                  IconSrc={
                    <svg
                      width='33'
                      height='32'
                      viewBox='0 0 33 32'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M24.5 17.3334H23.5933L20.9267 20.0001H23.4733L25.8333 22.6667H7.16667L9.54 20.0001H12.2733L9.60667 17.3334H8.5L4.5 21.3334V26.6667C4.5 28.1334 5.68667 29.3334 7.15333 29.3334H25.8333C27.3 29.3334 28.5 28.1467 28.5 26.6667V21.3334L24.5 17.3334ZM23.1667 10.6001L16.5667 17.2001L11.8467 12.4801L18.4467 5.88005L23.1667 10.6001ZM17.5133 3.05339L9.02 11.5467C8.5 12.0667 8.5 12.9067 9.02 13.4267L15.62 20.0267C16.14 20.5467 16.98 20.5467 17.5 20.0267L25.98 11.5467C26.5 11.0267 26.5 10.1867 25.98 9.66672L19.38 3.06672C18.8733 2.53339 18.0333 2.53339 17.5133 3.05339Z'
                        fill='#2E2B2E'
                      />
                    </svg>
                  }
                  title='Total Number of Recipients'
                  count={countRecipientsDeliveredAndFailed.totalRecipients}
                />
                <CardComponent
                  IconSrc={<UserCheck />}
                  title='Total Delivered'
                  count={countRecipientsDeliveredAndFailed.totalDelivered}
                />
                <CardComponent
                  IconSrc={<CrossIcon />}
                  title='Total Failed'
                  count={countRecipientsDeliveredAndFailed.totalFailed}
                />
              </div>
              <SMSBroadcastTable />
            </div>
        }
      </AdminPageWrapper>
    </>
  )
}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject
})

const mapDispatchToProps = {}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SMSBroadcast))

SMSBroadcast.propTypes = {
  appProject: propTypes.string
}
