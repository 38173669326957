/* eslint-disable */
// @ts-nocheck
import React from "react"
import CreateOrUpdateEmailTemplate from "../CreateOrUpdateEmailTemplate"
import { Modal } from "@material-ui/core"

const AddNewEmailTemplateModal = React.memo(({
  show = false,
  onClose = (_id) => { },
  templateId = null,
  onSuccess = () => { }
}) => {
  if (!show) return null

  return (
    <Modal
      open={show}
      onClose={onClose}
      aria-labelledby="Create Email template"
      aria-describedby="Create Email template modal"
      className="!z-[99999]"
    >
      <div className="relative bg-white rounded-lg shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[calc(100vw-5px)] h-[calc(100dvh-5px)] overflow-y-auto flex flex-col gap-6">
        <div className="h-full w-full flex flex-col overflow-hidden">
          <CreateOrUpdateEmailTemplate
            templateId={templateId}
            onBack={onClose}
            showContentAsModal
            goBackBtnText="Close"
            onSuccess={onSuccess}
          />
        </div>
      </div>
    </Modal>
  )
})

export default AddNewEmailTemplateModal