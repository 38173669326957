import React, { useState } from 'react'
import { Modal } from '@material-ui/core'
import { X } from 'lucide-react'
import CustomButton from 'components/CustomButton'
import { CONTRACT_ROLES_CONFIG } from '../constants'

const RoleSelectionModal = ({ open, onClose, onSignContract = (role) => { }, roles = [] }) => {
  const [selectedRole, setSelectedRole] = useState(null)

  const handleClose = () => {
    setSelectedRole(null)
    onClose()
  }

  return (
    <Modal
      open={open && roles?.length > 0}
      onClose={handleClose}
      aria-labelledby='Role Selection'
      aria-describedby='Role selection modal'
    >
      <div
        className='font-openSans relative bg-white py-0 rounded-none md:rounded-lg shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[calc(100%-5px)] md:h-fit overflow-hidden w-[calc(100vw-5px)] md:w-[600px]'
      >
        <button
          className='absolute right-5 top-4.5 z-[500]'
          onClick={handleClose}
        >
          <X />
        </button>
        <div className='p-4'>
          <h4>Role Selection Modal</h4>
          <p>Please select the role you want to sign the contract as</p>
          <div className='w-fit flex flex-col gap-2'>
            {
              roles.map((item) => (
                <div key={item.value} className='flex gap-2 items-center'>
                  <input
                    type='radio'
                    name='role'
                    value={item.value}
                    checked={selectedRole === item.value}
                    onChange={() => setSelectedRole(item.value)}
                    className='text-lg'
                  />
                  <label
                    htmlFor={item.value}
                    className='text-lg cursor-pointer !m-0'
                    onClick={() => setSelectedRole(item.value)}
                  >
                    {CONTRACT_ROLES_CONFIG[item.label]}
                  </label>
                </div>
              ))
            }
          </div>
          <div className='w-full flex justify-end gap-3'>
            <CustomButton
              variant='outline'
              className='mt-3'
              handleClick={handleClose}
            >
              <span className='text-base font-medium flex items-center gap-2 px-3 py-1.5'>
                Close
              </span>
            </CustomButton>
            <CustomButton
              className='mt-3'
              handleClick={() => {
                if (selectedRole) {
                  onSignContract(selectedRole)
                }
              }}
              disabled={!(selectedRole?.length > 0)}
            >
              <span className='text-base font-medium flex items-center gap-2 px-3 py-1.5'>
                Sign Contract
              </span>
            </CustomButton>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default RoleSelectionModal
