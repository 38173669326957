// @ts-nocheck
/* eslint-disable */

import { Badge } from "components/ShadcnComponents/badge"
import CustomSelect from "../../../pages/reservation/NewReservation/components/CustomSelect"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "components/ShadcnComponents/card"
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "components/ShadcnComponents/chart"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ShadcnComponents/table"
import { ArrowDown, ArrowUp } from "lucide-react"
import React, { useEffect, useMemo, useState } from "react"
import {
  Bar,
  BarChart,
  CartesianGrid,
  Line,
  LineChart,
  XAxis,
  YAxis,
  Area,
  AreaChart as RechartsAreaChart,
  ResponsiveContainer,
  Tooltip,
  Legend
} from "recharts"
import { getAllWebsiteConfig } from "store/actions/websiteReportConfigActions"
import { Button } from "components/ShadcnComponents/button"
import { ADMIN_ADS_REPORT_CONFIGS } from "routes"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import AdminWebsiteTrafficReport from "../websiteTrafficReport"
import useContactContext from "pages/newContacts/hooks/useContactContext"
import { PageLoader } from "pages/contractTemplates"
import useGoogleAdsAccounts from "hooks/useGoogleAdsAccounts"
import { getGoogleAdsReportById } from "store/actions/googleAdsActions"
import TableWithPagination from "components/TableWithPagination"
import { AreaChart } from "pages/dashboardv2/components/charts"

// Empty data message component
const EmptyDataMessage = ({ message = 'No data available for the selected time period.' }) => (
  <div className="h-full w-full flex items-center justify-center">
    <p className="text-gray-500 text-sm">{message}</p>
  </div>
)

// Loading skeleton for charts
const ChartSkeleton = () => (
  <div className="h-full w-full animate-pulse">
    <div className="h-full w-full bg-gray-200 rounded-md"></div>
  </div>
)

const MetricCard = ({ title, value }) => {
  // Get different icons based on the metric title
  const getIcon = () => {
    switch (title) {
      case "Total Campaigns":
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-[#222222]">
            <rect width="18" height="18" x="3" y="3" rx="2" />
            <path d="M9 9h6" />
            <path d="M9 13h6" />
            <path d="M9 17h6" />
          </svg>
        );
      case "Total Spend":
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-[#222222]">
            <path d="M12 2v20"/>
            <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"/>
          </svg>
        );
      case "Total Clicks":
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-[#222222]">
            <path d="M8 13v-1m4 1v-3m4 3V8M8 21l4-4 4 4M4 21h16" />
            <circle cx="12" cy="12" r="10" />
          </svg>
        );
      case "Conversion Rate":
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-[#222222]">
            <path d="M16 3h5v5" />
            <path d="M21 3l-7 7" />
            <path d="M3 21l5-5" />
            <path d="M3 16h5v5" />
            <circle cx="12" cy="12" r="3" />
          </svg>
        );
      default:
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-[#222222]">
            <path d="M21 11V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h6"/>
            <circle cx="16" cy="16" r="3"/>
            <path d="m21 21-1.5-1.5"/>
          </svg>
        );
    }
  };

  return (
    <Card className="overflow-hidden shadow-none bg-white">
      <CardHeader className="flex flex-row items-center justify-between py-2">
        <CardTitle className="text-sm font-normal !mb-0 text-[#1F2937]">
          {title}
        </CardTitle>
        {getIcon()}
      </CardHeader>
      <CardContent className="py-2">
        <div className="text-xl font-bold text-[#1F2937]">{value}</div>
      </CardContent>
    </Card>
  );
};

const AdminGoogleAdsReports = ({ isDashboard = false }) => {
  const history = useHistory()
  const [selectedAdAccountId, setSelectedAdAccountId] = useState(null)
  const [currentAdAccountCurrencyCode, setCurrentAdAccountCurrencyCode] = useState(null)
  const listOfProjects = useSelector((state) => state.appReducer.listOfProjects)
  const projectId = useSelector((state) => state.appReducer.appProject)
  const userObject = useSelector((state) => state.authReducer.userObject)
  const { view } = useContactContext()
  const [projectList, setProjectList] = useState([])
  const [selectedProjectId, setSelectedProjectId] = useState(null)
  const [reportData, setReportData] = useState([])
  const [isReportDataLoading, setIsReportDataLoading] = useState(false)
  const { loading: isAdsAccountsLoading, accounts, googleAdsConnectionId } = useGoogleAdsAccounts(selectedProjectId || projectId)
  const [metrics, setMetrics] = useState([])

  const [chartData, setChartData] = useState(null)
  const [totalValues, setTotalValues] = useState(null)
  const [totalConversionRate, setTotalConversionRate] = useState(null)

  useEffect(() => {
    let tempMapped = listOfProjects.map((item) => {
      return {
        label: item.projectName,
        value: item._id,
        _id: item._id,
      }
    })

    if (tempMapped?.length > 0 && view === "corporate") {
      setSelectedProjectId(tempMapped?.[0]?._id)
    }

    setProjectList(tempMapped)
  }, [listOfProjects, view])

  const accountOptions = useMemo(() => {
    const tempAccountList = accounts?.filter((account) => (account?.status === "enabled"))?.map((account) => ({
      label: account.name,
      value: account.id,
      _id: account.id,
    })) || []

    if (tempAccountList?.length) {
      setSelectedAdAccountId(tempAccountList[0]._id)
      setCurrentAdAccountCurrencyCode(accounts?.filter((account) => (account?.status === "enabled"))?.[0]?.currencyCode)
    }
    return tempAccountList
  }, [accounts, projectId])

  const getAdsReport = async () => {
    try {
      setIsReportDataLoading(true)
      const response = await getGoogleAdsReportById(
        googleAdsConnectionId,
        selectedAdAccountId,
      )
      if (response?.data?.length) {
        setReportData(response.data)
        setMetrics(calculateMetrics(response.data, currentAdAccountCurrencyCode))
        
        // Process chart data
        const timeSeriesData = processChartData(response.data)
        setChartData(timeSeriesData)
        
        // Calculate totals
        const totals = calculateTotals(response.data)
        setTotalValues(totals)
        setTotalConversionRate((totals.totalConversions / totals.totalClicks) * 100)
      } else {
        // Handle empty data case
        setReportData([])
        setMetrics([])
        setChartData([])
        setTotalValues({
          totalClicks: 0,
          totalBudget: 0,
          totalSpend: 0,
          totalConversions: 0
        })
        setTotalConversionRate(0)
      }
    } catch (error) {
      console.error("Error fetching data:", error)
      // Reset states on error
      setReportData([])
      setMetrics([])
      setChartData([])
      setTotalValues({
        totalClicks: 0,
        totalBudget: 0,
        totalSpend: 0,
        totalConversions: 0
      })
      setTotalConversionRate(0)
    } finally {
      setIsReportDataLoading(false)
    }
  }

  useEffect(() => {
    if (googleAdsConnectionId && selectedAdAccountId) {
      getAdsReport()
    }
  }, [googleAdsConnectionId, selectedAdAccountId, currentAdAccountCurrencyCode])

  const columnDefs = [
    {
      headerName: "Name",
      flex: 1,
      minWidth: 400,
      field: "name",
      sortable: true,
      filter: true,
      pinned: 'left'
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      cellRenderer: ({ value }) => (
        <Badge variant={getStatusBadgeVariant(value)}>
          {getStatusText(value)}
        </Badge>
      ),
    },
    {
      headerName: "Daily budget",
      field: "budget",
      sortable: true,
      cellRenderer: ({ value }) => (
        <>
          {formatMoney(value?.amount?.toFixed(2), currentAdAccountCurrencyCode)}
        </>
      )
    },
    {
      headerName: "Spent",
      field: "metrics",
      sortable: true,
      cellRenderer: ({ value }) => (
        <>
          {formatMoney(value?.cost?.toFixed(2), currentAdAccountCurrencyCode)}
        </>
      )
    },
    {
      headerName: "Clicks",
      field: "metrics",
      sortable: true,
      cellRenderer: ({ value }) => (
        <>
          {value.clicks?.toLocaleString()}
        </>
      )
    },
    {
      headerName: "Conversions",
      field: "metrics",
      sortable: true,
      cellRenderer: ({ value }) => (
        <>
          {Math.round(value.conversions || 0)}
        </>
      )
    },
  ]

  return (
    <div className={`flex flex-col gap-4 min-h-full w-full pb-6 ${isDashboard ? "pb-6" : "py-6"}`} >
      <div className="flex flex-col gap-3">
        {!isDashboard && (
          <p className="text-2xl font-semibold">Website Ads Reports</p>
        )}
        <div className="flex justify-between">
          {view === "corporate" && (
            <div className="flex flex-col gap-1">
              <div className={`font-bold ${isDashboard && "text-white"}`}>
                Select Project
              </div>
              <CustomSelect
                value={selectedProjectId}
                handleChange={setSelectedProjectId}
                options={projectList}
                showSearch
                placeholder="Select Project"
                className="w-[300px]"
                disabled={isReportDataLoading || isAdsAccountsLoading}
              />
            </div>
          )}
          {accounts?.length > 0 && (
            <div className="flex flex-col gap-1">
              <div className={`font-bold ${isDashboard && "text-white"}`}>
                Select Account
              </div>
              <CustomSelect
                value={selectedAdAccountId}
                handleChange={(value) => {
                  setSelectedAdAccountId(value)
                  setCurrentAdAccountCurrencyCode(accounts?.filter((account) => (account?.id === value))?.[0]?.currencyCode || null)
                }}
                options={accountOptions}
                showSearch
                placeholder="Select Account"
                className="w-[300px]"
                disabled={isReportDataLoading || isAdsAccountsLoading}
              />
            </div>
          )}
        </div>
      </div>

      {
        !(isReportDataLoading || isAdsAccountsLoading)
          ? <>
            {accounts?.length > 0 ? (
              <div className="flex flex-col gap-4">
                <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
                  {metrics?.map((metric, index) => (
                    <MetricCard
                      key={index}
                      title={metric.title}
                      value={metric.value}
                    />
                  ))}
                </div>

                <Card className="p-3 flex flex-col gap-4 bg-white border-0">
                  <CardContent className="p-0">
                    <ChartGroup
                      chartData={chartData}
                      totalClicks={totalValues?.totalClicks}
                      totalBudget={totalValues?.totalBudget}
                      totalSpend={totalValues?.totalSpend}
                      totalConversionRate={totalConversionRate}
                      currencyCode={currentAdAccountCurrencyCode}
                    />
                  </CardContent>
                </Card>

                <Card className="p-0 flex flex-col bg-white border-0">
                  <CardHeader className="p-3 border-b">
                    <CardTitle className="text-2xl">Recent Campaigns</CardTitle>
                  </CardHeader>
                  <CardContent className="p-0">
                    <div className="w-full">
                    <table className="w-full">
                    <thead>
                    <tr className="border-b">
                    <th className="text-left py-2 px-4">Name</th>
                         <th className="text-center py-2 px-4">Status</th>
                            <th className="text-center py-2 px-4">Daily budget</th>
                            <th className="text-center py-2 px-4">Spent</th>
                            <th className="text-center py-2 px-4">Clicks</th>
                            <th className="text-center py-2 px-4">Conversions</th>
                          </tr>
                          </thead>
                        <tbody>
                          {reportData.map((campaign) => (
                            <tr key={campaign.id} className="border-b">
                              <td className="py-2 px-4">{campaign.name}</td>
                              <td className="text-center py-2 px-4">
                                <Badge variant={getStatusBadgeVariant(campaign.status)}>
                                  {getStatusText(campaign.status)}
                                </Badge>
                              </td>
                              <td className="text-center py-2 px-4">
                             {formatMoney(campaign.budget?.amount?.toFixed(2), currentAdAccountCurrencyCode)}</td>
                              <td className="text-center py-2 px-4">
                             {formatMoney(campaign.metrics?.cost?.toFixed(2), currentAdAccountCurrencyCode)}</td>
                              <td className="text-center py-2 px-4">
                             {campaign.metrics?.clicks?.toLocaleString()}</td>
                              <td className="text-center py-2 px-4">
                             {Math.round(campaign.metrics?.conversions || 0)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </CardContent>
                </Card>
              </div>
            ) : (
              <div className="flex-1 flex justify-center items-center flex-col h-[calc(100vh-200px)] bg-white !rounded-[10px]">
                {userObject.userType !== "SalesRep" ? (
                  <>
                    <p>No Config is being set for site, Please do it now</p>
                    <Button onClick={() => history.push(ADMIN_ADS_REPORT_CONFIGS)}>
                      Set Configs
                    </Button>
                  </>
                ) : (
                  <p>
                    No Config is being set for site, Please contact admin to do so
                  </p>
                )}
              </div>
            )}
          </>
          : <PageLoader />
      }
    </div>
  )
}

export default AdminGoogleAdsReports

const calculateMetrics = (data, currencyCode) => {
  const totalCampaigns = data.length;
  const totalSpend = data.reduce((sum, campaign) => sum + campaign.metrics.cost, 0);
  const totalClicks = data.reduce((sum, campaign) => sum + campaign.metrics.clicks, 0);
  const totalConversions = data.reduce((sum, campaign) => sum + (campaign.metrics.conversions || 0), 0);
  const conversionRate = totalClicks > 0 ? (totalConversions / totalClicks) * 100 : 0;

  return [
    {
      title: "Total Campaigns",
      value: totalCampaigns,
    },
    {
      title: "Total Spend",
      value: formatMoney(totalSpend?.toFixed(2), currencyCode),
    },
    {
      title: "Total Clicks",
      value: totalClicks?.toLocaleString(),
    },
    {
      title: "Conversion Rate",
      value: `${conversionRate?.toFixed(2)}%`,
    }
  ];
}

const getStatusBadgeVariant = (status) => {
  switch (status) {
    case 2:
      return "success";
    case 3:
      return "pending";
    default:
      return "outline";
  }
}

const getStatusText = (status) => {
  switch (status) {
    case 2:
      return "Active";
    case 3:
      return "Paused";
    default:
      return "Unknown";
  }
}

export const formatMoney = (amount, currencyCode = '') => {
  try {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
    }).format(amount);
  } catch (error) {
    console.error("Invalid currency code:", currencyCode, error);
    return amount;
  }
}

const processChartData = (data) => {
  // Use campaign names for x-axis as in the original bar chart
  return data.map(campaign => ({
    name: campaign.name,
    clicks: campaign.metrics.clicks,
    budget: campaign.budget.amount,
    spend: campaign.metrics.cost,
    conversionRate: campaign.metrics.conversions / campaign.metrics.clicks * 100
  }));
}

const calculateTotals = (data) => {
  return data.reduce((acc, campaign) => {
    acc.totalClicks += campaign.metrics.clicks;
    acc.totalBudget += campaign.budget.amount;
    acc.totalSpend += campaign.metrics.cost;
    acc.totalConversions += campaign.metrics.conversions;
    return acc;
  }, {
    totalClicks: 0,
    totalBudget: 0,
    totalSpend: 0,
    totalConversions: 0
  });
}

const ChartGroup = ({
  chartData,
  totalClicks,
  totalBudget,
  totalSpend,
  totalConversionRate,
  isFetching = false,
  currencyCode
}) => {
  const [activeChart, setActiveChart] = React.useState("clicks")

  const chartConfig = {
    clicks: {
      label: "Clicks",
      color: "#3b82f6", // Blue
    },
    budget: {
      label: "Budget",
      color: "#10b981", // Green
    },
    spend: {
      label: "Spend",
      color: "#f59e0b", // Orange
    },
    conversionRate: {
      label: "Conversion Rate",
      color: "#6366f1", // Purple
    },
  }

  // Value formatter for different metrics
  const valueFormatter = (value) => {
    if (activeChart === "clicks") return parseInt(value).toLocaleString();
    if (activeChart === "budget" || activeChart === "spend") return formatMoney(parseFloat(value).toFixed(2), currencyCode);
    if (activeChart === "conversionRate") return `${parseFloat(value).toFixed(2)}%`;
    return value;
  };

  return (
    <Card className="md:col-span-2 flex flex-col gap-5 bg-white">
      <CardHeader className="flex !flex-row items-center justify-between px-4 py-3">
        <div className="flex flex-col">
          <div className="text-xl font-semibold">Campaign Performance</div>
          <div className="text-sm text-gray-500">Clicks, cost, and conversions by day</div>
        </div>
        <div className="flex space-x-1">
          {Object.keys(chartConfig).map((key) => (
            <button
              key={key}
              onClick={() => setActiveChart(key)}
              className={`px-3 py-1.5 text-xs font-medium rounded-md transition-colors ${
                activeChart === key 
                  ? 'bg-slate-200 text-gray-800 border border-slate-300' 
                  : 'bg-slate-100 text-gray-600 hover:bg-slate-200'
              }`}
              style={{ minWidth: "80px" }}
            >
              {key === "conversionRate" ? "Conversions" : chartConfig[key].label}
            </button>
          ))}
        </div>
      </CardHeader>
      {isFetching ? (
        <div className="h-[414px] w-full flex justify-center items-center">
          <span className="text-base dark:text-white">Loading...</span>
        </div>
      ) : (
        <CardContent className="px-2 py-3.5">
          {chartData?.length > 0 ? (
            <div className="h-[400px] w-full">
              <ResponsiveContainer width="100%" height="100%">
                <RechartsAreaChart 
                  data={chartData} 
                  margin={{ top: 10, right: 30, left: 10, bottom: 80 }}
                >
                  <CartesianGrid vertical={true} horizontal={true} strokeDasharray="3 3" stroke="#E4E4E7" />
                  <XAxis 
                    dataKey="name" 
                    tick={{ fontSize: 12, angle: -45, textAnchor: 'end' }} 
                    axisLine={{ stroke: '#E4E4E7' }}
                    tickLine={{ stroke: '#E4E4E7' }}
                    interval={0}
                    height={80}
                  />
                  <YAxis 
                    tick={{ fontSize: 12 }}
                    axisLine={{ stroke: '#E4E4E7' }}
                    tickLine={{ stroke: '#E4E4E7' }}
                    tickFormatter={activeChart === "conversionRate" ? (value) => `${value}%` : undefined}
                    domain={activeChart === "conversionRate" ? [0, 'auto'] : [0, 'auto']}
                  />
                  <Tooltip
                    formatter={(value) => [valueFormatter(value), chartConfig[activeChart].label]}
                    contentStyle={{
                      backgroundColor: 'white',
                      borderColor: '#E4E4E7',
                      borderRadius: '6px',
                      fontSize: '12px'
                    }}
                  />
                  <Area
                    type="monotone"
                    dataKey={activeChart}
                    fill={chartConfig[activeChart].color}
                    stroke={chartConfig[activeChart].color}
                    fillOpacity={0.7}
                  />
                </RechartsAreaChart>
              </ResponsiveContainer>
            </div>
          ) : (
            <div className="text-center h-[414px] w-full text-base font-medium text-gray-400 flex justify-center items-center">
              There's no information to display at this time
            </div>
          )}
        </CardContent>
      )}
    </Card>
  )
}
