// @ts-nocheck
/* eslint-disable */
import React, { useRef, useEffect } from 'react'
import { Loader } from '@googlemaps/js-api-loader'

const NeighbourhoodMap = ({ neighbourhoodData, apiKey }) => {
  const mapRef = useRef(null)
  const mapContainerRef = useRef(null)

  useEffect(() => {
    if (!mapContainerRef.current || !neighbourhoodData?.length) return

    const loader = new Loader({
      apiKey,
      version: 'weekly'
    })

    // Default to Vancouver area if no data
    const defaultCenter = { lat: 49.2827, lng: -123.1207 }

    // Process data to standard format
    const processedData = neighbourhoodData.map(item => ({
      neighbourhood: item.neighbourhood || '',
      city: item.city || '',
      province: item.province || '',
      value: item.count || 0
    }))

    loader.load().then((google) => {
      const map = new google.maps.Map(mapContainerRef.current, {
        center: defaultCenter,
        zoom: 10,
        mapTypeId: 'roadmap',
        streetViewControl: false,
        disableDefaultUI: true,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        rotateControl: false,
        fullscreenControl: false,
        scrollwheel: false, // Disable scrolling to zoom
        gestureHandling: 'none' // Disable all gestures including pinch to zoom
      })

      mapRef.current = map

      // Create a geocoder for converting neighbourhood names to coordinates
      const geocoder = new google.maps.Geocoder()
      const bounds = new google.maps.LatLngBounds()

      // Sort neighbourhoods by value (highest first)
      const sortedNeighbourhoods = [...processedData].sort((a, b) => b.value - a.value)

      // Find max and min values for better scaling
      const maxValue = sortedNeighbourhoods[0]?.value || 1
      const minValue = sortedNeighbourhoods[sortedNeighbourhoods.length - 1]?.value || 1

      // British Columbia context to improve geocoding accuracy
      const regionContext = 'British Columbia, Canada'

      // Track all geocoding attempts to avoid getting stuck
      let geocodingAttempts = 0
      let successfulGeocodes = 0
      const totalItems = sortedNeighbourhoods.length

      // For each neighbourhood, geocode and add circle/marker
      sortedNeighbourhoods.forEach((item, index) => {
        if (!item.neighbourhood || item.neighbourhood === 'Unknown') return

        // Build a better search string with city and province if available
        let searchString = item.neighbourhood
        if (item.city) searchString += `, ${item.city}`
        if (item.province) searchString += `, ${item.province}`
        else searchString += `, ${regionContext}`

        geocodingAttempts++

        geocoder.geocode({ address: searchString }, (results, status) => {
          geocodingAttempts--

          if (status === 'OK' && results[0]) {
            successfulGeocodes++
            const location = results[0].geometry.location

            // Improved logarithmic scale for circle sizes
            // This gives better visual representation for varying data ranges
            // Min radius: 500m, Max radius: 3000m
            const minRadius = 500
            const maxRadius = 3000

            // Use logarithmic scale: small differences in small values look larger
            // than the same differences in large values
            const normalizedValue = item.value / maxValue // 0 to 1 scale

            // Logarithmic scale formula (prevents tiny circles for small values)
            const logBase = 10
            const logScale = Math.log(1 + (logBase - 1) * normalizedValue) / Math.log(logBase)

            // Calculate the final radius between min and max
            const circleRadius = minRadius + (maxRadius - minRadius) * logScale

            // Color based on value percentile (blue gradient from light to dark)
            const percentile = item.value / maxValue
            const colorIntensity = Math.floor(percentile * 100)
            const hue = 220 // Blue hue
            const saturation = 80 // High saturation

            // Create a circle for the neighbourhood
            const circle = new google.maps.Circle({
              strokeColor: `hsl(${hue}, ${saturation}%, 35%)`,
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: `hsl(${hue}, ${saturation}%, ${Math.max(40, 65 - colorIntensity / 2)}%)`,
              fillOpacity: 0.6,
              map,
              center: location,
              radius: circleRadius,
              zIndex: 100 - index // Higher values on top
            })

            // Create a marker for the neighbourhood with the count
            // Scale marker size slightly with value too
            const markerScale = Math.max(10, Math.min(15, 10 + (item.value / maxValue) * 8))

            const marker = new google.maps.Marker({
              position: location,
              map,
              title: `${item.neighbourhood}: ${item.value} leads`,
              label: {
                text: item.value.toString(),
                color: 'white',
                fontSize: '12px',
                fontWeight: 'bold'
              },
              icon: {
                path: google.maps.SymbolPath.CIRCLE,
                scale: markerScale,
                fillColor: `hsl(${hue}, ${saturation}%, 35%)`,
                fillOpacity: 1,
                strokeWeight: 1,
                strokeColor: '#FFFFFF'
              },
              zIndex: 200 - index // Keep labels above circles
            })

            // Add info window with location info
            const infoWindow = new google.maps.InfoWindow({
              content: `
                <div style="font-weight:bold;font-size:14px;">${item.neighbourhood}</div>
                <div>${item.city ? `${item.city}, ` : ''}${item.province || ''}</div>
                <div><strong>${item.value}</strong> leads</div>
              `
            })

            // Add listeners to both the circle and marker
            google.maps.event.addListener(circle, 'click', () => {
              infoWindow.setPosition(location)
              infoWindow.open(map)
            })

            marker.addListener('click', () => {
              infoWindow.open(map, marker)
            })

            // Extend bounds to include this neighbourhood
            bounds.extend(location)

            // Only fit bounds after processing all locations
            if (successfulGeocodes === sortedNeighbourhoods.length ||
                (geocodingAttempts === 0 && successfulGeocodes > 0)) {
              map.fitBounds(bounds)

              // Adjust zoom if too close
              const listener = google.maps.event.addListener(map, 'idle', () => {
                if (map.getZoom() > 12) {
                  map.setZoom(12)
                }
                google.maps.event.removeListener(listener)
              })
            }
          } else if (geocodingAttempts === 0 && successfulGeocodes === 0) {
            // If all geocoding failed, center on default location
            map.setCenter(defaultCenter)
            map.setZoom(10)
          }
        })
      })
    })

    return () => {
      // Cleanup
      mapRef.current = null
    }
  }, [neighbourhoodData, apiKey])

  return (
    <div className="neighbourhood-map-container">
      <div
        ref={mapContainerRef}
        style={{
          width: '100%',
          height: '400px',
          borderRadius: '8px',
          border: '1px solid #e5e7eb'
        }}
      />
      {(!neighbourhoodData || neighbourhoodData.length === 0) && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: 'rgba(255,255,255,0.8)',
            padding: '10px',
            borderRadius: '4px'
          }}
        >
          No neighbourhood data available
        </div>
      )}
    </div>
  )
}

export default NeighbourhoodMap
