// @ts-nocheck
/* eslint-disable */
import React, { useState, useEffect } from 'react'
import PageHeader from 'components/PageHeader'
import PagesWrapper from 'components/PagesWrapper'
import { Card, CardContent } from 'components/ShadcnComponents/card'
import { Button } from 'components/ShadcnComponents/button'
import { Shield } from 'lucide-react'
import { Switch } from 'antd'
import standardStyle from 'assets/css/standardStyle'
import { getSharedInboxSettings, updateSharedInboxSettings } from 'store/actions/sharedInboxAction'
import toast from 'react-hot-toast'

const CustomSwitch = ({ ...props }) => {
  return (
    <Switch
      style={{
        background: props.checked && standardStyle.colors.softBlack,
      }}
      {...props}
    />
  )
}

const SharedInboxSettings = () => {
  const [settings, setSettings] = useState({
    salesRepSharedInbox: true,
    developerAdminSharedInbox: true
  })
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    fetchSettings()
  }, [])

  const fetchSettings = async () => {
    try {
      const data = await getSharedInboxSettings()
      if(data) setSettings(data);
    } catch (error) {
      toast.error('Failed to load settings')
    } finally {
      setLoading(false)
    }
  }

  const sharedInboxOptions = [
    {
      id: 'salesRepSharedInbox',
      title: 'Sales Representative Shared Inbox',
      description: 'Enable shared inbox access for all sales representatives'
    },
    {
      id: 'developerAdminSharedInbox',
      title: 'Developer Admin Shared Inbox',
      description: 'Enable shared inbox access for developer administrators'
    }
  ]

  const handleToggleChange = (id, checked) => {
    setSettings(prev => ({
      ...prev,
      [id]: checked
    }))
  }

  const handleSave = async () => {
    try {
      setSaving(true)
      await updateSharedInboxSettings(settings)
      toast.success('Settings saved successfully')
    } catch (error) {
      toast.error('Failed to save settings')
    } finally {
      setSaving(false)
    }
  }

  const handleCancel = () => {
    fetchSettings() // Reset to saved settings
  }

  if (loading) {
    return (
      <PagesWrapper>
        <div className="flex items-center justify-center h-screen">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
        </div>
      </PagesWrapper>
    )
  }

  return (
    <PagesWrapper>
      <PageHeader 
        title="Shared Inbox Settings" 
        showParameters={false}
        showImage={false}
      />
      
      <div className="container pt-10 mx-auto">
        <div className="bg-white rounded-xl shadow-sm mb-6">
          <div className="p-6 pb-3">
            <div className="flex items-center">
              <Shield className="h-5 w-5 mr-2 text-zinc-900" />
              <h2 className="text-lg font-medium text-zinc-900 mb-0">Inbox Sharing Configuration</h2>
            </div>
            <p className="text-sm text-[#666666] mt-1">
              Configure which user roles have access to shared inboxes across the platform
            </p>
          </div>

          <div className="p-6">
            <div className="space-y-4">
              {sharedInboxOptions.map((option) => (
                <div key={option.id} className="flex items-center justify-between border border-zinc-800 rounded-lg p-3">
                  <div className="flex-1">
                    <div className="font-medium text-zinc-900">{option.title}</div>
                    <div className="text-sm text-[#666666] mt-1">{option.description}</div>
                  </div>
                  <CustomSwitch
                    checked={settings[option.id]}
                    onChange={(checked) => handleToggleChange(option.id, checked)}
                    className="ml-4"
                    disabled={saving}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-3 mt-4">
       {/*    <Button 
            variant="outline" 
            className="px-4 py-2 h-auto" 
            onClick={handleCancel}
            disabled={saving}
          >
            Cancel
          </Button> */}
          <Button 
            className="px-4 py-2 h-auto" 
            onClick={handleSave}
            disabled={saving}
          >
            {saving ? 'Saving...' : 'Save Changes'}
          </Button>
        </div>
      </div>
    </PagesWrapper>
  )
}

export default SharedInboxSettings 