/* eslint-disable */
import React, { useMemo, useState } from 'react'
import { EmailAttachmentSVGIcon } from 'pages/contacts/EmailModal'
import { downloadAttachementNewV3, formatBytes } from 'utils/emailUtils'
import { Download, CheckCircle } from 'lucide-react'

const EmailAttachmentItem = ({ file, threadId, message }) => {
  const [downloading, setDownloading] = useState(false)
  const [downloaded, setDownloaded] = useState(false)

  const fileType = useMemo(() => {
    return (file?.content_type || file?.contentType)?.split(';')?.[0] ?? null
  }, [file?.content_type || file?.contentType])

  const download = async (file) => {
    setDownloading(true)
    try {
      await downloadAttachementNewV3(file, threadId, message)
      setDownloaded(true)
    } catch (e) {
      console.error('Download failed:', e)
    } finally {
      setTimeout(() => setDownloaded(false), 1500)
    }
    setDownloading(false)
  }

  const truncateFilename = (filename, maxLength) => {
    if (filename.length <= maxLength) return filename
    const extension = filename.split('.').pop()
    const name = filename.substring(0, maxLength - extension.length - 3)
    return `${name}...${extension}`
  }

  return (
    <div className='bg-white rounded-lg border shadow-am hover:shadow-md transition-shadow duration-300 overflow-hidden w-full sm:w-64 md:w-72'>
      <div className='p-4 flex items-start space-x-3'>
        <div className='flex-shrink-0'>
          {EmailAttachmentSVGIcon(fileType)}
        </div>
        <div className='flex-grow min-w-0'>
          <h3 className='text-sm font-medium text-gray-900 truncate mb-1' title={file?.filename || 'attachment'}>
            {truncateFilename(file?.filename || 'attachment', 20)}
          </h3>
          <p className='text-xs text-gray-500 mt-1 mb-0'>{formatBytes(file.size)}</p>
        </div>
      </div>
      <div className='px-3 pb-3 flex justify-end items-center'>
        <button
          onClick={() => download(file)}
          disabled={downloading || downloaded}
          className={`flex items-center justify-center rounded-lg w-8 h-8 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${downloaded
            ? 'bg-green-500 text-white'
            : downloading
              ? 'bg-gray-300 cursor-not-allowed'
              : 'bg-zinc-900 hover:bg-zinc-800 text-white'
            }`}
          aria-label={downloaded ? 'Downloaded' : downloading ? 'Downloading' : 'Download'}
        >
          {downloaded ? (
            <CheckCircle size={16} />
          ) : downloading ? (
            <svg className='animate-spin h-4 w-4 text-white' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
              <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
              <path className='opacity-75' fill='currentColor' d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
            </svg>
          ) : (
            <Download size={16} />
          )}
        </button>
      </div>
    </div>
  )
}

export default EmailAttachmentItem
