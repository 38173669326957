/* eslint-disable */
// @ts-nocheck
import dompurify from 'dompurify'

// ONLY NUMBERS
export const onlyNumbers = (value = '') => Number(String(value).replace(/[^0-9]/g, ''))

// CLEAN CLASSES SPACES
export const classNames = (...classes) => classes.filter(Boolean).join(' ')

// CLEAN LAST , ON STRINGS FINAL
export const removeLastCommaFromStrings = (value) => {
  const trimmedValue = value.trim()

  if (trimmedValue[trimmedValue.length - 1] === ',') {
    return trimmedValue.slice(0, -1)
  }
  return trimmedValue
}

// Process CID references in email HTML
export const processCidReferences = (html, attachments = []) => {
  if (!html || !attachments || attachments.length === 0) return html
  
  let processedHtml = html
  
  // Find all CID references in the HTML
  const cidRegex = /cid:([^"'\s>]+)/g
  const matches = html.match(cidRegex)
  
  if (matches) {
    matches.forEach(cidRef => {
      // Extract the content ID without the 'cid:' prefix
      const contentId = cidRef.substring(4)
      
      // Find the matching attachment
      const attachment = attachments.find(att => 
        att.contentId === contentId || 
        att.contentId === `<${contentId}>` || 
        `<${att.contentId}>` === contentId
      )
      
      if (attachment && attachment.content) {
        // Create a data URL from the attachment content
        const mimeType = attachment.contentType || 'image/png'
        const base64Content = typeof attachment.content === 'string' 
          ? attachment.content 
          : btoa(String.fromCharCode.apply(null, new Uint8Array(attachment.content)))
        
        const dataUrl = `data:${mimeType};base64,${base64Content}`
        
        // Replace all instances of the CID reference with the data URL
        processedHtml = processedHtml.replace(new RegExp(cidRef, 'g'), dataUrl)
      }
    })
  }
  
  return processedHtml
}

// SANITIZE code
export const sanitizeCode = (value, attachments = []) => {
  if (!value) return ''
  
  // First process any CID references
  const processedValue = processCidReferences(value, attachments)
  
  // Then sanitize the HTML
  const sanitizer = dompurify.sanitize
  
  return sanitizer(processedValue)
}
