// import { message } from 'antd'
import { api, ApiErrors } from 'services/api'
import mixpanel, { MIXPANEL_EVENTS } from 'services/mixpanel'

const BASE_PATH = 'inventory-notes'

export const getInventoryNotes = (inventoryId) =>
  new Promise((resolve, reject) => {
    if (!inventoryId || !inventoryId?.length) {
      return reject()
    }
    api.get(`${BASE_PATH}/inventory/${inventoryId}`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const createOrUpdateInventoryNote = (props) => new Promise((resolve, reject) => {
  const { _id, ...params } = props

  // If id, so we have to update the record, or we're gonna create
  const verb = _id ? 'put' : 'post'
  const url = _id ? `${BASE_PATH}/${_id}` : BASE_PATH

  api[verb](url, params)
    .then((response) => {
      if (response.ok) {
        // message.success(`This note was ${_id ? 'successfully updated' : 'created successfully'}`)

        if (!_id) {
          // Track note save event
          mixpanel.track(MIXPANEL_EVENTS.NOTE_CREATE, {
            createdNoteId: response?.data?._id
          })
        }

        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const getInventoryNotesById = (id = '') => new Promise((resolve, reject) => {
  api.get(`${BASE_PATH}/${id}`)
    .then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const deleteInventoryNote = (id = '') => new Promise((resolve, reject) => {
  api.delete(`${BASE_PATH}/${id}`)
    .then((response) => {
      if (response.ok) {
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})
