/* eslint-disable space-before-function-paren */
import Input from 'antd/lib/input/Input'
import CustomButton from 'components/CustomButton'
import CustomSelect from 'components/CustomSelect'
import CustomModal from 'components/Modal'
import TableGrid from 'components/TableGrid/Table'
import { useGetUsersCounts } from 'hooks/useGetUsers'
import useLeadSource from 'hooks/useLeadSource'
import useLeadStatus from 'hooks/useLeadStatus'
import useProjectId from 'hooks/useProjectId'
import { Loader, Loader2, PackageIcon, PlusCircle, XIcon } from 'lucide-react'
import React, { useEffect, useCallback, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getCorporatDemandNotes, GetDemandNotes } from 'store/actions/demandNotes'
import { createSmartList, createStaticList, getSmartListPreview, getUsersPagination, updateSmartList } from 'store/actions/usersActions'
import { isMobile } from 'react-device-detect'
import clsx from 'clsx'
import { Modal } from '@material-ui/core'
import { api } from 'services/api'
import { AgGridReact } from 'ag-grid-react'
import { Switch } from 'ui'
// Main Component
export default function CreateListDialog(props) {
  const isCorporate = props.isCorporate ?? false
  const projectId = props.projectId ?? null
  const onClose = props.onClose ?? null
  return (
    <div>
      <CreateList
        projectId={projectId}
        isCorporate={isCorporate}
        onClose={onClose}
        refreshData={
          (data) => {
            if (props.refreshData && typeof props?.refreshData === 'function') props?.refreshData(data)
          }
        } />
    </div>
  )
}

// Create List Component
function CreateList(props) {
  // @ts-ignore
  const { leadSources, loading: loadingLeadSources } = useLeadSource()
  const { leadStatuses, loading: loadingLeadStatuses } = useLeadStatus()
  const [demandNotes, setDemandNotes] = useState([])
  const [corpDemandNotes, setCorpDemandNotes] = useState([])
  const [isCorporate, setIsCorporate] = useState(props.isCorporate ?? false)

  const [showListChooser, setShowListChooser] = useState(false)

  // @ts-ignore
  const appProject = useSelector(state => state?.appReducer?.appProject)
  const [showSmartList, setShowSmartList] = useState(false)
  const [showStaticList, setShowStaticList] = useState(false)
  const [creating, setCreating] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [previewing, setPreviewing] = useState(false)
  useEffect(() => {
    if ((!showSmartList && !showStaticList)) return
    if (isCorporate) return
    GetDemandNotes().then((result) => {
      setDemandNotes(result)
    })
  }, [showSmartList, showStaticList, isCorporate])

  useEffect(() => {
    if ((!showSmartList && !showStaticList)) return
    getCorporatDemandNotes().then((result) => {
      setCorpDemandNotes(result)
    })
  }, [showSmartList, showStaticList])

  if (loadingLeadSources || loadingLeadStatuses) return <div>Loading...</div>
  const handleClose = () => {
    setShowSmartList(false)
    setShowStaticList(false)
    if (props.onClose && typeof props.onClose === 'function') props.onClose()
  }
  async function updateList(id, data) {
    setUpdating(true)
    updateSmartList(id, data)
      .then(() => {
        setUpdating(false)
      })
      .catch((error) => {
        setUpdating(false)
        console.error('Error updating list', error)
      }).finally(() => {
        handleClose()
        if (props.refreshData && typeof props?.refreshData === 'function') props?.refreshData()
      })
  }

  async function getDataPreviewForFilter(filter) {
    return new Promise((resolve, reject) => {
      setPreviewing(true)
      // @ts-ignore
      getSmartListPreview(filter)
        .then((data) => {
          resolve(data)
          setPreviewing(false)
        })
        .catch((error) => {
          console.error('Error getting preview data', error)
        }).finally(() => {
          setPreviewing(false)
          if (props.refreshData && typeof props?.refreshData === 'function') props?.refreshData()
        })
    })
  }

  async function createList(type, data) {
    setCreating(true)
    let fun = null
    if (type === 'smart') {
      fun = createSmartList
    } else if (type === 'static') {
      fun = createStaticList
    }
    if (!fun) return
    const projectId = props.projectId ? props.projectId : appProject
    fun(data, projectId, isCorporate)
      .then((data) => {
        alert('List created successfully')
        setCreating(false)
        handleClose()
        if (props.refreshData && typeof props?.refreshData === 'function') props?.refreshData(data)
      })
      .catch((error) => {
        setCreating(false)
        console.error('Error creating list', error)
      })
  }

  return (
    <div className='w-full'>
      <button
        onClick={() => setShowListChooser(true)}
        className="w-full bg-black text-white px-4 py-2 rounded-lg font-semibold"
      >
        {isCorporate ? 'Create Corporate List' : 'Create Project List'}
      </button>
      <br />
      <ChooseListType
        projectId={props.projectId}
        selectedListType={isCorporate ? 'corporate' : 'project'}
        show={showListChooser}
        onListTypeSelected={(e) => {
          setIsCorporate(e === 'corporate')
        }}
        onMethodSelected={(w) => {
          const { method, type } = w
          setShowListChooser(false)
          setIsCorporate(type === 'corporate')
          if (method === 'smart-list') {
            setShowSmartList(true)
          } else if (method === 'static-list') {
            setShowStaticList(true)
          }
        }} />
      <CustomModal
        customStyles={{
          width: '100%',
          height: '100%'
        }}
        isOpen={showSmartList}
        closeModal={handleClose}
        contentLabel="Add Or Edit Contact Modal"
      >
        <ComponentCreateSmartList
          listId={null}
          listData={null}
          projectId={props.projectId}
          isCorporate={isCorporate}
          demandNotes={demandNotes}
          corpDemandNotes={corpDemandNotes}
          leadSources={leadSources.values}
          leadStatuses={leadStatuses.values}
          disable={creating || updating}
          closeModal={handleClose}
          previewing={previewing}
          updateList={(id, data) => {
            if (updating) return
            updateList(id, data)
          }}
          getDataPreviewForFilter={(data) => {
            return getDataPreviewForFilter(data)
          }}
          createList={(type, data) => {
            if (creating) return
            createList(type, data)
          }} />
      </CustomModal>
      <CustomModal
        customStyles={{
          width: '100%',
          height: '100%'
        }}
        isOpen={showStaticList}
        closeModal={handleClose}
        contentLabel="Add Or Edit Contact Modal"
      >
        <ComponentCreateStaticList
          listId={null}
          listData={null}
          projectId={props.projectId}
          isCorporate={isCorporate}
          demandNotes={demandNotes}
          corpDemandNotes={corpDemandNotes}
          leadSources={leadSources.values}
          leadStatuses={leadStatuses.values}
          disable={creating || updating}
          closeModal={handleClose}
          previewing={previewing}
          updateList={(id, data) => {
            if (updating) return
            updateList(id, data)
          }}
          getDataPreviewForFilter={(data) => {
            return getDataPreviewForFilter(data)
          }}
          createList={(type, data) => {
            if (creating) return
            createList(type, data)
          }}
          onCreated={(data) => {
            handleClose()
          }}
        />
      </CustomModal>
    </div >
  )
}

function getInWithOption(
  options = []
) {
  return {
    label: 'In',
    key: '$in',
    inputs: [{
      key: '$in',
      label: 'In',
      type: 'select-multiple',
      options
    }]
  }
}

function getNotInWithOption(
  options = []
) {
  return {
    label: 'Not In',
    key: '$nin',
    inputs: [{
      key: '$nin',
      label: 'Not In',
      type: 'select-multiple',
      options
    }]
  }
}
// Content Component inside the Modal
const ComponentCreateSmartList = (
  props
) => {
  const isCorporate = props.isCorporate ?? false
  const disable = props.disable ?? false
  const OPS = {
    CONTAINS: 'contains',
    EQUALS: 'equals',
    NOT_EQUALS: 'not-equals',
    RATINGS: {
      IN: 'in',
      NOT_IN: 'not-in'
    },
    LEAD_STATUS: {
      IN: 'in',
      NOT_IN: 'not-in'
    },
    LEAD_SOURCE: {
      IN: 'in',
      NOT_IN: 'not-in'
    },
    USER_TYPE: {
      IN: 'in',
      NOT_IN: 'not-in'
    },
    DEMAND_NOTES: {
      IN: 'in',
      NOT_IN: 'not-in'
    },
    IN_ARRAY: 'in-array',
    NOT_IN_ARRAY: 'not-in-array',
    DATE: {
      GREATER_THAN: 'date-greater-than',
      LESS_THAN: 'date-less-than',
      EQUAL_TO: 'date-equal-to'
    }
  }

  const opEqual = {
    label: 'Is',
    key: OPS.EQUALS,
    inputs: [{
      key: '$eq',
      label: 'Label',
      type: 'text',
      defaultValue: 'value'
    }]
  }

  const opNotEqual = {
    label: 'Is not',
    key: OPS.NOT_EQUALS,
    inputs: [{
      key: '$ne',
      label: 'Label',
      type: 'text',
      defaultValue: 'value'
    }]
  }

  const opGreaterThanDate = (label) => {
    return {
      label: label ?? 'Greater Than',
      key: OPS.DATE.GREATER_THAN,
      inputs: [{
        key: '$gt',
        label: 'Date',
        type: 'date',
        defaultValue: null
      }]
    }
  }

  const opLessThanDate = (label) => {
    return {
      label: label ?? 'Less Than',
      key: OPS.DATE.LESS_THAN,
      inputs: [{
        key: '$lt',
        label: 'Date',
        type: 'date',
        defaultValue: null
      }]
    }
  }

  const opEqualDate = (label) => {
    return {
      label: label ?? 'Equal To',
      key: OPS.DATE.EQUAL_TO,
      inputs: [{
        key: '$eq',
        label: 'Date',
        type: 'date',
        defaultValue: null
      }]
    }
  }

  const contains = {
    label: 'Contains',
    key: OPS.CONTAINS,
    inputs: [{
      key: '$contains',
      label: 'Label',
      type: 'text',
      defaultValue: 'value'
    }]
  }

  const doesNotContains = {
    label: 'Does not contain',
    key: OPS.NOT_CONTAINS,
    inputs: [{
      key: '$notContains',
      label: 'Label',
      type: 'text',
      defaultValue: 'value'
    }]
  }

  const opRatingsIn = {
    label: 'In',
    key: OPS.RATINGS.IN,
    inputs: [{
      key: '$in',
      label: 'In',
      type: 'select-multiple',
      options: [
        { label: 'A', value: 'A' },
        { label: 'B', value: 'B' },
        { label: 'C', value: 'C' },
        { label: 'Unrated', value: 'Unrated' }
      ]
    }]
  }
  const opRatingsNotIn = {
    label: 'Not In',
    key: OPS.RATINGS.NOT_IN,
    inputs: [{
      key: '$nin',
      label: 'Not In',
      type: 'select-multiple',
      options: [
        { label: 'A', value: 'A' },
        { label: 'B', value: 'B' },
        { label: 'C', value: 'C' },
        { label: 'Unrated', value: 'Unrated' }
      ]
    }]
  }

  const leadStatusOptions = props.leadStatuses.map((status) => {
    return {
      label: status,
      value: status
    }
  })

  const leadSourceOptions = props.leadSources.map((source) => {
    return {
      label: source,
      value: source
    }
  })
  const opLeadStatusIn = {
    label: 'In',
    key: OPS.LEAD_STATUS.IN,
    inputs: [{
      key: '$in',
      label: 'In',
      type: 'select-multiple',
      options: leadStatusOptions
    }]
  }

  const opLeadStatusNotIn = {
    label: 'Not In',
    key: OPS.LEAD_STATUS.NOT_IN,
    inputs: [{
      key: '$nin',
      label: 'Not In',
      type: 'select-multiple',
      options: leadStatusOptions
    }]
  }
  const opLeadSourceIn = {
    label: 'In',
    key: OPS.LEAD_SOURCE.IN,
    inputs: [{
      key: '$in',
      label: 'In',
      type: 'select-multiple',
      options: leadSourceOptions
    }]
  }

  const opLeadSourceNotIn = {
    label: 'Not In',
    key: OPS.LEAD_SOURCE.NOT_IN,
    inputs: [{
      key: '$nin',
      label: 'Not In',
      type: 'select-multiple',
      options: leadSourceOptions
    }]
  }

  const AvailableFilters = [{
    label: 'First Name',
    key: 'firstName',
    ops: [opEqual, opNotEqual, contains, doesNotContains]
  },
  {
    label: 'Last Name',
    key: 'lastName',
    ops: [opEqual, opNotEqual]
  }, {
    label: 'Email',
    key: 'email',
    ops: [opEqual, opNotEqual]
  },
  {
    label: 'Registered',
    key: 'createdAt',
    ops: [
      opGreaterThanDate('After'),
      opLessThanDate('Before'),
      opEqualDate('On')
    ]
  },
  {
    label: 'Ratings',
    key: 'buyerData.rating',
    ops: [
      opRatingsIn,
      opRatingsNotIn
    ]
  },
  {
    label: 'Lead Status',
    key: 'buyerData.leadStatus',
    ops: [opLeadStatusIn, opLeadStatusNotIn]
  },
  {
    label: 'Lead Source',
    key: 'buyerData.leadSource',
    ops: [opLeadSourceIn, opLeadSourceNotIn]
  },
  ...props?.demandNotes?.map((note) => {
    const options = note.options.map((option) => {
      return {
        label: option.label,
        value: option.value
      }
    })
    const inOp = getInWithOption(options)
    const notInOp = getNotInWithOption(options)
    const final = [inOp, notInOp]
    return {
      label: `(Demand) ${note.label}`,
      key: `buyerData.customDemands.${note._id}`,
      ops: final
    }
  }),
  ...props?.corpDemandNotes?.map((note) => {
    const options = note.options.map((option) => {
      return {
        label: option.label,
        value: option.id
      }
    })
    const inOp = getInWithOption(options)
    const notInOp = getNotInWithOption(options)
    const final = [inOp, notInOp]
    return {
      label: `(Corp) ${note.label}`,
      key: `buyerData.corporateDemands.${note.id}`,
      ops: final
    }
  })
  ]

  const [title, setTitle] = useState(props.listData?.title || '')
  const [filters, setFilters] = useState([])
  const [editing] = useState(!!props.listId)
  const [previewContacts, setPreviewContacts] = useState([])
  const [previewCounts, setPreviewCounts] = useState(0)
  const { projectId: defaultProject } = useProjectId()
  const [projectId] = useState(props?.projectId ?? defaultProject)
  const { count: usersCounts, isLoading: isLoadingCounts } = useGetUsersCounts(projectId)
  const tableRef = useRef(null)
  function addFilter() {
    const [filter] = AvailableFilters
    const [defaultOp] = filter.ops
    const newFilter = {
      key: filter.key,
      op: defaultOp?.key ?? '',
      inputs: {}
    }
    setFilters([...filters, newFilter])
  }

  function removeFilter(index) {
    setFilters(filters.filter((_, i) => i !== index))
  }

  function getQueryFromFilter() {
    const mongoQuery = filters.map((filter) => {
      const { key, op, inputs } = filter
      const query = {}
      query[key] = {}
      if (op === OPS.EQUALS) {
        if (inputs?.$eq) {
          query[key] = { $eq: inputs?.$eq }
        }
      } else if (op === OPS.NOT_EQUALS) {
        if (inputs?.$ne) {
          query[key] = { $ne: inputs?.$ne }
        }
      } else if (op === OPS.IN_ARRAY) {
        const value = inputs?.$in
        const array = []
        if (typeof value === 'string') {
          array.push(value)
        } else if (typeof value === 'object' && Array.isArray(value)) {
          array.push(...value)
        }
        query[key] = { $in: array }
      } else if (op === OPS.NOT_IN_ARRAY) {
        const value = inputs?.$nin
        const array = []
        if (typeof value === 'string') {
          array.push(value)
        } else if (typeof value === 'object' && Array.isArray(value)) {
          array.push(...value)
        }
        query[key] = { $nin: array }
      } else if (op === OPS.CONTAINS) {
        if (inputs?.$contains) {
          query[key] = { $regex: inputs?.$contains, $options: 'i' }
        }
      } else if (op === OPS.RATINGS.IN) {
        if (inputs?.$in) {
          const value = inputs?.$in
          const array = []
          if (typeof value === 'string') {
            array.push(value)
          } else if (typeof value === 'object' && Array.isArray(value)) {
            array.push(...value)
          }
          query[key] = {
            $in: array
          }
        }
      } else if (op === OPS.RATINGS.NOT_IN) {
        if (inputs?.$nin) {
          const value = inputs?.$nin
          const array = []
          if (typeof value === 'string') {
            array.push(value)
          } else if (typeof value === 'object' && Array.isArray(value)) {
            array.push(...value)
          }
          query[key] = {
            $nin: array
          }
        }
      } else if (op === OPS.LEAD_STATUS.IN) {
        if (inputs?.$in) {
          const value = inputs?.$in
          const array = []
          if (typeof value === 'string') {
            array.push(value)
          } else if (typeof value === 'object' && Array.isArray(value)) {
            array.push(...value)
          }
          query[key] = {
            $in: array
          }
        }
      } else if (op === OPS.LEAD_STATUS.NOT_IN) {
        if (inputs?.$nin) {
          const value = inputs?.$nin
          const array = []
          if (typeof value === 'string') {
            array.push(value)
          } else if (typeof value === 'object' && Array.isArray(value)) {
            array.push(...value)
          }
          query[key] = {
            $nin: array
          }
        }
      } else if (op === OPS.LEAD_SOURCE.IN) {
        if (inputs?.$in) {
          const value = inputs?.$in
          const array = []
          if (typeof value === 'string') {
            array.push(value)
          } else if (typeof value === 'object' && Array.isArray(value)) {
            array.push(...value)
          }
          query[key] = {
            $in: array
          }
        }
      } else if (op === OPS.LEAD_SOURCE.NOT_IN) {
        if (inputs?.$nin) {
          const value = inputs?.$nin
          const array = []
          if (typeof value === 'string') {
            array.push(value)
          } else if (typeof value === 'object' && Array.isArray(value)) {
            array.push(...value)
          }
          query[key] = {
            $nin: array
          }
        }
      } else if (op === OPS.DATE.GREATER_THAN) {
        if (inputs?.$gt) {
          query[key] = {
            $gt: inputs?.$gt
          }
        }
      } else if (op === OPS.DATE.LESS_THAN) {
        if (inputs?.$lt) {
          query[key] = {
            $lt: inputs?.$lt
          }
        }
      } else if (op === OPS.DATE.EQUAL_TO) {
        if (inputs?.$eq) {
          const date = new Date(inputs?.$eq)
          query[key] = {
            $gte: date,
            $lt: new Date(date.getTime() + 86400000)
          }
        }
      } else {
        // delete the key if it is not used
        delete query[key]
      }
      return query
    }).filter((query) => {
      return Object.keys(query).length > 0
    })

    // loop through demand notes keys and
    // add them to the query
    const demandQuery = {}
    const notInArrayCustomDemandNotes = []
    filters.forEach((filter) => {
      const { key, inputs } = filter
      // if buyerData.customDemands
      if (key.includes('buyerData.customDemands')) {
        const demandNote = props?.demandNotes.find((note) => {
          return key.includes(note._id)
        })
        const op = filter.op
        if (demandNote) {
          const value = inputs[`${op}`]
          // create mongodb for array match for custom demands
          if (op) {
            demandQuery[key] = {
              $elemMatch: {
                label: demandNote.label,
                value: { [op]: value }
              }
            }
          }
          if (op === '$nin') {
            notInArrayCustomDemandNotes.push(demandNote.label)
          }
        }
      }
    })

    Object.keys(demandQuery).forEach((key) => {
      const query = {
        $or: [
          {
            'buyerData.customDemands': demandQuery[key]
          }, {
            'leadBrokerData.customDemands': demandQuery[key]
          }
        ]
      }

      if (notInArrayCustomDemandNotes.length > 0) {
        query.$or.push({
          // @ts-ignore
          $and: [
            {
              'buyerData.customDemands': {
                $not: {
                  $elemMatch: {
                    label: { $in: notInArrayCustomDemandNotes }
                  }
                }
              }
            },
            {
              'leadBrokerData.customDemands': {
                $not: {
                  $elemMatch: {
                    label: { $in: notInArrayCustomDemandNotes }
                  }
                }
              }
            }
          ]
        })
      }
      mongoQuery.push(query)
    })

    const corpDemandQuery = {}
    const notInArrayDemandNotes = []
    filters.forEach((filter) => {
      const { key, inputs } = filter
      // if buyerData.corporateDemands
      if (key.includes('buyerData.corporateDemands')) {
        const demandNote = props?.corpDemandNotes.find((note) => {
          return key.includes(note.id)
        })
        const op = filter.op
        if (demandNote) {
          const value = inputs[`${op}`]
          // create mongodb for array match for custom demands
          if (op) {
            corpDemandQuery[key] = {
              $elemMatch: {
                label: demandNote.id ?? demandNote._id ?? demandNote.label,
                values: { [op]: value }
              }
            }
          }
          if (op === '$nin') {
            notInArrayDemandNotes.push(demandNote.id ?? demandNote._id ?? demandNote.label)
          }
        }
      }
    })

    Object.keys(corpDemandQuery).forEach((key) => {
      // @ts-ignore
      const query = {
        $or: [
          {
            'buyerData.corporateDemands': corpDemandQuery[key]
          }, {
            'leadBrokerData.corporateDemands': corpDemandQuery[key]
          }
        ]
      }
      if (notInArrayDemandNotes.length > 0) {
        query.$or.push({
          // @ts-ignore
          $and: [
            {
              'buyerData.corporateDemands': {
                $not: {
                  $elemMatch: {
                    label: { $in: notInArrayDemandNotes }
                  }
                }
              }
            },
            {
              'leadBrokerData.corporateDemands': {
                $not: {
                  $elemMatch: {
                    label: { $in: notInArrayDemandNotes }
                  }
                }
              }
            }
          ]
        })
      }
      mongoQuery.push(query)
    })
    return mongoQuery
  }

  function handleSubmit(type) {
    const mongoQuery = getQueryFromFilter()
    const _title = String(title).trim()
    if (!_title) {
      alert('Please enter a title for the list')
      document.getElementById('new-list-title')?.focus()
      return
    }
    getDataForPreview((data) => {
      const { counts } = data
      const confirm = window.confirm(
        `You are about to create ${type}-List with name "${title}", that will have ${counts} contacts. Do you want to proceed?`
      )
      if (!confirm) return
      if (editing) {
        props.updateList(props.listId, {
          title,
          filters: mongoQuery
        })
      } else {
        props.createList(type, { title, filters: mongoQuery, uiState: filters })
      }
    })
  }

  function getDataForPreview(callback) {
    const mongoQuery = getQueryFromFilter()
    props.getDataPreviewForFilter(mongoQuery).then((data) => {
      setPreviewCounts(data.counts)
      setPreviewContacts(data.users)
      if (callback && typeof callback === 'function') {
        setTimeout(() => {
          callback(data)
        }, 1000)
      }
    }).catch((error) => {
      console.error('Error getting preview data', error)
    })
  }

  useEffect(() => {
    getDataForPreview()
  }, [])

  function updateInputField(filterIndex, inputKey, value) {
    const newFilters = [...filters]
    if (!newFilters[filterIndex].inputs) {
      newFilters[filterIndex].inputs = {}
    }
    newFilters[filterIndex].inputs[inputKey] = value
    setFilters(newFilters)
  }
  return (
    <div className='font-openSans flex flex-col gap-y-2 h-full px-4 md:py-3.5 lg:py-5 rounded min-w-3xl w-full bg-siteColor'>
      <div className='w-full flex justify-between'>
        <div className='text-2xl font-bold text-gray-800'>
          Create Smart List {isCorporate ? ' (Corporate List)' : '(Project List)'}
        </div>
        <div className='flex flex-row gap-x-2 '>
          <CustomButton
            disabled={disable}
            className='whitespace-nowrap min-w-[150px] px-3.5 py-1.5 flex gap-x-1'
            handleClick={
              () => {
                if (disable) return
                handleSubmit('smart')
              }
            }>
            <PlusCircle size='20' />
            <span>
              Create Smart List
            </span>
          </CustomButton>
          {/* <CustomButton
            disabled={disable}
            className='whitespace-nowrap min-w-[150px] px-3.5 py-1.5 flex gap-x-1 bg-green-500 text-white'
            handleClick={
              () => {
                if (disable) return
                handleSubmit('static')
              }
            }>
            <PlusCircle size='20' />
            <span>
              Create Static List
            </span>
          </CustomButton> */}
          <button
            disabled={disable}
            onClick={() => {
              if (disable) return
              props?.closeModal()
            }}
            className='bg-red-500 text-white px-4 py-2 text-sm rounded-lg hover:bg-red-600'
          >
            Cancel Model
          </button>
        </div>
      </div>
      <div className='sm:grid grid-cols-1 xl:grid-cols-2 gap-4 h-full '>
        <div className='order-0 sm:order-1 border border-border px-4 py-2 rounded-sm flex flex-col gap-y-4 bg-white'>
          {props.previewing && (
            <div className='w-full bg-gray-300 p-2 flex items-center justify-center rounded'>
              <div className='flex gap-x-1.5 items-center'>
                <Loader size='20' className='animate-spin' />
                <span>Loading...</span>
              </div>
            </div>
          )}
          <div className='grid grid-cols-3'>
            <div className='border border-border px-3 py-4 shadow-sm rounded-md flex flex-col gap-y-2'>
              <div className='flex text-xl'>
                Filter Contacts
              </div>
              <div className='text-3xl font-bold'>
                {previewCounts}
              </div>
            </div>
          </div>
          <div className='border-t border-border py-2'>
            <div>
              <div className='text-xl font-light text-black'>
                Contacts Preview ({previewContacts?.length})
              </div>
              <div className='text-xs font-extralight'>
                {'Preview will show only the first 20 contacts that match the filters.'}
              </div>
            </div>
            <TableGrid
              style={{ height: '470px', minHeight: '470px' }}
              rowSelectKey='id'
              columnDefs={[
                {
                  headerName: 'Email',
                  field: 'email',
                  sortable: true,
                  checkboxSelection: true,
                  width: 270
                },
                {
                  headerName: 'First Name',
                  field: 'firstName',
                  sortable: true
                },
                {
                  headerName: 'Last Name',
                  field: 'lastName',
                  sortable: true
                },
                {
                  headerName: 'Registered',
                  field: 'createdAt',
                  sortable: true,
                  resizable: true,
                  cellRenderer: ({ value }) => {
                    return new Date(value).toLocaleString()
                  }
                },
                {
                  headerName: 'User Type',
                  field: 'userType',
                  sortable: true,
                  cellRenderer: ({ value }) => {
                    const userType = value
                    const availableTags = [
                      ['buyer', 'bg-blue-500 text-white'],
                      ['DeveloperAdmin', 'bg-red-500 text-white'],
                      ['CoopBroker', 'bg-green-500 text-white'],
                      ['LeadBroker', 'bg-green-500 text-white'],
                      ['SalesRep', 'bg-orange-500 text-white']
                    ]
                    return <div>
                      {/* eslint-disable-next-line array-callback-return */}
                      {availableTags.map(([tag, color]) => {
                        if (userType?.toLowerCase() === tag.toLowerCase()) {
                          return (
                            <div className={
                              `capitalize text-gray-800 border border-gray-300 px-2 py-1 rounded-full text-xs ${color}`
                            }>
                              {tag}
                            </div>
                          )
                        }
                      })}
                    </div>
                  }
                }
              ]}
              getData={async (
                filter,
                pagination,
                sorting
              ) => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise(async (resolve) => {
                  resolve(previewContacts)
                })
              }} >
              {(params) => {
                tableRef.current = params
              }}
            </TableGrid>
          </div>
        </div>
        <div className='sm:order-0  border border-border px-2 py-2 rounded-sm bg-white'>
          <div className='grid grid-cols-3'>
            <div className='flex flex-col'>
              <div className='flex text-lg'>
                Total Contacts
              </div>
              <div className='text-xl font-bold'>
                {isLoadingCounts ? 'Loading...' : usersCounts}
              </div>
            </div>
          </div>
          <div className='my-4'>
            <label className='text-sm font-bold text-gray-600'>List Title</label>
            <input type='text' id='new-list-title' value={title} onChange={(e) => setTitle(e.target.value)} className='border border-border px-3.5 py-1.5 w-full' />
          </div>
          <div className='flex flex-col gap-y-2 my-4'>
            <div className='text-sm font-bold text-gray-600'>
              List Segment Filters
            </div>
            {filters.map((filter, index) => {
              const filterKey = filter.key
              const filterInputs = filter.inputs || {}
              const selectedFilter = AvailableFilters.find((f) => f.key === filterKey)
              if (!selectedFilter) return null
              const { ops } = selectedFilter
              const availableOps = ops
              const selectedOp = availableOps.find((op) => op.key === filter.op)
              const availableInputs = selectedOp?.inputs || []
              return (
                <div
                  key={index}
                  className='flex flex-row justify-between border border-gray-300 px-3 py-2 bg-gray-100 rounded-lg items-center'>
                  <div className='flex gap-x-2'>
                    <div>
                      <select
                        value={filterKey}
                        onChange={(e) => {
                          const _value = e.target.value
                          const newFilters = [...filters]
                          newFilters[index].key = _value
                          const [defaultOp] = AvailableFilters.find((f) => f.key === _value)?.ops
                          newFilters[index].op = defaultOp?.key ?? ''
                          newFilters[index].inputs = {}
                          setFilters(newFilters)
                        }}
                        className='border border-border rounded-full px-3.5 py-1.5 min-w-fit pr-8'
                      >
                        {AvailableFilters.map((filter) => {
                          return (
                            <option key={`filter-${filter.key}`} value={filter.key}>
                              {filter.label}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    <div>
                      <select
                        id='op'
                        value={filter.op}
                        onChange={(e) => {
                          const _value = e.target.value
                          const newFilters = [...filters]
                          newFilters[index].op = _value
                          setFilters(newFilters)
                        }}
                        className='border border-border rounded-full px-3.5 py-1.5 min-w-fit pr-8 w-full'
                      >
                        {availableOps.map((op) => {
                          return (
                            <option
                              key={`${op.key}-${op.label}`} value={op.key}>
                              {op.label}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    <div>
                      {availableInputs.map((input) => {
                        const type = input.type
                        const inputKey = input.key
                        const inputVal = filterInputs[inputKey]
                        const options = input.options
                        const selectedOptions = []
                        if (typeof inputVal === 'object' && Array.isArray(inputVal)) {
                          inputVal.forEach((val) => {
                            const selectedOption = options.find((option) => option.value === val)
                            if (selectedOption) {
                              selectedOptions.push(selectedOption.value)
                            }
                          })
                        }
                        return (
                          <div
                            key={JSON.stringify(input)}
                          >
                            {type === 'text' && (
                              <input
                                type='text'
                                value={inputVal}
                                onChange={(e) => {
                                  const _value = e.target.value
                                  updateInputField(
                                    index,
                                    inputKey,
                                    _value
                                  )
                                }}
                                className='border border-border rounded-full px-3.5 py-1.5 min-w-fit pr-6'
                              />
                            )}
                            {type === 'date' && (
                              <Input
                                type='date'
                                value={inputVal}
                                onChange={(e) => {
                                  const _value = e.target.value
                                  updateInputField(
                                    index,
                                    inputKey,
                                    _value
                                  )
                                }}
                                className='border border-border rounded-full px-3.5 py-1.5 min-w-fit pr-6'
                              />
                            )}
                            {type === 'select' && (
                              <select
                                value={inputVal}
                                onChange={(e) => {
                                  const _value = e.target.value
                                  updateInputField(
                                    index,
                                    inputKey,
                                    _value
                                  )
                                }}
                                className='border border-border rounded-full px-3.5 py-1.5 min-w-fit pr-6'
                              >
                                <option value=''>Select Option</option>
                                {options.map((option) => {
                                  return (
                                    <option key={option.value} value={option.value}>
                                      {option.label}
                                    </option>
                                  )
                                })}
                              </select>
                            )}
                            {type === 'select-multiple' && (
                              <CustomSelect
                                multiple
                                disabled={false}
                                label={''}
                                options={options.map((option) => {
                                  return ({
                                    id: option.value,
                                    value: option.label
                                  })
                                })}
                                selectedOption={selectedOptions}
                                setSelectedOption={(value) => {
                                  updateInputField(
                                    index,
                                    inputKey,
                                    value
                                  )
                                }}
                                labelClasses={'py-0'} inputClasses={'h-[38px] bg-white border border-gray-900 rounded-full px-3.5 py-0 pr-6 min-w-[200px]'} defaultValue={null} />
                            )}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className='h-full flex items-center justify-center'>
                    <button onClick={() => {
                      removeFilter(index)
                    }}
                      className='bg-red-500 text-white text-sm px-2 py-1 rounded-lg hover:bg-red-600'>
                      Remove
                    </button>
                  </div>
                </div>
              )
            }
            )}
            <div>
              <button onClick={() => {
                addFilter()
              }} className='bg-gray-200 border border-gray-400 text-black  hover:text-white px-3.5 py-1.5 hover:bg-gray-600'>
                + Add Filter
              </button>
            </div>
          </div>
          <div className='flex flex-row gap-x-2'>
            <div>
              <CustomButton
                className='whitespace-nowrap px-2 py-2'
                disabled={props.previewing}
                handleClick={(event) => {
                  event.preventDefault()
                  getDataForPreview()
                }}>
                {props.previewing ? <Loader size='20' className='animate-spin' /> : null}
                {props.previewing ? 'Loading...' : 'Reload Preview'}
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

// Content Component inside the Modal
const ComponentCreateStaticList = (
  props
) => {
  const isCorporate = props.isCorporate ?? false
  const disable = props.disable ?? false
  const gridRef = useRef(null)
  const [title, setTitle] = useState(props.listData?.title || '')
  const { projectId: defaultProject } = useProjectId()
  const [projectId] = useState(props?.projectId ?? defaultProject)
  const { count: usersCounts, isLoading: isLoadingCounts } = useGetUsersCounts(projectId)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [creating, setCreating] = useState(false)
  const [loadingContactsPreview, setLoadingContactsPreview] = useState(false)
  const [previewContacts, setPreviewContacts] = useState([])
  const reloadTheContactPreview = useCallback(() => {
    setLoadingContactsPreview(true)
    api.post('/users/byIds', {
      ids: selectedUsers
    }).then((response) => {
      setPreviewContacts(response?.data?.data)
    }).catch((error) => {
      console.error('Error loading contacts preview', error)
    }).finally(() => {
      setLoadingContactsPreview(false)
    })
  }, [selectedUsers])

  useEffect(() => {
    reloadTheContactPreview()
  }, [reloadTheContactPreview, selectedUsers])

  function handleSubmit(type) {
    const _title = String(title).trim()
    if (!_title) {
      alert('Please enter a title for the list')
      document.getElementById('new-list-title')?.focus()
      return
    }
    const confirm = window.confirm(
      `You are about to create ${type}-List with name "${title}", that will have ${selectedUsers.length} contacts. Do you want to proceed?`
    )
    if (!confirm) return
    const newList = {
      title,
      projectId: !isCorporate ? projectId : null,
      isCorporate,
      users: selectedUsers.map((user) => user)
    }
    setCreating(true)
    api.post('/lists', newList).then((response) => {
      alert('List created successfully')
      if (props.onCreated) {
        props?.onCreated(response.data)
      }
    }).catch((error) => {
      alert(error)
    }).finally(() => {
      setCreating(false)
    })
  }
  return (
    <div className='font-openSans flex flex-col gap-y-2 h-full px-4 md:py-3.5 lg:py-5 rounded min-w-3xl w-full bg-siteColor'>
      <div className='w-full flex justify-between'>
        <div className='text-2xl font-bold text-gray-800'>
          Create Static List {isCorporate ? ' (Corporate List)' : '(Project List)'}
        </div>
        <div className='flex flex-row gap-x-2 '>
          <CustomButton
            disabled={disable || creating}
            className='whitespace-nowrap min-w-[150px] px-3.5 py-1.5 flex gap-x-1 bg-green-500 text-white'
            handleClick={
              () => {
                if (disable) return
                if (creating) return
                handleSubmit('static')
              }
            }>
            <PlusCircle size='20' />
            <span>
              {creating ? 'Creating...' : 'Create Static List'}
            </span>
          </CustomButton>
          <button
            disabled={disable}
            onClick={() => {
              if (disable) return
              props?.closeModal()
            }}
            className='bg-red-500 text-white px-4 py-2 text-sm rounded-lg hover:bg-red-600'
          >
            Cancel Model
          </button>
        </div>
      </div>
      <div className='flex flex-col gap-y-2'>
        <div>
          <div className='w-fit border border-border px-2.5 py-2 rounded-sm flex flex-col gap-0 bg-white'>
            <div className='flex text-base'>
              Total Contacts
            </div>
            <div className='text-lg font-semibold'>
              {isLoadingCounts ? 'Loading...' : usersCounts}
            </div>
          </div>
        </div>

      </div>
      <div>
        <div className='flex w-full justify-between gap-x-6'>
          <div className='flex-1'>
            <label className='text-sm font-bold text-gray-600'>List Title</label>
            <input type='text' id='new-list-title' value={title} onChange={(e) => setTitle(e.target.value)} className='border border-border px-3.5 py-1.5 w-full' />
          </div>
          <ModelAddContacts
            existingUserIds={selectedUsers}
            onAdded={(contacts) => {
              const finalContacts = [...selectedUsers, ...contacts]
              setSelectedUsers(finalContacts)
            }} />
        </div>
        <div className='flex flex-col gap-y-2'>
          <div className='flex justify-between pt-4 items-center'>
            <div className='text-sm font-semibold text-gray-600'>
              Selected Contacts ({selectedUsers.length})
            </div>
            <div>
              {selectedUsers.length > 0 && (
                <CustomButton
                  disabled={loadingContactsPreview}
                  onClick={reloadTheContactPreview} className='text-sm font-bold text-gray-600 px-3.5 py-1.5'>
                  {loadingContactsPreview && <Loader2 size={16} className='animate-spin' />}
                  {loadingContactsPreview ? 'Loading...' : 'Reload Preview'}
                </CustomButton>
              )}
            </div>
          </div>
          <div className='border border-border p-2 rounded-md'>
            <div className='flex flex-wrap gap-2'>
              <div className='flex-1 w-full flex flex-col gap-4 pt-2 pb-4'>
                <div className="w-full overflow-hidden ag-theme-alpine min-h-[500px] max-h-[60vh]">
                  {selectedUsers.length > 0 && !loadingContactsPreview && (
                    <AgGridReact
                      ref={gridRef}
                      columnDefs={[
                        {
                          headerName: 'No.',
                          field: 'id',
                          width: 70,
                          cellRenderer: ({ rowIndex }) => {
                            return <div className='flex items-center justify-between w-full'>
                              <div>
                                {rowIndex + 1}
                              </div>
                            </div>
                          }
                        },
                        {
                          headerName: 'Email',
                          field: 'email',
                          flex: 2
                        },
                        {
                          headerName: 'First Name',
                          field: 'firstName'
                        },
                        {
                          headerName: 'Last Name',
                          field: 'lastName'
                        },
                        {
                          headerName: '#',
                          field: 'id',
                          width: 100,
                          cellRenderer: (data) => {
                            const rowIndex = data.rowIndex
                            return <button
                              className='bg-red-500 text-white px-1.5 py-0.5 rounded-lg hover:bg-red-600'
                              onClick={() => {
                                setSelectedUsers((e) => {
                                  return e.filter((_, i) => i !== rowIndex)
                                })
                              }}>
                              Remove
                            </button>
                          }
                        }
                      ]}
                      rowData={previewContacts}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

const CardRadio = ({ id, title, Icon, onClick, selected }) => {
  return (
    <div
      id={id}
      onClick={onClick}
      className={clsx(
        'group p-4 md:p-0 cursor-pointer flex flex-col w-full items-center justify-center h-[120px] bg-menuGray rounded overflow-hidden border transition-all duration-200',
        selected
          ? 'border-softBlack shadow-md bg-softBlack/5 '
          : 'border-transparent hover:border-softBlack/50 hover:bg-softBlack/10'
      )}
    >
      {Icon && (
        <span className={clsx(
          'px-1.5 flex items-center justify-center transition-colors duration-200 scale-100',
          selected ? 'text-softBlack' : 'text-softBlack/80 group-hover:text-softBlack'
        )}>
          {Icon}
        </span>
      )}
      <span className={clsx(
        'mt-4 font-light text-base italic transition-colors duration-200',
        selected ? 'text-softBlack' : 'text-softBlack/80 group-hover:text-softBlack'
      )}>
        {title}
      </span>
    </div>
  )
}

function ChooseListType(props) {
  const [selectedRadio, setSelectedRadio] = useState('')
  const [selectedListType, setSelectedListType] = useState(props.selectedListType ?? 'project')
  const onMethodSelected = props.onMethodSelected

  useEffect(() => {
    setSelectedRadio('')
  }, [props.show])

  return <div>
    <Modal
      open={props.show}
      onClose={() => {
        onMethodSelected?.({
          method: null,
          type: null
        })
      }}
      aria-labelledby="Contact Edit Method Selection Modal"
      aria-describedby="Contact Edit Method Selection Modal description"
    >
      <div
        className="font-openSans relative bg-white p-5 md:p-7 lg:p-10 text-center flex flex-col items-center justify-center text-softBlack rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 overflow-hidden"
        style={{
          width: isMobile ? '95%' : 1015
          // maxHeight: isMobile ? '95vh' : 600
        }}
      >
        <button
          className="absolute right-5 md:right-7 lg:right-10 top-5 md:top-7 lg:top-10"
          onClick={
            () => {
              onMethodSelected?.({
                method: null,
                type: null
              })
            }
          }
        >
          <XIcon />
        </button>
        <h1 className="font-bold text-xl text-softBlack">
          Create New List ({selectedListType === 'corporate' ? 'Corporate Level' : 'Project Level'})
        </h1>
        <div>
          <div>
            Choose the type of list you want to create
          </div>
          <div className="w-full flex flex-row md:mx-10 mt-2 md:mt-8 gap-1.5 my-3">
            <div>
              Project List
            </div>
            <div>
              <Switch
                checked={selectedListType === 'corporate'}
                onChange={() => {
                  setSelectedListType(selectedListType === 'corporate' ? 'project' : 'corporate')
                }}
                style={{
                  background: selectedListType === 'corporate' ? '#4F46E5' : '#E5E5E5',
                  outline: 'none'
                }}
              />
            </div>
            <div>
              Corporate List
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col md:flex-row md:mx-10 mt-2 md:mt-8 gap-4 md:gap-x-6">
          <CardRadio
            id="Static List"
            key='static-list'
            title="Static List"
            selected={selectedRadio === 'static-list'}
            onClick={() => {
              setSelectedRadio('static-list')
            }}
            Icon={<PackageIcon size={42} />}
          />
          <CardRadio
            id="smart-list"
            key={'smart-list'}
            title="Smart List"
            selected={selectedRadio === 'smart-list'}
            onClick={() => {
              setSelectedRadio('smart-list')
            }}
            Icon={<PackageIcon size={42} />}
          />
        </div>
        {selectedRadio && selectedListType && (
          <div className="flex mt-8 justify-center">
            <CustomButton
              disabled={!selectedRadio}
              className="w-fit mx-auto"
              handleClick={() => {
                onMethodSelected({
                  method: selectedRadio,
                  type: selectedListType
                })
              }}
            >
              <span className="font-medium py-2 px-6 text-base flex gap-2 items-center">
                Continue
              </span>
            </CustomButton>
          </div>
        )}
      </div>
    </Modal>
  </div>
}

function ModelAddContacts({ onAdded, existingUserIds }) {
  const [show, setShow] = React.useState(false)
  const handleClose = () => { setShow(false) }
  const tableRef = React.useRef({
    reloadTableData: () => { }
  })
  const [searchFilter, setSearchFilter] = React.useState('')
  return (
    <div className='w-fit'>
      <button onClick={() => setShow(true)} className='w-fit bg-black text-white font-bold py-2 px-4 rounded'>
        Add Contacts
      </button>
      <CustomModal
        isOpen={show}
        closeModal={handleClose}
        contentLabel="Add Contacts to List"
      >
        <div className='w-[100%] min-w-[600px] sm:min-w-[900px] p-2.5'>
          <div className='flex justify-between w-full'>
            <div>
              Add Contacts to the list
            </div>
            <div>
              <button onClick={handleClose} className='bg-red-500 hover:bg-red-700 text-white font-bold py-0.5 px-2.5 rounded'>
                Cancel
              </button>
            </div>
          </div>
          <div>
            <input
              type='text'
              placeholder='Search by Name or email'
              className='w-full border border-gray-300 rounded-md px-2 my-1'
              value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)} />
          </div>
          <div>
            <TableGrid
              multiple
              style={{ height: '470px', minHeight: '470px' }}
              allowSelect
              allowSelectAll
              rowSelectKey='id'
              columnDefs={[
                {
                  headerName: 'Email',
                  field: 'email',
                  sortable: true,
                  checkboxSelection: true,
                  flex: 1
                },
                {
                  headerName: 'First Name',
                  field: 'firstName',
                  sortable: true
                },
                {
                  headerName: 'Last Name',
                  field: 'lastName',
                  sortable: true
                },
                {
                  headerName: 'User Type',
                  field: 'userType',
                  sortable: true,
                  cellRenderer: ({ value }) => {
                    const userType = value
                    const availableTags = [
                      ['buyer', 'bg-blue-500 text-white'],
                      ['DeveloperAdmin', 'bg-red-500 text-white'],
                      ['CoopBroker', 'bg-green-500 text-white'],
                      ['LeadBroker', 'bg-green-500 text-white'],
                      ['SalesRep', 'bg-orange-500 text-white']
                    ]
                    return <div>
                      {/* eslint-disable-next-line array-callback-return */}
                      {availableTags.map(([tag, color]) => {
                        if (userType.toLowerCase() === tag.toLowerCase()) {
                          return (
                            <div className={
                              `capitalize text-gray-800 border border-gray-300 px-2 py-1 rounded-full text-xs ${color}`
                            }>
                              {tag}
                            </div>
                          )
                        }
                      })}
                    </div>
                  }
                }
              ]}
              actions={[
                {
                  label: 'Add to List',
                  progressLabel: 'Adding...',
                  onlyWhenSelected: true,
                  apply: async (selectedRows) => {
                    try {
                      const uniqueContacts = new Set(selectedRows.map((row) => row))
                      onAdded?.(uniqueContacts)
                      handleClose()
                    } catch (e) {
                      alert('Error adding contacts to list' + e.message)
                    }
                  }
                }
              ]}
              getData={async (
                filter,
                pagination,
                sorting
              ) => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise(async (resolve) => {
                  const searchText = {}
                  let search = searchFilter
                  if (search) {
                    if (!isNaN(Number(search))) {
                      search = ''
                    }
                    if (search) {
                      if (!searchText?.$or) {
                        searchText.$or = []
                      }
                      searchText.$or = [
                        { email: { $regex: search, $options: 'i' } },
                        { firstName: { $regex: search, $options: 'i' } },
                        { lastName: { $regex: search, $options: 'i' } }
                      ]
                    }
                  }
                  const { docs: users } = await getUsersPagination({
                    _id: { $nin: existingUserIds },
                    ...searchText,
                    ...filter
                  }, {
                    ...pagination,
                    sort: sorting
                  })
                  resolve(users)
                })
              }} >
              {(params) => {
                tableRef.current = params
              }}
            </TableGrid>
          </div>
        </div>
      </CustomModal >
    </div >
  )
}
