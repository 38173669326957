// @ts-nocheck
/* eslint-disable */
import React, {useState, useEffect} from 'react';
import {Modal} from '@material-ui/core';
import {ReactComponent as CrossIcon} from 'assets/icons/cross.svg';
import UseCSV from '@usecsv/react';
import useContactContext from 'pages/newContacts/hooks/useContactContext';
import CustomButton from 'components/CustomButton';
import useGetLists from 'hooks/useGetLists';
import {Select} from 'antd';
import Checkbox from 'components/Checkbox';
import AddOrEditBulkContact from 'components/AddBulkContact';
import CreateSMSBroadcast from './CreateSMSBroadcast';

const CreateSMSBroadcastModal = ( {show, onSuccess, setShow, project, filter} ) => {
  const handleClose = () => {
    setShow( false );
    onSuccess();
  };

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="Add Or Edit Bulk Contact Modal"
      aria-describedby="Add Or Edit Bulk Contact description"
      className="!z-[100]"
    >
      <div
        className="relative flex flex-col font-openSans bg-grey-8 p-10 z-[10] rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        style={{width: 'calc(100% - 50px)', height: 'calc(100% - 50px)'}}
      >
        <button className="absolute right-10" onClick={handleClose}>
          <CrossIcon />
        </button>
        <CreateSMSBroadcast filter={filter}/>
      </div>
    </Modal>
  );
};

export default CreateSMSBroadcastModal;
