/*eslint-disable */
// @ts-nocheck
import { Drawer, makeStyles } from '@material-ui/core';
import CustomButton from 'components/CustomButton';
import CustomSelect from 'components/Inventory/components/CustomSelect';
import useGetProjectBuyers from 'hooks/useGetProjectBuyers';
import { SquareArrowOutUpRight } from 'lucide-react';
import React, { useEffect, useMemo, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { Oval } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { getDocuSealContractTemplateByProjectId } from 'store/actions/contractTemplateActions';
import { createOrUpdateSingularContract } from 'store/actions/singularContractsAction';
import CustomModal from "ui/customModal";

const CONTRACT_TYPE = "singularContract"

const IS_ALLOW_TWO_BUYER_ROLE_FOR_SINGULAR_CONTRACT = [true, 'true'].includes(process.env.REACT_APP_IS_ALLOW_TWO_BUYER_ROLE_FOR_SINGULAR_CONTRACT)

const CreateSingularContract = ({ isOpen, onClose, onSuccess = () => { } }) => {
  const projectId = useSelector((state) => state.appReducer.appProject)
  const [templates, setTemplates] = useState([])
  const [selectedTemplates, setSelectedTemplates] = useState([])
  const { loading: loadingBuyers, buyers } = useGetProjectBuyers()
  const [primaryBuyer, setPrimaryBuyer] = useState(null)
  const [secondaryBuyer, setSecondaryBuyer] = useState(null)
  const [isSending, setIsSending] = useState(false)
  const [isOpenConfirmRemoveModal, setIsOpenConfirmRemoveModal] = useState(false)
  const [removeToBeUser, setRemoveToBeUser] = useState(null)

  const useStyles = makeStyles((theme) => ({
    hide: {
      display: 'none',
    },
    drawer: {
      width: '100%',
      flexShrink: 0,
    },
    drawerPaper: {
      width: '100%',
      height: '100vh',
      overflow: 'hidden',
      boxShadow: '-10px 0px 15px 0px rgba(46, 43, 46, 0.05)',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
  }));

  const classes = useStyles();

  const handleClose = () => {
    setSelectedTemplates([])
    setPrimaryBuyer(null)
    setSecondaryBuyer(null)
    setIsOpenConfirmRemoveModal(false)
    setRemoveToBeUser(null)
    onClose()
  }

  const getContracts = () => {
    getDocuSealContractTemplateByProjectId(projectId, CONTRACT_TYPE)
      .then((res) => {
        setTemplates(res ? res?.filter((template) => (template?.external_id && template?.external_id?.length)) : [])
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  useEffect(() => {
    getContracts()
  }, [projectId])

  const isValid = useMemo(() => {
    return primaryBuyer && selectedTemplates?.length > 0
  }, [primaryBuyer, selectedTemplates])

  const sendContract = async () => {
    if (isValid) {
      setIsSending(true)
      const recipients = [primaryBuyer]
      if (secondaryBuyer) {
        recipients.push(secondaryBuyer)
      }

      await createOrUpdateSingularContract({
        // buyers: recipients.map(buyer => buyer._id),
        buyer: primaryBuyer._id,
        secondaryBuyer: secondaryBuyer?._id,
        contractTemplates: selectedTemplates,
        projectId,
        version: IS_ALLOW_TWO_BUYER_ROLE_FOR_SINGULAR_CONTRACT ? 2 : 1
      }).then(() => {
        onSuccess()
        handleClose()
        setIsSending(false)
      }).catch((error) => {
        console.error('error: ', error)
        handleClose()
        setIsSending(false)
      })
    }
  }

  const selectedContractTemplatePreviews = useMemo(() => {
    if (templates?.length > 0 && selectedTemplates?.length > 0) {
      return templates.filter((template) => (selectedTemplates?.find((doc) => (template._id === doc))))?.map((contract) => ({ name: contract.name, image: contract?.documents?.[0]?.preview_image_url, url: contract?.documents?.[0]?.url }))
    }
    return null
  }, [templates, selectedTemplates])

  const closeConfirmRemoveModal = () => {
    setIsOpenConfirmRemoveModal(false)
    setRemoveToBeUser(null)
  }

  const handleRemoveRecipient = () => {
    if (removeToBeUser?._id === primaryBuyer?._id) {
      setPrimaryBuyer(null)
    } else if (removeToBeUser?._id === secondaryBuyer?._id) {
      setSecondaryBuyer(null)
    }
    closeConfirmRemoveModal()
  }

  return (
    <>
      <Drawer
        open={isOpen}
        variant='persistent'
        anchor='right'
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className='p-6 pt-22 relative h-full flex flex-col gap-4'>
          <div className='col-span-2 h-fit flex justify-between items-center'>
            <h2 className='capitalize text-xl font-bold mb-0'>
              Create Singular Contract
            </h2>
            <CustomButton
              handleClick={handleClose}
              variant='outline'
              className='overflow-hidden'
            >
              <span className='text-sm flex items-center gap-2 px-3 py-1.5 hover:bg-zinc-200 transition-all'>
                <IoMdClose fontSize='16px' />
                Close
              </span>
            </CustomButton>
          </div>
          <div className='w-full flex-1 grid grid-cols-2 gap-4 overflow-hidden'>
            <div className='col-span-1 flex flex-col gap-4 h-full overflow-hidden'>
              <div className='w-full border rounded-md p-[16px]'>
                <h3 className='capitalize text-xl font-bold mb-0'>
                  Contract Settings
                </h3>
                <p className='text-xs font-medium text-zinc-400 mb-0 mt-1'>
                  Configure your contract
                </p>
                <div className='pt-8 flex flex-col gap-4'>
                  <CustomSelect
                    label='Choose Contract Templates'
                    value={selectedTemplates}
                    handleChange={(value) => setSelectedTemplates(value)}
                    options={templates?.length > 0 ? templates.map((template) => ({ value: template._id, label: template.name })) : []}
                    placeholder='Select templates'
                    labelClass='text-xs !font-medium capitalize !text-softBlack'
                    disabled={isSending}
                    mode='multiple'

                  />

                  <CustomSelect
                    label='Primary Buyer *'
                    value={primaryBuyer?._id}
                    handleChange={(value) => {
                      const selected = buyers.find(b => b._id === value)
                      setPrimaryBuyer(selected)
                    }}
                    options={buyers?.length > 0 ? buyers.map((buyer) => ({ value: buyer._id, label: buyer.fullName })) : []}
                    placeholder='Select primary buyer'
                    labelClass='text-xs !font-medium capitalize !text-softBlack'
                    showSearch
                    disabled={isSending || loadingBuyers || buyers?.length === 0}
                    loading={loadingBuyers}
                  />

                  <CustomSelect
                    label='Secondary Buyer (Optional)'
                    value={secondaryBuyer?._id}
                    handleChange={(value) => {
                      const selected = buyers.find(b => b._id === value)
                      setSecondaryBuyer(selected)
                    }}
                    options={buyers?.length > 0 ? buyers.filter(b => b._id !== primaryBuyer?._id).map((buyer) => ({ value: buyer._id, label: buyer.fullName })) : []}
                    placeholder='Select secondary buyer'
                    labelClass='text-xs !font-medium capitalize !text-softBlack'
                    showSearch
                    disabled={isSending || loadingBuyers || buyers?.filter(b => b._id !== primaryBuyer?._id)?.length === 0 || !primaryBuyer?._id}
                    loading={loadingBuyers}
                  />
                </div>
              </div>

              <div className='flex-1 flex flex-col gap-8 w-full border rounded-md p-[16px] overflow-hidden'>
                <div className='w-full flex flex-col'>
                  <h3 className='capitalize text-xl font-bold mb-0'>
                    Selected Recipients
                  </h3>
                </div>
                <div className='flex-1 flex flex-col overflow-y-auto thin-scrollbar'>
                  {primaryBuyer && (
                    <div className='w-full p-1 py-2 flex justify-between items-center hover:bg-zinc-100 transition-all'>
                      <div className='flex items-center gap-2'>
                        <div className="h-10 w-10 rounded-full flex items-center justify-center bg-zinc-200 text-text-softBlack_70/50 font-bold">
                          {primaryBuyer?.firstName?.[0]}
                          {primaryBuyer?.lastName?.[0]}
                        </div>
                        <div className='flex flex-col'>
                          <p className='font-medium mb-0'>{primaryBuyer?.fullName} (Primary)</p>
                          <p className='mb-0 leading-none'>{primaryBuyer?.email}</p>
                        </div>
                      </div>
                      <div className='flex items-center gap-2'>
                        <CustomButton
                          handleClick={() => {
                            setIsOpenConfirmRemoveModal(true)
                            setRemoveToBeUser(primaryBuyer)
                          }}
                          variant='ghost'
                          className='overflow-hidden'
                          disabled={isSending}
                        >
                          <span className='text-xs font-medium flex items-center gap-2 px-3 py-2 hover:bg-red-100 text-red-500 transition-all'>
                            Remove
                          </span>
                        </CustomButton>
                      </div>
                    </div>
                  )}

                  {secondaryBuyer && (
                    <div className='w-full p-1 py-2 flex justify-between items-center hover:bg-zinc-100 transition-all'>
                      <div className='flex items-center gap-2'>
                        <div className="h-10 w-10 rounded-full flex items-center justify-center bg-zinc-200 text-text-softBlack_70/50 font-bold">
                          {secondaryBuyer?.firstName?.[0]}
                          {secondaryBuyer?.lastName?.[0]}
                        </div>
                        <div className='flex flex-col'>
                          <p className='font-medium mb-0'>{secondaryBuyer?.fullName} (Secondary)</p>
                          <p className='mb-0 leading-none'>{secondaryBuyer?.email}</p>
                        </div>
                      </div>
                      <div className='flex items-center gap-2'>
                        <CustomButton
                          handleClick={() => {
                            setIsOpenConfirmRemoveModal(true)
                            setRemoveToBeUser(secondaryBuyer)
                          }}
                          variant='ghost'
                          className='overflow-hidden'
                          disabled={isSending}
                        >
                          <span className='text-xs font-medium flex items-center gap-2 px-3 py-2 hover:bg-red-100 text-red-500 transition-all'>
                            Remove
                          </span>
                        </CustomButton>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <CustomButton
                handleClick={sendContract}
                disabled={!isValid || isSending}
              >
                <span className='text-base font-medium flex items-center gap-2 px-3 py-2'>
                  {isSending && <Oval height='18' width='18' color='white' />}
                  Send singular contract
                </span>
              </CustomButton>
            </div>
            <div className='col-span-1 h-full overflow-hidden'>
              <div className='w-full h-full flex flex-col gap-3 border rounded-md p-[16px] overflow-hidden'>
                <div className='w-full flex flex-col'>
                  <h3 className='capitalize text-xl font-bold mb-0'>
                    Singular Contract Template Preview
                  </h3>
                  <p className='text-xs font-medium text-zinc-400 mb-0 mt-1'>
                    Review the contract template before sending
                  </p>
                </div>
                {
                  selectedContractTemplatePreviews && selectedContractTemplatePreviews?.length > 0
                    ? <div className='w-full flex-1 flex flex-col overflow-y-auto gap-5'>
                      {
                        selectedContractTemplatePreviews?.map((contract, index) => (
                          <div key={index} className='w-full flex flex-col gap-4'>
                            <div className='w-full flex items-center justify-between gap-4 overflow-hidden'>
                              <h4 className='flex-1 capitalize text-lg font-semibold mb-0 truncate'>
                                {index + 1}. {contract?.name}
                              </h4>
                              <a target='_blank' href={contract?.url}>
                                <CustomButton variant="outline">
                                  <span className="font-medium py-1.5 px-3.5 text-sm flex gap-2 items-center">
                                    <SquareArrowOutUpRight className='w-4 h-4' />
                                    View
                                  </span>
                                </CustomButton>
                              </a>
                            </div>
                            <img src={contract?.image} className='w-full object-contain rounded-md border' />
                          </div>
                        ))
                      }
                    </div>
                    : <div className='flex-1 flex justify-center items-center'>
                      <p className='text-sm font-medium text-zinc-400 mb-0 mt-1 text-center'>
                        Select a contract template and recipients to preview
                      </p>
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <CustomModal
        showModal={isOpenConfirmRemoveModal}
        onCancel={closeConfirmRemoveModal}
        onOk={handleRemoveRecipient}
        minHeight={false}
      >
        <div className="min-w-[95%] md:min-w-[500px] md:min-h-[200px] flex flex-col gap-3">
          <h2 className="text-2xl mb-0">Are you sure?</h2>
          <h4 className="text-xl mb-2">
            Do you want to remove
            <span className='ml-1.5 font-bold'>
              {removeToBeUser?.fullName}
            </span>
            ?
          </h4>
          <div className="flex-1 w-full mt-2 flex justify-center md:justify-end items-end gap-3">
            <CustomButton
              variant="ghost"
              handleClick={closeConfirmRemoveModal}
              className="min-w-[150px]"
            >
              <span className="font-medium py-2 px-4 text-base flex gap-2 items-center">
                Cancel
              </span>
            </CustomButton>
            <CustomButton variant="danger" handleClick={handleRemoveRecipient} className="min-w-[150px]">
              <span className="font-medium py-2 px-4 text-base flex gap-2 items-center">
                Yes
              </span>
            </CustomButton>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default CreateSingularContract;
