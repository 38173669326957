/* eslint-disable */
import React from 'react'
import { ArrowUp, ArrowDown } from 'lucide-react'
import { MockDataOverlay } from './charts'

const KPICard = ({
  title,
  value,
  icon: Icon,
  trend = {
    value: 0,
    text: '',
    isPositive: true,
    hidden: false
  },
  progress = null,
  isLoading = false
}) => {
  return (
    <div className='bg-white rounded-lg p-3 shadow-sm relative'>
      <div className='flex flex-row items-center justify-between pb-1'>
        <h3 className='text-sm font-medium text-[#1F2937]'>{title}</h3>
        {Icon && <Icon className='h-4 w-4 text-[#222222]' />}
      </div>
      <div className='pt-0'>

        {isLoading
          ? (
            <div className="animate-pulse">
              <div className="h-8 w-28 bg-gray-200 rounded mb-1"></div>
              <div className="h-4 w-20 bg-gray-200 rounded"></div>
            </div>
          ) : (
            <>
              <MockDataOverlay />
              <div className='text-2xl font-bold text-[#1F2937]'>{value || 'No data'}</div>
              {trend && !trend.hidden && (
                <p className='text-xs text-[#1F2937] mt-1 flex items-center'>
                  <span className={`${trend.isPositive ? 'text-emerald-500' : 'text-red-500'} flex items-center mr-1`}>
                    {trend.isPositive
                      ? <ArrowUp className='h-3 w-3 mr-1' />
                      : <ArrowDown className='h-3 w-3 mr-1' />
                    }
                    {trend.value || '0%'}
                  </span>
                  {trend.text || 'Awaiting data'}
                </p>
              )}
              {progress && (
                <>
                  <div className='mt-2 h-1 w-full bg-[#E4E4E7] rounded-full overflow-hidden'>
                    <div
                      className='bg-blue-500 h-1 rounded-full'
                      style={{ width: `${progress.percentage || 0}%` }}
                    />
                  </div>
                  <p className='text-xs text-[#1F2937] mt-1'>{progress.text || 'No data available for the selected time period.'}</p>
                </>
              )}
              <MockDataOverlay />
            </>
          )}
      </div>

    </div>
  )
}

export default KPICard
