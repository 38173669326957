import React from 'react'

const CustomButton = ({
  disabled = false,
  handleClick = () => { },
  children,
  variant = 'default',
  className = '',
  ...props
}) => {
  const variants = {
    default: 'bg-[#18181B] border border-[#18181B] text-white',
    outline: 'bg-white border border-[#18181B] text-[#18181B]',
    ghost: 'bg-transparent border-none text-[#18181B] hover:!bg-zinc-200',
    volt: 'bg-[#18181B] border border-[#18181B] text-white',
    danger: 'bg-red-500 border border-red-500 text-white',
    ghost_2: 'bg-transparent border-none text-[#18181B] hover:bg-[#18181B3c]',
    danger_outline: 'bg-white border !border-red-500 text-red-500 hover:!bg-red-100'
  }

  return (
    <button
      className={`${variants[variant]} rounded-md flex gap-1 items-center justify-center transition-all focus:outline-none focus:ring-0 ${className}`}
      disabled={disabled}
      onClick={handleClick}
      {...props}
    >
      {children}
    </button>
  )
}

export default CustomButton
