// @ts-nocheck
/* eslint-disable */
import { Box, Button } from "@material-ui/core";
import CustomSelect from "components/VerticalCardProfile/components/CustomSelect";
import AddForm from "pages/desktopMode/demandNotes/AddForm";
import AddFormCorporateDemand from "pages/desktopMode/corporateDemandNotes/AddForm";
import ConfirmDelete from "pages/desktopMode/demandNotes/confirmDelete";
import DemandNotesContextProvider, {
  DemandNotesContext,
} from "pages/desktopMode/demandNotes/context";
import Table from "pages/desktopMode/demandNotes/Table/index";
import React, { useEffect, useContext, useState } from "react";
import {
  getCorporatDemandNotes,
  GetDemandNotes,
} from "store/actions/demandNotes";
import { getUserDataByType } from "utils/getUserDataByType";
import { ContactContext } from "./context/Contacts";
import useUpdateUsers from "hooks/useUpdateUsers";
import toast from "react-hot-toast";
import CustomButton from "components/CustomButton";
import { Divider } from "antd";
import useGetCurrentProject from "hooks/useGetCurrentProject";
const RightArrowIcon = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.15832 6.675L10.975 10.5L7.15833 14.325L8.33333 15.5L13.3333 10.5L8.33332 5.5L7.15832 6.675Z"
      fill="#2E2B2E"
    />
  </svg>
);

export const DemandTab = ({ contact, isInsideActivityTab = false }) => {
  const { userType } = contact ?? {};
  const userDataObject = getUserDataByType(userType, contact);
  const { dropdownData, updateContact } = useContext(ContactContext);
  const { data: currentProject } = useGetCurrentProject();
  const { createOrUpdateUser } = useUpdateUsers();
  const [open, setOpen] = React.useState(false);
  const [corporateDemandModal, setCorporateDemandModal] = React.useState(false);
  const { label } = useContext(DemandNotesContext);
  const [isEdit, setIsEdit] = React.useState(false);
  useEffect(() => {
    if (isEdit) {
      setOpen(true);
    }
  }, [isEdit]);

  const [corporateDemands, setCorporateDemands] = useState(
    userDataObject?.corporateDemands ?? []
  );

  const [customDemands, setCustomDemands] = useState(
    userDataObject?.customDemands ?? []
  );
  const [extraDemands, setExtraDemands] = useState(null);
  const [corporateDemandsTotal, setCorporateDemandsTotal] = useState(null);
  const [initialValues, setInitialValues] = useState({
    label: "",
    options: [],
    isMulti: false,
  });
  const getCustomDemandFields = async () => {
    const data = await GetDemandNotes();
    if (data) {
      const formatedData = await data.map((item) => {
        return {
          label: item.label,
          options: item.options.map((option) => {
            return {
              label: option.label,
              value: option.value,
              _id: option.value,
            };
          }),
        };
      });
      setExtraDemands(formatedData);
    }
  };

  const getCorporateDemands = async () => {
    const data = await getCorporatDemandNotes();
    if (data) {
      const formatedData = data.map((item) => {
        return {
          label: item._id,
          title: item.label,
          isMulti: item.isMulti,
          options: item.options.map((option) => {
            return {
              label: option.label,
              value: option.id,
              _id: option.id,
            };
          }),
        };
      });
      setCorporateDemandsTotal(formatedData);
    }
  };

  useEffect(() => {
    getCorporateDemands();
    getCustomDemandFields();
  }, []);
  return (
    <DemandNotesContextProvider>
      <div
        className={`${
          !isInsideActivityTab && "px-6"
        } h-full w-full flex flex-col`}
      >
        <ConfirmDelete />
        <div
          className={`${
            !isInsideActivityTab ? "py-8 gap-10" : "gap-6"
          } flex flex-col`}
        >
          {currentProject?.showCorporateDemandNote && (
            <div
              className={`p-4.5 border flex flex-col ${
                isInsideActivityTab
                  ? "gap-4 rounded"
                  : "gap-8 shadow rounded-sm"
              }`}
            >
              <div className="flex xl:max-w-[80%] justify-between gap-y-2">
                <h2 className="text-xl font-semibold">Corporate Demands</h2>
                {!isInsideActivityTab && (
                  <div className="flex w-fit">
                    <CustomButton
                      handleClick={() =>
                        setCorporateDemandModal(!corporateDemandModal)
                      }
                    >
                      <span className="font-medium py-2 px-4 text-base flex items-center space-x-2">
                        {isEdit ? "Update" : "Create"}
                      </span>
                    </CustomButton>
                  </div>
                )}
              </div>

              <div
                className={`${
                  isInsideActivityTab
                    ? "flex flex-col gap-5"
                    : "grid grid-cols-4 xl:max-w-[80%] gap-x-8 gap-y-6"
                }`}
              >
                {corporateDemandsTotal &&
                  corporateDemandsTotal.map((demand, index) => {
                    return (
                      <div className="w-full col-span-3 xl:col-span-2">
                        <CustomSelect
                          key={index}
                          mode={demand.isMulti ? "tags" : ""}
                          label={demand.title}
                          value={
                            corporateDemands.find(
                              (corporateDemand) =>
                                corporateDemand.label === demand.label
                            )?.values ?? []
                          }
                          handleChange={(value) => {
                            const newCorporateDemands = [...corporateDemands];
                            const demandIndex = newCorporateDemands.findIndex(
                              (corporateDemand) =>
                                corporateDemand.label === demand.label
                            );
                            if (demandIndex === -1) {
                              newCorporateDemands.push({
                                label: demand.label,
                                values: value,
                              });
                            } else {
                              newCorporateDemands[demandIndex] = {
                                label: demand.label,
                                values: value,
                              };
                            }
                            setCorporateDemands(newCorporateDemands);
                            const newUserData = { ...contact };
                            if (newUserData?.userType === "Buyer") {
                              newUserData.buyerData.corporateDemands =
                                newCorporateDemands;
                            } else if (newUserData?.userType === "LeadBroker") {
                              newUserData.leadBrokerData.corporateDemands =
                                newCorporateDemands;
                            }
                            try {
                              updateContact(newUserData);
                              createOrUpdateUser(newUserData, false, false);
                              toast.success(
                                `${demand.title} updated successfully`
                              );
                            } catch (err) {
                              setCorporateDemands(corporateDemands);
                              console.error(
                                `${demand.title} couldn't be updated`,
                                err
                              );
                            }
                          }}
                          options={demand.options}
                        />
                      </div>
                    );
                  })}
                {isInsideActivityTab && (
                  <CustomButton
                    handleClick={() =>
                      setCorporateDemandModal(!corporateDemandModal)
                    }
                    className="w-full"
                  >
                    <span className="font-medium py-2 px-4 text-base flex items-center space-x-2">
                      {isEdit ? "Update" : "Create"} Corporate Demands
                    </span>
                  </CustomButton>
                )}
              </div>
            </div>
          )}
          {currentProject?.showProjectDemandNote && (
            <div
              className={`p-5 border flex flex-col ${
                isInsideActivityTab
                  ? "gap-4 rounded"
                  : "gap-8 shadow rounded-sm"
              }`}
            >
              <div className="flex xl:max-w-[80%] justify-between gap-y-2">
                <h2 className="text-xl font-semibold">Project Demands</h2>
                {!isInsideActivityTab && (
                  <div className="flex w-fit">
                    <CustomButton handleClick={() => setOpen(!open)}>
                      <span className="font-medium py-2 px-4 text-base flex items-center space-x-2">
                        {isEdit ? "Update" : "Create"}
                      </span>
                    </CustomButton>
                  </div>
                )}
              </div>
              <div
                className={`${
                  isInsideActivityTab
                    ? "flex flex-col gap-5"
                    : "grid grid-cols-4 xl:max-w-[80%] gap-x-8 gap-y-6"
                }`}
              >
                {extraDemands !== null ? (
                  extraDemands.map((demand, index) => {
                    return (
                      <div className="w-full col-span-3 xl:col-span-2">
                        <CustomSelect
                          key={index}
                          label={demand.label}
                          value={
                            customDemands.find(
                              (customDemand) =>
                                customDemand.label === demand.label
                            )?.value ?? ""
                          }
                          handleChange={(value) => {
                            const newCustomDemands = [...customDemands];
                            const demandIndex = newCustomDemands.findIndex(
                              (customDemand) =>
                                customDemand.label === demand.label
                            );
                            if (demandIndex === -1) {
                              newCustomDemands.push({
                                label: demand.label,
                                value: value,
                              });
                            } else {
                              newCustomDemands[demandIndex] = {
                                label: demand.label,
                                value: value,
                              };
                            }
                            setCustomDemands(newCustomDemands);
                            const newUserData = { ...contact };
                            if (newUserData?.userType === "Buyer") {
                              newUserData.buyerData.customDemands =
                                newCustomDemands;
                            } else if (newUserData?.userType === "LeadBroker") {
                              newUserData.leadBrokerData.customDemands =
                                newCustomDemands;
                            }
                            try {
                              updateContact(newUserData);
                              createOrUpdateUser(newUserData, false, false);
                              toast.success(
                                `${demand.label} updated successfully`
                              );
                            } catch (err) {
                              setCustomDemands(customDemands);
                              console.error(
                                `${demand.label} couldn't be updated`,
                                err
                              );
                            }
                          }}
                          options={demand.options}
                          isDemandNotes
                          isName
                        />
                      </div>
                    );
                  })
                ) : (
                  <div className="text-[20px] col-span-4 xl:col-span-2">
                    No Demand Notes
                  </div>
                )}
                {isInsideActivityTab && (
                  <CustomButton
                    handleClick={() => setOpen(!open)}
                    className="w-full"
                  >
                    <span className="font-medium py-2 px-4 text-base flex items-center space-x-2">
                      {isEdit ? "Update" : "Create"} Project Demands
                    </span>
                  </CustomButton>
                )}
              </div>
            </div>
          )}
        </div>

        {open && (
          <AddForm
            open={open}
            setOpen={setOpen}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            setRefetch={getCustomDemandFields}
          />
        )}
        {corporateDemandModal && (
          <AddFormCorporateDemand
            open={corporateDemandModal}
            setOpen={setCorporateDemandModal}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            setRefetch={getCustomDemandFields}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            refetch={getCorporateDemands}
          />
        )}
      </div>
    </DemandNotesContextProvider>
  );
};
