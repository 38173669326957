/* eslint-disable */
// @ts-nocheck
import { api, ApiErrors } from 'services/api'

export const UpsertDemandNote = async (data) => {
  try {
    const response = await api.post('demandNotes', data)
    if (response.ok) {
      return response.data
    } else {
      ApiErrors(response)
    }
  } catch (error) {
    console.error(error)
  }
}

export const createOrUpdateCorporateDemandNotes = async (data) => {
  try {
    const response = await api.post('corporate/demandNotes', data)
    if (response.ok) {
      return response.data
    } else {
      ApiErrors(response)
    }
  } catch (error) {
    console.error(error)
  }
}

export const GetDemandNotes = async (oldName = '') => {
  try {
    const response = await api.get(`demandNotes?label=${oldName}`, {})
    if (response.ok) {
      return response.data
    } else {
      ApiErrors(response)
    }
  } catch (error) {
    console.error(error)
  }
}

export const GetDemandNotesByName = async (oldName) => {
  try {
    const response = await api.get(`demandNotes?label=${oldName}`, {})
    if (response.ok) {
      return response.data
    } else {
      ApiErrors(response)
    }
  } catch (error) {
    console.error(error)
  }
}

export const deleteDemandNote = async (oldName) => {
  try {
    const response = await api.delete(`demandNotes?label=${oldName}`, {})
    if (response.ok) {
      return response.data
    } else {
      ApiErrors(response)
    }
  } catch (error) {
    console.error(error)
  }
}

export const deleteCorporateDemandNote = async (id) => {
  try {
    const response = await api.delete(`corporate/demandNotes/${id}`)
    if (response.ok) {
      return response.data
    } else {
      ApiErrors(response)
    }
  } catch (error) {
    console.error(error)
  }
}

export const getCorporatDemandNotes = (data = '') =>
  new Promise((resolve, reject) => {
    api.get(`/corporate/demandNotes?label=${data}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getDemandNotesById = (id) =>
  new Promise((resolve, reject) => {
    api.get(`/demandNotes/${id}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getDemandNotesUsers = (id) =>
  new Promise((resolve, reject) => {
    api.get(`/demandNotes/${id}/users`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getCorporatDemandNotesById = (id) =>
  new Promise((resolve, reject) => {
    api.get(`/corporate/demandNotes/${id}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getCorporatDemandNotesUsers = (id) =>
  new Promise((resolve, reject) => {
    api.get(`/corporate/demandNotes/${id}/users`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })


export const migrateDemandNotes = async (demandNote, corporateDemandNote, mapping) => {
  try {
    const response = await api.post(`demandNotes/migrate`, { demandNote, corporateDemandNote, mapping })
    if (response.ok) {
      return response.data
    } else {
      ApiErrors(response)
    }
  } catch (error) {
    console.error(error)
  }
}
