/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { css } from 'glamor'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { ReactSVG } from 'react-svg'
import { handleLogout } from 'store/actions/authActions'
import { changeAppProject } from 'store/actions/appActions'
import MobileAdminMenuBar from './MobileAdminSideBar'
const styles = css({
  ' svg': {
    height: '100%',
    width: '100%'
  }
})

const DeveloperImage = ({ developerCompanyLogo }) => {
  let image = ''

  // Check if DeveloperLogo is an .svg file
  if (developerCompanyLogo.includes('.svg')) {
    // @ts-ignore
    image = (
      <ReactSVG src={developerCompanyLogo} className="text-black" {...styles} />
    )
  } else {
    // If not, create and image element
    // @ts-ignore
    image = (
      <img
        src={developerCompanyLogo}
        className="h-full w-full"
        alt="Developer Logo"
      />
    )
  }
  return (
    <div className="flex-shrink-0 flex flex-col items-center px-4 my-10">
      {image}
    </div>
  )
}

const AdminSideMenu = (props) => {
  const {
    showMenu,
    setShowMenu,
    userObject: { developerCompanyLogo, userType, projectPermissions },
    appProject,
    listOfProjects,
    changeAppProject,
    handleLogout
  } = props

  const filteredProjects = listOfProjects.filter((project) => {
    if (projectPermissions?.length > 0) {
      return projectPermissions.includes(project._id)
    } else {
      return true
    }
  })

  const [selectedMenuId, setSelectedMenuId] = useState(
    userType === 'CoopBroker' ? 'availability_board' : 'dashboard'
  )
  const [selectedSubMenuId, setSelectedSubMenuId] = useState('')
  const [expandedMenus, setExpandedMenus] = useState([])

  return (
    <>
      <MobileAdminMenuBar
        projects={filteredProjects}
        changeAppProject={changeAppProject}
        appProject={appProject}
        handleLogout={handleLogout}
      />
      {/* <Sider
      showMenu={showMenu}
      setShowMenu={setShowMenu}
    > */}
      {/* {
        developerCompanyLogo && (
          <DeveloperImage developerCompanyLogo={developerCompanyLogo} />
        )
      }

      {
        listOfProjects.length > 1 && (
          <>
            <span className='text-base text-center'>Selected Project</span>
            <InputLabel
              id='appProject'
              key='appProject'
              typeInput='select'
              data={listOfProjects.map((project) => ({ id: project._id, value: project.projectName }))}
              value={appProject}
              onChange={(project) => onChangeAppProject(project)}
              inputWrapperClassName='px-2 pb-2'
            />
          </>
        )
      }

      <AdminMenus
        executeAfterSelect={setShowMenu}
        selectedMenuId={selectedMenuId}
        setSelectedMenuId={setSelectedMenuId}
        selectedSubMenuId={selectedSubMenuId}
        setSelectedSubMenuId={setSelectedSubMenuId}
        expandedMenus={expandedMenus}
        setExpandedMenus={setExpandedMenus}
      />
      <DarkModeSideMenuSelector /> */}
      {/* </Sider> */}
    </>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject,
  appProject: state.appReducer.appProject,
  listOfProjects: state.appReducer.listOfProjects
})

const mapDispatchToProps = {
  changeAppProject,
  handleLogout
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminSideMenu)

AdminSideMenu.propTypes = {
  showMenu: propTypes.bool,
  setShowMenu: propTypes.func,
  userObject: propTypes.object,
  appProject: propTypes.string,
  listOfProjects: propTypes.array,
  changeAppProject: propTypes.func,
  handleLogout: propTypes.func
}

DeveloperImage.propTypes = {
  developerCompanyLogo: propTypes.string
}
