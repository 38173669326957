// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { formatMoney } from 'utils/formatters';
import { Tooltip } from '@material-ui/core';
import { DollarSign, Ruler } from 'lucide-react';
import useInventory from 'components/Inventory/hooks/useInventory';
import { getActiveInventoryStatuses } from 'store/actions/inventoryActions';
import { checkV2Inventory } from 'utils/checkV2Inventory';

const InventoryItemV2 = ({
  heatMapData,
  data,
  inventoryCardFields,
  isDisabled,
  onSelect,
  isAllocateInventory = false,
  type = '',
}) => {
  const [inventoryItem, setInventoryItem] = useState(data);
  const [isSelected, setIsSelected] = useState(false);
  const { selectedInventoryItem } = useInventory();
  const {
    state: { selectedInventoryView, statusColors: defaultStatusColors },
  } = useInventory();
  const headerNameArry = ["number", "price", "status", "floorplan", "image", "files"];
  const fixedFields = ["floor number", "bedroom", "bathroom", "size", "area"]
  const filteredInventoryCard = inventoryCardFields.filter(({headerName, flag}) => {
    return !(headerNameArry.some(field => headerName.toLowerCase().includes(field.toLowerCase()) || field.toLowerCase().includes(headerName.toLowerCase())))
          && !(fixedFields.some(field => headerName.toLowerCase().includes(field.toLowerCase()) || field.toLowerCase().includes(headerName.toLowerCase())))
          && !(flag === "additionalFields")
  });
  const additionalFieldsInventoryCard = inventoryCardFields.filter(({headerName, flag}) => {
    return !(fixedFields.some(field => headerName.toLowerCase().includes(field.toLowerCase()) || field.toLowerCase().includes(headerName.toLowerCase())))
          && flag === "additionalFields"
  });
  let fixedFieldsToShow = inventoryCardFields.filter(({headerName, flag}) => {
    return (headerName.toLowerCase()!='number' 
            && fixedFields.some(field => headerName.toLowerCase().includes(field.toLowerCase()) 
            || field.toLowerCase().includes(headerName.toLowerCase()))) && flag !== "additionalFields";
  })

  // useEffect(() => {
  //   const fetchStatusColors = async () => {
  //     try {
  //       const statuses = await getActiveInventoryStatuses();
  //       const colorMap = statuses.reduce((acc, status) => {
  //         acc[status.name.toLowerCase()] = {
  //           bgColor: status.color || '#808080',
  //           borderColor: status.color || '#808080',
  //           textColor: '#000000' // You might want to calculate contrast color based on background
  //         };
  //         return acc;
  //       }, {});
  //       setStatusColors(colorMap);
  //     } catch (error) {
  //       console.error('Failed to fetch status colors:', error);
  //     }
  //   };

  //   if (checkV2Inventory()) {
  //     fetchStatusColors();
  //   }
  // }, []);

  useEffect(
    () => handleSelect(inventoryItem, selectedInventoryItem),
    [selectedInventoryItem]
  );

  useEffect(() => {
    if(data?.price) {
      const price = data.price.replace(/[\$,]/g, '').trim();
      if(isNaN(price)) { 
        data.price = "" 
      } else { 
        data.price = price 
      };
    }
    setInventoryItem({ ...data });
  }, [data]);

  const handleSelect = (inventoryItem, selectedInventoryItemsTemp) => {
    if (isAllocateInventory) {
      let inventoryItemFound = false;
      if (selectedInventoryItemsTemp.length > 0) {
        for (let i = 0; i < selectedInventoryItemsTemp.length; i++) {
          const inventoryItem2 = selectedInventoryItemsTemp[i];
          if (inventoryItem.id === inventoryItem2.id) {
            setIsSelected(true);
            inventoryItemFound = true;
            break;
          }
        }
      }
      if (!inventoryItemFound) setIsSelected(false);
    }
  };

  const getTextColorFromBgColor = (bgcolor) => {
    try {
      const color_data = hexToRgb(bgcolor);
      if (!color_data) return 'white';
      const { b, g, r } = color_data;
      return Math.round((r * 299 + g * 587 + b * 114) / 1000) > 125
        ? 'black'
        : 'white';
    } catch (error) {
      return 'white';
    }
  };

  const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  };

  const getItemColors = () => {
    if (selectedInventoryView === 'availabilityStatus') {
      if (checkV2Inventory()) {
        const statusColor = inventoryItem?.statusColor;
        return {
          backgroundColor: `${statusColor}`,
          border: `1px solid ${statusColor || '#000000'}`,
          color: getTextColorFromBgColor(statusColor)
        };
      } else {
        return {
          backgroundColor: defaultStatusColors?.[inventoryItem?.status?.toLowerCase()]?.bgColor || '#FFF',
          border: `1px solid ${defaultStatusColors?.[inventoryItem?.status?.toLowerCase()]?.borderColor || '#000000'}`,
          color: defaultStatusColors?.[inventoryItem?.status?.toLowerCase()]?.textColor || '#000000'
        };
      }
    } else {
      return {
        backgroundColor: heatMapData?.[inventoryItem?._id]?.bgColor || '#FFF',
        border: `1px solid ${heatMapData?.[inventoryItem?._id]?.bgColor || '#000000'}`,
        color: heatMapData?.[inventoryItem?._id]?.textColor || '#000000'
      };
    }
  };

  const buttonContent = (inventoryItem) => (
    <div
      className='w-full min-h-[132px] p-3 rounded-[7.07px] flex-col justify-between items-start inline-flex cursor-pointer'
    >
      <div className='flex-col justify-start items-start gap-1 flex w-full'>
        <div className='grid grid-cols-2 items-center w-full'>
          <div className='text-2xl mr-auto font-bold leading-normal truncate tracking-wide'>
            {/* {type === 'Unit' ? inventoryItem?.type : ''}  # */}
            {/* {console.log(inventoryItem['lotData.number'], inventoryCardFields[1].field, inventoryItem)} */}
            {(type === 'Unit' || type === 'Storage') && inventoryItem?.number}
            {type === 'Parking' && inventoryItem?.stall}
          </div>
          <div className='text-xs ml-auto font-normal leading-normal truncate tracking-wide'>
            {inventoryItem?.status && inventoryItem?.status.charAt(0).toUpperCase() + inventoryItem?.status.slice(1)}
          </div>
          <div className='text-xs mr-auto font-normal leading-normal truncate tracking-wide'>
            {
              fixedFieldsToShow?.filter(({headerName}) => "floor number".includes(headerName.toLowerCase()))?.map(({field}) => {
                const [firstField, secondField, thirdField] = field.split('.');
                if(inventoryItem[secondField.toLowerCase()] && inventoryItem[secondField.toLowerCase()][thirdField]) {
                  return <>
                    {'Floor ' + inventoryItem[secondField.toLowerCase()][thirdField]}
                  </>
                } else {
                  return <span className='text-sm font-normal leading-none truncate mr-2 invisible'>
                    {'Floor '}
                  </span>
                }
              })
            }
          </div>
        </div>
        <div className='grid items-start w-full mt-2'>
          <div className='justify-start items-center gap-1 flex'>
              {inventoryItem?.price !== "" 
                ? <span className='text-base font-bold leading-none truncate'>
                    {formatMoney(Math.round(inventoryItem?.price))}
                  </span>
                : <span className='text-base font-bold leading-none truncate invisible'>
                    {"$50,000"}
                  </span>
              }
          </div>
        </div>
        <div className='justify-start items-start gap-4 inline-flex mt-2 w-full'>
          <div className='justify-start items-center gap-1 flex'>
            {
              fixedFieldsToShow?.filter(({headerName}) => "bedrooms".includes(headerName.toLowerCase()))?.map(({field}) => {
                const [firstField, secondField, thirdField] = field.split('.');
                if(inventoryItem[secondField.toLowerCase()] && inventoryItem[secondField.toLowerCase()][thirdField]) {
                  return <span className='text-sm font-normal leading-none truncate mr-2'>
                    {inventoryItem[secondField.toLowerCase()][thirdField] + " BR"}
                  </span>
                } else {
                  return <span className='text-sm font-normal leading-none truncate mr-2 invisible'>
                    {"h" + " BR"}
                  </span>
                }
              })
            }
            {
              fixedFieldsToShow?.filter(({headerName}) => "bathrooms".includes(headerName.toLowerCase()))?.map(({field}) => {
                const [firstField, secondField, thirdField] = field.split('.');
                if(inventoryItem[secondField.toLowerCase()] && inventoryItem[secondField.toLowerCase()][thirdField]) {
                  return <span className='text-sm font-normal leading-none truncate mr-2'>
                    {inventoryItem[secondField.toLowerCase()][thirdField] + " Bath"}
                  </span>
                } else {
                  return <span className='text-sm font-normal leading-none truncate mr-2 invisible'>
                    {"h" + " Bath"}
                  </span>
                }
              })
            }
            {
              fixedFieldsToShow?.filter(({headerName}) => "size".includes(headerName.toLowerCase()))?.map(({field}) => {
                const [firstField, secondField] = field.split('.');
                if(inventoryItem[firstField][secondField]) {
                  return <span className='text-sm font-normal leading-none truncate'>
                    {inventoryItem[firstField][secondField] + " sqft"}
                  </span>
                }
              })
            }
          </div>
        </div>
        {
          additionalFieldsInventoryCard && additionalFieldsInventoryCard.length > 0 && additionalFieldsInventoryCard.map(({ headerName, field }, ind) => {
            const [firstField, secondField, thirdField] = field.split('.');
            if(inventoryItem[secondField.toLowerCase()] && inventoryItem[secondField.toLowerCase()][thirdField]) {
              return (
                <div className='justify-start items-start gap-4 inline-flex mt-1 w-full'>
                  <div className='justify-start items-center gap-1 flex'>
                    <div className='text-xs font-normal leading-none truncate'>
                      {inventoryItem[secondField.toLowerCase()][thirdField]}
                    </div>
                  </div>
                </div>
              )
            }
            return <></>
          })
        }
        {
          filteredInventoryCard && filteredInventoryCard.length > 0 && filteredInventoryCard?.map(({ headerName, field }, ind) => {
            const [firstField, secondField] = field.split('.');
            if(inventoryItem[firstField] && inventoryItem[firstField][secondField]) {
              return (
                <div className='justify-start items-start gap-4 inline-flex mt-1 w-full'>
                  <div className='justify-start items-center gap-1 flex'>
                    <div className='text-xs font-normal leading-none truncate'>
                      {inventoryItem[firstField][secondField]}
                    </div>
                  </div>
                </div>
              )
            }
            return <></>
           })
        }
      </div>
      {/* {inventoryItem?.status?.toLowerCase().includes('reserved') && (
        <div className='px-3 py-1 bg-white rounded-md justify-end items-center gap-2 inline-flex'>
          <div className='text-zinc-950 text-xs font-bold leading-none truncate'>
            Reserved
          </div>
          <div className='justify-end items-center gap-[7.07px] flex'>
            <div className='justify-start items-center gap-[3.54px] flex'>
              <svg
                width='15'
                height='15'
                viewBox='0 0 15 15'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g clip-path='url(#clip0_2009_82)'>
                  <path
                    d='M7.74197 13.2888C10.9974 13.2888 13.6363 10.6499 13.6363 7.39449C13.6363 4.13907 10.9974 1.50018 7.74197 1.50018C5.98138 1.50018 4.40113 2.272 3.32109 3.49576C2.40407 4.5348 1.84766 5.89965 1.84766 7.39449C1.84766 10.6499 4.48654 13.2888 7.74197 13.2888Z'
                    stroke='#D4D4D8'
                    strokeWidth='1.17591'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M13.636 7.39449C13.636 4.13907 10.9971 1.50018 7.7417 1.50018'
                    stroke='#047857'
                    strokeWidth='1.17591'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_2009_82'>
                    <rect
                      width='14.1463'
                      height='14.1463'
                      fill='white'
                      transform='translate(0.668701 0.321228)'
                    />
                  </clipPath>
                </defs>
              </svg>
              <div className='text-zinc-500 text-xs font-normal leading-[14.15px] truncate'>
                4 Days Left
              </div>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );

  return (
    <div className='group min-w-[200px] flex-1 py-4'>
      <div
        className={`flex w-full h-full justify-center text-center rounded-[7.07px] ${
          isSelected && 'border-2 rounded border-solid border-softBlack'
        }`}
        style={getItemColors()}
        disabled={
          !isAllocateInventory
            ? isDisabled
            : inventoryItem.status.toLowerCase() === 'open'
            ? false
            : true
        }
        onClick={() => onSelect(inventoryItem)}
      >
        {isAllocateInventory && inventoryItem.status.toLowerCase() !== 'open' ? (
          <Tooltip
            title={
              <h1
                style={{
                  color: 'rgba(255, 255, 255, 1)',
                  fontSize: 14,
                  paddingTop: 4,
                }}
              >
                This {type?.toLowerCase()} is already reserved by another buyer
              </h1>
            }
            arrow
            placement='bottom'
          >
            {buttonContent(inventoryItem)}
          </Tooltip>
        ) : (
          buttonContent(inventoryItem)
        )}
      </div>
    </div>
  );
};

export default InventoryItemV2;
