/* eslint-disable */
import React, { useEffect, useState, useRef, useMemo } from 'react'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './styles/richTextEditor.css'

// import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'

import { getToolbarOptions, attachmentIcon } from './config/config'
import { EMAIL_ATTACHMENT_SVG } from 'utils/emailUtils'
import { FileText, X } from 'lucide-react'
import toast from 'react-hot-toast'

const MAX_ATTACHMENT_SIZE = 25 * 1024 * 1024 // in bytes

export function AttachmentUI({ fileAttachments = [], onDeleteAttachment = (file) => { }, containerClassName = '' }) {
  if (fileAttachments.length === 0) return null

  const totalSize = useMemo(() => {
    return fileAttachments?.reduce(
      (acc, file) => acc + file.size,
      0
    ) ?? 0
  }, [fileAttachments])

  const getFileExtension = (filename) => {
    return filename.split(".").pop()?.toLowerCase() || ""
  }

  const getFileIcon = (file) => {
    const extension = getFileExtension(file.name)
    if (file.type && EMAIL_ATTACHMENT_SVG[file.type]) {
      return EMAIL_ATTACHMENT_SVG[file.type]
    }
    if (EMAIL_ATTACHMENT_SVG[extension]) {
      return EMAIL_ATTACHMENT_SVG[extension]
    }
    return EMAIL_ATTACHMENT_SVG.default
  }

  const formatFileSize = (bytes) => {
    if (!bytes) return ""
    const units = ["B", "KB", "MB", "GB"]
    let size = bytes
    let unitIndex = 0
    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024
      unitIndex++
    }
    return `${size.toFixed(1)} ${units[unitIndex]}`
  }

  return (
    <div className={containerClassName}>
      <p className="mb-2 text-zinc-600 text-sm font-semibold uppercase tracking-wider">
        Attachments ({fileAttachments.length}) - {(totalSize / (1024 * 1024)).toFixed(1)}MB
        <span className='lowercase font-medium mx-1'>used out of</span>
        {MAX_ATTACHMENT_SIZE / (1024 * 1024)}MB
      </p>
      <div className="w-full flex flex-wrap gap-2">
        {fileAttachments.map((file, index) => {
          const FileIcon = getFileIcon(file)
          const extension = getFileExtension(file.name)

          return (
            <div
              key={index}
              className="flex items-center gap-2 bg-zinc-50/50 hover:bg-zinc-50 border border-zinc-200 rounded-lg px-2.5 py-2 group transition-all"
            >
              <div className="flex-shrink-0 text-zinc-600">
                {typeof FileIcon === "string" ? <FileText size={28} /> : <FileIcon className='w-7 h-7' />}
              </div>
              <div className="flex flex-col min-w-0">
                <a
                  href={file ? URL.createObjectURL(file) : "#"}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="max-w-[220px] text-sm font-medium text-zinc-800 overflow-hidden truncate hover:text-zinc-900 cursor-pointer transition-all"
                  title={file.name}
                >
                  {file.name}
                </a>
                <span className="text-xs text-zinc-500">
                  {extension.toUpperCase()} {file.size ? `• ${formatFileSize(file.size)}` : ""}
                </span>
              </div>
              <button
                onClick={() => onDeleteAttachment(file)}
                className="ml-1 flex items-center justify-center text-zinc-400 hover:text-red-500 hover:bg-zinc-300 rounded-full p-1 transition-colors"
                aria-label={`Remove ${file.name}`}
              >
                <X size={14} strokeWidth={2.5} />
              </button>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const RichTextField = ({
  text,
  onChangeText,
  placeholder = '',
  hasFileAttachments = true,
  onChangeFileAttachments = null,
  editorRef,
  customModules = [],
  height = '560px'
}) => {
  const [fileAttachments, setFileAttachments] = useState([])
  const fileInputRef = useRef(null)

  const handleFileAttachment = () => {
    const fileInput = fileInputRef.current
    fileInput.click()
  }

  const handleFileSelect = () => {
    const fileAttachmentsTemp = []
    const fileInput = fileInputRef.current
    const currentFileAttachments = [...fileAttachments]

    let totalSize = currentFileAttachments?.reduce(
      (acc, file) => acc + file.size,
      0
    ) ?? 0

    for (let i = 0; i < fileInput.files.length; ++i) {
      const file = fileInput.files.item(i)
      if (totalSize + file.size > MAX_ATTACHMENT_SIZE) {
        toast.error(`File size (${(file.size / (1024 * 1024)).toFixed(1)}MB) exceeds the 25 MB limit. Please choose a smaller file.`)
        fileInputRef.current.value = ''
        return
      }
      totalSize += file.size
      fileAttachmentsTemp.push(file)
    }

    setFileAttachments([...currentFileAttachments, ...fileAttachmentsTemp])
    fileInputRef.current.value = ''
  }

  const handleFileAttachmentDelete = (file) => {
    setFileAttachments(fileAttachments.filter((fileTemp) => fileTemp !== file))
    fileInputRef.current.value = ''
  }

  if (hasFileAttachments) {
    const icons = ReactQuill.Quill.import('ui/icons')
    icons.attachment = attachmentIcon
  }

  const [modules] = useState(
    hasFileAttachments
      ? {
        toolbar: {
          container: getToolbarOptions(hasFileAttachments),
          handlers: {
            attachment: handleFileAttachment
          }
        }
      }
      : {
        toolbar: {
          container: getToolbarOptions(hasFileAttachments)
        }
      }
  )

  useEffect(() => {
    onChangeFileAttachments && onChangeFileAttachments(fileAttachments)
  }, [fileAttachments])

  return (
    <>
      <ReactQuill
        ref={editorRef}
        theme='snow'
        className='border-black'
        value={text}
        modules={
          customModules?.length > 0
            ? {
              toolbar: {
                container: customModules
              }
            }
            : modules
        }
        onChange={(html) => {
          onChangeText && onChangeText(html)
        }}
        style={{ background: 'white', height }}
        placeholder={placeholder}
      />
      <input
        type={'file'}
        className='hidden'
        ref={fileInputRef}
        onChange={handleFileSelect}
      />
      {hasFileAttachments && (
        <AttachmentUI
          containerClassName='mt-13 pb-6'
          fileAttachments={fileAttachments}
          onDeleteAttachment={handleFileAttachmentDelete}
        />
        // <div className=''>
        //   {fileAttachments?.length > 0 && (
        //     <p
        //       className='mb-1 text-softBlack70 text-sm font-medium uppercase'
        //       style={{ letterSpacing: '0.16px' }}
        //     >
        //       ATTACHMENTS
        //     </p>
        //   )}
        //   <div className='w-full flex gap-2 flex-wrap'>
        //     {fileAttachments?.map((file, index) => (
        //       <div
        //         key={index}
        //         // style={{ color: 'blue' }}
        //         className='flex gap-1.5 flex-wrap bg-zinc-200 rounded-md px-2 py-1'
        //       >
        //         <a
        //           href='' // open on new tab
        //           target='_blank'
        //           className='max-w-[250px] overflow-hidden truncate hover:text-black cursor-pointer transition-all'
        //         >
        //           {file.name}
        //         </a>
        //         <div onClick={() => handleFileAttachmentDelete(file)}>
        //           <CrossIcon
        //             style={{ width: 15, height: 23 }}
        //             className='hover:cursor-pointer'
        //           />
        //         </div>
        //       </div>
        //     ))}
        //   </div>
        // </div>
      )}
    </>
  )
}

export default RichTextField
