// @ts-nocheck
/* eslint-disable */
import React from "react";

const buttonVariants = {
  variant: {
    default: "bg-zinc-900 text-zinc-50 hover:bg-zinc-800 active:bg-zinc-950",
    outline: "border border-zinc-200 bg-transparent hover:bg-zinc-100 text-zinc-900",
    secondary: "bg-zinc-100 text-zinc-900 hover:bg-zinc-200",
    ghost: "hover:bg-zinc-100 text-zinc-900",
    link: "text-zinc-900 underline-offset-4 hover:underline",
    white: "border border-zinc-200 bg-white hover:bg-zinc-100 text-zinc-900",
    destructive: "bg-red-500 text-white hover:bg-red-600",
  },
  size: {
    default: "h-9 px-4 py-2",
    sm: "h-8 rounded-md px-3 text-xs",
    lg: "h-10 rounded-md px-8",
    icon: "h-9 w-9",
  },
};

const Button = React.forwardRef(
  ({ className = "", variant = "default", size = "default", ...props }, ref) => {
    const baseClasses = "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-zinc-950 disabled:pointer-events-none disabled:opacity-50";
    const variantClasses = buttonVariants.variant[variant] || "";
    const sizeClasses = buttonVariants.size[size] || "";

    const combinedClasses = `${baseClasses} ${variantClasses} ${sizeClasses} ${className}`.trim();

    return (
      <button
        className={combinedClasses}
        ref={ref}
        {...props}
      />
    );
  }
);

Button.displayName = "Button";

export { Button, buttonVariants };