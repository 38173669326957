/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import {
  CheckCheckIcon,
} from "lucide-react";
import moment from "moment";
import DeleteModal from "components/DeleteModal";
import CustomButton from "components/CustomButton";
import { createOrUpdateInventoryNote, deleteInventoryNote, getInventoryNotes } from "store/actions/inventoryNotesActions";

const NotesLists = ({ notesList, setSelectedNote, selectedNote }) => {
  const formatDate = (createdAt) => {
    const currentDay = moment()
    const createdDay = moment(createdAt)
    return currentDay.diff(createdDay, "days") > 1 ? createdDay.format("lll") : createdDay.format("LT")
  }

  return (
    <div className="flex col-span-3 overflow-auto flex-col gap-y-3">
      {notesList.map((note) => {
        const parsedNotes = JSON.parse(note.notes)
        return (
          <React.Fragment key={note._id}>
            <div
              onClick={() => setSelectedNote(note)}
              className="flex flex-col cursor-pointer p-3 rounded-[8px]"
              style={{
                backgroundColor: selectedNote?._id === note._id ? "#F0F0F0" : "#FFFFFF",
              }}
            >
              <div className="font-bold text-[18px]">
                {parsedNotes.title.length > 20 ? `${parsedNotes.title.slice(0, 20)}...` : parsedNotes.title}
              </div>
              <div className="text-[14px]">
                <span className="mr-2">{formatDate(note.createdAt)}</span>
                {parsedNotes.notes.length > 20 ? `${parsedNotes.notes.slice(0, 20)}...` : parsedNotes.notes}
              </div>
            </div>
            <div className="w-full h-[1px] bg-slate-200"></div>
          </React.Fragment>
        )
      })}
    </div>
  )
}

const NotesTab = ({ inventoryId, projectId, createdBy }) => {
  const [loading, setLoading] = useState(true)
  const [deleteToBeNoteId, setDeleteToBeNoteId] = useState(null)
  const [isNewNote, setNewNote] = useState(false)
  const [notesList, setNotesList] = useState([])
  const [isDeleting, setIsDeleting] = useState(false)
  const [saveStatus, setSaveStatus] = useState("")
  const [selectedNote, setSelectedNote] = useState(null)

  useEffect(() => {
    fetchNotes()
  }, [])

  const fetchNotes = async () => {
    setLoading(true)
    try {
      const data = await getInventoryNotes(inventoryId)
      setNotesList(data)
      if (data.length > 0) setSelectedNote(data[0])
    } catch (error) {
      console.error("Error fetching notes:", error)
    } finally {
      setLoading(false)
    }
  }

  const deleteTheNote = async () => {
    if (!deleteToBeNoteId) return

    setIsDeleting(true)
    try {
      await deleteInventoryNote(deleteToBeNoteId)
      setNotesList(notesList.filter((note) => note._id !== deleteToBeNoteId))
      setSelectedNote(null)
      setDeleteToBeNoteId(null)
    } catch (error) {
      console.error("Error deleting note:", error)
    } finally {
      setIsDeleting(false)
    }
  }

  const createNewNote = async () => {
    setNewNote(true)
    setSaveStatus("Saving...")

    const newNote = {
      inventory: inventoryId,
      notes: JSON.stringify({ title: "", notes: "" }),
      project: projectId,
      createdBy
    }

    try {
      const createdNote = await createOrUpdateInventoryNote(newNote)
      setNotesList([createdNote, ...notesList])
      setSelectedNote(createdNote)
      setSaveStatus("Saved")
    } catch (error) {
      console.error("Error creating new note:", error)
      setSaveStatus("Error saving note")
    } finally {
      setNewNote(false)
    }
  }

  const debounce = (func, wait) => {
    let timeout
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout)
        func(...args)
      }
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }

  const typingTimeoutRef = useRef(null)

  const handleInputChange = (e, inputType) => {
    if (selectedNote) {
      const updatedNote = {
        ...selectedNote,
        notes: JSON.stringify({
          ...JSON.parse(selectedNote.notes),
          [inputType]: e.target.value,
        }),
      }
      setSelectedNote(updatedNote)

      clearTimeout(typingTimeoutRef.current)
      setSaveStatus("Saving...")
      typingTimeoutRef.current = setTimeout(() => {
        onSaveNoteDebounced(updatedNote)
      }, 1000)
    }
  }

  const onSaveNote = async (note) => {
    try {
      const updatedNote = await createOrUpdateInventoryNote(note)
      setNotesList(notesList.map((n) => (n._id === updatedNote._id ? updatedNote : n)))
      setSaveStatus("Saved")
    } catch (error) {
      console.error("Error saving note:", error)
      setSaveStatus("Error saving note")
    }
  }

  const onSaveNoteDebounced = debounce(onSaveNote, 500)

  useEffect(() => {
    if (saveStatus === "Saved") {
      const timeoutId = setTimeout(() => {
        setSaveStatus("")
      }, 10000)
      return () => clearTimeout(timeoutId)
    }
  }, [saveStatus])

  if (loading) {
    return (
      <div className="animate-pulse">
        <div className="flex justify-between mb-4">
          <div className="h-8 bg-gray-200 rounded w-1/4"></div>
          <div className="flex space-x-2">
            <div className="h-8 bg-gray-200 rounded w-24"></div>
            <div className="h-8 bg-gray-200 rounded w-24"></div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-3 space-y-2">
            {[...Array(5)].map((_, i) => (
              <div key={i} className="h-16 bg-gray-200 rounded"></div>
            ))}
          </div>
          <div className="col-span-9 space-y-4">
            <div className="h-8 bg-gray-200 rounded w-1/2"></div>
            <div className="h-64 bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex-1 overflow-hidden">
      {notesList.length === 0 ? (
        <div className="flex flex-col justify-center items-center gap-2 p-6 h-full w-full">
          <svg width="30" height="27" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.3417 1.83324L12.3333 0.824902C11.6833 0.174902 10.625 0.174902 9.975 0.824902L7.74167 3.05824L0.5 10.2999V13.6666H3.86667L11.15 6.38324L13.3417 4.19157C14 3.54157 14 2.48324 13.3417 1.83324ZM3.175 11.9999H2.16667V10.9916L9.38333 3.7749L10.3917 4.78324L3.175 11.9999ZM7.16667 13.6666L10.5 10.3332H15.5V13.6666H7.16667Z"
              fill="#323232"
            />
          </svg>
          <div className="font-semibold mt-4">You don't have any notes yet</div>
          <div className="text-sm text-softBlack70 mb-4">Create your first note</div>
          <CustomButton handleClick={createNewNote}>
            <span className="font-semibold px-4 py-2 text-base">Create Note</span>
          </CustomButton>
        </div>
      ) : (
        <div className="h-full flex flex-col gap-4 md:gap-6 overflow-y-auto">
          <div className="w-full flex items-center justify-between">
            <h3 className="text-lg mb-0"></h3>
            <div className="flex items-center gap-x-4">
              <CustomButton variant="danger" handleClick={() => setDeleteToBeNoteId(selectedNote?._id || null)}>
                <span className="font-semibold px-4 py-2 text-base">Delete current note</span>
              </CustomButton>
              <CustomButton handleClick={createNewNote} disabled={isNewNote}>
                <span className="font-semibold px-4 py-2 text-base">Create New Note</span>
              </CustomButton>
            </div>
          </div>

          <div className="grid grid-cols-12 flex-1 relative gap-x-4 col-span-12">
            <div className="overflow-auto rounded-[8px] sticky border py-3 px-2 top-0 col-span-3">
              <NotesLists selectedNote={selectedNote} notesList={notesList} setSelectedNote={setSelectedNote} />
            </div>
            <div className="col-span-9 sticky top-4 md:top-6 max-h-[80vh] border rounded-[8px]">
              <div className="flex-1 relative w-full p-2">
                <div
                  className="absolute font-semibold text-[16px] right-4"
                  style={{
                    color: saveStatus === "Saved" ? "green" : "",
                  }}
                >
                  <div className="flex items-center">
                    {saveStatus === "Saved" && (
                      <span>
                        <CheckCheckIcon className="mr-2" color="green" />
                      </span>
                    )}
                    {saveStatus}
                  </div>
                </div>
                <div className="font-openSans text-softBlack">
                  <input
                    type="text"
                    name="title"
                    value={selectedNote ? JSON.parse(selectedNote.notes).title : ""}
                    className="text-[20px] font-bold bg-transparent border-none focus:ring-0 focus:ring-offset-0 appearance-none"
                    onChange={(e) => handleInputChange(e, "title")}
                    placeholder={"Add Title"}
                  />
                </div>
                <div className="font-openSans text-softBlack">
                  <textarea
                    name="note"
                    className="bg-transparent border-none focus:ring-0 focus:ring-offset-0 appearance-none w-full resize-none overflow-auto"
                    cols={70}
                    value={selectedNote ? JSON.parse(selectedNote.notes).notes : ""}
                    rows={29}
                    placeholder={"Type your note here ..."}
                    onChange={(e) => handleInputChange(e, "notes")}
                  />
                </div>
              </div>
            </div>
          </div>
          <DeleteModal
            title="Confirm deletion?"
            description="Are you sure to delete this note?"
            confirmButtonTitle="Yes"
            cancelButtonTitle="No"
            isOpen={!!deleteToBeNoteId}
            setIsOpen={() => setDeleteToBeNoteId(null)}
            onConfirm={deleteTheNote}
            loading={isDeleting}
            isBtnLoading={isDeleting}
          />
        </div>
      )}
    </div>
  )
}

export {
  NotesLists,
  NotesTab
}